import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import debounce from "lodash.debounce";

export class ClientResizeSystem extends GameplaySystem {
    private gameCanvasRef: HTMLCanvasElement;
    private gameContainerRef: HTMLElement;
    private lastKnownHeight: number;
    private lastKnownWidth: number;

    public constructor() {
        super();

        const gameContainer = document.getElementById("game-container") as HTMLElement;

        this.gameContainerRef = gameContainer;
        this.gameCanvasRef = document.getElementById("game-canvas") as HTMLCanvasElement;
    }

    protected override getSystemName(): string {
        return "Resizer";
    }

    public Initialize(): void {
        this._forceCanvasToMatchContainerSize();

        window.onresize = debounce(this._onGameWindowResized.bind(this), 100);
    }

    private _forceCanvasToMatchContainerSize(): void {
        this.gameCanvasRef.style.width = this._getGameContainerWidth() + "px";
        this.gameCanvasRef.style.height = this._getGameContainerHeight() + "px";
    }

    private _getGameContainerWidth(): number {
        return parseInt(window.getComputedStyle(this.gameContainerRef).width, 10);
    }

    private _getGameContainerHeight(): number {
        return parseInt(window.getComputedStyle(this.gameContainerRef).height, 10);
    }

    private _onGameWindowResized(): void {
        this._forceCanvasToMatchContainerSize();

        if (window.innerHeight !== this.lastKnownHeight || window.innerWidth !== this.lastKnownWidth) {
            this.lastKnownHeight = window.innerHeight;
            this.lastKnownWidth = window.innerWidth;
            Game.EmitEvent("Resizer::GameWindowResizeComplete", {
                newWidth: this.lastKnownWidth,
                newHeight: this.lastKnownHeight
            });
        }
    }

    public override Update(__deltaTimeS: number, __deltaTimeMS: number, __currentTime: Timestamp): void {}

    public Cleanup(): void {
        this.LogInfo("Cleaning up...");
    }
}
