import { TextureLoader } from "three";
import { getNumberOfUniquePlatformMeshes } from "../../shared/data/LevelDataUtils";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { CharacterSkinMetadata } from "../../shared/data/CharacterData";

interface LoadEvent {
    eventName: string;
}

export class ClientAssetLoadingSystem extends GameplaySystem {
    public AssetsFullyLoaded: boolean = false;
    public TextureLoader: TextureLoader = new TextureLoader();

    private readonly requiredEvents: string[] = ["UIReady", "RendererReady", "InputReady", "NetcodeReady", "CollisionReady", "AudioReady", "LevelLoaded", "IdentityReady"];

    private receivedEvents: string[] = [];

    private modelLoader: GLTFLoader;

    private initialLoadComplete: boolean = false;

    private numberOfModelsToLoad = getNumberOfUniquePlatformMeshes() + 3; // HACK: 3 includes: skybox geo, player geo, and geo for their invisible server-side entity I... think?
    private modelsLoaded = 0;

    public constructor() {
        super();

        this.modelLoader = new GLTFLoader();
    }

    public async Initialize(): Promise<void> {
        this.LogInfo("Loading assets!");

        CharacterSkinMetadata.forEach((characterDetails) => {
            this.numberOfModelsToLoad++;
            this.LoadModel(characterDetails.assetPath);
        });

        Game.ListenForEvent("LoadEvent", (event: LoadEvent) => {
            const { eventName } = event;

            // console.log("Got load event");

            if (eventName === "modelLoaded") {
                this.modelsLoaded++;

                // console.log(this.modelsLoaded, this.numberOfModelsToLoad);

                if (this.modelsLoaded >= this.numberOfModelsToLoad) {
                    Game.EmitEvent("LoadEvent", { eventName: "LevelLoaded" });
                }

                this._onLoadProgress();
            } else {
                if (this.receivedEvents.includes(eventName)) {
                    // console.warn("Received duplicate loading event, ignoring...");
                    return;
                }

                this.receivedEvents.push(eventName);

                this._onLoadProgress();
            }
        });

        this.LogInfo("Ready (ish)!");
    }

    private _onLoadProgress(): void {
        // console.log("---");
        // console.warn("On load progress");

        const totalRequired = this.requiredEvents.length + this.numberOfModelsToLoad;

        const loaded = this.receivedEvents.length + this.modelsLoaded;

        const aggregatePercentageLoaded = Math.floor((loaded / totalRequired) * 100);

        // console.log({ totalRequired, loaded, aggregatePercentageLoaded });

        // console.log("aggregatePercentageLoaded", aggregatePercentageLoaded);

        if (aggregatePercentageLoaded >= 85) {
            Game.EmitEvent("Loading::AlmostComplete", aggregatePercentageLoaded);
        } else {
            Game.EmitEvent("Loading::ProgressUpdated", aggregatePercentageLoaded);
        }

        if (aggregatePercentageLoaded >= 100 && this.initialLoadComplete === false) {
            this._onLoadComplete();
        }
    }

    public async LoadModel(modelPath: string): Promise<GLTF> {
        try {
            return new Promise((resolve, reject) => {
                this.modelLoader.load(
                    modelPath,
                    (gltf) => {
                        Game.EmitEvent("LoadEvent", { eventName: "modelLoaded" });

                        resolve(gltf);
                    },
                    (__progress) => {
                        // console.log("Loading model progress:", progress);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        } catch (e) {
            console.error("Caught error loading assets!");
            console.log(e);
            return Promise.reject(e);
        }
    }

    private _onLoadComplete() {
        this.initialLoadComplete = true;
        this.LogInfo("Assets loaded!");
        Game.EmitEvent("UI::FadeOutLoadingScreen");
        Game.EmitEvent("SDK::GameLoadingFinished");
    }

    protected override getSystemName(): string {
        return "Loader";
    }

    public Update(__deltaTimeS: number, __deltaTimeMS: number, __currentTickStartTimestampMs: number): void {}

    public Cleanup(): void {}
}
