import { Config } from "../../shared/Config";
import storage from "local-storage-fallback";

const MAJOR_GAME_VERSION = Config.Game.MAJOR_GAME_VERSION;

export const getLocalStoragePrefix = (version: string) => {
    return `uptogether::${version}::`;
};

export enum LSKeys {
    AuthToken = "authToken",
    AnonymousUserId = "anonymousUserId",
    MusicMuted = "musicMuted",
    SFXMuted = "sfxMuted",
    SkinUnlocks = "skinUnlocks",
    TrailUnlocks = "trailUnlocks"
}

export const localStorageContainsKey = (key: LSKeys): boolean | undefined => {
    try {
        return storage.getItem(`${getLocalStoragePrefix(MAJOR_GAME_VERSION)}${key}`) !== null;
    } catch (e) {
        console.error("Caught LS error:", e);
    }
};

export const saveToLocalStorage = (key: LSKeys, value: string): string | undefined => {
    try {
        storage.setItem(`${getLocalStoragePrefix(MAJOR_GAME_VERSION)}${key}`, value);
        return value;
    } catch (e) {
        console.error("Caught LS error:", e);
    }
};

export const loadFromLocalStorage = (key: LSKeys): string | null => {
    try {
        const retrievedFromStore = storage.getItem(`${getLocalStoragePrefix(MAJOR_GAME_VERSION)}${key}`);
        return retrievedFromStore;
    } catch (e) {
        console.error("Caught LS error:", e);
        return null;
    }
};

export const loadFromOldLocalStorage = (key: LSKeys, version: number): string | null => {
    try {
        const retrievedFromStore = storage.getItem(`${getLocalStoragePrefix(version.toString())}${key}`);
        return retrievedFromStore;
    } catch (e) {
        console.error("Caught LS error:", e);
        return null;
    }
};

export const deleteFromOldLocalStorage = (key: LSKeys, version: number): void => {
    try {
        return storage.removeItem(`${getLocalStoragePrefix(version.toString())}${key}`);
    } catch (e) {
        console.error("Caught LS error:", e);
    }
};

export const deleteFromLocalStorage = (key: LSKeys): void => {
    try {
        return storage.removeItem(`${getLocalStoragePrefix(MAJOR_GAME_VERSION)}${key}`);
    } catch (e) {
        console.error("Caught LS error:", e);
    }
};
