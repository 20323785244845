"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AABB2D = void 0;
class AABB2D {
    constructor(x, y, halfWidth, halfHeight) {
        this.x = x;
        this.y = y;
        this.halfWidth = halfWidth;
        this.halfHeight = halfHeight;
    }
}
exports.AABB2D = AABB2D;
