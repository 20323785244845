import { DebugTeleportCommand, NType, DebugForceCheckpointCommand } from "../../shared/SharedNetcodeSchemas";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import nipplejs from "nipplejs";
import { Screens } from "./ClientUI";
import { HostPortals } from "../ClientTypes";

// These are not arbitrary, these are the values in web engines for left right and middle! do not change!
enum MouseButton {
    Left = 0,
    Middle = 1,
    Right = 2
}

export class ClientInputSystem extends GameplaySystem {
    private keyState: Map<string, boolean> = new Map();
    private mouseState: Map<MouseButton, boolean> = new Map();
    private mouseWheelScrolled: boolean = false;

    private virtualJumpButtonPressed: boolean = false;
    // private joystickForward: boolean = false;
    // private joystickBack: boolean = false;
    // private joystickLeft: boolean = false;
    // private joystickRight: boolean = false;
    private mobileXDirection: number = 0;
    private mobileZDirection: number = 0;
    private mobileJoystickStrengthMovementMultiplier: number = 0;
    private mobileRotationAngle: number = 0;
    public MobilePlayerIsMoving = false;

    private joystickSize: number = 110;
    private joystickHalfSize: number = this.joystickSize / 2;

    private forwardJoystickTolerance: number = 30;
    private rightJoystickTolerance: number = 30;
    private backJoystickTolerance: number = -30;
    private leftJoystickTolerance: number = -30;

    private readonly keysToPreventDefault: string[] = ["Space", "ControlLeft", "ControlRight", "ShiftLeft", "ShiftRight", "AltLeft", "AltRight", "MetaLeft", "MetaRight", "F10", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];

    private readonly buttonInternalCooldownLengthMS: number = 500;
    private readonly buttonsWithInteralCooldowns: string[] = ["KeyF", "KeyM", "F10", "KeyC", "KeyE", "KeyT", "KeyX", "KeyP"];

    private internalCooldowns = new Map<string, number>();

    public constructor() {
        super();

        // disallows right click menu on the game screen
        document.addEventListener("contextmenu", (event) => event.preventDefault());

        // listen for keystrokes, update keyboard state accordingly
        document.addEventListener("keydown", this._updateKeyState.bind(this));
        document.addEventListener("keyup", this._updateKeyState.bind(this));
        // listen for mouse clicks, update mouse state accordingly
        document.addEventListener("mousedown", (event) => this.mouseState.set(event.button, true));
        document.addEventListener("mouseup", (event) => this.mouseState.set(event.button, false));

        document.addEventListener(
            "wheel",
            (event: WheelEvent) => {
                event.preventDefault();
                this.mouseWheelScrolled = true;
            },
            { passive: false }
        );

        window.onblur = () => {
            this._resetAllkeyStates();
        };
    }

    protected override getSystemName(): string {
        return "Input";
    }

    public Initialize(): void {
        if (globalThis.IsMobile) {
            this._initializeVirtualJoystick();
            this._initializeVirtualJumpButton();
        }

        if (window.location.href.includes("uptogether")) {
            window.addEventListener("beforeunload", (e) => {
                e.preventDefault();
            });
        }

        Game.EmitEvent("LoadEvent", { eventName: "InputReady" });
    }

    public Update(__deltaTime: number, __deltaTimeMS: number, __currentTime: Timestamp): void {
        if (this.mouseWheelScrolled) {
            this.mouseWheelScrolled = false;
        }
    }

    public Cleanup(): void {}

    public RightMousePressed(): boolean {
        return this._isMouseButtonPressed(MouseButton.Right);
    }

    public MiddleMousePressed(): boolean {
        return this._isMouseButtonPressed(MouseButton.Middle);
    }

    public Scrolled(): boolean {
        return this.mouseWheelScrolled;
    }

    public IsKeyDown(key: string): boolean {
        return !!this.keyState.get(key);
    }

    public ToggleFullScreen(): void {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }

    public LeftMousePressed(): boolean {
        return this._isMouseButtonPressed(MouseButton.Left);
    }

    // public JoystickIsForward(): boolean {
    //     return this.joystickForward;
    // }

    // public JoystickIsBack(): boolean {
    //     return this.joystickBack;
    // }

    // public JoystickIsLeft(): boolean {
    //     return this.joystickLeft;
    // }

    // public JoystickIsRight(): boolean {
    //     return this.joystickRight;
    // }

    public VirtualJumpIsBeingPressed(): boolean {
        return this.virtualJumpButtonPressed;
    }

    public GetMobileXDirection(): number {
        return this.mobileXDirection;
    }

    public GetMobileZDirection(): number {
        return this.mobileZDirection;
    }

    public GetMobileJoystickStrengthMovementMultiplier(): number {
        return this.mobileJoystickStrengthMovementMultiplier;
    }

    public GetMobileRotationAngle(): number {
        return this.mobileRotationAngle;
    }

    private _resetAllkeyStates(): void {
        this.keyState.forEach((_, key) => {
            this.keyState.set(key, false);
        });
    }

    private _updateKeyState(e: KeyboardEvent) {
        const keyToMutate = e.code;

        // console.log(e.code);

        if (this.keysToPreventDefault.includes(keyToMutate)) {
            e.preventDefault();
        }

        // Check if the key code being pressed is escape
        if (keyToMutate === "Escape") {
            console.info("Pressed esc!");
            // If it is, emit the event to the game to handle
            if (globalThis.IsMobile === false) {
                Game.UI.HideAllScreens();
                Game.UI.CancelPointerLock();
                // console.info("Emitting gameplay stop 1!");
                Game.EmitEvent("SDK::GameplayStop");

                if (Game.Predictor.GetPredictedEntity() !== undefined) {
                    Game.Predictor.GetPredictedEntity().ShouldFireGameplayStartEventOnNextMovement = true;
                }
            }
        }

        if (this.buttonsWithInteralCooldowns.includes(keyToMutate)) {
            if (this.internalCooldowns.has(keyToMutate)) {
                const lastTriggeredInput = this.internalCooldowns.get(keyToMutate);

                if (lastTriggeredInput && Date.now() - lastTriggeredInput < this.buttonInternalCooldownLengthMS) {
                    return;
                }
            }

            if (process.env.NODE_ENV === "development") {
                if (keyToMutate === "KeyT") {
                    console.info("KeyPress Handler for KeyT (debug teleport) fired!");
                    if (Game.Predictor.GetPredictedEntity() === undefined) return;

                    const teleportCommand: DebugTeleportCommand = {
                        ntype: NType.DebugTeleportCommand,
                        shouldTeleport: true
                    };

                    Game.Netcode.SendCommand(teleportCommand);
                    Game.Predictor.GetPredictedEntity().DebugForceTeleport();
                }

                if (keyToMutate === "KeyC") {
                    console.info("KeyPress Handler for KeyC (debug checkpoint) fired!");
                    if (Game.Predictor.GetPredictedEntity() === undefined) return;

                    const forceCheckpointCommand: DebugForceCheckpointCommand = {
                        ntype: NType.DebugForceCheckpointCommand,
                        shouldForceCheckpoint: true
                    };

                    Game.Netcode.SendCommand(forceCheckpointCommand);
                    Game.Predictor.GetPredictedEntity().DebugForceCheckpoint();
                }
            }

            if (keyToMutate === "KeyE") {
                Game.UI.ShowScreen(Screens.EmojiSelector);
            }

            if (keyToMutate === "KeyX") {
                if (Game.Predictor.GetPredictedEntity() === undefined) return;

                if (Game.Predictor.GetPredictedEntity().runInProgress === false) return;

                Game.UI.ShowScreen(Screens.RestartRunWarning);
            }

            if (keyToMutate === "KeyX") {
                if (Game.Predictor.GetPredictedEntity() === undefined) return;

                if (Game.Predictor.GetPredictedEntity().RunHasStarted() === false) return;

                Game.UI.ShowScreen(Screens.RestartRunWarning);
            }

            if (globalThis.HostPortal !== HostPortals.Poki) {
                if (keyToMutate === "F10") {
                    this.ToggleFullScreen();
                }
            }

            if (keyToMutate === "KeyM") {
                Game.Audio.ToggleMusicMute();
            }

            if (keyToMutate === "KeyF") {
                Game.Audio.ToggleSFXMute();
            }

            if (keyToMutate === "KeyP") {
                Game.UI.ShowScreen(Screens.ViewSettings);
            }

            this.internalCooldowns.set(keyToMutate, Date.now());
        }

        this.keyState.set(keyToMutate, e.type === "keydown");
    }

    private _isMouseButtonPressed(button: MouseButton): boolean {
        return !!this.mouseState.get(button);
    }

    private _initializeVirtualJumpButton(): void {
        const jumpButtonContainer = document.querySelector(".jump-button-container") as HTMLElement;

        if (jumpButtonContainer === null) {
            throw new Error("jumpButtonContainer is null! Make sure this is assigned the right ID!");
        } else {
            jumpButtonContainer.style.display = "flex";

            // TODO: Make sure mousedown event works with touch inputs and adjust to right event if not
            jumpButtonContainer.addEventListener("touchstart", () => {
                // console.log(`jump button pressed (touchstart event), current jump button state: ${this.virtualJumpButtonPressed}`);
                this.virtualJumpButtonPressed = true;
            });

            // TODO: Make sure mouseup event works with touch inputs and adjust to right event if not
            //Adding this event to the document itself in case player's held finger comes off the button when finger in lifted
            document.addEventListener("touchend", () => {
                // console.log(`jump button unpressed (touchend event), current jump button state: ${this.virtualJumpButtonPressed}`);
                if (this.virtualJumpButtonPressed) {
                    this.virtualJumpButtonPressed = false;
                }
            });
        }
    }

    private _initializeVirtualJoystick(): void {
        const joystickContainer = document.querySelector(".joystick-container-inner") as HTMLElement;
        // console.log("Initializing Joystick");

        if (joystickContainer === null) {
            throw new Error("Joystick container is null! Please make sure you have assigned it the correct ID");
        } else {
            // joystickContainer.style.display = "flex";

            // console.warn("on joystick create");

            const joystick = nipplejs.create({
                zone: joystickContainer,
                mode: "static",
                size: this.joystickSize,
                dynamicPage: true,
                position: {
                    top: "50%",
                    left: "50%"
                },
                color: "#9f6ac0",
                restOpacity: 1.0
            });

            joystick.on("move", (event, data) => {
                // console.log("joystick move");

                // console.log(event);

                // console.log(data.distance);

                // We want to get a multiplier from 0 to 1 to apply to the players movement speed.
                // That multiplier should be 0 when the joystick is in the center, and 1 when the joystick is at the edge.
                // The joystick has a value above (joystickSize) and a half size (joystickHalfSize). We want to
                // normalize the *distance* property between 0 (joystick at center) and 55 (the current joystick half size value) to 0 and 1.
                // We can do this by dividing the distance by the joystick half size value.
                this.mobileJoystickStrengthMovementMultiplier = data.distance / this.joystickHalfSize;

                // Calculate the movement vector using nipple.angle and nipple.force.
                const angle = data.angle.radian; // Angle in radians
                const force = data.force; // Magnitude of the joystick's movement

                // Calculate the movement vector
                const movementX = Math.cos(angle) * force;
                const movementZ = Math.sin(angle) * force;
                const rotationAngle = Math.atan2(-movementX, movementZ);

                this.mobileXDirection = movementX;
                this.mobileZDirection = movementZ;
                this.mobileRotationAngle = rotationAngle;
                this.MobilePlayerIsMoving = true;
                // Now, you can use the movementX and movementY values for your game movement logic.
                // For example, update the player's position.
            });

            joystick.on("end", () => {
                // console.log("joystick end");

                this.MobilePlayerIsMoving = false;
            });
        }
    }
}
