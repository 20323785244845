import level from "./level_uppity.json";

const { platforms } = level;

const playerSpawnPoint = platforms.find((platform) => platform.uniqueIdentifier === "Spawn");

if (!playerSpawnPoint) {
    throw new Error("Could not find player spawn point in level data!");
}

export const getNumberOfUniquePlatformMeshes = (): number => {
    const uniquePlatformMeshes = new Set<string>();

    for (const platform of platforms) {
        // if (uniquePlatformMeshes.has(platform.assetIdentifier!) === false) {
        //     console.log(platform.assetIdentifier!);
        // }

        if (platform.assetIdentifier !== undefined) {
            uniquePlatformMeshes.add(platform.assetIdentifier!);
        }
    }

    return uniquePlatformMeshes.size;
};

export const playerSpawn = playerSpawnPoint.position;
