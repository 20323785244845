/*************************************************/
/*                                               */
/*  Misc / General                               */
/*                                               */
/*************************************************/

export enum ServerConnectionErrorReasons {
    GameClientOutOfDate = "GameClientOutOfDate",
    InvalidConnectionToken = "InvalidConnectionToken",
    UnexpectedConnectionError = "UnexpectedConnectionError",
    PlayerAlreadyLoggedIn = "PlayerAlreadyLoggedIn"
}

export type uuid = string;

/*************************************************/
/*                                               */
/*  Backend                                      */
/*                                               */
/*************************************************/

export enum UserType {
    Anonymous
}
interface BaseUser {
    _id: uuid;
    username: string;
    createdAt: Date;

    SMG_Stats: {
        logins: number;
        lastLoggedIn: Date;
    };

    UpTogether: {
        routedToServer: string | null;
        personalBest: string | null;
        numberOfCompletions: number;
        timePlayed: number;
        falls: number;
        country: number;
        chosenSkin: number;
        chosenTrail: number;
    };

    PersonalLeaderboardData: {
        pb: string;
        globalRank: string;
        countryRank: string;
        deaths: string;
        runs: string;
        timePlayed: string;
    };
}

export interface AnonymousUser extends BaseUser {
    _id: uuid;
    userType: UserType.Anonymous;
}
