{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "error_screen__server_maybe_full": "सर्वर से कनेक्ट करने में त्रुटि: सर्वर शायद इस समय भरा हुआ हो, कृपया कुछ मिनटों बाद पुनः प्रयास करें!",
    "error_screen__maintenance_mode": "UpTogether.io का अपडेट हो रहा है! कुछ मिनटों में वापस आकर नए चीज़ को आज़माएं 🎉",
    "error_screen__client_out_of_date": "आपका गेम क्लाइंट अपडेट नहीं है! कृपया अपने गेम क्लाइंट को नवीनतम संस्करण में अपडेट करें और पुनः प्रयास करें।",
    "error_screen__invalid_connection": "सर्वर ने आपको मना कर दिया है! कृपया पुनः प्रयास करें।",
    "error_screen__already_logged_in": "आप पहले से ही लॉग इन हैं!",
    "error_screen__unknown_reason": "अज्ञात कनेक्शन त्रुटि :(",
    "error_screen__disconnected_from_server": "सर्वर से डिस्कनेक्ट हो गए! सर्वर अपडेट के लिए जा रहा है। कृपया कुछ मिनटों बाद पुनः प्रयास करें।",
    "heightmarker__to_bottom": "नीचे वापस जाओ!",
    "heightmarker__inspiration_come_far": "देखो, तुमने कितना दूर पहुँच गए हो!",
    "heightmarker__inspiration_keep_going": "आगे बढ़ो!",
    "heightmarker__inspiration_up_up": "ऊपर! ऊपर! ऊपर!",
    "in_game__secret_edward": "नमस्ते एडवर्ड!!",
    "heightmarker__secret_gorillas": "तुम कितने गोरिले ढूंढ सकते हो??",
    "heightmarker__change_character": "कैरेक्टर बदलो",
    "heightmarker__change_name": "नाम बदलो",
    "heightmarker__change_trail": "एक ट्रेल प्राप्त करें!",
    "heightmarker__global_leaderboard": "वैश्विक लीडरबोर्ड",
    "heightmarker__your_stats": "तुम्हारे स्टैट्स",
    "heightmarker__game_stats": "गेम स्टैट्स",
    "heightmarker__tutorial_speed": "गति",
    "heightmarker_tutorial_boost": "बूस्ट!",
    "heightmarker__tutorial_climb": "चढ़ाई",
    "heightmarker__tutorial_the_wall": "दीवार",
    "heightmarker__tutorial_bouncy": "बाउंसी!",
    "in_game__secret_invis_maze": "मेज द्वार के पास, बाईं ओर...",
    "in_game__secret_upside_down_plate": "उलटा हुआ प्लेट से आगे कूदो!",
    "in_game__tutorial_start_timer": "अपने टाइमर को शुरू करने के लिए स्टार्टिंग लाइन पार करें",
    "in_game__tutorial_invis_platforms": "अदृश्य प्लेटफ़ॉर्म्स हैं!",
    "in_game__how_fast_top": "आप शीर्ष तक कितनी तेज़ी से पहुँच सकते हैं?",
    "in_game__no_touchy": "मत छूना!",
    "in_game__tutorial_running": "दौड़ने के लिए बाएं स्टिक का उपयोग करें",
    "in_game__tutorial_tap": "टैप करें",
    "in_game__tutorial_press": "दबाएं",
    "in_game__tutorial_or": "या",
    "in_game__tutorial__to_jump": "जाने के लिए",
    "in_game__tutorial__hold": "पकड़",
    "in_game__tutorial__jump_repeatedly": "बार-बार कूदने के लिए",
    "in_game__tutorial_wall_climb": "दीवारों पर चढ़ने के लिए",
    "in_game__tutorial_while_running": "दौड़ते हुए",
    "in_game__tutorial_drag_move_camera": "कैमरा को मूव करने के लिए स्क्रीन को खींचें",
    "in_game__checkpoint": "चेकपॉइंट!",
    "loading_text__connecting": "सर्वर से कनेक्ट हो रहा है...",
    "loading_text__splines": "स्प्लाइन्स को रेटिकुलेट कर रहे हैं",
    "loading_text__tofu": "टोफू को मरिनेट कर रहे हैं",
    "loading_text__corn": "मक्का को छिल रहे हैं",
    "loading_text__broccoli": "ब्रोकोली को छोटे पेड़ों की तरह करने की पुष्टि कर रहे हैं",
    "loading_text__brussel_sprouts": "ब्रसेल स्प्राउट्स को स्वादिष्ट बनाने की पुष्टि करना",
    "loading_text__secret_sauce": "रहस्यमय सॉस जोड़ना",
    "loading_text__digesting": "पचाना जा रहा है",
    "loading_text__stirring": "लगातार हिलाना",
    "loading_text__fixing_ice_cream": "मैकडॉनल्ड्स में आइसक्रीम मशीन को ठीक कर रहे हैं",
    "loading_text__chopping_broccoli": "ब्रोकोली को काट रहे हैं",
    "loading_text__carmelize_apples": "सेब को कैरेमेलाइज़ कर रहे हैं",
    "loading_text__water_boil": "पानी उबाल रहे हैं",
    "loading_text__filleting_fish": "मछली को फिलेट कर रहे हैं",
    "loading_text__plating": "प्लेटिंग",
    "loading_text__garnishing": "गार्निशिंग",
    "loading_text__moldy_bits": "मोल्डी बिट्स को काट रहे हैं",
    "death_block__almost": "लगभग!",
    "death_block__close_one": "करीब!",
    "death_block__try_again": "फिरसे कोशिश करो!",
    "death_block__roasted": "तुम रोस्ट हो गए हो!",
    "death_block__burnt": "तुम जल गए हो!",
    "death_block__oh_no": "ओह नो!",
    "death_block__grilled": "तुम ग्रिल हो गए हो!",
    "death_block__blanched": "तुम ब्लांच्ड हो गए हो!",
    "death_block__crisp": "तुम कुरकुरे हो गए हो!",
    "death_block__flameed": "तुम फ्लैम्ड हो गए हो!",
    "death_block__order_up": "ऑर्डर रेडी!",
    "death_block__ouch": "आउच!",
    "death_block__good_try": "अच्छा प्रयास!",
    "death_block__baked": "तुम बेक्ड हो गए हो!",
    "death_block__crusty": "तुम कस्टी हो गए हो!",
    "death_block__next_time": "अगली बार!",
    "death_block__toast": "तुम टोस्ट हो गए हो!",
    "death_block__no_chef": "कोई शेफ नहीं!",
    "death_block__singe_left": "बस बाएं में एक बार सिंगे!",
    "death_block__oof": "ओफ!",
    "death_block__fried": "गिर गए और फ्राइड हो गए!",
    "death_block__recipe_ruined": "रेसिपी बिगड़ गई",
    "death_block__sizzling_stop": "एक सिज़लिंग स्टॉप!",
    "death_block__defeated": "शेफ हार गया!",
    "death_block__burnt_out": "जल गए?",
    "death_block__meltdown": "मेल्टडाउन हो रहा है?",
    "falling_death__slippery": "स्लिपरी!",
    "falling_death__gg": "जीजी",
    "falling_death__you_died": "तुम मर गए हो ",
    "falling_death__f": "एफ",
    "falling_death__floor_food": "फ़्लोर फ़ूड!",
    "falling_death__three_second": "तीन सेकंड रूल!",
    "falling_death__floor_seasoning": "मम्म! फ़्लोर सीज़निंग!",
    "falling_death__just_missed": "बस बच गया!",
    "falling_death__rip": "रिप",
    "falling_death__floor_vs_flavor": "फ़्लोर: 1",
    "falling_death__floor_fall": "फ्लोर फेल!",
    "falling_death__saucy_slip": "रसीला स्लिप!",
    "falling_death__fried_fell": "फ्राइड और गिरा!",
    "falling_death__dive": "स्वादहीन डाइव!",
    "falling_death__floor_finish": "फ्लोर फिनिश!",
    "falling_death__dinner_dive": "डिनर डाइव!",
    "falling_death__splat": "स्प्लैट!",
    "falling_death__oops": "ऊप्स",
    "falling_death__greasy_spot": "तुम ने तेलीय स्थान पाया है!",
    "falling_death__barely_missed": "मात्र बच गया! ",
    "falling_death__down_we_go": "नीचे जा रहे हैं",
    "button__play": "चलाएँ!",
    "restart__title": "पुनः आरंभ करें?",
    "restart__are_you_sure": "क्या आप निश्चित हैं कि आप अपनी दौड़ को पुनः आरंभ करना चाहते हैं?",
    "restart__confirm": "हाँ!",
    "restart__restarted": "पुनः आरंभ हो गया...",
    "leaderboard__players": "खिलाड़ी",
    "leaderboard__countries": "देश",
    "leaderboard__falls": "गिरावटें",
    "leaderboard__hours_played": "खेले गए घंटे",
    "leaderboard__got_to_top": "शीर्ष पर पहुंचा",
    "leaderboard__personal_best": "व्यक्तिगत सर्वश्रेष्ठ",
    "leaderboard__global_rank": "वैश्विक रैंक",
    "leaderboard__rank": "रैंक",
    "leaderboard__runs": "रन्स",
    "leaderboard__time_played": "खेले गए समय"
  }
