import { Context, Binary, defineSchema, Schema } from "nengi";

const nengiConfig = new Context();

/*************************************************/
/*                                               */
/*  Top level Ntypes                             */
/*                                               */
/*************************************************/

export enum NType {
    NULL = 0,

    // Commands (client->server)
    InputCommand = 1,
    ChangeSkinCommand,
    EmoteCommand,
    ChangeUsernameCommand,
    DebugTeleportCommand,
    DebugForceCheckpointCommand,
    ParticleTrailCommand,

    // Entities (replicated)
    PlayerEntity,
    PlatformEntity,

    // Messages (server->client)
    IdentityMessage,
    RunDurationUpdatedMessage,
    EmoteMessage,
    RunCompleteMessage,
    // ParticleTrailMessage,
    LeaderboardMessage
}

export interface NTyped {
    ntype: NType;
}

/*************************************************/
/*                                               */
/*  Entity Replication!                          */
/*                                               */
/*************************************************/

/*****************/
/* Player Entity */
/*****************/

const playerEntity: Schema = defineSchema({
    x: { type: Binary.Float64, interp: true },
    y: { type: Binary.Float64, interp: true },
    z: { type: Binary.Float64, interp: true },
    rotX: { type: Binary.Float64, interp: true },
    rotY: { type: Binary.Float64, interp: true },
    rotZ: { type: Binary.Float64, interp: true },
    runInProgress: Binary.Boolean,
    runDurationAcc: Binary.Float64,
    animationSpeedMultiplier: Binary.Float64,
    username: Binary.String,
    country: Binary.UInt16,
    characterSkin: Binary.UInt8,
    characterTrail: Binary.Int8,
    animationState: Binary.String,
    currentState: Binary.String,
    previousState: Binary.String,
    visualModelRotation: Binary.Float64,
    IsGrounded: Binary.Boolean
});

export interface PlayerEntityCreationPayload {
    nid: NetworkEntityId;
    ntype: NType.PlayerEntity;
    x: number;
    y: number;
    z: number;
    rotX: number;
    rotY: number;
    rotZ: number;
    runInProgress: boolean;
    runDurationAcc: number;
    animationSpeedMultiplier: number;
    username: string;
    country: number;
    characterSkin: number;
    characterTrail: number;
    animationState: string;
    currentState: string;
    previousState: string;
    visualModelRotation: number;
    IsGrounded: boolean;
}

nengiConfig.register(NType.PlayerEntity, playerEntity);

/*************************************************/
/*                                               */
/*  Messages (server ---> client)                */
/*                                               */
/*************************************************/

/*****************&***/
/* Leaderboard Msg   */
/*********************/

const leaderboardMessageSchema: Schema = defineSchema({
    leaderboard: Binary.String
});

export interface LeaderboardMessage extends NTyped {
    ntype: NType.LeaderboardMessage;
    leaderboard: string;
}

nengiConfig.register(NType.LeaderboardMessage, leaderboardMessageSchema);

/********************/
/* Identity Message */
/********************/

const identityMessageSchema: Schema = defineSchema({
    myId: Binary.UInt32,
    myX: Binary.Float64,
    myY: Binary.Float64,
    myZ: Binary.Float64
});

export interface IdentityMessage extends NTyped {
    ntype: NType.IdentityMessage;
    myId: number;
    myX: number;
    myY: number;
    myZ: number;
}

nengiConfig.register(NType.IdentityMessage, identityMessageSchema);

/********************************/
/*     Run Complete Message     */
/********************************/

const runCompleteMessage: Schema = defineSchema({
    minutes: Binary.String,
    seconds: Binary.String,
    milliseconds: Binary.String
});

export interface RunCompleteMessage extends NTyped {
    ntype: NType.RunCompleteMessage;
    minutes: string;
    seconds: string;
    milliseconds: string;
}

nengiConfig.register(NType.RunCompleteMessage, runCompleteMessage);

/********************************/
/* Updated Run Duration Message */
/********************************/

const runDurationUpdatedMessage: Schema = defineSchema({
    newRunDuration: Binary.UInt32
});

export interface RunDurationUpdatedMessage extends NTyped {
    ntype: NType.RunDurationUpdatedMessage;
    newRunDuration: number;
}

nengiConfig.register(NType.RunDurationUpdatedMessage, runDurationUpdatedMessage);

/*****************/
/* Emote Message */
/*****************/

const emoteMessage: Schema = defineSchema({
    playerId: Binary.UInt32,
    emote: Binary.UInt8,
    animation: Binary.UInt8
});

export interface EmoteMessage extends NTyped {
    ntype: NType.EmoteMessage;
    playerId: number;
    emote: number;
    animation: number;
}

nengiConfig.register(NType.EmoteMessage, emoteMessage);

/**************************/
/* Particle Trail Message */
/**************************/

// const particleTrailMessage: Schema = defineSchema({
//     playerId: Binary.UInt32,
//     particleTrail: Binary.UInt8
// });

// export interface ParticleTrailMessage extends NTyped {
//     ntype: NType.ParticleTrailMessage;
//     playerId: number;
//     particleTrail: number;
// }

// nengiConfig.register(NType.ParticleTrailMessage, particleTrailMessage);

/*************************************************/
/*                                               */
/*  Commands (client ---> server)                */
/*                                               */
/*************************************************/

/**************/
/* ChangeSkin */
/**************/

const changeSkinCommandSchema = defineSchema({
    newSkin: Binary.UInt8
});

export interface ChangeSkinCommand extends NTyped {
    ntype: NType.ChangeSkinCommand;
    newSkin: number;
}

nengiConfig.register(NType.ChangeSkinCommand, changeSkinCommandSchema);

/*********/
/* Input */
/*********/

const inputCommandSchema = defineSchema({
    forward: Binary.Boolean,
    back: Binary.Boolean,
    left: Binary.Boolean,
    right: Binary.Boolean,
    jump: Binary.Boolean,
    rotX: Binary.Float64,
    rotY: Binary.Float64,
    rotZ: Binary.Float64,
    isMobile: Binary.Boolean,
    playerIsMoving: Binary.Boolean,
    mobileXDirection: Binary.Float64,
    mobileZDirection: Binary.Float64,
    mobileYRotation: Binary.Float64,
    mobileSpeedMultiplier: Binary.Float64,
    visualModelRotation: Binary.Float64,
    wantsToRespawnAtCheckpoint: Binary.Boolean,
    wantsToRestartRun: Binary.Boolean,
    delta: Binary.Float64
});

export interface InputCommand extends NTyped {
    ntype: NType.InputCommand;
    forward: boolean;
    back: boolean;
    left: boolean;
    right: boolean;
    jump: boolean;
    rotX: number;
    rotY: number;
    rotZ: number;
    isMobile: boolean;
    playerIsMoving: boolean;
    mobileXDirection: number;
    mobileZDirection: number;
    mobileYRotation: number;
    mobileSpeedMultiplier: number;
    visualModelRotation: number;
    wantsToRespawnAtCheckpoint: boolean;
    wantsToRestartRun: boolean;
    delta: number;
}

nengiConfig.register(NType.InputCommand, inputCommandSchema);

/******************/
/* Debug Teleport */
/******************/

const debugTeleportCommandSchema = defineSchema({
    shouldTeleport: Binary.Boolean
});

export interface DebugTeleportCommand extends NTyped {
    ntype: NType.DebugTeleportCommand;
    shouldTeleport: boolean;
}

nengiConfig.register(NType.DebugTeleportCommand, debugTeleportCommandSchema);

/**************************/
/* Debug Force Checkpoint */
/**************************/

const debugForceCheckpointCommandSchema = defineSchema({
    shouldForceCheckpoint: Binary.Boolean
});

export interface DebugForceCheckpointCommand extends NTyped {
    ntype: NType.DebugForceCheckpointCommand;
    shouldForceCheckpoint: boolean;
}

nengiConfig.register(NType.DebugForceCheckpointCommand, debugForceCheckpointCommandSchema);

/*********/
/* Emote */
/*********/

const emoteCommandSchema = defineSchema({
    emote: Binary.UInt8,
    animation: Binary.UInt8
});

export interface EmoteCommand extends NTyped {
    ntype: NType.EmoteCommand;
    emote: number;
    animation: number;
}

nengiConfig.register(NType.EmoteCommand, emoteCommandSchema);

/******************/
/* Particle Trail */
/******************/

const particleTrailCommandSchema = defineSchema({
    particleTrail: Binary.Int8
});

export interface ParticleTrailCommand extends NTyped {
    ntype: NType.ParticleTrailCommand;
    particleTrail: number;
}

nengiConfig.register(NType.ParticleTrailCommand, particleTrailCommandSchema);

/*******************/
/* Change Username */
/*******************/

const changeUsernameCommandSchema = defineSchema({
    prefix: Binary.UInt16,
    suffix: Binary.UInt16,
    country: Binary.UInt16
});

export interface ChangeUsernameCommand extends NTyped {
    ntype: NType.ChangeUsernameCommand;
    prefix: number;
    suffix: number;
    country: number;
}

nengiConfig.register(NType.ChangeUsernameCommand, changeUsernameCommandSchema);

/*************************************************/
/*                                               */
/*  Primary Parent Export                        */
/*                                               */
/*************************************************/

export { nengiConfig };
