import { InputCommand, PlayerEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { ClientPlayerPredicted } from "../entities/ClientPlayerPredicted";

interface ReadyToCreatePredictedEntityEvent {
    x: number;
    y: number;
    originalCreationPayload: PlayerEntityCreationPayload;
}

export class ClientPredictor extends GameplaySystem {
    public PredictionReady: boolean = false;
    private initialized: boolean = false;
    private myLocallyPredictedEntity: ClientPlayerPredicted;

    public constructor() {
        super();

        Game.ListenForEvent("Prediction::ReadyToCreatePredictedEntity", this._setupLocallyPredictedPlayer.bind(this));
    }

    private _setupLocallyPredictedPlayer(event: ReadyToCreatePredictedEntityEvent) {
        const { originalCreationPayload } = event;
        this.myLocallyPredictedEntity = new ClientPlayerPredicted(originalCreationPayload);

        // console.log(this.myLocallyPredictedEntity);
        this.Initialize();
    }

    public Initialize(): void {
        // Game.Renderer.AddRenderedEntity(this.myLocallyPredictedEntity);
        // Game.Renderer.StartFollowing(this.myLocallyPredictedEntity.nid, this.myLocallyPredictedEntity);
        this.initialized = true;
        Game.EmitEvent("Prediction::PredictionReady");

        this.PredictionReady = true;
    }

    protected override getSystemName(): string {
        return "CSP";
    }

    public GetPredictedEntity(): ClientPlayerPredicted {
        return this.myLocallyPredictedEntity;
    }

    // public GetPredictedEntityX(): number {
    //     if (this.myLocallyPredictedEntity === undefined) return 0;
    //     return this.myLocallyPredictedEntity.x;
    // }

    // public GetPredictedEntityY(): number {
    //     if (this.myLocallyPredictedEntity === undefined) return 0;
    //     return this.myLocallyPredictedEntity.y;
    // }

    // public LocallyPredictedEntityIsAlive(): boolean {
    //     return Boolean(this.myLocallyPredictedEntity.isAlive);
    // }

    public PredictPlayerInputCommand(command: InputCommand): void {
        if (this.initialized === false) return;
        if (this.myLocallyPredictedEntity === undefined) return;

        // if (globalThis.IsMobile === true) {
        //     this.myLocallyPredictedEntity.UpdateAnimationTimeScale(command.mobileSpeedMultiplier);
        // }

        this.myLocallyPredictedEntity.ProcessInputs(command);
    }

    public Update(deltaTimeS: number, deltaTimeMS: number, currentTime: Timestamp): void {
        this.myLocallyPredictedEntity?.Update(deltaTimeS, deltaTimeMS, currentTime);
    }

    public Cleanup(): void {}
}
