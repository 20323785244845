{
	"platforms": [
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "0",
			"position": [354.51, 697.00, -2.73],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.8", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1",
			"position": [347.33, 699.00, 14.39],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.8", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "2",
			"position": [303.31, 623.78, -26.20],
			"scale": [1.83, 1.83, 1.83],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.04", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "3",
			"position": [313.88, 578.50, 68.35],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "4",
			"position": [316.88, 577.50, 82.24],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.7", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "5",
			"position": [327.38, 577.50, 82.24],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.7", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "6",
			"position": [184.01, 508.00, 20.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.6", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "7",
			"position": [210.01, 542.00, 42.13],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.6", "", ""]

		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "8",
			"position": [279.88, 557.50, 38.74],
			"scale": [7.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "9",
			"position": [316.31, 550.01, 25.41],
			"scale": [2.02, 2.02, 2.02],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "10",
			"position": [310.38, 549.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "11",
			"position": [306.38, 549.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "12",
			"position": [306.38, 549.50, 30.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "13",
			"position": [302.38, 549.50, 30.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "14",
			"position": [302.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "15",
			"position": [298.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "16",
			"position": [294.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "17",
			"position": [290.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "18",
			"position": [286.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "19",
			"position": [282.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "20",
			"position": [278.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "21",
			"position": [196.51, 534.50, -7.95],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.42", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "22",
			"position": [199.51, 534.50, -7.95],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.42", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "23",
			"position": [202.51, 534.50, -7.95],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.42", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "24",
			"position": [205.51, 534.50, -7.95],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.42", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "25",
			"position": [208.51, 534.50, -7.95],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.42", "", ""]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_41",
			"position": [295.62, 664.48, -14.24],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_39",
			"position": [338.38, 572.00, 80.74],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_38",
			"position": [317.71, 564.00, 35.24],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_37",
			"position": [225.38, 545.50, 31.99],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "26",
			"position": [340.76, 696.50, 19.05],
			"scale": [1.50, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "27",
			"position": [335.26, 696.50, 19.05],
			"scale": [1.50, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "28",
			"position": [338.01, 696.50, 22.55],
			"scale": [7.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_42",
			"position": [338.01, 696.50, 20.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "29",
			"position": [289.76, 613.50, -3.70],
			"scale": [4.00, 4.00, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "30",
			"position": [338.51, 692.50, 12.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "31",
			"position": [339.01, 689.50, 3.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "32",
			"position": [331.01, 688.00, -0.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "33",
			"position": [321.01, 684.50, 0.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "34",
			"position": [312.51, 680.00, 0.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "35",
			"position": [304.01, 676.00, 0.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "36",
			"position": [295.51, 672.00, 0.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "37",
			"position": [295.51, 668.00, -7.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "38",
			"position": [296.26, 659.00, -24.20],
			"scale": [8.00, 4.00, 6.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "39",
			"position": [303.51, 655.00, -24.20],
			"scale": [6.50, 4.00, 6.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "40",
			"position": [303.51, 651.00, -15.20],
			"scale": [6.50, 4.00, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "41",
			"position": [303.51, 647.00, -5.95],
			"scale": [6.50, 4.00, 7.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "42",
			"position": [294.01, 643.00, -5.95],
			"scale": [12.50, 4.00, 7.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "43",
			"position": [283.76, 639.00, -5.95],
			"scale": [8.00, 4.00, 7.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "44",
			"position": [283.76, 635.00, -15.20],
			"scale": [8.00, 4.00, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "45",
			"position": [283.76, 631.00, -24.45],
			"scale": [8.00, 4.00, 7.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "46",
			"position": [303.51, 619.50, -15.20],
			"scale": [6.50, 4.00, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "47",
			"position": [294.01, 615.50, -13.20],
			"scale": [12.50, 4.00, 7.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "48",
			"position": [289.76, 611.50, 2.80],
			"scale": [4.00, 4.00, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "49",
			"position": [288.88, 589.50, 41.24],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "50",
			"position": [292.88, 589.50, 50.24],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "51",
			"position": [299.38, 588.50, 55.24],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "52",
			"position": [304.38, 584.50, 61.24],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "53",
			"position": [299.38, 584.50, 71.74],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "54",
			"position": [304.38, 584.50, 82.74],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "55",
			"position": [314.38, 580.00, 87.74],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "56",
			"position": [324.38, 580.00, 82.74],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "57",
			"position": [334.88, 580.00, 87.74],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "58",
			"position": [190.01, 508.00, 15.55],
			"scale": [3.00, 2.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "59",
			"position": [178.01, 508.00, 18.05],
			"scale": [21.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_40",
			"position": [289.76, 611.00, 18.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "60",
			"position": [208.51, 513.00, 8.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "61",
			"position": [208.51, 509.00, 8.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "62",
			"position": [199.01, 508.00, 13.05],
			"scale": [15.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "63",
			"position": [158.01, 512.00, 18.05],
			"scale": [4.00, 6.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "64",
			"position": [158.01, 512.00, 26.80],
			"scale": [4.00, 6.00, 6.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "65",
			"position": [151.76, 512.00, 22.05],
			"scale": [8.50, 6.00, 11.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_35",
			"position": [158.01, 514.00, 22.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "66",
			"position": [357.51, 700.50, 10.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "67",
			"position": [360.01, 698.50, 16.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "68",
			"position": [362.51, 698.50, 10.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "69",
			"position": [357.51, 698.50, 10.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "70",
			"position": [359.51, 696.50, 14.96],
			"scale": [8.00, 2.00, 18.18],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "71",
			"position": [358.01, 700.50, 23.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "72",
			"position": [353.01, 700.50, 23.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "73",
			"position": [345.51, 700.50, 23.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "74",
			"position": [349.01, 698.50, 23.05],
			"scale": [29.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "75",
			"position": [338.01, 696.50, 16.55],
			"scale": [7.00, 2.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "76",
			"position": [331.88, 697.25, 19.74],
			"scale": [0.20, 1.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "77",
			"position": [329.88, 690.50, 20.24],
			"scale": [0.20, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "78",
			"position": [326.88, 683.75, 20.99],
			"scale": [0.20, 2.50, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "79",
			"position": [323.38, 676.00, 21.49],
			"scale": [0.20, 3.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "80",
			"position": [320.38, 667.50, 19.74],
			"scale": [0.20, 4.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "81",
			"position": [317.38, 658.50, 17.74],
			"scale": [0.20, 5.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "82",
			"position": [313.38, 649.75, 14.74],
			"scale": [0.20, 6.50, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "83",
			"position": [309.88, 642.25, 12.74],
			"scale": [0.20, 6.50, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "84",
			"position": [305.38, 634.75, 11.24],
			"scale": [0.20, 6.50, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "85",
			"position": [300.88, 626.75, 11.24],
			"scale": [0.20, 6.50, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "86",
			"position": [296.38, 618.75, 11.24],
			"scale": [0.20, 6.50, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "87",
			"position": [289.76, 610.00, 20.05],
			"scale": [4.00, 4.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "88",
			"position": [289.38, 603.50, 24.74],
			"scale": [4.00, 4.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "89",
			"position": [289.38, 597.00, 30.24],
			"scale": [4.00, 4.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "90",
			"position": [291.38, 590.00, 33.24],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "91",
			"position": [291.38, 586.00, 41.24],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "92",
			"position": [291.38, 586.00, 52.74],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "93",
			"position": [301.88, 585.00, 52.74],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "94",
			"position": [301.88, 581.00, 63.74],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "95",
			"position": [301.88, 581.00, 74.24],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "96",
			"position": [301.88, 581.00, 85.24],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "97",
			"position": [311.88, 576.50, 85.24],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "98",
			"position": [321.88, 576.50, 85.24],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "99",
			"position": [332.38, 576.50, 85.24],
			"scale": [8.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "100",
			"position": [336.38, 568.50, 75.24],
			"scale": [8.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "101",
			"position": [340.38, 568.50, 71.24],
			"scale": [8.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "102",
			"position": [338.38, 572.50, 67.24],
			"scale": [4.00, 4.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "103",
			"position": [340.38, 568.50, 63.24],
			"scale": [8.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "104",
			"position": [338.38, 564.50, 59.24],
			"scale": [12.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "105",
			"position": [338.38, 568.50, 55.24],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "106",
			"position": [334.38, 568.50, 51.24],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "107",
			"position": [338.38, 564.50, 51.24],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "108",
			"position": [342.38, 564.50, 47.24],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "109",
			"position": [338.38, 564.50, 43.24],
			"scale": [12.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "110",
			"position": [334.38, 568.50, 39.24],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "111",
			"position": [342.38, 568.50, 39.24],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "112",
			"position": [338.38, 568.50, 27.24],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "113",
			"position": [338.38, 564.50, 33.24],
			"scale": [4.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "114",
			"position": [334.38, 564.50, 29.24],
			"scale": [4.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "115",
			"position": [330.38, 568.50, 25.49],
			"scale": [4.00, 4.00, 7.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "116",
			"position": [326.38, 564.50, 28.99],
			"scale": [4.00, 4.00, 7.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "117",
			"position": [308.38, 561.50, 35.99],
			"scale": [4.00, 4.00, 7.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "118",
			"position": [298.88, 561.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "119",
			"position": [289.88, 561.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "120",
			"position": [281.38, 561.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "121",
			"position": [281.38, 557.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "122",
			"position": [279.88, 557.50, 30.74],
			"scale": [7.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "123",
			"position": [274.38, 557.50, 30.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "124",
			"position": [270.38, 557.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "125",
			"position": [274.38, 549.50, 32.74],
			"scale": [4.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "126",
			"position": [270.38, 553.50, 30.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "127",
			"position": [266.38, 549.50, 32.74],
			"scale": [4.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "128",
			"position": [262.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "129",
			"position": [262.38, 549.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "130",
			"position": [258.38, 549.50, 30.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "131",
			"position": [254.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "132",
			"position": [254.38, 545.50, 32.74],
			"scale": [4.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "133",
			"position": [250.38, 545.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "134",
			"position": [246.38, 545.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "135",
			"position": [242.38, 545.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "136",
			"position": [242.38, 549.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "137",
			"position": [238.38, 549.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "138",
			"position": [238.38, 549.50, 34.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "139",
			"position": [234.38, 549.50, 32.74],
			"scale": [4.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "140",
			"position": [234.38, 545.50, 26.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "141",
			"position": [225.38, 543.00, 43.24],
			"scale": [6.00, 2.00, 10.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "142",
			"position": [216.51, 543.00, 52.38],
			"scale": [6.00, 2.00, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "143",
			"position": [208.01, 542.00, 45.13],
			"scale": [6.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "144",
			"position": [196.51, 542.00, 38.13],
			"scale": [6.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "145",
			"position": [194.01, 540.50, 28.05],
			"scale": [6.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "146",
			"position": [191.51, 539.50, 18.05],
			"scale": [6.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "147",
			"position": [191.51, 538.50, 7.55],
			"scale": [6.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "148",
			"position": [191.51, 537.00, -1.95],
			"scale": [6.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "149",
			"position": [202.51, 535.00, -10.95],
			"scale": [28.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "150",
			"position": [213.51, 532.00, -4.45],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "151",
			"position": [213.51, 528.50, 2.05],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "152",
			"position": [205.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "153",
			"position": [203.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "154",
			"position": [201.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "155",
			"position": [199.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "156",
			"position": [197.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "157",
			"position": [195.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "158",
			"position": [193.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "159",
			"position": [193.51, 507.00, 18.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "160",
			"position": [193.51, 511.00, 18.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "161",
			"position": [193.51, 515.00, 18.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "162",
			"position": [193.51, 519.00, 18.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "163",
			"position": [213.51, 508.00, 13.05],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "164",
			"position": [218.26, 508.00, 14.05],
			"scale": [3.50, 2.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "165",
			"position": [217.51, 518.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "166",
			"position": [217.51, 526.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "167",
			"position": [217.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "168",
			"position": [207.76, 508.00, 18.30],
			"scale": [24.50, 2.00, 3.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "169",
			"position": [213.51, 526.00, 9.05],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "170",
			"position": [215.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "171",
			"position": [213.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "172",
			"position": [211.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "173",
			"position": [209.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "174",
			"position": [208.51, 529.00, 24.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "175",
			"position": [208.51, 529.00, 20.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "176",
			"position": [208.51, 529.00, 16.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "177",
			"position": [208.51, 529.00, 12.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "178",
			"position": [208.51, 525.00, 12.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "179",
			"position": [208.51, 521.00, 12.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "180",
			"position": [208.51, 517.00, 12.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "181",
			"position": [208.51, 513.00, 12.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "182",
			"position": [208.51, 509.00, 12.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "183",
			"position": [207.51, 526.00, 25.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "184",
			"position": [203.51, 526.00, 25.05],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "185",
			"position": [167.51, 510.00, 12.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "186",
			"position": [167.51, 518.00, 12.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "187",
			"position": [167.51, 514.00, 12.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "188",
			"position": [167.51, 508.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "189",
			"position": [167.51, 510.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "190",
			"position": [167.51, 512.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "191",
			"position": [193.51, 523.00, 18.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "192",
			"position": [204.01, 517.00, 15.55],
			"scale": [25.00, 20.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "193",
			"position": [207.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "194",
			"position": [191.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "195",
			"position": [189.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "196",
			"position": [187.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "197",
			"position": [185.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "198",
			"position": [183.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "199",
			"position": [181.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "200",
			"position": [179.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "201",
			"position": [177.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "202",
			"position": [175.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "203",
			"position": [173.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "204",
			"position": [171.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "205",
			"position": [169.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "206",
			"position": [167.51, 526.00, 17.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "207",
			"position": [167.51, 526.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "208",
			"position": [167.51, 524.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "209",
			"position": [167.51, 522.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "210",
			"position": [167.51, 520.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "211",
			"position": [167.51, 518.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "212",
			"position": [167.51, 516.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "213",
			"position": [178.51, 517.00, 15.55],
			"scale": [20.00, 20.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "214",
			"position": [164.26, 512.00, 22.05],
			"scale": [8.50, 6.00, 11.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "215",
			"position": [167.51, 514.00, 15.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "216",
			"position": [338.38, 572.50, 85.74],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "217",
			"position": [289.76, 611.00, 11.30],
			"scale": [7.00, 2.00, 7.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "218",
			"position": [335.51, 700.50, 23.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_67",
			"position": [77.09, 1400.80, 383.14],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "219",
			"position": [-34.17, 1315.28, 122.80],
			"scale": [3.00, 4.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "220",
			"position": [76.64, 1399.44, 357.14],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "221",
			"position": [76.64, 1399.44, 353.14],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "pan_004",
			"uniqueIdentifier": "222",
			"position": [74.89, 1395.62, 327.45],
			"scale": [2.80, 2.80, 2.80],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "3", "", ""]

		},
		{
			"assetIdentifier": "pan_004",
			"uniqueIdentifier": "223",
			"position": [74.89, 1377.62, 300.14],
			"scale": [2.80, 2.80, 2.80],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "3", "", ""]

		},
		{
			"assetIdentifier": "pan_004",
			"uniqueIdentifier": "224",
			"position": [-20.96, 1318.55, 89.45],
			"scale": [1.48, 1.48, 1.48],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "225",
			"position": [-21.40, 1317.78, 72.97],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.8", "", ""]

		},
		{
			"assetIdentifier": "toaster_002",
			"uniqueIdentifier": "226",
			"position": [52.39, 1429.13, 491.14],
			"scale": [24.00, 24.00, 24.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "227",
			"position": [52.53, 1413.28, 493.96],
			"scale": [47.00, 2.00, 68.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "228",
			"position": [48.53, 1413.78, 444.71],
			"scale": [8.00, 1.00, 24.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "229",
			"position": [62.78, 1413.78, 428.96],
			"scale": [36.50, 1.00, 7.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "toaster_003",
			"uniqueIdentifier": "230",
			"position": [58.37, 1402.40, 411.63],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "tissue_box_003",
			"uniqueIdentifier": "231",
			"position": [100.13, 1403.78, 393.85],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "232",
			"position": [47.71, 1415.30, 417.15],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [5.30, 2.88, 3.57]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "233",
			"position": [-11.17, 1331.78, 220.30],
			"scale": [3.00, 6.00, 7.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "234",
			"position": [-18.67, 1326.03, 220.30],
			"scale": [3.00, 15.50, 7.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "235",
			"position": [-33.67, 1326.03, 220.30],
			"scale": [3.00, 15.50, 7.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "236",
			"position": [-33.67, 1323.78, 211.55],
			"scale": [3.00, 3.00, 10.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "237",
			"position": [-41.17, 1322.78, 201.55],
			"scale": [3.00, 3.00, 10.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "238",
			"position": [-34.17, 1321.78, 190.55],
			"scale": [3.00, 3.00, 10.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "239",
			"position": [-41.17, 1320.28, 180.05],
			"scale": [3.00, 3.00, 10.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "240",
			"position": [74.78, 1413.28, 415.46],
			"scale": [12.50, 2.00, 12.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "241",
			"position": [113.11, 1425.60, 416.25],
			"scale": [4.33, 4.33, 4.33],
			"rotation": [5.75, 4.12, 0.70]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "242",
			"position": [32.33, 1374.28, 282.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "243",
			"position": [24.83, 1374.28, 280.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "244",
			"position": [14.33, 1371.78, 282.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "245",
			"position": [10.83, 1370.28, 289.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "246",
			"position": [4.33, 1368.78, 289.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "247",
			"position": [-1.17, 1367.78, 283.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "248",
			"position": [-3.17, 1366.78, 277.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "249",
			"position": [1.33, 1365.28, 273.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "250",
			"position": [7.83, 1355.28, 273.30],
			"scale": [3.00, 23.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "251",
			"position": [10.83, 1344.78, 273.30],
			"scale": [3.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "252",
			"position": [15.83, 1344.78, 267.80],
			"scale": [3.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "253",
			"position": [20.83, 1344.78, 261.30],
			"scale": [3.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "254",
			"position": [-6.17, 1334.78, 223.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "255",
			"position": [18.83, 1374.03, 272.80],
			"scale": [3.00, 2.50, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "256",
			"position": [23.83, 1373.28, 262.80],
			"scale": [3.00, 16.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "257",
			"position": [16.33, 1366.28, 258.80],
			"scale": [3.00, 20.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "258",
			"position": [23.83, 1353.78, 254.80],
			"scale": [3.00, 20.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "259",
			"position": [12.83, 1339.28, 254.80],
			"scale": [3.00, 13.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "260",
			"position": [12.83, 1334.28, 248.05],
			"scale": [3.00, 3.00, 10.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "261",
			"position": [7.83, 1334.78, 239.30],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "262",
			"position": [3.33, 1334.78, 235.80],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "263",
			"position": [0.83, 1334.78, 231.30],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "264",
			"position": [-2.67, 1334.78, 227.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "265",
			"position": [-37.42, 1315.28, 127.55],
			"scale": [9.50, 4.00, 6.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "266",
			"position": [-37.42, 1315.28, 113.55],
			"scale": [8.50, 4.00, 6.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "267",
			"position": [-16.67, 1315.28, 14.05],
			"scale": [13.00, 2.00, 0.25],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "268",
			"position": [-7.67, 1315.28, 14.80],
			"scale": [5.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "269",
			"position": [-37.67, 1315.28, 79.55],
			"scale": [4.00, 4.00, 48.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "270",
			"position": [-37.17, 1315.28, 48.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "271",
			"position": [-37.17, 1315.28, 30.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "272",
			"position": [-25.67, 1315.28, 14.80],
			"scale": [5.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "273",
			"position": [-37.17, 1315.28, 23.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "274",
			"position": [-37.17, 1315.28, 39.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "275",
			"position": [-33.17, 1315.28, 14.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "276",
			"position": [73.40, 1415.99, 451.71],
			"scale": [3.89, 3.89, 3.89],
			"rotation": [0.31, 6.18, 0.60]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "277",
			"position": [-36.17, 1322.78, 220.30],
			"scale": [2.00, 2.00, 7.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "278",
			"position": [-37.67, 1317.28, 170.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "279",
			"position": [-43.67, 1317.28, 162.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "280",
			"position": [-31.67, 1317.28, 162.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "281",
			"position": [-37.67, 1317.28, 154.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "282",
			"position": [-43.67, 1317.28, 147.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "283",
			"position": [-31.67, 1317.28, 147.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "284",
			"position": [-37.67, 1317.28, 138.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "285",
			"position": [106.38, 1409.80, 451.95],
			"scale": [6.74, 6.74, 6.74],
			"rotation": [5.83, 5.97, 0.63]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "286",
			"position": [73.03, 1404.28, 386.71],
			"scale": [2.00, 5.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "287",
			"position": [78.78, 1415.03, 413.71],
			"scale": [2.50, 1.50, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "288",
			"position": [70.53, 1411.53, 399.46],
			"scale": [2.00, 1.50, 11.50],
			"rotation": [0.00, 0.00, 3.14]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "289",
			"position": [77.53, 1406.28, 394.71],
			"scale": [2.00, 9.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "290",
			"position": [83.53, 1405.53, 394.71],
			"scale": [2.00, 7.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "291",
			"position": [82.28, 1398.78, 398.16],
			"scale": [52.00, 6.00, 37.37],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "292",
			"position": [77.28, 1398.78, 376.47],
			"scale": [10.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "293",
			"position": [47.33, 1374.28, 286.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "294",
			"position": [67.83, 1377.28, 291.30],
			"scale": [1.50, 1.50, 1.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "295",
			"position": [61.83, 1376.28, 288.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "296",
			"position": [54.83, 1375.28, 286.30],
			"scale": [2.50, 2.50, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "297",
			"position": [-49.34, 1318.28, 60.20],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "298",
			"position": [-49.34, 1319.28, 79.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "299",
			"position": [-31.34, 1318.28, 79.70],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "300",
			"position": [-29.48, 1318.28, 55.53],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "301",
			"position": [-20.19, 1319.28, 79.28],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "302",
			"position": [-41.69, 1319.28, 94.78],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "salt",
			"uniqueIdentifier": "303",
			"position": [71.70, 1414.46, 418.03],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "salt",
			"uniqueIdentifier": "304",
			"position": [63.81, 1402.13, 404.76],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "rolling_pin",
			"uniqueIdentifier": "305",
			"position": [58.35, 1402.21, 399.65],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pot_004",
			"uniqueIdentifier": "306",
			"position": [93.75, 1402.41, 392.87],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "307",
			"position": [84.50, 1401.93, 402.86],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_002",
			"uniqueIdentifier": "308",
			"position": [79.23, 1401.93, 406.18],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_001",
			"uniqueIdentifier": "309",
			"position": [97.38, 1401.93, 384.02],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "310",
			"position": [71.32, 1414.46, 418.48],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "311",
			"position": [-21.40, 1317.78, 60.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "finish_line_001",
			"uniqueIdentifier": "312",
			"position": [76.89, 1405.69, 377.23],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "313",
			"position": [4.83, 1366.78, 273.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "314",
			"position": [7.33, 1370.28, 289.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "315",
			"position": [23.83, 1365.28, 254.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "316",
			"position": [16.33, 1377.78, 258.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "317",
			"position": [-27.67, 1329.78, 215.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "318",
			"position": [-27.67, 1326.78, 215.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "319",
			"position": [-30.67, 1329.78, 215.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "320",
			"position": [-30.67, 1326.78, 215.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "321",
			"position": [-33.67, 1326.78, 215.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "322",
			"position": [-33.67, 1329.78, 215.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "323",
			"position": [-60.57, 1317.78, 73.79],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "324",
			"position": [73.94, 1414.36, 418.27],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_65",
			"position": [20.33, 1344.78, 255.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_66",
			"position": [39.83, 1374.78, 286.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_64",
			"position": [-26.17, 1332.78, 221.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "bowl_004",
			"uniqueIdentifier": "325",
			"position": [90.93, 1402.14, 410.85],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "bowl_001",
			"uniqueIdentifier": "326",
			"position": [61.29, 1402.48, 387.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "bowl_001",
			"uniqueIdentifier": "327",
			"position": [61.29, 1402.48, 391.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "bowl_001",
			"uniqueIdentifier": "328",
			"position": [64.79, 1402.48, 391.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "bowl_001",
			"uniqueIdentifier": "329",
			"position": [64.79, 1402.48, 387.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "baking_tray_001",
			"uniqueIdentifier": "330",
			"position": [102.60, 1401.86, 411.97],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "baking_pan_004",
			"uniqueIdentifier": "331",
			"position": [71.65, 1402.60, 400.93],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "332",
			"position": [383.92, 926.01, 249.75],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "3.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "333",
			"position": [397.22, 925.77, 239.75],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.75", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "334",
			"position": [413.54, 926.21, 239.75],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.75", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "335",
			"position": [407.58, 944.28, 335.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.9", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "336",
			"position": [395.83, 943.78, 345.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "337",
			"position": [405.33, 939.78, 313.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "338",
			"position": [394.33, 939.78, 305.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "339",
			"position": [390.83, 931.78, 290.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.9", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "340",
			"position": [371.02, 931.92, 269.14],
			"scale": [3.28, 3.28, 3.28],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.95", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "341",
			"position": [434.26, 887.00, 205.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "342",
			"position": [442.26, 885.50, 189.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "343",
			"position": [455.76, 883.00, 170.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "344",
			"position": [465.51, 881.00, 136.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "345",
			"position": [463.19, 890.50, 113.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "346",
			"position": [454.69, 890.75, 109.42],
			"scale": [4.00, 0.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "347",
			"position": [454.69, 879.75, 109.42],
			"scale": [4.00, 0.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "348",
			"position": [454.69, 868.25, 109.42],
			"scale": [4.00, 0.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "349",
			"position": [454.69, 856.75, 109.42],
			"scale": [4.00, 0.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "350",
			"position": [454.69, 845.75, 109.42],
			"scale": [4.00, 0.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_52",
			"position": [391.83, 995.28, 362.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_50",
			"position": [348.33, 932.78, 269.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_48",
			"position": [454.51, 882.00, 118.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_49",
			"position": [431.76, 894.50, 242.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "351",
			"position": [448.53, 833.00, 109.96],
			"scale": [8.50, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "352",
			"position": [459.03, 833.00, 109.96],
			"scale": [4.50, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "353",
			"position": [452.78, 833.00, 114.96],
			"scale": [17.00, 4.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "354",
			"position": [399.58, 980.78, 378.55],
			"scale": [6.50, 22.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_51",
			"position": [396.33, 944.78, 358.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "355",
			"position": [385.83, 1016.28, 385.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "356",
			"position": [378.33, 1014.28, 377.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "357",
			"position": [385.83, 1010.78, 371.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "358",
			"position": [386.33, 1003.28, 382.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "359",
			"position": [380.33, 998.78, 374.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "360",
			"position": [454.69, 888.50, 109.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 3.14],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_47",
			"position": [454.78, 834.00, 110.96],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "361",
			"position": [393.33, 1018.28, 383.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "362",
			"position": [378.33, 1015.28, 385.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "363",
			"position": [378.33, 1014.28, 370.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "364",
			"position": [395.33, 1008.28, 371.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "365",
			"position": [395.33, 1004.28, 379.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "366",
			"position": [377.83, 1001.28, 382.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "367",
			"position": [386.83, 996.78, 369.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "368",
			"position": [390.08, 969.78, 366.30],
			"scale": [8.50, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "369",
			"position": [399.58, 971.78, 370.05],
			"scale": [6.50, 4.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "370",
			"position": [400.83, 959.78, 381.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "371",
			"position": [394.33, 962.28, 381.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "372",
			"position": [406.83, 955.78, 378.80],
			"scale": [4.00, 4.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "373",
			"position": [388.33, 966.28, 374.30],
			"scale": [4.00, 4.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "374",
			"position": [396.83, 955.78, 374.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "375",
			"position": [401.83, 949.78, 375.30],
			"scale": [14.00, 8.00, 16.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "376",
			"position": [396.08, 941.78, 371.55],
			"scale": [25.50, 8.00, 23.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "377",
			"position": [396.83, 941.78, 351.55],
			"scale": [8.00, 8.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "378",
			"position": [396.33, 941.78, 337.05],
			"scale": [8.00, 8.00, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "379",
			"position": [396.33, 941.78, 323.55],
			"scale": [8.00, 8.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "380",
			"position": [407.08, 941.78, 325.80],
			"scale": [8.00, 8.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "381",
			"position": [407.08, 939.78, 317.30],
			"scale": [8.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "382",
			"position": [409.33, 939.78, 317.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "383",
			"position": [409.33, 939.78, 313.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "384",
			"position": [394.33, 939.78, 315.30],
			"scale": [4.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "385",
			"position": [394.33, 935.78, 307.30],
			"scale": [4.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "386",
			"position": [394.33, 939.78, 301.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "387",
			"position": [409.33, 939.78, 301.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "388",
			"position": [409.33, 935.78, 301.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "389",
			"position": [409.33, 935.78, 297.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "390",
			"position": [405.33, 935.78, 297.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "391",
			"position": [394.83, 935.78, 290.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "392",
			"position": [398.83, 931.78, 290.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "393",
			"position": [394.83, 931.78, 290.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "394",
			"position": [394.83, 931.78, 281.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "395",
			"position": [408.83, 931.78, 281.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "396",
			"position": [404.83, 931.78, 281.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "397",
			"position": [404.83, 931.78, 271.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "398",
			"position": [404.83, 931.78, 267.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "399",
			"position": [394.83, 931.78, 267.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "400",
			"position": [383.33, 931.78, 271.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "401",
			"position": [359.33, 931.78, 268.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "402",
			"position": [431.76, 917.50, 245.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "403",
			"position": [339.01, 931.73, 268.60],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "404",
			"position": [335.01, 929.73, 263.10],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "405",
			"position": [334.51, 929.73, 255.60],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "406",
			"position": [335.51, 929.73, 249.10],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "rolling_pin",
			"uniqueIdentifier": "407",
			"position": [350.92, 929.65, 245.02],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "rolling_pin",
			"uniqueIdentifier": "408",
			"position": [367.42, 928.15, 245.02],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "rolling_pin",
			"uniqueIdentifier": "409",
			"position": [384.42, 926.65, 245.02],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "rolling_pin",
			"uniqueIdentifier": "410",
			"position": [402.42, 926.65, 245.02],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "411",
			"position": [431.76, 925.50, 245.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "412",
			"position": [431.76, 921.50, 245.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "413",
			"position": [431.76, 913.50, 245.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "414",
			"position": [431.76, 909.50, 245.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "415",
			"position": [431.76, 905.50, 245.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "416",
			"position": [431.76, 901.50, 245.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "417",
			"position": [431.76, 891.00, 232.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "418",
			"position": [431.76, 889.00, 223.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "419",
			"position": [431.76, 889.00, 214.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "420",
			"position": [430.26, 887.00, 205.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "421",
			"position": [428.26, 885.50, 196.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "422",
			"position": [433.26, 885.50, 188.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "423",
			"position": [442.26, 885.50, 185.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "424",
			"position": [451.26, 885.50, 185.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "425",
			"position": [459.76, 884.00, 185.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "426",
			"position": [459.76, 883.00, 178.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "427",
			"position": [459.76, 882.00, 170.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "428",
			"position": [459.76, 881.00, 162.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "429",
			"position": [448.01, 884.50, 153.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "430",
			"position": [457.51, 884.50, 151.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "431",
			"position": [453.01, 884.50, 145.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "432",
			"position": [462.01, 884.50, 142.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "433",
			"position": [449.51, 884.50, 131.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "434",
			"position": [456.51, 884.50, 128.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "435",
			"position": [455.01, 881.00, 149.05],
			"scale": [17.00, 4.00, 15.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "436",
			"position": [455.01, 881.00, 127.05],
			"scale": [17.00, 4.00, 15.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "437",
			"position": [454.69, 877.50, 109.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 3.14],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "438",
			"position": [454.69, 866.00, 109.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 3.14],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "439",
			"position": [454.69, 854.50, 109.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 3.14],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "440",
			"position": [452.78, 833.00, 104.96],
			"scale": [17.00, 4.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "441",
			"position": [454.69, 843.50, 109.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 3.14],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "442",
			"position": [448.01, 884.50, 120.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "443",
			"position": [431.76, 897.50, 245.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "rolling_pin",
			"uniqueIdentifier": "444",
			"position": [420.92, 926.65, 245.02],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "445",
			"position": [341.01, 929.73, 245.10],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "446",
			"position": [409.33, 935.78, 307.30],
			"scale": [4.00, 4.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "447",
			"position": [406.83, 947.78, 365.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "448",
			"position": [399.33, 990.78, 365.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "449",
			"position": [167.83, 1162.78, -21.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "450",
			"position": [185.83, 1162.78, -21.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "pan_004",
			"uniqueIdentifier": "451",
			"position": [8.54, 1315.13, 27.78],
			"scale": [1.45, 1.45, 1.45],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "452",
			"position": [14.33, 1314.28, 26.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "pan_004",
			"uniqueIdentifier": "453",
			"position": [92.34, 1183.91, 29.34],
			"scale": [1.48, 1.48, 1.48],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2", "", ""]

		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "454",
			"position": [111.83, 1173.28, 21.55],
			"scale": [2.00, 12.00, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "455",
			"position": [98.59, 1183.78, 30.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "pan_004",
			"uniqueIdentifier": "456",
			"position": [117.84, 1169.41, 29.34],
			"scale": [1.48, 1.48, 1.48],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "457",
			"position": [121.59, 1168.78, 30.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "458",
			"position": [302.83, 1125.28, -10.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.25", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "459",
			"position": [309.83, 1123.78, -3.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.25", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "460",
			"position": [319.83, 1122.28, -9.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.25", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "461",
			"position": [325.33, 1120.28, -0.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.25", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "462",
			"position": [333.33, 1120.28, -7.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.25", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "463",
			"position": [342.33, 1120.28, -2.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.25", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "464",
			"position": [351.33, 1120.28, -4.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.25", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "465",
			"position": [359.83, 1118.78, -4.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.25", "", ""]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "466",
			"position": [88.09, 1168.78, 40.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "467",
			"position": [79.59, 1168.78, 37.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "468",
			"position": [78.09, 1168.78, 23.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "469",
			"position": [66.09, 1165.80, 14.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "470",
			"position": [90.09, 1165.80, 14.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "471",
			"position": [90.09, 1168.78, 21.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "472",
			"position": [106.59, 1168.78, 25.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "473",
			"position": [126.59, 1168.78, 38.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "474",
			"position": [118.09, 1168.78, 38.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "475",
			"position": [118.09, 1168.78, 24.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_62",
			"position": [12.83, 1243.78, -6.70],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_60",
			"position": [208.83, 1160.28, -10.70],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "476",
			"position": [11.83, 1187.03, 22.80],
			"scale": [4.00, 47.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "477",
			"position": [3.33, 1187.03, 22.80],
			"scale": [5.00, 47.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "478",
			"position": [7.33, 1187.03, 18.30],
			"scale": [13.00, 47.50, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_61",
			"position": [7.83, 1209.78, 23.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "479",
			"position": [41.33, 1269.28, 26.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "480",
			"position": [41.33, 1269.28, 11.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "481",
			"position": [41.33, 1269.28, 23.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "482",
			"position": [41.33, 1269.28, 20.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "483",
			"position": [41.33, 1269.28, 17.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "484",
			"position": [41.33, 1269.28, 14.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "485",
			"position": [39.58, 1308.53, 18.80],
			"scale": [2.50, 11.50, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "486",
			"position": [40.58, 1297.03, 18.80],
			"scale": [1.50, 11.50, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "487",
			"position": [41.08, 1285.53, 18.80],
			"scale": [2.50, 11.50, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "488",
			"position": [37.83, 1255.53, 17.80],
			"scale": [4.00, 11.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "489",
			"position": [33.83, 1251.78, 17.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "490",
			"position": [7.33, 1195.78, 8.30],
			"scale": [13.00, 65.00, 15.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "491",
			"position": [193.83, 1162.78, -11.20],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "492",
			"position": [237.83, 1150.78, -11.20],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "493",
			"position": [5.83, 1318.28, 18.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "494",
			"position": [5.83, 1318.28, 22.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "495",
			"position": [14.33, 1318.28, 22.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "496",
			"position": [22.83, 1318.28, 18.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "497",
			"position": [5.83, 1314.28, 18.80],
			"scale": [4.00, 4.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "498",
			"position": [14.33, 1314.28, 18.80],
			"scale": [4.00, 4.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "499",
			"position": [22.83, 1314.28, 18.80],
			"scale": [4.00, 4.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "500",
			"position": [31.83, 1314.28, 18.80],
			"scale": [4.00, 4.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "501",
			"position": [45.83, 1263.28, 22.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "502",
			"position": [45.83, 1266.03, 18.80],
			"scale": [4.00, 9.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "503",
			"position": [49.83, 1263.28, 18.80],
			"scale": [4.00, 4.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "504",
			"position": [41.83, 1275.28, 18.80],
			"scale": [4.00, 9.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "505",
			"position": [25.83, 1251.28, 18.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "506",
			"position": [18.83, 1250.28, 13.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "507",
			"position": [12.83, 1247.28, 9.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "508",
			"position": [12.83, 1245.78, 1.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "509",
			"position": [12.83, 1240.78, -17.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "510",
			"position": [12.83, 1238.28, -26.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "511",
			"position": [12.83, 1234.78, -37.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "512",
			"position": [1.83, 1232.78, -37.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "513",
			"position": [4.83, 1195.78, -28.70],
			"scale": [10.00, 65.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "514",
			"position": [7.58, 1228.78, -22.20],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "515",
			"position": [7.58, 1228.78, -14.70],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "516",
			"position": [7.58, 1228.78, -7.20],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "517",
			"position": [7.33, 1187.03, 27.80],
			"scale": [13.00, 47.50, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "518",
			"position": [20.33, 1177.53, 35.55],
			"scale": [13.00, 28.50, 39.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "519",
			"position": [53.59, 1168.78, 20.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "520",
			"position": [126.09, 1168.78, 20.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "521",
			"position": [126.09, 1168.78, 46.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "522",
			"position": [74.59, 1168.78, 18.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "523",
			"position": [74.59, 1168.78, 53.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "524",
			"position": [47.59, 1168.78, 26.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "525",
			"position": [50.59, 1168.78, 46.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "526",
			"position": [47.59, 1168.78, 39.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "527",
			"position": [65.09, 1168.78, 39.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "528",
			"position": [65.09, 1168.78, 30.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "529",
			"position": [74.59, 1168.78, 30.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "530",
			"position": [74.59, 1168.78, 46.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "531",
			"position": [93.09, 1168.78, 46.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "532",
			"position": [104.09, 1168.78, 35.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "533",
			"position": [103.09, 1168.78, 49.04],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "534",
			"position": [103.09, 1168.78, 19.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "535",
			"position": [95.59, 1168.78, 30.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "536",
			"position": [124.59, 1168.78, 30.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "537",
			"position": [58.83, 1173.28, 35.55],
			"scale": [2.00, 12.00, 39.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "538",
			"position": [83.83, 1173.28, 35.55],
			"scale": [2.00, 12.00, 39.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "539",
			"position": [111.83, 1173.28, 44.30],
			"scale": [2.00, 12.00, 22.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "540",
			"position": [149.33, 1165.28, 10.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "541",
			"position": [158.33, 1165.28, 4.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "542",
			"position": [158.33, 1165.28, -15.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "543",
			"position": [158.33, 1165.28, -5.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "544",
			"position": [167.83, 1162.78, -11.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "545",
			"position": [167.83, 1162.78, -1.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "546",
			"position": [176.83, 1162.78, -6.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "547",
			"position": [176.83, 1162.78, -26.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "548",
			"position": [176.83, 1162.78, -16.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "549",
			"position": [185.83, 1162.78, -1.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "550",
			"position": [185.83, 1162.78, -11.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "551",
			"position": [201.33, 1162.78, -11.20],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "552",
			"position": [216.33, 1158.28, -12.70],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "553",
			"position": [223.83, 1154.78, -10.70],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "554",
			"position": [240.83, 1148.78, -3.70],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "555",
			"position": [246.83, 1145.78, 3.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "556",
			"position": [255.33, 1141.78, 5.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "557",
			"position": [263.83, 1138.28, 1.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "558",
			"position": [267.33, 1135.28, -7.70],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "559",
			"position": [284.33, 1130.78, -13.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "560",
			"position": [293.83, 1128.78, -6.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "561",
			"position": [275.83, 1131.28, -7.70],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "562",
			"position": [230.83, 1152.78, -11.70],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "563",
			"position": [92.83, 1165.28, 35.55],
			"scale": [106.00, 4.00, 39.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "564",
			"position": [132.83, 1173.28, 35.55],
			"scale": [2.00, 12.00, 39.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "565",
			"position": [117.09, 1168.78, 46.54],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "566",
			"position": [33.33, 1170.53, 35.55],
			"scale": [13.00, 14.50, 39.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "567",
			"position": [7.58, 1226.28, -11.95],
			"scale": [1.00, 4.00, 25.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "568",
			"position": [7.58, 1228.78, -2.70],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "569",
			"position": [1.83, 1230.28, -28.20],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "570",
			"position": [45.83, 1259.28, 18.80],
			"scale": [12.00, 4.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "571",
			"position": [31.83, 1318.28, 14.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_63",
			"position": [-1.17, 1315.28, 15.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "fridge_001",
			"uniqueIdentifier": "572",
			"position": [413.02, 1117.35, 51.98],
			"scale": [0.45, 0.45, 0.45],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "573",
			"position": [385.33, 1103.78, 101.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "574",
			"position": [397.33, 1103.78, 101.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "575",
			"position": [391.33, 1103.78, 101.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "576",
			"position": [391.33, 1103.78, 101.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.55", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "577",
			"position": [385.33, 1103.78, 107.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "578",
			"position": [385.33, 1103.78, 113.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "579",
			"position": [385.33, 1103.78, 119.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "580",
			"position": [385.33, 1103.78, 125.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "581",
			"position": [385.33, 1103.78, 131.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "582",
			"position": [385.33, 1103.78, 137.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "583",
			"position": [385.33, 1103.78, 143.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "584",
			"position": [385.33, 1103.78, 149.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "585",
			"position": [385.33, 1103.78, 155.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "4.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "586",
			"position": [385.33, 1103.78, 161.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "3.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "587",
			"position": [385.33, 1103.78, 167.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "588",
			"position": [385.33, 1103.78, 173.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "589",
			"position": [385.33, 1103.78, 179.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.75", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "590",
			"position": [391.33, 1103.78, 179.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.55", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "591",
			"position": [397.33, 1103.78, 179.29],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "pan_004",
			"uniqueIdentifier": "592",
			"position": [458.58, 1106.25, 149.22],
			"scale": [1.61, 1.61, 1.61],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "593",
			"position": [458.83, 1104.78, 170.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2.5", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "594",
			"position": [428.23, 1031.10, 324.69],
			"scale": [1.60, 1.60, 1.60],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.3", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "595",
			"position": [428.23, 1031.10, 336.19],
			"scale": [1.60, 1.60, 1.60],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.5", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "596",
			"position": [428.23, 1031.10, 347.69],
			"scale": [1.60, 1.60, 1.60],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.3", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "597",
			"position": [428.23, 1031.10, 357.69],
			"scale": [1.60, 1.60, 1.60],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.3", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "598",
			"position": [428.23, 1031.10, 368.69],
			"scale": [1.60, 1.60, 1.60],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.5", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "599",
			"position": [428.23, 1031.10, 380.19],
			"scale": [1.60, 1.60, 1.60],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.3", "", ""]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "600",
			"position": [461.83, 1061.71, 147.62],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "601",
			"position": [461.83, 1061.71, 192.12],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "602",
			"position": [461.83, 1085.71, 114.12],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "603",
			"position": [461.83, 1085.71, 142.62],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "604",
			"position": [461.83, 1085.71, 182.62],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "605",
			"position": [461.83, 1072.21, 182.62],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "606",
			"position": [461.83, 1065.21, 167.12],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "607",
			"position": [461.83, 1075.71, 167.12],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "608",
			"position": [454.83, 1059.78, 119.55],
			"scale": [12.00, 59.00, 53.49],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_56",
			"position": [411.33, 1105.78, 149.79],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_58",
			"position": [455.33, 1088.28, 90.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_55",
			"position": [438.83, 1033.78, 232.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_54",
			"position": [433.33, 1031.78, 309.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_59",
			"position": [366.83, 1118.28, -2.20],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "609",
			"position": [426.83, 1111.78, 41.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_53",
			"position": [428.33, 1028.78, 391.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "610",
			"position": [372.33, 1116.28, 2.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "611",
			"position": [375.33, 1116.28, 5.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "612",
			"position": [369.33, 1113.28, 10.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "613",
			"position": [372.33, 1113.28, 13.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "614",
			"position": [375.33, 1113.28, 16.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "615",
			"position": [369.33, 1113.28, 22.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "616",
			"position": [372.33, 1113.28, 25.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "617",
			"position": [375.33, 1113.28, 28.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "618",
			"position": [369.33, 1113.28, 35.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "619",
			"position": [372.33, 1113.28, 38.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "620",
			"position": [375.33, 1113.28, 41.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "621",
			"position": [380.83, 1113.28, 35.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "622",
			"position": [383.83, 1113.28, 38.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "623",
			"position": [386.83, 1113.28, 41.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "624",
			"position": [386.33, 1113.28, 27.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "625",
			"position": [389.33, 1113.28, 30.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "626",
			"position": [392.33, 1113.28, 33.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "627",
			"position": [397.33, 1113.28, 41.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "628",
			"position": [400.33, 1113.28, 44.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "629",
			"position": [403.33, 1113.28, 47.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "630",
			"position": [397.33, 1113.28, 27.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "631",
			"position": [400.33, 1113.28, 30.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "632",
			"position": [403.33, 1113.28, 33.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "633",
			"position": [412.83, 1113.28, 45.55],
			"scale": [16.00, 3.00, 20.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "634",
			"position": [434.83, 1110.78, 41.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "635",
			"position": [442.33, 1107.78, 38.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "636",
			"position": [450.83, 1103.78, 38.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "637",
			"position": [456.83, 1101.78, 46.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "638",
			"position": [455.33, 1100.78, 58.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "639",
			"position": [457.33, 1101.78, 54.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "640",
			"position": [450.83, 1101.78, 61.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "641",
			"position": [448.33, 1099.28, 70.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "642",
			"position": [455.83, 1097.28, 75.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "643",
			"position": [462.33, 1093.78, 79.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "644",
			"position": [447.83, 1096.78, 87.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "645",
			"position": [442.83, 1105.28, 87.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "646",
			"position": [454.83, 1082.78, 59.80],
			"scale": [12.00, 13.00, 54.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "647",
			"position": [415.58, 1098.03, 94.05],
			"scale": [3.50, 17.50, 3.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "648",
			"position": [403.08, 1098.03, 103.54],
			"scale": [5.50, 17.50, 3.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "649",
			"position": [403.08, 1098.03, 124.04],
			"scale": [5.50, 17.50, 3.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "650",
			"position": [411.08, 1098.03, 144.04],
			"scale": [5.50, 17.50, 3.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "651",
			"position": [403.08, 1098.03, 163.05],
			"scale": [5.50, 17.50, 3.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "652",
			"position": [416.33, 1098.03, 174.30],
			"scale": [5.00, 17.50, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "653",
			"position": [439.83, 1098.03, 180.80],
			"scale": [5.00, 17.50, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "654",
			"position": [459.33, 1090.78, 106.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "655",
			"position": [456.33, 1090.78, 106.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "656",
			"position": [453.33, 1090.78, 106.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "657",
			"position": [450.33, 1090.78, 106.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "658",
			"position": [450.33, 1090.78, 120.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "659",
			"position": [453.33, 1090.78, 120.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "660",
			"position": [456.33, 1090.78, 120.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "661",
			"position": [459.33, 1090.78, 120.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "662",
			"position": [459.33, 1090.78, 135.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "663",
			"position": [456.33, 1090.78, 135.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "664",
			"position": [453.33, 1090.78, 135.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "665",
			"position": [450.33, 1090.78, 135.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "666",
			"position": [459.33, 1090.78, 151.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "667",
			"position": [456.33, 1090.78, 151.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "668",
			"position": [453.33, 1090.78, 151.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "669",
			"position": [431.58, 1098.03, 97.05],
			"scale": [13.50, 17.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "670",
			"position": [407.08, 1098.03, 97.05],
			"scale": [13.50, 17.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "671",
			"position": [407.08, 1098.03, 117.55],
			"scale": [13.50, 17.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "672",
			"position": [407.08, 1098.03, 137.55],
			"scale": [13.50, 17.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "673",
			"position": [407.08, 1098.03, 156.55],
			"scale": [13.50, 17.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "674",
			"position": [407.08, 1098.03, 177.55],
			"scale": [13.50, 17.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "675",
			"position": [430.58, 1098.03, 177.55],
			"scale": [13.50, 17.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "676",
			"position": [454.83, 1098.03, 177.55],
			"scale": [12.00, 17.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "677",
			"position": [469.33, 1065.28, 129.30],
			"scale": [17.00, 13.00, 27.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "678",
			"position": [469.33, 1036.78, 171.80],
			"scale": [17.00, 13.00, 27.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "679",
			"position": [464.83, 1036.78, 215.30],
			"scale": [8.00, 13.00, 27.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "680",
			"position": [442.08, 1066.78, 139.55],
			"scale": [13.50, 73.00, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "681",
			"position": [454.83, 1059.78, 174.05],
			"scale": [12.00, 59.00, 55.49],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "682",
			"position": [442.08, 1043.03, 159.05],
			"scale": [13.50, 25.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "683",
			"position": [442.08, 1043.03, 178.05],
			"scale": [13.50, 25.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "684",
			"position": [442.08, 1043.03, 197.05],
			"scale": [13.50, 25.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "685",
			"position": [437.08, 1060.03, 220.05],
			"scale": [3.50, 25.50, 3.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "686",
			"position": [454.83, 1055.03, 207.55],
			"scale": [12.00, 49.50, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "687",
			"position": [442.08, 1043.03, 213.55],
			"scale": [13.50, 25.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "688",
			"position": [451.08, 1044.53, 219.05],
			"scale": [4.50, 28.50, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "689",
			"position": [451.33, 1036.78, 226.80],
			"scale": [8.00, 13.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "690",
			"position": [442.08, 1038.78, 221.55],
			"scale": [13.50, 17.00, 6.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "691",
			"position": [439.33, 1036.78, 226.80],
			"scale": [8.00, 13.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "692",
			"position": [441.83, 1032.78, 249.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "693",
			"position": [441.83, 1032.78, 274.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "694",
			"position": [441.83, 1032.78, 293.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "695",
			"position": [435.83, 1032.78, 249.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "696",
			"position": [435.83, 1032.78, 274.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "697",
			"position": [435.83, 1032.78, 293.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "698",
			"position": [438.83, 1035.78, 244.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "699",
			"position": [438.83, 1035.78, 255.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "700",
			"position": [438.83, 1035.78, 267.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "701",
			"position": [438.83, 1035.78, 280.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "702",
			"position": [438.83, 1035.78, 293.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "703",
			"position": [438.83, 1032.28, 273.30],
			"scale": [3.00, 4.00, 77.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "704",
			"position": [428.33, 1032.28, 320.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "705",
			"position": [428.33, 1032.28, 320.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "706",
			"position": [428.33, 1035.28, 331.30],
			"scale": [4.00, 10.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "707",
			"position": [428.33, 1032.28, 342.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "708",
			"position": [428.33, 1032.28, 353.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "709",
			"position": [428.33, 1034.78, 363.80],
			"scale": [4.00, 9.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "710",
			"position": [428.33, 1026.78, 399.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "711",
			"position": [418.83, 1025.28, 399.30],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "712",
			"position": [410.33, 1025.28, 399.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "713",
			"position": [404.33, 1023.78, 404.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "714",
			"position": [398.83, 1022.28, 406.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "715",
			"position": [393.33, 1021.28, 402.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "716",
			"position": [393.33, 1021.28, 396.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "717",
			"position": [393.33, 1019.28, 387.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "718",
			"position": [393.33, 1021.28, 390.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "719",
			"position": [428.33, 1028.28, 347.30],
			"scale": [4.00, 4.00, 77.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "720",
			"position": [428.33, 1032.28, 374.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "721",
			"position": [438.83, 1035.78, 309.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "722",
			"position": [450.33, 1090.78, 151.80],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "723",
			"position": [459.33, 1090.78, 85.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "724",
			"position": [454.28, 828.25, 58.46],
			"scale": [4.00, 0.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "725",
			"position": [454.28, 816.75, 67.46],
			"scale": [4.00, 0.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "726",
			"position": [454.28, 805.25, 76.46],
			"scale": [4.00, 0.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "727",
			"position": [371.01, 699.00, -36.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "728",
			"position": [369.01, 699.00, -36.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "729",
			"position": [364.51, 699.00, -36.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "730",
			"position": [362.51, 699.00, -36.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "731",
			"position": [357.51, 699.00, -36.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "732",
			"position": [355.51, 699.00, -36.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "733",
			"position": [353.51, 699.00, -36.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "734",
			"position": [440.78, 722.25, 61.96],
			"scale": [4.00, 12.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "735",
			"position": [434.96, 733.54, 108.01],
			"scale": [2.18, 2.18, 2.18],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "3.2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "736",
			"position": [435.01, 733.56, 103.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "737",
			"position": [435.01, 733.56, 99.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "738",
			"position": [435.01, 733.56, 95.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "739",
			"position": [435.01, 733.56, 91.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "740",
			"position": [435.01, 733.56, 87.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "741",
			"position": [435.01, 733.56, 83.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "742",
			"position": [435.01, 733.56, 79.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "743",
			"position": [435.01, 733.56, 75.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "744",
			"position": [435.01, 733.56, 71.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "745",
			"position": [435.01, 733.56, 67.42],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "746",
			"position": [439.51, 710.76, 49.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "747",
			"position": [435.51, 710.76, 49.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "748",
			"position": [435.51, 710.76, 45.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "749",
			"position": [431.51, 710.76, 45.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "750",
			"position": [431.51, 710.76, 41.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "751",
			"position": [427.51, 710.76, 41.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "752",
			"position": [427.51, 710.76, 37.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "753",
			"position": [423.51, 710.76, 37.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "754",
			"position": [423.51, 710.76, 33.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "755",
			"position": [419.51, 710.76, 33.37],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_46",
			"position": [420.78, 803.50, 94.96],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_45",
			"position": [439.28, 790.50, 47.46],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_43",
			"position": [376.51, 701.50, -34.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "756",
			"position": [410.28, 740.50, 91.96],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "757",
			"position": [440.78, 718.00, 57.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "758",
			"position": [403.28, 718.00, 57.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "759",
			"position": [408.28, 713.00, 46.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "760",
			"position": [426.28, 787.50, 48.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "761",
			"position": [423.28, 774.00, 74.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "762",
			"position": [426.28, 796.00, 40.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "763",
			"position": [440.28, 831.00, 104.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "764",
			"position": [433.28, 827.00, 104.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "765",
			"position": [435.78, 824.00, 111.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "766",
			"position": [442.78, 820.50, 111.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "767",
			"position": [442.78, 816.50, 102.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "768",
			"position": [447.78, 812.50, 93.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "769",
			"position": [438.78, 809.00, 93.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "770",
			"position": [428.78, 805.00, 93.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "771",
			"position": [412.28, 798.50, 91.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "772",
			"position": [413.28, 798.50, 49.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "773",
			"position": [412.28, 798.50, 58.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "774",
			"position": [415.28, 781.00, 59.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "775",
			"position": [420.28, 800.50, 40.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "776",
			"position": [433.78, 794.00, 40.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "777",
			"position": [412.28, 798.50, 79.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "778",
			"position": [412.28, 798.50, 69.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "779",
			"position": [433.78, 787.50, 51.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "780",
			"position": [421.78, 786.00, 54.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "781",
			"position": [420.28, 776.00, 67.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_44",
			"position": [422.19, 762.50, 107.53],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "782",
			"position": [454.28, 833.00, 92.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "783",
			"position": [454.28, 832.50, 83.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "784",
			"position": [454.28, 831.50, 75.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "785",
			"position": [454.28, 830.00, 66.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "786",
			"position": [454.28, 826.00, 58.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 3.14],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "787",
			"position": [454.28, 816.00, 58.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "788",
			"position": [454.28, 814.50, 67.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 3.14],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "789",
			"position": [454.28, 804.50, 67.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "790",
			"position": [454.28, 793.00, 76.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "791",
			"position": [454.28, 788.00, 84.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "792",
			"position": [454.28, 784.00, 92.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "793",
			"position": [445.78, 779.00, 92.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "794",
			"position": [441.78, 775.00, 100.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "795",
			"position": [434.28, 775.00, 90.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "796",
			"position": [431.28, 772.00, 81.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "797",
			"position": [421.78, 770.00, 81.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "798",
			"position": [421.78, 766.00, 89.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "799",
			"position": [419.51, 709.00, 20.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "800",
			"position": [419.51, 721.00, 20.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "801",
			"position": [419.51, 717.00, 20.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "802",
			"position": [419.51, 713.00, 20.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "803",
			"position": [403.51, 709.00, 20.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "804",
			"position": [403.51, 721.00, 20.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "805",
			"position": [403.51, 717.00, 20.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "806",
			"position": [403.51, 713.00, 20.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "807",
			"position": [421.78, 762.00, 97.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "808",
			"position": [430.76, 756.00, 134.12],
			"scale": [85.50, 4.00, 22.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "809",
			"position": [430.76, 756.00, 63.12],
			"scale": [85.50, 4.00, 80.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "810",
			"position": [422.28, 759.50, 114.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "811",
			"position": [416.28, 755.00, 115.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "812",
			"position": [409.78, 751.00, 115.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "813",
			"position": [409.28, 746.50, 109.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "814",
			"position": [416.28, 742.50, 109.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "815",
			"position": [422.28, 737.50, 109.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "816",
			"position": [448.78, 712.00, 125.96],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "817",
			"position": [415.78, 712.00, 122.96],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "818",
			"position": [399.78, 714.00, 109.96],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "819",
			"position": [399.78, 712.00, 109.96],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "820",
			"position": [406.78, 712.00, 70.96],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "821",
			"position": [399.78, 712.00, 46.96],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "822",
			"position": [447.78, 713.00, 84.96],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "823",
			"position": [417.30, 736.00, 98.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "824",
			"position": [417.30, 736.00, 94.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "825",
			"position": [417.30, 736.00, 90.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "826",
			"position": [417.30, 736.00, 86.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "827",
			"position": [417.30, 736.00, 82.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "828",
			"position": [417.30, 736.00, 78.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "829",
			"position": [417.30, 736.00, 74.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "830",
			"position": [417.30, 736.00, 70.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "831",
			"position": [417.30, 736.00, 66.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "832",
			"position": [427.80, 736.00, 98.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "833",
			"position": [427.80, 736.00, 94.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "834",
			"position": [427.80, 736.00, 90.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "835",
			"position": [427.80, 736.00, 86.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "836",
			"position": [427.80, 736.00, 82.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "837",
			"position": [427.80, 736.00, 78.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "838",
			"position": [427.80, 736.00, 74.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "839",
			"position": [427.80, 736.00, 70.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "840",
			"position": [415.51, 717.00, 24.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "841",
			"position": [415.51, 720.50, 24.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "842",
			"position": [407.51, 720.50, 24.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "843",
			"position": [407.51, 717.00, 24.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "844",
			"position": [407.51, 713.00, 24.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "845",
			"position": [411.51, 738.25, 27.12],
			"scale": [12.00, 31.50, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "846",
			"position": [399.51, 732.50, 27.12],
			"scale": [12.00, 43.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "847",
			"position": [442.76, 732.50, 27.12],
			"scale": [50.50, 43.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "848",
			"position": [430.76, 732.50, 141.12],
			"scale": [74.50, 43.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "849",
			"position": [390.76, 732.50, 84.12],
			"scale": [5.50, 43.00, 122.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "850",
			"position": [470.76, 732.50, 84.12],
			"scale": [5.50, 43.00, 122.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "851",
			"position": [430.76, 709.00, 84.12],
			"scale": [85.50, 4.00, 122.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "852",
			"position": [410.01, 710.00, 14.87],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "853",
			"position": [403.51, 710.00, 4.37],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "854",
			"position": [396.51, 707.00, -4.13],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "855",
			"position": [388.51, 705.00, -13.13],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "856",
			"position": [381.01, 705.00, -22.13],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "857",
			"position": [381.01, 701.50, -28.13],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "858",
			"position": [351.51, 699.00, -33.63],
			"scale": [2.00, 2.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "859",
			"position": [351.51, 696.50, -22.38],
			"scale": [2.00, 2.00, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "860",
			"position": [356.76, 696.50, -17.63],
			"scale": [8.50, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "861",
			"position": [360.01, 696.50, -14.38],
			"scale": [2.00, 2.00, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "862",
			"position": [384.86, 709.00, 16.57],
			"scale": [2.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 3.14]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "863",
			"position": [384.86, 711.50, 19.57],
			"scale": [2.00, 7.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "864",
			"position": [384.86, 716.00, 17.57],
			"scale": [2.00, 2.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "865",
			"position": [384.86, 710.00, 13.57],
			"scale": [2.00, 14.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "866",
			"position": [384.86, 710.00, 8.57],
			"scale": [2.00, 14.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "867",
			"position": [382.86, 717.00, 17.57],
			"scale": [2.00, 2.00, 8.00],
			"rotation": [0.00, 0.00, 3.14]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "868",
			"position": [382.86, 710.00, 18.57],
			"scale": [2.00, 2.00, 6.00],
			"rotation": [0.00, 0.00, 3.14]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "869",
			"position": [382.86, 713.50, 20.57],
			"scale": [2.00, 5.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "870",
			"position": [382.86, 710.00, 14.57],
			"scale": [2.00, 12.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "871",
			"position": [382.86, 711.00, 9.57],
			"scale": [2.00, 14.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "872",
			"position": [356.51, 698.50, 0.37],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "873",
			"position": [359.51, 696.50, -4.38],
			"scale": [8.00, 2.00, 15.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "874",
			"position": [362.51, 698.50, 0.37],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "875",
			"position": [382.86, 711.00, 2.07],
			"scale": [2.00, 14.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "876",
			"position": [382.86, 705.00, 5.82],
			"scale": [2.00, 2.00, 5.50],
			"rotation": [0.00, 0.00, 3.14]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "877",
			"position": [384.86, 704.00, 4.82],
			"scale": [2.00, 2.00, 5.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "878",
			"position": [386.86, 710.00, 3.57],
			"scale": [2.00, 14.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "879",
			"position": [415.51, 713.00, 24.87],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "toaster_001",
			"uniqueIdentifier": "880",
			"position": [422.51, 724.01, 80.05],
			"scale": [21.00, 21.00, 21.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "881",
			"position": [427.80, 736.00, 66.93],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "882",
			"position": [445.78, 712.00, 67.46],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "883",
			"position": [454.28, 803.00, 76.46],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 3.14],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "baking_tray_002",
			"uniqueIdentifier": "884",
			"position": [67.54, 3.50, -46.59],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "885",
			"position": [79.49, 1.00, -20.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "886",
			"position": [67.74, 1.00, -5.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "887",
			"position": [104.24, 1.00, -12.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "888",
			"position": [75.24, 1.00, -34.75],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "889",
			"position": [117.76, 2.00, -52.95],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "3", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "890",
			"position": [117.76, 2.00, 1.80],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.15", "", ""]

		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "891",
			"position": [90.26, 13.13, 2.80],
			"scale": [29.00, 26.25, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "892",
			"position": [90.26, 23.18, 1.55],
			"scale": [28.00, 2.50, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "893",
			"position": [90.26, 19.75, 1.55],
			"scale": [28.00, 0.50, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "894",
			"position": [90.76, 23.18, -52.70],
			"scale": [28.00, 2.50, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "895",
			"position": [90.76, 19.75, -52.70],
			"scale": [28.00, 0.50, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "896",
			"position": [29.01, -2.00, -13.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "897",
			"position": [25.01, -2.00, -13.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "898",
			"position": [21.01, -2.00, -13.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "899",
			"position": [17.01, -2.00, -13.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "900",
			"position": [117.51, 23.18, -23.20],
			"scale": [0.50, 2.50, 27.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "901",
			"position": [118.93, 13.13, -23.18],
			"scale": [2.00, 26.25, 28.76],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "902",
			"position": [90.76, 13.13, -53.95],
			"scale": [29.00, 26.25, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "903",
			"position": [105.01, 17.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "904",
			"position": [105.01, 15.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "905",
			"position": [105.01, 13.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "906",
			"position": [105.01, 11.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "907",
			"position": [76.51, 19.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "908",
			"position": [76.51, 17.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "909",
			"position": [76.51, 15.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "910",
			"position": [76.51, 11.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "911",
			"position": [76.51, 13.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "912",
			"position": [105.01, 9.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "913",
			"position": [105.01, 7.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "914",
			"position": [105.01, 5.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "915",
			"position": [105.01, 3.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "916",
			"position": [105.01, 1.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "917",
			"position": [76.51, 9.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "918",
			"position": [76.51, 7.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "919",
			"position": [76.51, 5.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "920",
			"position": [76.51, 3.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "921",
			"position": [76.51, 1.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "922",
			"position": [105.01, 19.00, -52.70],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 4.71, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "923",
			"position": [91.89, -3.00, -25.58],
			"scale": [55.75, 6.00, 58.75],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "924",
			"position": [53.51, -3.00, -24.70],
			"scale": [21.00, 6.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "925",
			"position": [37.01, -3.00, -13.33],
			"scale": [12.00, 6.00, 34.75],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "926",
			"position": [31.46, 32.25, 178.21],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "927",
			"position": [37.21, 30.25, 179.21],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "928",
			"position": [42.46, 29.00, 182.46],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "929",
			"position": [38.51, 25.75, 186.30],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "930",
			"position": [31.51, 24.75, 186.80],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "931",
			"position": [28.76, 21.50, 180.80],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "932",
			"position": [-19.77, 59.25, 115.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "933",
			"position": [-19.27, 56.25, 110.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "934",
			"position": [-19.02, 53.50, 105.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "935",
			"position": [-15.02, 53.50, 105.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "936",
			"position": [-11.02, 53.50, 105.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "937",
			"position": [-7.02, 53.50, 105.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "938",
			"position": [-3.02, 53.50, 105.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "939",
			"position": [0.98, 53.50, 105.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "940",
			"position": [4.98, 53.50, 105.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "941",
			"position": [8.98, 53.50, 105.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "942",
			"position": [9.01, 4.50, 75.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "943",
			"position": [6.01, 4.50, 75.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "944",
			"position": [23.91, 20.25, 127.75],
			"scale": [19.03, 1.50, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "945",
			"position": [15.64, 20.25, 113.88],
			"scale": [2.50, 1.50, 25.25],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "946",
			"position": [-2.03, 19.00, 108.06],
			"scale": [4.00, 2.00, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "947",
			"position": [-8.53, 13.50, 101.06],
			"scale": [5.00, 2.00, 6.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "948",
			"position": [-7.74, 16.25, 108.55],
			"scale": [5.50, 2.50, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "949",
			"position": [7.76, 7.50, 83.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "950",
			"position": [4.76, 7.50, 83.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "951",
			"position": [9.01, 1.50, 66.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "952",
			"position": [9.01, 4.50, 72.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "953",
			"position": [9.01, 1.50, 63.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "954",
			"position": [9.01, 1.50, 69.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "955",
			"position": [7.76, 7.50, 80.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "956",
			"position": [9.01, 1.50, 60.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "957",
			"position": [4.76, 7.50, 80.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "958",
			"position": [6.01, 4.50, 72.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "959",
			"position": [6.01, 1.50, 69.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "960",
			"position": [6.01, 1.50, 66.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "961",
			"position": [6.01, 1.50, 63.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "962",
			"position": [6.01, 1.50, 60.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "963",
			"position": [-6.49, 3.75, -20.95],
			"scale": [9.00, 7.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "964",
			"position": [6.51, 3.75, -20.95],
			"scale": [9.00, 7.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "chocolate_001",
			"uniqueIdentifier": "965",
			"position": [-14.46, 0.00, 7.26],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "vanilla_001",
			"uniqueIdentifier": "966",
			"position": [-7.40, 0.00, -25.54],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "fridge_001",
			"uniqueIdentifier": "967",
			"position": [7.18, 2.56, -25.90],
			"scale": [0.45, 0.45, 0.45],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "968",
			"position": [-60.72, 8.50, -9.76],
			"scale": [12.00, 2.00, 7.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "969",
			"position": [-77.22, 14.75, -11.77],
			"scale": [4.00, 10.50, 27.53],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "970",
			"position": [-77.97, 8.50, -11.77],
			"scale": [22.50, 2.00, 27.53],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "971",
			"position": [76.01, 17.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "972",
			"position": [76.01, 15.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "973",
			"position": [76.01, 13.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "974",
			"position": [76.01, 11.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "975",
			"position": [104.51, 19.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "976",
			"position": [104.51, 17.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "977",
			"position": [104.51, 15.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "978",
			"position": [104.51, 11.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "979",
			"position": [104.51, 13.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "980",
			"position": [76.01, 9.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "981",
			"position": [76.01, 7.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "982",
			"position": [76.01, 5.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "983",
			"position": [76.01, 3.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "984",
			"position": [76.01, 1.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "985",
			"position": [104.51, 9.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "986",
			"position": [104.51, 7.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "987",
			"position": [104.51, 5.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "988",
			"position": [104.51, 3.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "989",
			"position": [104.51, 1.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "990",
			"position": [76.01, 19.00, 1.55],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 1.57, 0.00]
		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "991",
			"position": [-48.22, 9.00, -6.26],
			"scale": [3.00, 1.00, 13.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "992",
			"position": [-41.22, 9.00, -0.26],
			"scale": [3.00, 1.00, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "993",
			"position": [-34.22, 9.00, -8.51],
			"scale": [3.00, 1.00, 17.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "994",
			"position": [84.39, 37.20, -195.07],
			"scale": [17.50, 10.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "995",
			"position": [94.89, 37.20, -182.32],
			"scale": [3.50, 10.00, 28.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "996",
			"position": [73.89, 37.20, -182.32],
			"scale": [3.50, 10.00, 28.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "997",
			"position": [84.39, 37.20, -166.07],
			"scale": [24.50, 10.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "998",
			"position": [84.39, 30.20, -176.32],
			"scale": [24.50, 4.00, 40.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "999",
			"position": [80.39, 21.70, -156.57],
			"scale": [16.50, 13.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "1000",
			"position": [99.09, 15.38, -143.35],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "salt",
			"uniqueIdentifier": "1001",
			"position": [99.21, 15.38, -143.92],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "toaster_001",
			"uniqueIdentifier": "1002",
			"position": [99.67, 15.82, -141.88],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1003",
			"position": [89.64, 16.20, -155.07],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1004",
			"position": [91.64, 16.20, -155.07],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1005",
			"position": [93.64, 16.20, -155.07],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1006",
			"position": [95.64, 16.20, -155.07],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1007",
			"position": [97.64, 16.20, -155.07],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1008",
			"position": [99.64, 16.20, -155.07],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1009",
			"position": [101.64, 16.20, -155.07],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1010",
			"position": [103.64, 16.20, -155.07],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1011",
			"position": [88.39, 13.20, -142.57],
			"scale": [32.50, 4.00, 27.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1012",
			"position": [65.14, 13.20, -131.07],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1013",
			"position": [55.64, 13.20, -131.07],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1014",
			"position": [47.14, 13.20, -131.07],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1015",
			"position": [47.14, 13.20, -122.57],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1016",
			"position": [47.14, 13.20, -114.57],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1017",
			"position": [118.76, 19.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1018",
			"position": [118.76, 17.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1019",
			"position": [118.76, 15.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1020",
			"position": [118.76, 13.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1021",
			"position": [118.76, 11.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1022",
			"position": [118.76, 1.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1023",
			"position": [118.76, 5.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1024",
			"position": [118.76, 9.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1025",
			"position": [118.76, 3.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1026",
			"position": [118.76, 7.00, -23.20],
			"scale": [2.00, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1027",
			"position": [117.51, 17.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1028",
			"position": [117.51, 15.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1029",
			"position": [117.51, 13.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1030",
			"position": [117.51, 11.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1031",
			"position": [117.51, 19.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1032",
			"position": [117.51, 17.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1033",
			"position": [117.51, 15.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1034",
			"position": [117.51, 11.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1035",
			"position": [117.51, 13.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1036",
			"position": [117.51, 9.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1037",
			"position": [117.51, 7.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1038",
			"position": [117.51, 5.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1039",
			"position": [117.51, 3.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1040",
			"position": [117.51, 1.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1041",
			"position": [117.51, 9.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1042",
			"position": [117.51, 7.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1043",
			"position": [117.51, 5.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1044",
			"position": [117.51, 3.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1045",
			"position": [117.51, 1.00, -37.45],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1046",
			"position": [117.51, 19.75, -23.20],
			"scale": [0.50, 0.50, 28.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1047",
			"position": [117.51, 19.00, -8.95],
			"scale": [0.50, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "1048",
			"position": [47.14, 13.20, -105.57],
			"scale": [4.00, 4.00, 10.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "1049",
			"position": [47.14, 13.20, -91.07],
			"scale": [4.00, 4.00, 10.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1050",
			"position": [-4.49, 2.00, -78.81],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1051",
			"position": [-0.49, 2.00, -78.81],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1052",
			"position": [3.51, 2.00, -78.81],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_6",
			"position": [15.26, 53.00, 149.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_5",
			"position": [21.07, 35.50, 174.65],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_3",
			"position": [-1.11, 20.00, 103.50],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_1",
			"position": [-2.50, -0.98, -6.15],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1053",
			"position": [-4.24, 2.00, -92.95],
			"scale": [13.50, 4.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1054",
			"position": [0.01, 3.75, -40.95],
			"scale": [22.00, 7.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1055",
			"position": [0.01, 1.00, -60.45],
			"scale": [22.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1056",
			"position": [31.01, 29.00, 152.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1057",
			"position": [16.01, 49.00, 171.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1058",
			"position": [15.01, 51.00, 166.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1059",
			"position": [17.01, 51.00, 166.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1060",
			"position": [19.01, 51.00, 166.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1061",
			"position": [16.01, 49.00, 177.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1062",
			"position": [14.51, 49.00, 185.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1063",
			"position": [7.51, 47.00, 185.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1064",
			"position": [1.01, 45.00, 185.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1065",
			"position": [1.01, 43.50, 180.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1066",
			"position": [1.01, 41.50, 173.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1067",
			"position": [7.51, 39.00, 173.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1068",
			"position": [14.51, 36.50, 173.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "bowl_002",
			"uniqueIdentifier": "1069",
			"position": [55.08, -0.93, -55.15],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "start_line",
			"uniqueIdentifier": "1070",
			"position": [-2.68, 3.92, 14.21],
			"scale": [2.50, 2.50, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "toaster_004",
			"uniqueIdentifier": "1071",
			"position": [-18.20, -9.60, 4.38],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1072",
			"position": [-18.32, -11.72, 4.61],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "toaster_003",
			"uniqueIdentifier": "1073",
			"position": [3.22, -9.60, -4.36],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1074",
			"position": [3.18, -11.72, -3.89],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "toaster_002",
			"uniqueIdentifier": "1075",
			"position": [-18.51, -9.60, -4.30],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1076",
			"position": [-18.32, -11.72, -3.89],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "toaster_003",
			"uniqueIdentifier": "1077",
			"position": [3.24, -9.60, 4.91],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1078",
			"position": [3.18, -11.72, 5.11],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1079",
			"position": [-7.49, -14.22, 0.48],
			"scale": [31.00, 2.00, 15.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1080",
			"position": [-28.49, 8.50, -4.70],
			"scale": [3.00, 2.00, 26.50],
			"rotation": [0.00, 0.00, 3.14]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1081",
			"position": [-23.49, 7.00, -14.45],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1082",
			"position": [-17.99, 4.50, -13.45],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "spatula_004",
			"uniqueIdentifier": "1083",
			"position": [60.27, 6.20, -85.14],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 3.14, 0.00]
		},
		{
			"assetIdentifier": "spatula_004",
			"uniqueIdentifier": "1084",
			"position": [53.77, 6.20, -85.14],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 3.14, 0.00]
		},
		{
			"assetIdentifier": "spatula_004",
			"uniqueIdentifier": "1085",
			"position": [47.27, 6.20, -85.14],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 3.14, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1086",
			"position": [60.15, 4.15, -71.10],
			"scale": [6.74, 6.74, 6.74],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1087",
			"position": [47.65, 4.15, -75.60],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1088",
			"position": [40.65, 4.15, -75.60],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1089",
			"position": [40.65, 4.15, -69.10],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1090",
			"position": [35.99, 2.55, -67.02],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1091",
			"position": [-11.99, 1.50, -13.45],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1092",
			"position": [62.41, 4.15, -54.61],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "tissue_box_004",
			"uniqueIdentifier": "1093",
			"position": [-16.62, -1.99, -19.72],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pot_002",
			"uniqueIdentifier": "1094",
			"position": [-8.89, 0.63, -17.92],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 3.14, 3.14]
		},
		{
			"assetIdentifier": "pot_001",
			"uniqueIdentifier": "1095",
			"position": [-8.79, 0.63, -14.60],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 3.14, 3.14]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1096",
			"position": [37.01, 20.00, 173.55],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1097",
			"position": [43.01, 20.00, 171.05],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1098",
			"position": [48.51, 20.00, 166.55],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1099",
			"position": [53.01, 20.00, 160.55],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1100",
			"position": [53.01, 20.00, 153.55],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1101",
			"position": [53.01, 20.00, 146.55],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1102",
			"position": [50.01, 20.00, 139.05],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1103",
			"position": [45.01, 20.00, 132.55],
			"scale": [4.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_7",
			"position": [8.98, 54.00, 112.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_4",
			"position": [36.42, 19.50, 129.25],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1104",
			"position": [-0.99, -3.00, 61.05],
			"scale": [6.00, 6.00, 13.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1105",
			"position": [-0.99, -3.00, 46.05],
			"scale": [6.00, 6.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1106",
			"position": [6.51, -3.00, 55.55],
			"scale": [9.00, 6.00, 24.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_2",
			"position": [-0.99, -1.50, 53.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1107",
			"position": [10.98, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1108",
			"position": [10.98, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1109",
			"position": [10.98, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1110",
			"position": [10.98, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1111",
			"position": [10.98, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1112",
			"position": [8.98, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1113",
			"position": [8.98, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1114",
			"position": [8.98, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1115",
			"position": [8.98, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1116",
			"position": [8.98, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1117",
			"position": [6.98, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1118",
			"position": [6.98, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1119",
			"position": [6.98, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1120",
			"position": [6.98, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1121",
			"position": [6.98, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1122",
			"position": [4.98, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1123",
			"position": [4.98, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1124",
			"position": [4.98, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1125",
			"position": [4.98, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1126",
			"position": [4.98, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1127",
			"position": [2.98, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1128",
			"position": [2.98, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1129",
			"position": [2.98, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1130",
			"position": [2.98, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1131",
			"position": [2.98, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1132",
			"position": [0.98, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1133",
			"position": [0.98, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1134",
			"position": [0.98, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1135",
			"position": [0.98, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1136",
			"position": [0.98, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1137",
			"position": [-1.02, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1138",
			"position": [-1.02, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1139",
			"position": [-1.02, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1140",
			"position": [-1.02, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1141",
			"position": [-1.02, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1142",
			"position": [-3.02, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1143",
			"position": [-3.02, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1144",
			"position": [-3.02, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1145",
			"position": [-3.02, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1146",
			"position": [-3.02, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1147",
			"position": [-9.02, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1148",
			"position": [-9.02, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1149",
			"position": [-9.02, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1150",
			"position": [-9.02, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1151",
			"position": [-9.02, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1152",
			"position": [-7.02, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1153",
			"position": [-7.02, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1154",
			"position": [-7.02, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1155",
			"position": [-7.02, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1156",
			"position": [-7.02, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1157",
			"position": [-5.02, 54.50, 122.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1158",
			"position": [-5.02, 54.50, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1159",
			"position": [-5.02, 54.50, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1160",
			"position": [-5.02, 54.50, 114.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1161",
			"position": [33.01, 18.50, 160.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1162",
			"position": [33.01, 18.50, 154.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1163",
			"position": [31.01, 32.50, 160.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1164",
			"position": [31.01, 32.50, 154.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1165",
			"position": [31.01, 32.50, 149.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1166",
			"position": [31.01, 30.50, 149.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1167",
			"position": [31.01, 30.50, 154.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1168",
			"position": [31.01, 30.50, 160.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1169",
			"position": [-8.99, 55.00, 120.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1170",
			"position": [-0.99, 56.00, 118.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1171",
			"position": [14.26, 54.00, 116.05],
			"scale": [4.50, 3.00, 17.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1172",
			"position": [15.26, 53.25, 129.30],
			"scale": [1.00, 1.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1173",
			"position": [17.01, 51.00, 156.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1174",
			"position": [15.01, 53.00, 164.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1175",
			"position": [15.01, 51.00, 164.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1176",
			"position": [17.01, 51.00, 164.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1177",
			"position": [19.01, 51.00, 164.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1178",
			"position": [27.01, 27.25, 173.55],
			"scale": [4.00, 15.50, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1179",
			"position": [31.01, 20.50, 173.55],
			"scale": [4.00, 2.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1180",
			"position": [31.01, 28.50, 160.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1181",
			"position": [31.01, 28.50, 154.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1182",
			"position": [31.01, 28.50, 149.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1183",
			"position": [31.01, 27.00, 166.55],
			"scale": [2.00, 13.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1184",
			"position": [31.01, 20.50, 160.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1185",
			"position": [31.01, 22.50, 160.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1186",
			"position": [31.01, 24.50, 160.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1187",
			"position": [31.01, 26.50, 160.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1188",
			"position": [31.01, 26.50, 154.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1189",
			"position": [31.01, 24.50, 154.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1190",
			"position": [31.01, 22.50, 154.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1191",
			"position": [31.01, 20.50, 154.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1192",
			"position": [31.01, 26.50, 149.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1193",
			"position": [31.01, 24.50, 149.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1194",
			"position": [31.01, 22.50, 149.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1195",
			"position": [33.01, 20.50, 149.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1196",
			"position": [36.01, 20.50, 144.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1197",
			"position": [36.42, 12.00, 127.75],
			"scale": [6.00, 12.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1198",
			"position": [-1.11, 13.75, 102.50],
			"scale": [4.00, 10.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1199",
			"position": [-2.49, 10.75, 93.55],
			"scale": [7.00, 2.50, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1200",
			"position": [-1.74, -0.50, 33.30],
			"scale": [10.50, 7.00, 7.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1201",
			"position": [-4.24, -3.00, 26.80],
			"scale": [5.50, 6.00, 5.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1202",
			"position": [-2.49, 10.25, 74.55],
			"scale": [7.00, 2.50, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1203",
			"position": [-2.49, 5.75, 62.80],
			"scale": [7.00, 11.50, 9.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1204",
			"position": [-8.49, 3.00, 46.05],
			"scale": [5.00, 6.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1205",
			"position": [8.51, 3.00, 46.05],
			"scale": [5.00, 6.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1206",
			"position": [-7.49, -3.00, 55.55],
			"scale": [7.00, 6.00, 24.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1207",
			"position": [-19.49, -3.00, 0.05],
			"scale": [9.00, 6.00, 22.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1208",
			"position": [23.01, -3.00, -1.95],
			"scale": [16.00, 6.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1209",
			"position": [-2.99, -3.00, 21.30],
			"scale": [8.00, 6.00, 5.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1210",
			"position": [0.01, -3.00, -66.70],
			"scale": [22.00, 6.00, 97.51],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1211",
			"position": [0.01, -3.00, -1.70],
			"scale": [30.00, 6.00, 32.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1212",
			"position": [-1.99, 10.25, 84.55],
			"scale": [7.00, 2.50, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1213",
			"position": [7.64, 20.25, 102.50],
			"scale": [13.50, 1.50, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_004",
			"uniqueIdentifier": "1214",
			"position": [35.99, 20.00, 135.62],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.5", "", ""]

		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1215",
			"position": [31.01, 20.50, 149.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1216",
			"position": [15.26, 53.25, 141.55],
			"scale": [1.00, 1.50, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1217",
			"position": [5.01, 55.00, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1218",
			"position": [-5.02, 54.50, 116.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1219",
			"position": [-41.94, 1376.07, 307.32],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.02, 6.10, 0.58]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1220",
			"position": [136.48, 488.68, 211.14],
			"scale": [8.00, 8.00, 8.00],
			"rotation": [5.24, 5.02, 0.54]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1221",
			"position": [190.24, 428.86, 79.93],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [5.94, 5.79, 0.10]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1222",
			"position": [112.88, 347.31, 155.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [5.64, 5.85, 5.52]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1223",
			"position": [-290.77, 249.21, 112.44],
			"scale": [8.00, 8.00, 8.00],
			"rotation": [0.26, 5.76, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1224",
			"position": [39.18, 3.28, 71.87],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [5.48, 2.10, 4.31]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1225",
			"position": [36.57, 6.40, -69.59],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [6.11, 0.15, 5.57]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1226",
			"position": [-34.10, 7.00, 41.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.40, 0.31, 0.69]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1227",
			"position": [58.31, 9.20, 14.79],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [5.74, 0.02, 0.26]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1228",
			"position": [-31.68, 5.50, -45.68],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [5.89, 0.00, 0.40]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1229",
			"position": [12.42, 54.53, 206.03],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.25, 0.27, 0.07]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1230",
			"position": [-22.49, 58.10, 179.90],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [5.84, 6.06, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1231",
			"position": [-68.79, 74.74, 85.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.46, 0.22, 0.10]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1232",
			"position": [-74.29, 77.49, 90.65],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.12, 0.00, 0.27]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1233",
			"position": [-145.49, 199.51, 166.72],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [6.11, 5.96, 0.33]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1234",
			"position": [-231.91, 232.52, 150.40],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.26, 5.76, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1235",
			"position": [-168.30, 270.50, 17.32],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 5.76, 6.02]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1236",
			"position": [-86.89, 277.31, 11.15],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.31, 5.96, 6.24]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1237",
			"position": [104.55, 317.29, 285.85],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.26, 0.26]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1238",
			"position": [97.52, 369.06, -102.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 5.82, 5.85]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1239",
			"position": [202.71, 412.20, 52.65],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [5.49, 5.73, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1240",
			"position": [97.10, 450.56, 231.91],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 5.94, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1241",
			"position": [-22.39, 466.00, 73.75],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [1.05, 5.50, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1242",
			"position": [98.99, 488.80, 188.25],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 5.50, 0.26]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1243",
			"position": [210.68, 537.00, -53.13],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.52, 0.52, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1244",
			"position": [218.83, 545.00, 88.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.52, 6.02]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1245",
			"position": [206.43, 544.00, 90.75],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.32, 5.77, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1246",
			"position": [270.71, 584.00, 101.85],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.12, 5.18, 0.12]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1247",
			"position": [292.33, 646.00, -56.84],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.52, 0.52, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1248",
			"position": [294.85, 662.00, -65.21],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [6.02, 5.76, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1249",
			"position": [320.20, 680.00, 50.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [6.02, 0.52, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1250",
			"position": [-32.11, 354.39, -1.03],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.79, 5.76, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1251",
			"position": [24.39, 352.06, -94.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.26, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1252",
			"position": [441.71, 712.80, -27.35],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 6.02, 6.02]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1253",
			"position": [502.05, 887.38, 129.01],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [6.03, 4.39, 0.46]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1254",
			"position": [386.14, 888.50, 197.31],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [1.22, 0.69, 0.47]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1255",
			"position": [312.50, 934.78, 289.22],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [6.02, 5.76, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1256",
			"position": [519.24, 968.08, 391.69],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [6.02, 5.76, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1257",
			"position": [337.64, 1031.28, 348.19],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.26, 5.76, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1258",
			"position": [387.01, 1073.78, 227.27],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.52, 5.76, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1259",
			"position": [381.33, 1125.78, 140.74],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.26, 5.76, 0.00]
		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1260",
			"position": [513.95, 1090.28, 139.62],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.26, 0.26, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1261",
			"position": [414.03, 1115.78, -15.39],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.17, 5.46, 6.09]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1262",
			"position": [283.86, 1140.40, 35.65],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [5.96, 0.26, 0.12]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1263",
			"position": [-178.87, 1229.28, 10.65],
			"scale": [12.00, 12.00, 12.00],
			"rotation": [0.52, 6.02, 0.26]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1264",
			"position": [13.17, 1317.28, -214.24],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.78, 0.26]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1265",
			"position": [42.79, 1320.28, 139.17],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.52, 5.76]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1266",
			"position": [-37.32, 1371.28, 290.06],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [6.02, 0.26, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1267",
			"position": [171.99, 1384.28, 348.62],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 5.50]
		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1268",
			"position": [25.87, 283.49, 22.88],
			"scale": [2.39, 2.39, 2.39],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.1", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1269",
			"position": [33.13, 273.00, 11.66],
			"scale": [2.39, 2.39, 2.39],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.85", "", ""]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_25",
			"position": [-14.84, 267.51, 36.59],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "1270",
			"position": [-40.68, 286.89, 36.21],
			"scale": [24.50, 1.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "1271",
			"position": [-50.57, 282.19, 37.24],
			"scale": [4.28, 10.39, 0.36],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_24",
			"position": [45.73, 278.76, 38.82],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_23",
			"position": [-4.09, 272.00, -11.06],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1272",
			"position": [23.51, 271.50, 9.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1273",
			"position": [10.51, 271.50, 6.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1274",
			"position": [3.01, 271.50, -0.45],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1275",
			"position": [-32.49, 271.50, 12.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1276",
			"position": [-25.49, 271.50, 8.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1277",
			"position": [-21.99, 271.50, -0.95],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1278",
			"position": [-28.99, 271.00, 11.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1279",
			"position": [-22.99, 271.00, 3.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1280",
			"position": [-18.49, 271.00, -4.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1281",
			"position": [47.17, 278.76, 75.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1282",
			"position": [56.67, 278.76, 73.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1283",
			"position": [62.17, 278.76, 67.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1284",
			"position": [64.67, 277.76, 57.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1285",
			"position": [64.67, 277.76, 47.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1286",
			"position": [62.17, 277.76, 39.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1287",
			"position": [53.67, 277.76, 39.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1288",
			"position": [2.51, 271.00, -7.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1289",
			"position": [18.55, 297.00, 126.27],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1290",
			"position": [18.98, 295.84, 120.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1291",
			"position": [14.97, 295.84, 120.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.8", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1292",
			"position": [22.97, 295.84, 120.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.8", "", ""]

		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1293",
			"position": [29.01, 288.00, 102.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1294",
			"position": [36.01, 285.00, 101.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1295",
			"position": [39.51, 283.00, 91.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1296",
			"position": [40.01, 281.50, 81.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1297",
			"position": [40.01, 276.50, 32.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1298",
			"position": [36.51, 275.50, 22.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1299",
			"position": [23.01, 271.00, 13.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1300",
			"position": [14.01, 271.00, 7.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1301",
			"position": [6.51, 271.00, 0.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1302",
			"position": [-12.49, 271.00, -10.95],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1303",
			"position": [-38.99, 271.50, 14.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1304",
			"position": [-49.49, 272.00, 14.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1305",
			"position": [-59.49, 272.00, 14.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1306",
			"position": [-59.49, 272.00, 24.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1307",
			"position": [-59.49, 272.00, 33.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_26",
			"position": [18.97, 291.09, 109.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1308",
			"position": [19.20, 287.69, 92.98],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1309",
			"position": [10.70, 284.69, 92.98],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1310",
			"position": [7.70, 281.19, 99.98],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1311",
			"position": [-1.30, 278.19, 93.48],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1312",
			"position": [-2.80, 275.19, 85.48],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1313",
			"position": [-1.30, 271.69, 75.48],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1314",
			"position": [2.70, 271.19, 63.98],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1315",
			"position": [2.70, 268.69, 53.48],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1316",
			"position": [2.70, 267.69, 44.48],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1317",
			"position": [29.39, 304.13, 144.31],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1318",
			"position": [21.05, 301.13, 142.67],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1319",
			"position": [18.98, 291.84, 112.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1320",
			"position": [18.96, 293.84, 116.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.8", "", ""]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1321",
			"position": [22.97, 293.84, 116.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1322",
			"position": [14.97, 291.84, 112.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.8", "", ""]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1323",
			"position": [14.97, 293.84, 116.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1324",
			"position": [22.97, 291.84, 112.47],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.8", "", ""]

		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1325",
			"position": [21.41, 287.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1326",
			"position": [19.41, 287.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1327",
			"position": [17.41, 287.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1328",
			"position": [15.41, 287.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1329",
			"position": [13.41, 289.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1330",
			"position": [15.41, 289.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1331",
			"position": [17.41, 289.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1332",
			"position": [19.41, 289.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1333",
			"position": [21.41, 289.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1334",
			"position": [2.70, 267.69, 35.48],
			"scale": [2.00, 10.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1335",
			"position": [-20.49, 281.50, 61.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1336",
			"position": [-17.49, 281.50, 61.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1337",
			"position": [-17.49, 281.50, 55.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1338",
			"position": [-17.49, 281.50, 52.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1339",
			"position": [-14.49, 281.50, 40.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1340",
			"position": [-17.49, 281.50, 40.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1341",
			"position": [-20.49, 281.50, 40.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1342",
			"position": [-22.49, 281.50, 52.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1343",
			"position": [-14.49, 281.50, 52.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1344",
			"position": [-23.49, 281.50, 49.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1345",
			"position": [-23.49, 281.50, 46.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1346",
			"position": [-23.49, 281.50, 40.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1347",
			"position": [-34.01, 281.50, 62.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1348",
			"position": [-34.01, 281.50, 59.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1349",
			"position": [-27.99, 281.50, 63.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1350",
			"position": [-27.99, 281.50, 56.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1351",
			"position": [-37.01, 281.50, 62.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1352",
			"position": [-43.01, 281.50, 56.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1353",
			"position": [-37.01, 281.50, 59.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1354",
			"position": [-40.01, 281.50, 59.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1355",
			"position": [-43.01, 281.50, 59.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1356",
			"position": [-43.01, 281.50, 53.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1357",
			"position": [-35.99, 281.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1358",
			"position": [-28.99, 281.50, 44.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1359",
			"position": [-38.99, 281.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1360",
			"position": [-31.99, 281.50, 53.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1361",
			"position": [-28.99, 281.50, 50.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1362",
			"position": [-41.99, 281.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1363",
			"position": [-28.99, 281.50, 38.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1364",
			"position": [-44.99, 281.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1365",
			"position": [-28.99, 281.50, 47.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1366",
			"position": [-37.01, 281.50, 53.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1367",
			"position": [-28.99, 281.50, 41.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1368",
			"position": [-28.99, 281.50, 53.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1369",
			"position": [-38.99, 281.50, 38.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1370",
			"position": [-20.49, 278.50, 61.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1371",
			"position": [-17.49, 278.50, 61.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1372",
			"position": [-17.49, 278.50, 58.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1373",
			"position": [-17.49, 278.50, 55.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1374",
			"position": [-17.49, 278.50, 52.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1375",
			"position": [-14.49, 278.50, 40.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1376",
			"position": [-17.49, 278.50, 40.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1377",
			"position": [-20.49, 278.50, 40.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1378",
			"position": [-22.49, 278.50, 52.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1379",
			"position": [-14.49, 278.50, 52.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1380",
			"position": [-23.49, 278.50, 49.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1381",
			"position": [-23.49, 278.50, 46.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1382",
			"position": [-23.49, 278.50, 43.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1383",
			"position": [-23.49, 278.50, 40.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1384",
			"position": [-34.01, 278.50, 62.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1385",
			"position": [-34.01, 278.50, 59.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1386",
			"position": [-27.99, 278.50, 63.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1387",
			"position": [-27.99, 278.50, 56.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1388",
			"position": [-37.01, 278.50, 62.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1389",
			"position": [-11.49, 278.50, 33.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1390",
			"position": [-11.49, 281.50, 33.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1391",
			"position": [-11.49, 281.50, 36.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1392",
			"position": [-11.49, 278.50, 36.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1393",
			"position": [-11.49, 278.50, 39.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1394",
			"position": [-11.49, 281.50, 39.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1395",
			"position": [-11.49, 281.50, 42.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1396",
			"position": [-11.49, 278.50, 42.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1397",
			"position": [-11.49, 278.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1398",
			"position": [-11.49, 281.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1399",
			"position": [-11.49, 281.50, 48.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1400",
			"position": [-11.49, 278.50, 48.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1401",
			"position": [-11.49, 278.50, 51.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1402",
			"position": [-11.49, 281.50, 51.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1403",
			"position": [-11.49, 281.50, 54.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1404",
			"position": [-11.49, 278.50, 54.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1405",
			"position": [-11.49, 278.50, 57.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1406",
			"position": [-11.49, 281.50, 57.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1407",
			"position": [-11.49, 281.50, 60.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1408",
			"position": [-11.49, 278.50, 60.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1409",
			"position": [-11.49, 278.50, 63.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1410",
			"position": [-11.49, 281.50, 63.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1411",
			"position": [-11.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1412",
			"position": [-11.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1413",
			"position": [-53.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1414",
			"position": [-53.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1415",
			"position": [-50.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1416",
			"position": [-50.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1417",
			"position": [-47.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1418",
			"position": [-47.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1419",
			"position": [-44.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1420",
			"position": [-44.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1421",
			"position": [-41.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1422",
			"position": [-41.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1423",
			"position": [-38.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1424",
			"position": [-38.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1425",
			"position": [-35.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1426",
			"position": [-35.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1427",
			"position": [-32.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1428",
			"position": [-32.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1429",
			"position": [-29.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1430",
			"position": [-29.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1431",
			"position": [-26.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1432",
			"position": [-26.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1433",
			"position": [-23.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1434",
			"position": [-23.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1435",
			"position": [-20.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1436",
			"position": [-20.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1437",
			"position": [-17.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1438",
			"position": [-17.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1439",
			"position": [-14.49, 278.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1440",
			"position": [-14.49, 281.50, 66.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1441",
			"position": [-43.01, 278.50, 56.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1442",
			"position": [-43.01, 278.50, 59.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1443",
			"position": [-40.01, 278.50, 59.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1444",
			"position": [-37.01, 278.50, 59.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1445",
			"position": [-43.01, 278.50, 53.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1446",
			"position": [-40.01, 278.50, 53.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1447",
			"position": [-28.99, 278.50, 53.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1448",
			"position": [-31.99, 278.50, 53.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1449",
			"position": [-38.99, 278.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1450",
			"position": [-41.99, 278.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1451",
			"position": [-44.99, 278.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1452",
			"position": [-28.99, 278.50, 50.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1453",
			"position": [-28.99, 278.50, 47.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1454",
			"position": [-28.99, 278.50, 44.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1455",
			"position": [-28.99, 278.50, 38.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1456",
			"position": [-28.99, 278.50, 41.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1457",
			"position": [-37.01, 278.50, 53.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1458",
			"position": [-35.99, 278.50, 45.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1459",
			"position": [-38.99, 278.50, 38.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1460",
			"position": [-17.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1461",
			"position": [-17.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1462",
			"position": [-14.49, 281.50, 32.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1463",
			"position": [-14.49, 278.50, 32.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1464",
			"position": [-20.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1465",
			"position": [-20.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1466",
			"position": [-23.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1467",
			"position": [-23.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1468",
			"position": [-29.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1469",
			"position": [-29.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1470",
			"position": [-26.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1471",
			"position": [-26.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1472",
			"position": [-32.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1473",
			"position": [-32.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1474",
			"position": [-35.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1475",
			"position": [-35.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1476",
			"position": [-41.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1477",
			"position": [-41.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1478",
			"position": [-38.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1479",
			"position": [-38.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1480",
			"position": [-44.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1481",
			"position": [-44.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1482",
			"position": [-47.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1483",
			"position": [-47.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1484",
			"position": [-50.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1485",
			"position": [-50.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1486",
			"position": [-53.49, 281.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1487",
			"position": [-47.49, 282.00, 40.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1488",
			"position": [-47.49, 280.00, 53.05],
			"scale": [2.00, 6.00, 24.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_003",
			"uniqueIdentifier": "1489",
			"position": [-59.49, 272.00, 42.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1490",
			"position": [-33.99, 275.50, 51.02],
			"scale": [42.00, 3.00, 28.44],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1491",
			"position": [-47.49, 280.00, 38.05],
			"scale": [2.00, 6.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1492",
			"position": [-53.49, 278.50, 35.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1493",
			"position": [-7.12, 267.41, 35.48],
			"scale": [2.00, 8.05, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "large_square_004",
			"uniqueIdentifier": "1494",
			"position": [18.47, 291.09, 102.47],
			"scale": [4.00, 2.00, 2.67],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1495",
			"position": [23.41, 289.10, 99.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_004",
			"uniqueIdentifier": "1496",
			"position": [18.55, 298.13, 135.17],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "fridge_001",
			"uniqueIdentifier": "1497",
			"position": [-217.28, 256.07, 110.81],
			"scale": [0.45, 0.45, 0.45],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1498",
			"position": [-217.59, 252.50, 110.74],
			"scale": [4.00, 2.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1499",
			"position": [-169.86, 195.46, 146.44],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1500",
			"position": [-168.86, 193.50, 139.12],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1501",
			"position": [-86.49, 271.50, 69.55],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.55", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1502",
			"position": [-103.99, 271.00, 69.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.55", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1503",
			"position": [-162.46, 188.50, 122.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "invisible_square_001",
			"uniqueIdentifier": "1504",
			"position": [-189.13, 257.50, 68.80],
			"scale": [3.00, 1.00, 28.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_21",
			"position": [-140.60, 263.00, 53.97],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_20",
			"position": [-221.70, 259.50, 46.52],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_18",
			"position": [-220.46, 225.35, 100.91],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "check_point_001",
			"position": [-175.34, 202.50, 168.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "check_point_001",
			"position": [-183.81, 193.00, 133.68],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1505",
			"position": [-197.99, 260.00, 42.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1506",
			"position": [-205.49, 259.00, 42.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1507",
			"position": [-213.49, 259.00, 42.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1508",
			"position": [-221.99, 259.00, 54.55],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1509",
			"position": [-216.49, 259.00, 62.55],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1510",
			"position": [-216.49, 259.00, 71.55],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1511",
			"position": [-204.99, 259.00, 73.05],
			"scale": [4.00, 2.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1512",
			"position": [-169.36, 196.50, 152.62],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1513",
			"position": [-170.86, 194.00, 142.12],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1514",
			"position": [-185.96, 201.50, 130.00],
			"scale": [3.00, 1.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1515",
			"position": [-192.96, 196.50, 136.00],
			"scale": [3.00, 1.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1516",
			"position": [-162.86, 193.00, 142.12],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1517",
			"position": [-178.49, 190.00, 123.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1518",
			"position": [-173.99, 189.50, 119.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_22",
			"position": [-68.74, 270.50, 43.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1519",
			"position": [-214.59, 252.50, 108.74],
			"scale": [2.00, 2.00, 12.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1520",
			"position": [-220.59, 252.50, 108.74],
			"scale": [2.00, 2.00, 12.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_19",
			"position": [-217.59, 252.50, 105.49],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_17",
			"position": [-198.49, 220.58, 137.15],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1521",
			"position": [-163.86, 191.50, 139.12],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1522",
			"position": [-164.36, 190.00, 134.12],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1523",
			"position": [-162.49, 260.50, 43.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1524",
			"position": [-151.99, 260.50, 51.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1525",
			"position": [-175.49, 260.50, 51.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1526",
			"position": [-187.49, 260.50, 43.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1527",
			"position": [-182.99, 210.00, 144.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1528",
			"position": [-182.99, 206.00, 144.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1529",
			"position": [-182.99, 202.00, 144.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1530",
			"position": [-182.99, 213.00, 164.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1531",
			"position": [-182.99, 213.00, 148.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1532",
			"position": [-182.99, 213.00, 152.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1533",
			"position": [-182.99, 213.00, 156.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1534",
			"position": [-182.99, 213.00, 160.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1535",
			"position": [-188.99, 205.00, 132.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1536",
			"position": [-188.99, 203.00, 132.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1537",
			"position": [-188.99, 201.00, 132.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1538",
			"position": [-188.99, 199.00, 132.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1539",
			"position": [-188.99, 197.00, 132.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1540",
			"position": [-188.99, 195.00, 132.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1541",
			"position": [-187.46, 195.00, 136.50],
			"scale": [3.00, 1.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "large_square_001",
			"uniqueIdentifier": "1542",
			"position": [-150.99, 188.50, 131.55],
			"scale": [2.00, 2.00, 1.33],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1543",
			"position": [-68.99, 271.00, 59.55],
			"scale": [0.50, 2.00, 29.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1544",
			"position": [-77.49, 271.00, 69.55],
			"scale": [17.00, 2.00, 0.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1545",
			"position": [-94.99, 271.00, 69.55],
			"scale": [16.00, 2.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1546",
			"position": [-125.49, 270.00, 68.05],
			"scale": [5.00, 4.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1547",
			"position": [-130.49, 266.00, 63.05],
			"scale": [5.00, 4.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1548",
			"position": [-135.49, 262.00, 58.05],
			"scale": [5.00, 4.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1549",
			"position": [-145.49, 260.50, 52.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1550",
			"position": [-145.49, 260.50, 43.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1551",
			"position": [-156.49, 260.50, 43.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1552",
			"position": [-156.49, 260.50, 52.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1553",
			"position": [-181.49, 260.50, 52.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1554",
			"position": [-181.49, 260.50, 43.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1555",
			"position": [-168.99, 260.50, 52.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1556",
			"position": [-168.99, 260.50, 43.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1557",
			"position": [-141.99, 264.50, 47.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1558",
			"position": [-151.99, 264.50, 47.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1559",
			"position": [-162.49, 264.50, 47.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1560",
			"position": [-175.49, 264.50, 47.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1561",
			"position": [-187.49, 264.50, 47.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1562",
			"position": [-194.99, 249.50, 47.55],
			"scale": [4.00, 26.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1563",
			"position": [-166.49, 260.50, 47.55],
			"scale": [53.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1564",
			"position": [-194.99, 270.50, 59.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1565",
			"position": [-194.99, 253.00, 59.05],
			"scale": [4.00, 31.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1566",
			"position": [-194.99, 270.00, 70.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1567",
			"position": [-194.99, 252.50, 70.05],
			"scale": [4.00, 31.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1568",
			"position": [-204.99, 263.50, 77.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_003",
			"uniqueIdentifier": "1569",
			"position": [-198.14, 258.04, 89.05],
			"scale": [2.00, 3.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_003",
			"uniqueIdentifier": "1570",
			"position": [-190.14, 257.04, 89.05],
			"scale": [2.00, 3.00, 2.00],
			"rotation": [0.00, 3.14, 3.14]
		},
		{
			"assetIdentifier": "plate_003",
			"uniqueIdentifier": "1571",
			"position": [-190.14, 256.04, 96.55],
			"scale": [2.00, 3.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_003",
			"uniqueIdentifier": "1572",
			"position": [-195.14, 255.04, 104.05],
			"scale": [2.00, 3.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_003",
			"uniqueIdentifier": "1573",
			"position": [-205.14, 254.04, 104.05],
			"scale": [2.00, 3.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1574",
			"position": [-228.63, 245.58, 95.83],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1575",
			"position": [-218.13, 241.58, 92.83],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1576",
			"position": [-229.99, 227.08, 93.90],
			"scale": [7.00, 3.50, 7.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1577",
			"position": [-218.13, 237.08, 103.83],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1578",
			"position": [-230.13, 233.08, 103.83],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1579",
			"position": [-224.49, 224.33, 99.40],
			"scale": [25.00, 4.00, 25.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "1580",
			"position": [-206.93, 224.72, 107.04],
			"scale": [12.00, 12.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "1581",
			"position": [-201.43, 223.22, 107.04],
			"scale": [12.00, 12.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1582",
			"position": [-200.51, 219.30, 142.65],
			"scale": [3.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1583",
			"position": [-203.01, 216.51, 148.90],
			"scale": [2.00, 2.00, 7.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1584",
			"position": [-199.49, 210.33, 148.65],
			"scale": [5.00, 2.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1585",
			"position": [-190.99, 208.33, 156.65],
			"scale": [12.00, 2.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1586",
			"position": [-186.99, 206.50, 160.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1587",
			"position": [-194.99, 206.50, 176.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1588",
			"position": [-194.99, 206.50, 160.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1589",
			"position": [-190.99, 206.50, 164.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1590",
			"position": [-186.99, 206.50, 168.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1591",
			"position": [-194.99, 206.50, 168.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1592",
			"position": [-190.99, 206.50, 172.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1593",
			"position": [-186.99, 206.50, 176.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1594",
			"position": [-172.99, 199.00, 161.55],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1595",
			"position": [-182.99, 206.50, 172.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1596",
			"position": [-182.99, 209.00, 164.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1597",
			"position": [-182.99, 209.00, 160.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1598",
			"position": [-182.99, 209.00, 156.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1599",
			"position": [-182.99, 209.00, 152.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1600",
			"position": [-170.99, 196.00, 155.55],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1601",
			"position": [-188.99, 193.00, 132.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1602",
			"position": [-182.99, 209.00, 148.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1603",
			"position": [-182.24, 192.00, 127.80],
			"scale": [4.50, 2.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1604",
			"position": [-168.49, 188.50, 116.30],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1605",
			"position": [-160.99, 188.50, 116.65],
			"scale": [8.00, 2.00, 1.20],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1606",
			"position": [-187.49, 203.83, 162.65],
			"scale": [19.00, 6.50, 32.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1607",
			"position": [-202.01, 212.26, 151.65],
			"scale": [4.00, 4.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "tissue_box_004",
			"uniqueIdentifier": "1608",
			"position": [-198.99, 220.50, 127.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "tissue_box_002",
			"uniqueIdentifier": "1609",
			"position": [-197.99, 222.00, 118.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pepper",
			"uniqueIdentifier": "1610",
			"position": [-196.93, 221.72, 111.54],
			"scale": [12.00, 12.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_004",
			"uniqueIdentifier": "1611",
			"position": [-230.13, 229.08, 93.83],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_003",
			"uniqueIdentifier": "1612",
			"position": [-228.14, 250.04, 104.05],
			"scale": [2.00, 3.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1613",
			"position": [-204.99, 259.50, 77.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1614",
			"position": [-208.99, 259.00, 78.05],
			"scale": [4.00, 2.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_003",
			"uniqueIdentifier": "1615",
			"position": [-204.98, 259.73, 82.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.5", "", ""]

		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1616",
			"position": [-113.99, 271.00, 69.55],
			"scale": [18.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "large_square_001",
			"uniqueIdentifier": "1617",
			"position": [-152.49, 188.50, 122.30],
			"scale": [3.00, 2.00, 4.83],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1618",
			"position": [-161.36, 189.50, 134.12],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slippery_block_001",
			"uniqueIdentifier": "1619",
			"position": [-158.36, 189.00, 134.12],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "0.64", "", ""]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1620",
			"position": [-192.96, 198.50, 130.00],
			"scale": [3.00, 1.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1621",
			"position": [-186.96, 204.00, 135.50],
			"scale": [3.00, 1.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1622",
			"position": [-187.96, 205.00, 143.00],
			"scale": [3.00, 1.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1623",
			"position": [-144.98, 184.97, 122.95],
			"scale": [3.25, 3.00, 3.25],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1624",
			"position": [-113.35, 134.22, 98.58],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1625",
			"position": [-144.23, 180.97, 117.45],
			"scale": [3.25, 3.00, 3.25],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1626",
			"position": [-140.48, 177.97, 112.20],
			"scale": [3.25, 3.00, 3.25],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1627",
			"position": [-135.73, 174.72, 112.20],
			"scale": [3.25, 3.00, 3.25],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_12",
			"position": [-126.60, 172.22, 100.83],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1628",
			"position": [-130.98, 171.72, 102.33],
			"scale": [3.25, 3.00, 16.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1629",
			"position": [-120.48, 171.72, 95.58],
			"scale": [17.75, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1630",
			"position": [-113.10, 168.72, 95.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1631",
			"position": [-110.10, 168.72, 95.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1632",
			"position": [-110.10, 144.72, 86.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1633",
			"position": [-107.10, 144.72, 86.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1634",
			"position": [-107.10, 147.72, 86.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "check_point_001",
			"position": [-111.85, 142.97, 93.08],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1635",
			"position": [-107.10, 168.72, 95.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1636",
			"position": [-104.10, 165.72, 95.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1637",
			"position": [-104.10, 162.72, 95.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1638",
			"position": [-101.10, 162.72, 95.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1639",
			"position": [-98.10, 162.72, 95.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1640",
			"position": [-92.10, 162.72, 92.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1641",
			"position": [-92.10, 162.72, 89.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1642",
			"position": [-92.10, 159.72, 89.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1643",
			"position": [-95.10, 159.72, 92.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1644",
			"position": [-95.10, 156.72, 92.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1645",
			"position": [-92.10, 156.72, 89.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1646",
			"position": [-95.10, 156.72, 89.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1647",
			"position": [-98.10, 156.72, 89.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1648",
			"position": [-98.10, 153.72, 89.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1649",
			"position": [-98.10, 153.72, 86.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1650",
			"position": [-95.10, 150.72, 86.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1651",
			"position": [-98.10, 150.72, 86.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1652",
			"position": [-101.10, 150.72, 86.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1653",
			"position": [-101.10, 147.72, 86.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1654",
			"position": [-104.10, 147.72, 83.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1655",
			"position": [-104.10, 147.72, 86.58],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1656",
			"position": [-117.35, 137.97, 91.33],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1657",
			"position": [-122.85, 131.47, 98.33],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1658",
			"position": [-122.85, 128.72, 108.58],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1659",
			"position": [-123.25, 128.56, 116.13],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1660",
			"position": [-123.25, 128.56, 119.88],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1661",
			"position": [-123.25, 128.56, 123.63],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "check_point_001",
			"position": [-102.07, 110.47, 135.62],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1662",
			"position": [-123.25, 128.56, 127.38],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1663",
			"position": [-131.69, 91.75, 139.19],
			"scale": [1.50, 1.50, 1.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1664",
			"position": [-130.19, 91.75, 128.94],
			"scale": [1.50, 1.50, 1.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "check_point_001",
			"position": [-130.28, 86.22, 122.69],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1665",
			"position": [-112.50, 125.56, 127.38],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "cutting_board",
			"uniqueIdentifier": "1666",
			"position": [-102.25, 121.81, 127.38],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_001",
			"uniqueIdentifier": "1667",
			"position": [-102.06, 120.25, 134.54],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_001",
			"uniqueIdentifier": "1668",
			"position": [-103.56, 117.00, 143.79],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_001",
			"uniqueIdentifier": "1669",
			"position": [-96.31, 114.25, 141.54],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_001",
			"uniqueIdentifier": "1670",
			"position": [-110.11, 107.75, 134.81],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_001",
			"uniqueIdentifier": "1671",
			"position": [-102.81, 103.50, 141.54],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "plate_001",
			"uniqueIdentifier": "1672",
			"position": [-110.81, 99.75, 141.54],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1673",
			"position": [-117.69, 98.75, 141.94],
			"scale": [2.00, 2.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1674",
			"position": [-117.69, 95.50, 138.19],
			"scale": [2.00, 2.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1675",
			"position": [-117.69, 93.25, 133.94],
			"scale": [2.00, 2.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1676",
			"position": [-118.57, 89.50, 137.94],
			"scale": [9.25, 3.00, 10.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1677",
			"position": [-127.82, 89.50, 138.44],
			"scale": [9.25, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1678",
			"position": [-130.94, 89.50, 131.19],
			"scale": [3.00, 3.00, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1679",
			"position": [-129.68, 82.00, 115.93],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1680",
			"position": [-129.68, 78.50, 109.43],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1681",
			"position": [-121.93, 75.25, 109.43],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1682",
			"position": [-114.43, 71.50, 109.43],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1683",
			"position": [-108.18, 68.50, 109.43],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1684",
			"position": [-101.18, 65.50, 109.43],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1685",
			"position": [-87.35, 102.99, 99.71],
			"scale": [2.05, 2.05, 2.05],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "4.15", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1686",
			"position": [-113.91, 89.00, 106.21],
			"scale": [2.05, 2.05, 2.05],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.075", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1687",
			"position": [-107.63, 75.58, 124.21],
			"scale": [2.05, 2.05, 2.05],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.075", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1688",
			"position": [-91.99, 74.50, 119.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1689",
			"position": [-57.99, 74.00, 111.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1690",
			"position": [-57.99, 70.00, 111.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1691",
			"position": [-57.99, 66.00, 111.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1692",
			"position": [-61.91, 66.50, 103.21],
			"scale": [2.05, 2.05, 2.05],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.075", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1693",
			"position": [-50.99, 62.00, 111.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1694",
			"position": [-46.99, 62.00, 111.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.8", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1695",
			"position": [-42.99, 62.00, 111.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.7", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1696",
			"position": [-38.99, 62.00, 111.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.6", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1697",
			"position": [-34.99, 62.00, 111.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1698",
			"position": [-25.91, 66.50, 121.21],
			"scale": [2.05, 2.05, 2.05],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.075", "", ""]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_13",
			"position": [-144.93, 187.50, 128.26],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "check_point_001",
			"position": [-55.59, 75.50, 128.24],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1699",
			"position": [-29.99, 61.75, 111.05],
			"scale": [6.00, 4.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1700",
			"position": [-95.99, 74.50, 119.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1701",
			"position": [-85.24, 74.25, 122.55],
			"scale": [4.50, 4.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1702",
			"position": [-75.24, 74.25, 122.55],
			"scale": [4.50, 4.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1703",
			"position": [-66.24, 74.25, 127.55],
			"scale": [4.50, 4.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1704",
			"position": [-44.74, 74.25, 127.55],
			"scale": [4.50, 4.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1705",
			"position": [-139.49, 186.50, 127.05],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1706",
			"position": [-133.49, 186.00, 122.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1707",
			"position": [-125.99, 185.50, 118.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1708",
			"position": [-118.49, 185.00, 115.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_14",
			"position": [-102.49, 185.00, 116.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "pot_003",
			"uniqueIdentifier": "1709",
			"position": [-116.49, 175.16, 128.13],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pot_003",
			"uniqueIdentifier": "1710",
			"position": [-113.99, 166.66, 106.13],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1711",
			"position": [-35.24, 61.50, 121.30],
			"scale": [4.50, 4.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pot_003",
			"uniqueIdentifier": "1712",
			"position": [-78.99, 154.66, 100.63],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pot_003",
			"uniqueIdentifier": "1713",
			"position": [-113.99, 142.16, 124.13],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pot_003",
			"uniqueIdentifier": "1714",
			"position": [-113.99, 125.16, 103.13],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pot_001",
			"uniqueIdentifier": "1715",
			"position": [-108.28, 80.31, 104.55],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 3.14]
		},
		{
			"assetIdentifier": "pot_001",
			"uniqueIdentifier": "1716",
			"position": [-108.28, 73.81, 124.05],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 3.14]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1717",
			"position": [-102.49, 122.50, 115.05],
			"scale": [4.00, 122.99, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1718",
			"position": [-90.49, 63.25, 115.05],
			"scale": [20.00, 4.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1719",
			"position": [-70.49, 68.75, 115.05],
			"scale": [20.00, 15.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1720",
			"position": [-44.49, 68.00, 115.05],
			"scale": [23.00, 17.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1721",
			"position": [-27.49, 68.00, 127.55],
			"scale": [20.00, 17.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1722",
			"position": [-19.74, 58.75, 120.55],
			"scale": [4.50, 9.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1723",
			"position": [-13.24, 55.00, 120.55],
			"scale": [6.50, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pot_001",
			"uniqueIdentifier": "1724",
			"position": [-132.28, 89.31, 144.05],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 3.14]
		},
		{
			"assetIdentifier": "pot_002",
			"uniqueIdentifier": "1725",
			"position": [-100.49, 85.41, 101.18],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pot_003",
			"uniqueIdentifier": "1726",
			"position": [-114.99, 118.16, 126.13],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1727",
			"position": [-110.99, 184.50, 115.55],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "chocolate_001",
			"uniqueIdentifier": "1728",
			"position": [92.65, 487.80, 87.39],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "chocolate_001",
			"uniqueIdentifier": "1729",
			"position": [415.10, 711.00, 44.47],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1730",
			"position": [-218.99, 217.00, 187.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1731",
			"position": [-188.99, 220.00, 193.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1732",
			"position": [-200.99, 217.00, 193.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "1733",
			"position": [442.01, 1052.64, 152.94],
			"scale": [1.95, 1.95, 1.95],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "chocolate_001",
			"uniqueIdentifier": "1734",
			"position": [69.38, 1401.78, 382.78],
			"scale": [1.50, 1.00, 1.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "1735",
			"position": [444.36, 752.68, 145.66],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "fridge_001",
			"uniqueIdentifier": "1736",
			"position": [92.88, 490.37, 109.83],
			"scale": [0.45, 0.45, 0.45],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1737",
			"position": [25.33, 442.55, 47.95],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.6", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1738",
			"position": [16.33, 442.30, 59.45],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.6", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1739",
			"position": [16.33, 442.30, 62.45],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.6", "", ""]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1740",
			"position": [125.61, 485.80, 95.81],
			"scale": [49.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1741",
			"position": [117.61, 485.80, 109.06],
			"scale": [65.00, 4.00, 22.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1742",
			"position": [99.11, 485.80, 95.81],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1743",
			"position": [95.11, 485.80, 95.81],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.7", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1744",
			"position": [91.11, 485.80, 95.81],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.7", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1745",
			"position": [87.11, 485.80, 95.81],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.7", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1746",
			"position": [46.86, 480.80, 58.05],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.8", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1747",
			"position": [167.51, 514.00, 28.55],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1748",
			"position": [150.33, 513.60, 46.79],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.68", "", ""]

		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1749",
			"position": [146.00, 512.55, 69.01],
			"scale": [2.00, 1.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1750",
			"position": [150.33, 514.30, 66.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1751",
			"position": [46.83, 431.80, 66.10],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.6", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1752",
			"position": [50.83, 431.80, 66.10],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.6", "", ""]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_34",
			"position": [136.83, 509.80, 79.45],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_33",
			"position": [126.09, 498.78, 108.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_32",
			"position": [60.86, 477.55, 104.80],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_002",
			"uniqueIdentifier": "1753",
			"position": [101.83, 497.30, 99.95],
			"scale": [6.00, 19.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1754",
			"position": [117.61, 485.80, 82.56],
			"scale": [65.00, 4.00, 22.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1755",
			"position": [30.83, 430.80, 70.70],
			"scale": [4.00, 2.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1756",
			"position": [26.83, 430.80, 73.95],
			"scale": [4.00, 2.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1757",
			"position": [34.83, 430.80, 73.95],
			"scale": [4.00, 2.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_31",
			"position": [30.83, 430.80, 74.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1758",
			"position": [137.33, 512.80, 66.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1759",
			"position": [151.83, 516.30, 61.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1760",
			"position": [151.83, 514.30, 61.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1761",
			"position": [153.58, 511.05, 71.95],
			"scale": [10.50, 1.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1762",
			"position": [151.33, 514.30, 32.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1763",
			"position": [158.33, 513.80, 35.95],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1764",
			"position": [158.33, 512.80, 42.95],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1765",
			"position": [151.33, 513.30, 39.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1766",
			"position": [158.33, 511.80, 49.95],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1767",
			"position": [151.12, 511.30, 53.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1768",
			"position": [158.33, 510.80, 56.95],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1769",
			"position": [154.83, 512.55, 61.95],
			"scale": [8.00, 1.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1770",
			"position": [154.08, 512.55, 66.95],
			"scale": [9.50, 1.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1771",
			"position": [141.08, 510.80, 70.20],
			"scale": [14.50, 2.00, 8.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1772",
			"position": [129.83, 509.80, 85.45],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 3.14, 3.14]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1773",
			"position": [136.83, 508.80, 91.45],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 3.14, 3.14]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1774",
			"position": [136.83, 508.30, 97.95],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 3.14, 3.14]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1775",
			"position": [140.83, 501.80, 104.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1776",
			"position": [136.83, 507.80, 105.45],
			"scale": [6.00, 2.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1777",
			"position": [132.83, 505.80, 104.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1778",
			"position": [132.83, 505.80, 106.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1779",
			"position": [132.83, 505.80, 108.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1780",
			"position": [134.83, 501.80, 102.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1781",
			"position": [136.83, 501.80, 102.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1782",
			"position": [138.83, 501.80, 102.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1783",
			"position": [138.83, 501.80, 110.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1784",
			"position": [136.83, 501.80, 110.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1785",
			"position": [134.83, 501.80, 110.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1786",
			"position": [132.83, 501.80, 108.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1787",
			"position": [132.83, 501.80, 106.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1788",
			"position": [132.83, 501.80, 104.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1789",
			"position": [136.58, 499.80, 107.20],
			"scale": [15.50, 2.00, 16.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1790",
			"position": [134.83, 492.80, 110.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1791",
			"position": [136.83, 492.80, 110.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1792",
			"position": [138.83, 492.80, 110.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1793",
			"position": [140.83, 492.80, 108.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1794",
			"position": [140.83, 492.80, 106.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1795",
			"position": [140.83, 492.80, 104.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1796",
			"position": [132.83, 492.80, 104.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1797",
			"position": [132.83, 492.80, 106.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1798",
			"position": [132.83, 492.80, 108.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1799",
			"position": [136.83, 497.30, 106.95],
			"scale": [6.00, 19.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1800",
			"position": [114.83, 505.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1801",
			"position": [112.83, 505.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1802",
			"position": [110.83, 505.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1803",
			"position": [110.83, 501.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1804",
			"position": [112.83, 501.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1805",
			"position": [114.83, 501.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1806",
			"position": [119.58, 499.80, 107.95],
			"scale": [7.50, 2.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1807",
			"position": [112.83, 499.80, 113.95],
			"scale": [10.00, 2.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1808",
			"position": [112.83, 509.80, 107.95],
			"scale": [6.00, 6.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1809",
			"position": [110.83, 492.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1810",
			"position": [112.83, 492.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1811",
			"position": [114.83, 492.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1812",
			"position": [116.83, 492.80, 105.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1813",
			"position": [116.83, 492.80, 107.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1814",
			"position": [116.83, 492.80, 109.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1815",
			"position": [114.83, 492.80, 111.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1816",
			"position": [112.83, 492.80, 111.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1817",
			"position": [110.83, 492.80, 111.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1818",
			"position": [108.83, 492.80, 109.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1819",
			"position": [108.83, 492.80, 107.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1820",
			"position": [112.83, 497.30, 107.95],
			"scale": [6.00, 19.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1821",
			"position": [19.83, 431.80, 65.95],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1822",
			"position": [30.83, 430.80, 77.20],
			"scale": [4.00, 2.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1823",
			"position": [24.33, 431.30, 65.95],
			"scale": [5.00, 3.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1824",
			"position": [34.58, 431.05, 65.70],
			"scale": [5.50, 2.50, 3.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1825",
			"position": [41.83, 431.80, 68.95],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1826",
			"position": [41.83, 431.80, 72.95],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1827",
			"position": [45.83, 431.80, 84.95],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1828",
			"position": [45.83, 431.80, 80.95],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1829",
			"position": [30.83, 426.80, 75.45],
			"scale": [44.00, 6.00, 23.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1830",
			"position": [59.83, 436.55, 80.70],
			"scale": [4.00, 2.50, 3.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1831",
			"position": [67.08, 433.55, 84.34],
			"scale": [5.50, 2.50, 5.23],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1832",
			"position": [33.81, 448.80, 44.94],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1833",
			"position": [60.86, 480.55, 88.30],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1834",
			"position": [79.11, 484.80, 96.55],
			"scale": [4.50, 4.50, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1835",
			"position": [71.61, 481.80, 96.55],
			"scale": [4.50, 4.50, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1836",
			"position": [64.61, 478.80, 96.55],
			"scale": [4.50, 4.50, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1837",
			"position": [56.83, 476.80, 98.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1838",
			"position": [51.33, 478.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1839",
			"position": [51.33, 476.80, 103.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1840",
			"position": [44.83, 476.80, 106.45],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1841",
			"position": [46.83, 476.80, 98.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1842",
			"position": [62.11, 477.30, 89.55],
			"scale": [4.50, 4.50, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1843",
			"position": [58.61, 479.80, 82.55],
			"scale": [4.50, 4.50, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1844",
			"position": [46.83, 476.80, 78.45],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1845",
			"position": [48.83, 476.80, 76.45],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1846",
			"position": [52.11, 476.80, 77.55],
			"scale": [4.50, 4.50, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1847",
			"position": [45.36, 477.80, 49.30],
			"scale": [5.00, 4.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1848",
			"position": [45.36, 477.80, 73.30],
			"scale": [5.00, 4.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1849",
			"position": [46.83, 476.80, 76.45],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1850",
			"position": [43.83, 476.80, 67.45],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1851",
			"position": [50.33, 472.30, 103.95],
			"scale": [15.00, 7.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1852",
			"position": [45.33, 472.30, 72.45],
			"scale": [5.00, 7.00, 51.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1853",
			"position": [45.33, 461.80, 39.95],
			"scale": [5.00, 28.00, 14.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1854",
			"position": [38.83, 444.80, 39.95],
			"scale": [18.00, 6.00, 14.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1855",
			"position": [17.83, 442.80, 39.95],
			"scale": [18.00, 5.00, 13.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1856",
			"position": [13.33, 436.80, 75.45],
			"scale": [9.00, 14.00, 23.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1857",
			"position": [68.83, 431.05, 81.20],
			"scale": [9.00, 2.50, 11.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1858",
			"position": [86.33, 431.05, 69.45],
			"scale": [9.00, 2.50, 11.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1859",
			"position": [77.58, 426.80, 75.45],
			"scale": [26.50, 6.00, 23.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_005",
			"uniqueIdentifier": "1860",
			"position": [11.83, 441.30, 57.45],
			"scale": [6.00, 5.00, 13.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1861",
			"position": [46.83, 476.80, 61.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1862",
			"position": [45.36, 477.80, 58.30],
			"scale": [5.00, 4.00, 2.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1863",
			"position": [41.81, 448.80, 38.44],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1864",
			"position": [45.83, 431.80, 76.95],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1865",
			"position": [108.83, 492.80, 105.95],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "1866",
			"position": [149.04, 484.38, 120.52],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "chocolate_001",
			"uniqueIdentifier": "1867",
			"position": [418.21, 1114.78, 52.75],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "fridge_001",
			"uniqueIdentifier": "1868",
			"position": [65.65, 1404.35, 398.64],
			"scale": [0.45, 0.45, 0.45],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "1869",
			"position": [103.03, 392.10, -34.00],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pistachio_001",
			"uniqueIdentifier": "1870",
			"position": [87.22, 1401.78, 388.34],
			"scale": [1.50, 1.00, 1.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1871",
			"position": [-215.95, 209.00, 164.20],
			"scale": [1.50, 1.00, 1.50],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.5", "", ""]

		},
		{
			"uniqueIdentifier": "Spawn",
			"position": [-5.33, 1.50, -108.05]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1872",
			"position": [81.03, 430.06, 92.76],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.625", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1873",
			"position": [51.23, 345.06, -40.70],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.8", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1874",
			"position": [39.73, 351.39, 26.12],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1875",
			"position": [39.73, 351.39, 41.62],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1876",
			"position": [39.73, 351.39, 57.12],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1877",
			"position": [52.62, 361.07, 80.45],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.9", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1878",
			"position": [66.40, 361.06, -20.67],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.8", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1879",
			"position": [61.40, 361.06, -2.67],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.8", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1880",
			"position": [139.71, 409.45, 89.00],
			"scale": [2.18, 2.18, 2.18],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "3", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1881",
			"position": [144.41, 408.62, 83.24],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.625", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1882",
			"position": [144.01, 408.62, 68.65],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.625", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1883",
			"position": [134.91, 408.62, 56.68],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.625", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1884",
			"position": [128.66, 411.14, 32.94],
			"scale": [1.83, 1.83, 1.83],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.15", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1885",
			"position": [127.91, 412.80, 25.68],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1886",
			"position": [127.91, 413.30, 7.15],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1887",
			"position": [127.91, 413.30, -10.85],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1888",
			"position": [128.66, 404.14, -30.15],
			"scale": [1.83, 1.83, 1.83],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.81", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1889",
			"position": [127.91, 404.20, -41.55],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.36", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1890",
			"position": [127.91, 404.20, -60.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1891",
			"position": [127.91, 404.20, -79.05],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1892",
			"position": [116.21, 372.87, -86.74],
			"scale": [3.03, 3.03, 3.03],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2.89", "", ""]

		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1893",
			"position": [107.07, 367.89, -84.75],
			"scale": [4.00, 12.50, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "slow_block_001",
			"uniqueIdentifier": "1894",
			"position": [103.07, 363.64, -84.75],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1895",
			"position": [79.68, 354.87, -86.74],
			"scale": [3.03, 3.03, 3.03],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "2", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1896",
			"position": [79.23, 353.56, -58.90],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.5", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1897",
			"position": [79.68, 354.87, -63.81],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1898",
			"position": [58.46, 345.06, -53.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "1.6", "", ""]

		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "1899",
			"position": [37.84, 357.43, 116.53],
			"scale": [1.66, 1.66, 1.66],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.5", "", ""]

		},
		{
			"assetIdentifier": "moving_block_001",
			"uniqueIdentifier": "1900",
			"position": [38.73, 356.39, 123.62],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Speed", "2", "", ""]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_30",
			"position": [158.73, 410.56, 97.50],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_28",
			"position": [55.06, 362.06, 23.85],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_27",
			"position": [38.23, 307.56, 146.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1901",
			"position": [107.23, 370.06, -56.50],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1902",
			"position": [92.23, 367.06, -50.00],
			"scale": [4.00, 2.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1903",
			"position": [91.73, 424.56, 96.50],
			"scale": [5.00, 15.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1904",
			"position": [100.23, 424.56, 96.50],
			"scale": [5.00, 15.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1905",
			"position": [108.73, 424.56, 96.50],
			"scale": [5.00, 15.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1906",
			"position": [157.73, 411.06, 77.50],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1907",
			"position": [159.23, 411.06, 65.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1908",
			"position": [160.73, 411.06, 55.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1909",
			"position": [56.06, 364.56, 6.85],
			"scale": [4.00, 7.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1910",
			"position": [102.73, 394.31, -35.00],
			"scale": [4.00, 5.50, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_006",
			"uniqueIdentifier": "1911",
			"position": [103.23, 364.56, -45.00],
			"scale": [18.00, 65.00, 18.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1912",
			"position": [77.73, 348.81, -60.00],
			"scale": [7.00, 5.50, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1913",
			"position": [45.98, 350.89, 25.87],
			"scale": [8.50, 5.00, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1914",
			"position": [45.98, 350.89, 41.87],
			"scale": [8.50, 5.00, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1915",
			"position": [45.98, 350.89, 56.87],
			"scale": [8.50, 5.00, 4.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1916",
			"position": [88.73, 431.06, 90.50],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1917",
			"position": [117.23, 424.56, 96.50],
			"scale": [5.00, 15.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1918",
			"position": [122.23, 419.06, 96.50],
			"scale": [5.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1919",
			"position": [130.23, 417.56, 96.50],
			"scale": [5.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1920",
			"position": [137.73, 415.56, 96.50],
			"scale": [5.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1921",
			"position": [144.73, 414.06, 96.50],
			"scale": [5.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1922",
			"position": [151.73, 411.06, 96.50],
			"scale": [5.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1923",
			"position": [159.23, 401.56, 90.00],
			"scale": [5.00, 17.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1924",
			"position": [159.23, 401.56, 69.50],
			"scale": [5.00, 17.00, 31.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1925",
			"position": [147.73, 401.56, 47.50],
			"scale": [28.00, 17.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1926",
			"position": [108.91, 398.06, -27.58],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1927",
			"position": [108.91, 398.06, -51.08],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1928",
			"position": [96.41, 398.06, -47.58],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1929",
			"position": [98.41, 398.06, -35.58],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1930",
			"position": [105.41, 398.06, -42.58],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1931",
			"position": [88.23, 357.56, -49.50],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1932",
			"position": [88.23, 353.06, -70.00],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1933",
			"position": [87.23, 372.56, -46.00],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1934",
			"position": [89.23, 378.56, -46.00],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1935",
			"position": [91.23, 391.06, -46.00],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1936",
			"position": [120.73, 391.06, -46.00],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1937",
			"position": [117.23, 385.56, -46.00],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1938",
			"position": [120.73, 378.06, -46.00],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1939",
			"position": [97.48, 394.31, -33.00],
			"scale": [6.50, 5.50, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1940",
			"position": [108.48, 394.31, -33.00],
			"scale": [7.50, 5.50, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "checkpoint_29",
			"position": [102.73, 396.06, -31.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1941",
			"position": [45.98, 342.64, 101.62],
			"scale": [8.50, 11.50, 28.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "1942",
			"position": [45.98, 342.64, 45.12],
			"scale": [8.50, 11.50, 65.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1943",
			"position": [101.98, 428.06, 79.00],
			"scale": [8.50, 5.50, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1944",
			"position": [112.98, 416.56, 77.50],
			"scale": [8.50, 5.50, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1945",
			"position": [125.48, 409.06, 77.00],
			"scale": [8.50, 5.50, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1946",
			"position": [125.48, 406.56, 62.50],
			"scale": [8.50, 5.50, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1947",
			"position": [125.48, 401.56, 47.50],
			"scale": [8.50, 17.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1948",
			"position": [125.48, 395.06, 41.50],
			"scale": [8.50, 4.00, 6.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1949",
			"position": [127.73, 399.06, 4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1950",
			"position": [127.73, 399.06, 0.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1951",
			"position": [91.73, 395.06, 12.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1952",
			"position": [91.73, 395.06, 8.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1953",
			"position": [91.73, 395.06, 4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1954",
			"position": [91.73, 395.06, 0.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1955",
			"position": [110.31, 399.06, 30.50],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1956",
			"position": [115.91, 399.06, 22.50],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1957",
			"position": [109.31, 399.06, 14.50],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1958",
			"position": [127.73, 399.06, 26.50],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1959",
			"position": [122.81, 399.06, 18.50],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1960",
			"position": [116.64, 399.06, 10.50],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1961",
			"position": [91.73, 395.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1962",
			"position": [91.73, 399.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1963",
			"position": [127.73, 399.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1964",
			"position": [123.73, 399.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1965",
			"position": [119.73, 399.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1966",
			"position": [115.73, 399.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1967",
			"position": [107.73, 399.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1968",
			"position": [103.73, 399.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1969",
			"position": [99.73, 399.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1970",
			"position": [95.73, 399.06, -4.00],
			"scale": [4.00, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1971",
			"position": [111.73, 395.06, 16.25],
			"scale": [36.00, 4.00, 44.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1972",
			"position": [103.23, 398.06, -20.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1973",
			"position": [96.73, 350.06, -60.00],
			"scale": [31.00, 36.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "1974",
			"position": [64.48, 339.06, -60.00],
			"scale": [33.50, 14.00, 12.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1975",
			"position": [63.73, 337.06, -46.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1976",
			"position": [63.73, 346.06, -46.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1977",
			"position": [63.73, 355.06, -46.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1978",
			"position": [45.23, 362.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1979",
			"position": [47.23, 362.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1980",
			"position": [49.23, 362.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1981",
			"position": [59.23, 360.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1982",
			"position": [57.23, 360.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1983",
			"position": [55.23, 360.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1984",
			"position": [53.23, 360.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1985",
			"position": [54.73, 364.06, -46.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1986",
			"position": [63.73, 364.06, -46.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1987",
			"position": [72.73, 364.06, -46.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1988",
			"position": [72.73, 364.06, -37.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1989",
			"position": [72.73, 364.06, -28.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1990",
			"position": [72.73, 364.06, -19.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1991",
			"position": [72.73, 364.06, -10.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1992",
			"position": [72.73, 364.06, -1.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1993",
			"position": [72.73, 364.06, 7.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "1994",
			"position": [63.73, 357.06, -19.50],
			"scale": [9.00, 8.00, 44.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1995",
			"position": [45.73, 356.56, -10.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1996",
			"position": [45.73, 356.56, -1.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1997",
			"position": [54.73, 365.56, -1.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1998",
			"position": [54.73, 365.56, -10.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "1999",
			"position": [54.73, 365.56, -19.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2000",
			"position": [63.73, 364.06, 34.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2001",
			"position": [63.73, 364.06, 25.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2002",
			"position": [63.73, 364.06, 16.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2003",
			"position": [63.73, 364.06, 7.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2004",
			"position": [45.73, 330.06, 8.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2005",
			"position": [45.73, 338.56, 8.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2006",
			"position": [45.73, 347.56, 8.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2007",
			"position": [45.73, 356.56, 8.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2008",
			"position": [54.73, 365.56, 43.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2009",
			"position": [54.73, 365.56, 52.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2010",
			"position": [54.73, 365.56, 61.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2011",
			"position": [45.73, 330.06, 82.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2012",
			"position": [45.51, 338.50, 82.55],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2013",
			"position": [45.51, 347.50, 82.55],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2014",
			"position": [45.51, 356.50, 82.55],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2015",
			"position": [63.73, 364.06, 70.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2016",
			"position": [63.73, 364.06, 79.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2017",
			"position": [63.73, 364.06, 88.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2018",
			"position": [63.73, 364.06, 97.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2019",
			"position": [63.73, 364.06, 126.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2020",
			"position": [54.73, 365.56, 105.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2021",
			"position": [54.73, 365.56, 114.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2022",
			"position": [54.73, 365.56, 123.00],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2023",
			"position": [63.73, 364.06, 135.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2024",
			"position": [63.73, 364.06, 144.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2025",
			"position": [63.73, 364.06, 153.50],
			"scale": [9.00, 9.00, 9.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2026",
			"position": [49.23, 342.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2027",
			"position": [49.23, 340.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2028",
			"position": [49.23, 338.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2029",
			"position": [49.23, 336.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2030",
			"position": [49.23, 334.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "large_square_001",
			"uniqueIdentifier": "2031",
			"position": [54.73, 346.56, 54.25],
			"scale": [3.00, 29.00, 51.84],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2032",
			"position": [51.23, 352.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2033",
			"position": [51.23, 350.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2034",
			"position": [51.23, 348.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2035",
			"position": [51.23, 360.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2036",
			"position": [51.23, 358.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2037",
			"position": [51.23, 356.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2038",
			"position": [51.23, 354.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2039",
			"position": [51.23, 352.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2040",
			"position": [51.23, 350.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2041",
			"position": [51.23, 348.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2042",
			"position": [51.23, 360.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2043",
			"position": [51.23, 358.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2044",
			"position": [51.23, 356.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2045",
			"position": [51.23, 354.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2046",
			"position": [49.23, 332.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2047",
			"position": [47.23, 308.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2048",
			"position": [47.23, 310.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2049",
			"position": [47.23, 312.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2050",
			"position": [47.23, 314.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2051",
			"position": [47.23, 316.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2052",
			"position": [47.23, 318.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2053",
			"position": [47.23, 320.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2054",
			"position": [47.23, 322.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2055",
			"position": [47.23, 324.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2056",
			"position": [47.23, 326.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2057",
			"position": [47.23, 328.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2058",
			"position": [47.23, 330.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2059",
			"position": [47.23, 332.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2060",
			"position": [47.23, 308.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2061",
			"position": [47.23, 310.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2062",
			"position": [47.23, 312.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2063",
			"position": [47.23, 314.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2064",
			"position": [47.23, 316.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2065",
			"position": [47.23, 318.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2066",
			"position": [47.23, 320.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2067",
			"position": [47.23, 322.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2068",
			"position": [47.23, 324.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2069",
			"position": [47.23, 326.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2070",
			"position": [47.23, 328.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2071",
			"position": [47.23, 330.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2072",
			"position": [51.23, 346.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2073",
			"position": [51.23, 344.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2074",
			"position": [51.23, 342.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2075",
			"position": [51.23, 340.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2076",
			"position": [51.23, 338.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2077",
			"position": [51.23, 336.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2078",
			"position": [51.23, 334.06, 159.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2079",
			"position": [51.23, 346.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2080",
			"position": [51.23, 344.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2081",
			"position": [51.23, 342.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2082",
			"position": [51.23, 340.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2083",
			"position": [51.23, 338.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2084",
			"position": [51.23, 336.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2085",
			"position": [51.23, 334.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "death_block_001",
			"uniqueIdentifier": "2086",
			"position": [47.23, 332.06, 133.00],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "large_square_001",
			"uniqueIdentifier": "2087",
			"position": [54.73, 346.56, 146.00],
			"scale": [3.00, 29.00, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "large_square_001",
			"uniqueIdentifier": "2088",
			"position": [52.23, 319.81, 146.00],
			"scale": [4.00, 26.50, 8.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_007",
			"uniqueIdentifier": "2089",
			"position": [40.98, 356.39, 127.62],
			"scale": [8.50, 4.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_008",
			"uniqueIdentifier": "2090",
			"position": [56.06, 363.56, 14.85],
			"scale": [4.00, 5.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "2091",
			"position": [52.60, 339.06, -45.10],
			"scale": [9.73, 14.00, 17.80],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "2092",
			"position": [103.23, 394.31, -20.75],
			"scale": [18.00, 5.50, 18.50],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "2093",
			"position": [-211.99, 217.00, 196.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "2094",
			"position": [-223.59, 252.50, 112.49],
			"scale": [4.00, 2.00, 5.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "2095",
			"position": [373.97, 1102.25, 97.60],
			"scale": [3.28, 3.28, 3.28],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "4.25", "", ""]

		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "2096",
			"position": [67.55, 276.24, 47.90],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 3.14, 0.00]
		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "2097",
			"position": [55.47, 356.19, 158.00],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "2098",
			"position": [-171.99, 205.00, 176.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "2099",
			"position": [-175.99, 208.00, 193.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "2100",
			"position": [53.19, 1414.28, 521.62],
			"scale": [6.80, 6.80, 6.80],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "pan_001",
			"uniqueIdentifier": "2101",
			"position": [-181.95, 209.00, 192.20],
			"scale": [1.50, 1.00, 1.50],
			"rotation": [0.00, 0.00, 0.00],
			"behaviour": ["Bounce", "1.75", "", ""]

		},
		{
			"assetIdentifier": "chocolate_001",
			"uniqueIdentifier": "2102",
			"position": [-223.34, 253.50, 112.39],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "fridge_001",
			"uniqueIdentifier": "2103",
			"position": [403.79, 713.57, 43.14],
			"scale": [0.45, 0.45, 0.45],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "check_point_001",
			"position": [202.12, 535.29, -14.85],
			"scale": [2.00, 2.00, 2.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "2104",
			"position": [409.03, 1100.62, 92.04],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 3.14, 0.00]
		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "2105",
			"position": [-5.26, -13.22, 0.21],
			"scale": [2.94, 2.94, 2.94],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "2106",
			"position": [-218.99, 217.00, 176.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "check_point_001",
			"uniqueIdentifier": "check_point_001",
			"position": [463.83, 1056.28, 152.29],
			"scale": [3.00, 3.00, 3.00],
			"rotation": [0.00, 0.00, 0.00]

		},
		{
			"assetIdentifier": "square_001",
			"uniqueIdentifier": "2107",
			"position": [-171.99, 208.00, 185.05],
			"scale": [4.00, 2.00, 4.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "2108",
			"position": [12.83, 1180.06, 31.01],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		},
		{
			"assetIdentifier": "gorilla",
			"uniqueIdentifier": "2109",
			"position": [-14.48, 277.00, 55.70],
			"scale": [1.00, 1.00, 1.00],
			"rotation": [0.00, 0.00, 0.00]
		}
	]
}
