"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AABB3D = void 0;
class AABB3D {
    constructor(x, y, z, halfWidth, halfHeight, halfDepth) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.halfWidth = halfWidth;
        this.halfHeight = halfHeight;
        this.halfDepth = halfDepth;
    }
}
exports.AABB3D = AABB3D;
