import { ChangeUsernameCommand, EmoteCommand, NType, ParticleTrailCommand } from "../../shared/SharedNetcodeSchemas";
import { Emotes, GetRandomEmoteAnimation } from "../../shared/data/EmoteData";
import { ParticleEffects } from "../../shared/data/ParticleData";
import { chooseRandomCountry, chooseRandomPrefixInt, chooseRandomSuffixInt } from "../../shared/data/Usernames";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";

export class ClientDebugSystem extends GameplaySystem {
    public constructor() {
        super();
    }

    protected override getSystemName(): string {
        return "Debug";
    }

    public Initialize(): void {
        document.addEventListener("keydown", (event: KeyboardEvent) => {
            if (process.env.NODE_ENV === "development") {
                if ((event.key.startsWith("F") && event.key.length === 2) || event.key.length === 3) {
                    event.preventDefault();
                }
    
                if (event.code === "F1") {
                    Game.UI.PopText("Hello");
                }
    
                if (event.code === "F2") {
                    const testSetParticleTrailCommand: ParticleTrailCommand = {
                        ntype: NType.ParticleTrailCommand,
                        particleTrail: ParticleEffects.None
                    };
                    Game.Netcode.SendCommand(testSetParticleTrailCommand);
                }
    
                if (event.code === "F3") {
                    const testSetParticleTrailCommand: ParticleTrailCommand = {
                        ntype: NType.ParticleTrailCommand,
                        particleTrail: ParticleEffects.GingerbreadMan
                    };
                    Game.Netcode.SendCommand(testSetParticleTrailCommand);
                }
    
                if (event.code === "F4") {
                    const testSetParticleTrailCommand: ParticleTrailCommand = {
                        ntype: NType.ParticleTrailCommand,
                        particleTrail: ParticleEffects.Broccolis
                    };
                    Game.Netcode.SendCommand(testSetParticleTrailCommand);
                }
    
                if (event.code === "F5") {
                    const testSetParticleTrailCommand: ParticleTrailCommand = {
                        ntype: NType.ParticleTrailCommand,
                        particleTrail: ParticleEffects.Snow
                    };
                    Game.Netcode.SendCommand(testSetParticleTrailCommand);
                }
    
                if (event.code === "F6") {
                    const testSetParticleTrailCommand: ParticleTrailCommand = {
                        ntype: NType.ParticleTrailCommand,
                        particleTrail: ParticleEffects.GoldenFlies
                    };
                    Game.Netcode.SendCommand(testSetParticleTrailCommand);
                }
    
                if (event.code === "F7") {
                    const testSetParticleTrailCommand: ParticleTrailCommand = {
                        ntype: NType.ParticleTrailCommand,
                        particleTrail: ParticleEffects.Hearts
                    };
                    Game.Netcode.SendCommand(testSetParticleTrailCommand);
                    Game.WhereAmI();
                }
                
                if (event.code === "F8") {
                    Game.UI.ToggleUI();
                }
            }
        });
    }

    public override Update(__deltaTimeS: number, __deltaTimeMS: number, __currentTime: Timestamp): void {}
    public override Cleanup(): void {}
}

