import { Config } from "../shared/Config";
import { HostPortals } from "./ClientTypes";
import { GameClient } from "./GameClient";

/**********************************/
/*                                */
/* Document Referral, User Agent, */
/* and Host Portal Identification */
/*                                */
/**********************************/

const url = window.location !== window.parent.location ? document.referrer : document.location.href;

// @ts-ignore
globalThis.UserAgent = navigator.userAgent || navigator.vendor || window.opera;

globalThis.DocumentReferrer = document.referrer.length > 0 ? document.referrer : "Direct";

globalThis.OriginalLocation = document.location.href;

globalThis.OriginatingDomain = url.length > 0 ? url : "Direct";

if (DocumentReferrer.includes("poki")) {
    globalThis.HostPortal = HostPortals.Poki;
} else {
    globalThis.HostPortal = HostPortals.DIRECT;
}

/**********************************/
/*                                */
/*        Mobile Detection        */
/*                                */
/**********************************/

// Checks for iOS
// @ts-ignore
if (/iPad|iPhone|iPod/.test(globalThis.UserAgent) && !window.MSStream) {
    globalThis.IsMobile = true;
    globalThis.IsIOS = true;
} else if (/Macintosh/.test(globalThis.UserAgent) && "ontouchend" in document) {
    // This check is for Macintosh devices with touch support, which are typically iOS devices.
    globalThis.IsMobile = true;
    globalThis.IsIOS = true;
} else if (/Macintosh/.test(globalThis.UserAgent)) {
    globalThis.IsIOS = true;
} else {
    globalThis.IsIOS = false;
}

// Checks for Android
if (/android/i.test(globalThis.UserAgent)) {
    globalThis.IsMobile = true;
    globalThis.IsAndroid = true;
} else {
    globalThis.IsAndroid = false;
}

if (globalThis.IsMobile === undefined) {
    globalThis.IsMobile = false;
}

// globalThis.IsMobile = true;

if (globalThis.IsMobile) {
    document.querySelector("html")!.classList.add("mobile");
} else {
    document.querySelector("html")!.classList.add("desktop");
}

if (globalThis.IsIOS) {
    document.body.classList.add("ios");
} else {
    document.body.classList.add("windows");
}

/**********************************/
/*                                */
/*       Browser Detection        */
/*                                */
/**********************************/

const userAgent = navigator.userAgent;
let className = "";

if (userAgent.indexOf("Chrome") !== -1) {
    className = "browser-chrome";
} else if (userAgent.indexOf("Firefox") !== -1) {
    className = "browser-firefox";
} else if (userAgent.indexOf("Safari") !== -1) {
    className = "browser-safari";
} else if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1) {
    className = "browser-ie";
} else if (userAgent.indexOf("Edge") !== -1) {
    className = "browser-edge";
} else {
    className = "browser-default";
}

console.log("@@@ Browser: ", className);
document.body.classList.add(className);

/**********************************/
/*                                */
/*      Language Detection        */
/*                                */
/**********************************/

globalThis.Lang = navigator.language.substring(0, 2);

console.log("Language detected is: " + globalThis.Lang);

document.querySelector("body")!.classList.add(globalThis.Lang);

console.log("Language detected is: " + Lang);


/**********************************/
/*                                */
/*            Bootstrap           */
/*                                */
/**********************************/

console.log("-- ENVIRONMENT DETAILS --");
console.log("Lang: " + globalThis.Lang);
console.log("OriginalLocation: " + globalThis.OriginalLocation);
console.log("OriginatingDomain: " + globalThis.OriginatingDomain);
console.log("DocumentReferrer: " + globalThis.DocumentReferrer);
console.log("Host Portal:", HostPortals[globalThis.HostPortal]);
console.log("IsMobile: " + globalThis.IsMobile);
console.log("IsIOS: " + globalThis.IsIOS);
console.log("IsAndroid: " + globalThis.IsAndroid);
console.log("Game Config: \n" + JSON.stringify(Config, null, 2));

console.log("-- USING ENV VARS --");
console.log("NODE_ENV: " + process.env.NODE_ENV);
console.log("Backend API URL: " + process.env.BACKEND_API_URL);
console.log("\n");

globalThis.currentFrameNumber = 0;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const gameClient = new GameClient();

Game.Bootstrap();
