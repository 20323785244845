{
  "pan_004": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 1.0,
        "y": 0.18000000715255737,
        "z": 2.0199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.74000000953674316,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 0.57999998331069946,
        "y": 0.18000000715255737,
        "z": 1.5199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.74000000953674316,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 0.57999998331069946,
        "y": 0.18000000715255737,
        "z": 1.5199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -1.0199999809265137
      },
      "size": {
        "x": 0.38999998569488525,
        "y": 0.20999999344348907,
        "z": 1.559999942779541
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.014365881681442261,
        "y": 0.068935014307498932,
        "z": 0.80180740356445313
      },
      "size": {
        "x": 1.9003670215606689,
        "y": 0.19579802453517914,
        "z": 1.8452167510986328
      },
      "isTrigger": true
    }
  ],
  "large_square_004": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 3.0,
        "y": 1.0000002384185791,
        "z": 3.0
      },
      "isTrigger": false
    }
  ],
  "large_cube_001": [
    {
      "center": {
        "x": 5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 2.0000002384185791,
        "z": 2.0000002384185791
      },
      "isTrigger": false
    }
  ],
  "chocolate_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.1085267066955566,
        "y": 0.70549595355987549,
        "z": 2.2170348167419434
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 1.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0,
        "y": 1.5,
        "z": 1.0
      },
      "isTrigger": true
    }
  ],
  "square_002": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "pot_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.51318621635437,
        "y": 1.2653341293334961,
        "z": 1.9708390235900879
      },
      "isTrigger": false
    }
  ],
  "toaster_002": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.13758084177970886
      },
      "size": {
        "x": 1.0,
        "y": 1.2379544973373413,
        "z": 2.1030893325805664
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0034229867160320282,
        "y": -0.25063121318817139,
        "z": -1.0408449172973633
      },
      "size": {
        "x": 0.21347165107727051,
        "y": 0.061112463474273682,
        "z": 0.29656124114990234
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.28114610910415649,
        "y": -0.17570196092128754,
        "z": -0.8950505256652832
      },
      "size": {
        "x": 0.096408486366271973,
        "y": 0.20048382878303528,
        "z": 0.0788068175315857
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.34417948126792908,
        "y": -0.24012662470340729,
        "z": -0.93979746103286743
      },
      "size": {
        "x": 0.24769987165927887,
        "y": 0.30055311322212219,
        "z": 0.10375559329986572
      },
      "isTrigger": false
    }
  ],
  "large_cube_002": [
    {
      "center": {
        "x": 5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 2.0000002384185791,
        "z": 2.0000002384185791
      },
      "isTrigger": false
    }
  ],
  "finish_line_001": [
    {
      "center": {
        "x": -1.7479212284088135,
        "y": -0.069418430328369141,
        "z": -0.007613837718963623
      },
      "size": {
        "x": 0.17605257034301758,
        "y": 3.7768521308898926,
        "z": 0.14931517839431763
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 1.74601149559021,
        "y": -0.066172599792480469,
        "z": -0.014989811927080154
      },
      "size": {
        "x": 0.15746688842773438,
        "y": 3.78334379196167,
        "z": 0.15363988280296326
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.702745795249939
      },
      "size": {
        "x": 3.7778167724609375,
        "y": 3.9156889915466309,
        "z": 1.589111328125
      },
      "isTrigger": true
    }
  ],
  "square_004": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "large_square_002": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 3.0,
        "y": 1.0000002384185791,
        "z": 3.0
      },
      "isTrigger": false
    }
  ],
  "pot_003": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.51318621635437,
        "y": 1.2653341293334961,
        "z": 1.9708396196365356
      },
      "isTrigger": false
    }
  ],
  "knife_002": [
    {
      "center": {
        "x": -0.011043436825275421,
        "y": 0.16311782598495483,
        "z": -0.0024069510400295258
      },
      "size": {
        "x": 0.3683963418006897,
        "y": 1.0150455236434937,
        "z": 0.05806925892829895
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.023808896541595459,
        "y": 0.82785052061080933,
        "z": -0.0020783524960279465
      },
      "size": {
        "x": 0.29890555143356323,
        "y": 0.47955977916717529,
        "z": 0.055922228842973709
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.050384253263473511,
        "y": 1.1830744743347168,
        "z": -0.0038808174431324005
      },
      "size": {
        "x": 0.24084553122520447,
        "y": 0.25540637969970703,
        "z": 0.059584043920040131
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.082142122089862823,
        "y": -0.86230021715164185,
        "z": 4.4703483581542969E-08
      },
      "size": {
        "x": 0.2261989563703537,
        "y": 0.89695441722869873,
        "z": 0.098463132977485657
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0823669582605362,
        "y": -0.38454991579055786,
        "z": 0.00085180718451738358
      },
      "size": {
        "x": 0.17745760083198547,
        "y": 0.0929117202758789,
        "z": 0.051103830337524414
      },
      "isTrigger": false
    }
  ],
  "pan_alt_004": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 5.9604644775390625E-08
      },
      "size": {
        "x": 2.0500655174255371,
        "y": 0.40493813157081604,
        "z": 3.8588204383850098
      },
      "isTrigger": false
    }
  ],
  "gorilla": [
    {
      "center": {
        "x": 0.0,
        "y": 1.1322486400604248,
        "z": 0.24907012283802032
      },
      "size": {
        "x": 1.6004586219787598,
        "y": 2.2749147415161133,
        "z": 1.15657377243042
      },
      "isTrigger": false
    }
  ],
  "moving_block_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.1000000238418579,
        "y": 1.1000000238418579,
        "z": 1.1000000238418579
      },
      "isTrigger": true
    }
  ],
  "baking_pan_003": [
    {
      "center": {
        "x": -0.534504771232605,
        "y": 0.012933865189552307,
        "z": 0.0
      },
      "size": {
        "x": 0.14186429977416992,
        "y": 0.62792372703552246,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.544675350189209,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.12152343988418579,
        "y": 0.65379136800765991,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": -0.30177056789398193,
        "z": 0.0
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.050250142812728882,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -0.9915010929107666
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.65379136800765991,
        "z": 0.12336254119873047
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.97736108303070068
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.65379136800765991,
        "z": 0.1516425609588623
      },
      "isTrigger": false
    }
  ],
  "large_cube_006": [
    {
      "center": {
        "x": 5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 2.0000002384185791,
        "z": 2.0000002384185791
      },
      "isTrigger": false
    }
  ],
  "medium_square_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 2.0
      },
      "isTrigger": false
    }
  ],
  "death_block_001": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.95999997854232788,
        "y": 0.95999997854232788,
        "z": 0.95999997854232788
      },
      "isTrigger": true
    }
  ],
  "medium_square_006": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 2.0
      },
      "isTrigger": false
    }
  ],
  "plate_002": [
    {
      "center": {
        "x": 0.0,
        "y": 2.9802322387695313E-08,
        "z": 0.0
      },
      "size": {
        "x": 2.4441673755645752,
        "y": 0.30922162532806396,
        "z": 2.4441685676574707
      },
      "isTrigger": false
    }
  ],
  "big_spoon_004": [
    {
      "center": {
        "x": -0.00052617490291595459,
        "y": -0.60967212915420532,
        "z": -0.11327926814556122
      },
      "size": {
        "x": 0.11691701412200928,
        "y": 0.77707874774932861,
        "z": 0.099885314702987671
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.573598325252533,
        "z": 0.079839743673801422
      },
      "size": {
        "x": 0.73750853538513184,
        "y": 0.84922635555267334,
        "z": 0.12918432056903839
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.0018323063850402832,
        "y": -0.043056756258010864,
        "z": -0.052273482084274292
      },
      "size": {
        "x": 0.10984069108963013,
        "y": 0.3983338475227356,
        "z": 0.14457553625106812
      },
      "isTrigger": false
    }
  ],
  "baking_tray_002": [
    {
      "center": {
        "x": 0.0,
        "y": -0.058290369808673859,
        "z": 0.0
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.051662400364875793,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 1.0287703275680542,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.10455065965652466,
        "y": 0.16824313998222351,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -1.0334529876708984,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.0951852798461914,
        "y": 0.16824313998222351,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 1.5256156921386719
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.16824313998222351,
        "z": 0.10793650150299072
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -1.5329833030700684
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.16824313998222351,
        "z": 0.0932011604309082
      },
      "isTrigger": false
    }
  ],
  "pepper": [
    {
      "center": {
        "x": 0.0,
        "y": 5.9604644775390625E-08,
        "z": 1.4901161193847656E-08
      },
      "size": {
        "x": 0.22482398152351379,
        "y": 0.35887220501899719,
        "z": 0.213820219039917
      },
      "isTrigger": false
    }
  ],
  "toaster_003": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.13758084177970886
      },
      "size": {
        "x": 1.0,
        "y": 1.2379544973373413,
        "z": 2.1030893325805664
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.28114610910415649,
        "y": -0.17570196092128754,
        "z": -0.8950505256652832
      },
      "size": {
        "x": 0.096408486366271973,
        "y": 0.20048382878303528,
        "z": 0.0788068175315857
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.34417948126792908,
        "y": -0.24012662470340729,
        "z": -0.93979746103286743
      },
      "size": {
        "x": 0.24769987165927887,
        "y": 0.30055311322212219,
        "z": 0.10375559329986572
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0034229867160320282,
        "y": -0.25063121318817139,
        "z": -1.0408449172973633
      },
      "size": {
        "x": 0.21347165107727051,
        "y": 0.061112463474273682,
        "z": 0.29656124114990234
      },
      "isTrigger": false
    }
  ],
  "large_cube_005": [
    {
      "center": {
        "x": 5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 2.0000002384185791,
        "z": 2.0000002384185791
      },
      "isTrigger": false
    }
  ],
  "rectangle_004": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "large_cube_008": [
    {
      "center": {
        "x": 5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 2.0000002384185791,
        "z": 2.0000002384185791
      },
      "isTrigger": false
    }
  ],
  "large_square_008": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 3.0,
        "y": 1.0000002384185791,
        "z": 3.0
      },
      "isTrigger": false
    }
  ],
  "salt": [
    {
      "center": {
        "x": 0.0,
        "y": 5.9604644775390625E-08,
        "z": 1.4901161193847656E-08
      },
      "size": {
        "x": 0.22482398152351379,
        "y": 0.35887220501899719,
        "z": 0.2138201892375946
      },
      "isTrigger": false
    }
  ],
  "pan_alt_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 5.9604644775390625E-08
      },
      "size": {
        "x": 2.0500657558441162,
        "y": 0.40493813157081604,
        "z": 3.8588204383850098
      },
      "isTrigger": false
    }
  ],
  "toaster_004": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.13758084177970886
      },
      "size": {
        "x": 1.0,
        "y": 1.2379544973373413,
        "z": 2.1030893325805664
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.28114610910415649,
        "y": -0.17570196092128754,
        "z": -0.8950505256652832
      },
      "size": {
        "x": 0.096408486366271973,
        "y": 0.20048382878303528,
        "z": 0.0788068175315857
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.34417948126792908,
        "y": -0.24012662470340729,
        "z": -0.93979746103286743
      },
      "size": {
        "x": 0.24769987165927887,
        "y": 0.30055311322212219,
        "z": 0.10375559329986572
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0034229867160320282,
        "y": -0.25063121318817139,
        "z": -1.0408449172973633
      },
      "size": {
        "x": 0.21347165107727051,
        "y": 0.061112463474273682,
        "z": 0.29656124114990234
      },
      "isTrigger": false
    }
  ],
  "square_005": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "large_cube_007": [
    {
      "center": {
        "x": 5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 2.0000002384185791,
        "z": 2.0000002384185791
      },
      "isTrigger": false
    }
  ],
  "spatula_004": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -3.7252902984619141E-09
      },
      "size": {
        "x": 0.49156206846237183,
        "y": 2.005338191986084,
        "z": 0.21340546011924744
      },
      "isTrigger": false
    }
  ],
  "medium_square_005": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 2.0
      },
      "isTrigger": false
    }
  ],
  "large_square_006": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 3.0,
        "y": 1.0000002384185791,
        "z": 3.0
      },
      "isTrigger": false
    }
  ],
  "big_spoon_002": [
    {
      "center": {
        "x": -0.00052617490291595459,
        "y": -0.60967212915420532,
        "z": -0.11327926814556122
      },
      "size": {
        "x": 0.11691701412200928,
        "y": 0.77707874774932861,
        "z": 0.099885314702987671
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.573598325252533,
        "z": 0.079839743673801422
      },
      "size": {
        "x": 0.73750853538513184,
        "y": 0.84922635555267334,
        "z": 0.12918432056903839
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.0018323063850402832,
        "y": -0.043056756258010864,
        "z": -0.052273482084274292
      },
      "size": {
        "x": 0.10984069108963013,
        "y": 0.3983338475227356,
        "z": 0.14457553625106812
      },
      "isTrigger": false
    }
  ],
  "fork_003": [
    {
      "center": {
        "x": 0.0,
        "y": 0.57128024101257324,
        "z": 0.048055566847324371
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.55277061462402344,
        "z": 0.021714936941862106
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": -0.39334407448768616,
        "z": -0.013488059863448143
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.94731277227401733,
        "z": 0.0341276191174984
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.18652744591236115,
        "z": 0.021313212811946869
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.22889444231987,
        "z": 0.045524150133132935
      },
      "isTrigger": false
    }
  ],
  "purple_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.1085267066955566,
        "y": 0.70549595355987549,
        "z": 2.2170348167419434
      },
      "isTrigger": false
    }
  ],
  "chopping_kinfe_001": [
    {
      "center": {
        "x": -0.013463951647281647,
        "y": 0.15075382590293884,
        "z": -0.0014901086688041687
      },
      "size": {
        "x": 0.48350924253463745,
        "y": 0.99960392713546753,
        "z": 0.054346740245819092
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.014500349760055542,
        "y": 0.87413740158081055,
        "z": -0.0014534033834934235
      },
      "size": {
        "x": 0.48358696699142456,
        "y": 0.4431614875793457,
        "z": 0.05543527752161026
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.032472580671310425,
        "y": 1.20098876953125,
        "z": -0.0057065002620220184
      },
      "size": {
        "x": 0.385860800743103,
        "y": 0.21957731246948242,
        "z": 0.060679636895656586
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.13517045974731445,
        "y": -0.37926691770553589,
        "z": -0.0019107433035969734
      },
      "size": {
        "x": 0.1827847957611084,
        "y": 0.083763360977172852,
        "z": 0.0556485578417778
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.13590458035469055,
        "y": -0.865192174911499,
        "z": 4.4703483581542969E-08
      },
      "size": {
        "x": 0.22677695751190186,
        "y": 0.89597654342651367,
        "z": 0.098463132977485657
      },
      "isTrigger": false
    }
  ],
  "spatula_003": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -3.7252902984619141E-09
      },
      "size": {
        "x": 0.49156203866004944,
        "y": 2.0053384304046631,
        "z": 0.21340546011924744
      },
      "isTrigger": false
    }
  ],
  "baking_pan_002": [
    {
      "center": {
        "x": -0.534504771232605,
        "y": 0.012933865189552307,
        "z": 0.0
      },
      "size": {
        "x": 0.14186429977416992,
        "y": 0.62792372703552246,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.544675350189209,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.12152343988418579,
        "y": 0.65379136800765991,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": -0.30177056789398193,
        "z": 0.0
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.050250142812728882,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -0.9915010929107666
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.65379136800765991,
        "z": 0.12336254119873047
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.97736108303070068
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.65379136800765991,
        "z": 0.1516425609588623
      },
      "isTrigger": false
    }
  ],
  "fire_extinguisher_001": [
    {
      "center": {
        "x": 0.051660776138305664,
        "y": -0.32330912351608276,
        "z": 2.9802322387695313E-08
      },
      "size": {
        "x": 0.96925115585327148,
        "y": 2.6754655838012695,
        "z": 0.9146154522895813
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.29850995540618896,
        "y": 1.2706525325775146,
        "z": 0.033145681023597717
      },
      "size": {
        "x": 0.82843661308288574,
        "y": 0.68379044532775879,
        "z": 0.30527329444885254
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.40815630555152893,
        "y": 0.44088941812515259,
        "z": 0.012096703052520752
      },
      "size": {
        "x": 0.61047106981277466,
        "y": 1.9521888494491577,
        "z": 0.10329648852348328
      },
      "isTrigger": false
    }
  ],
  "baking_tray_004": [
    {
      "center": {
        "x": 0.0,
        "y": -0.058290369808673859,
        "z": 0.0
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.051662400364875793,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 1.0287703275680542,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.10455065965652466,
        "y": 0.16824313998222351,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -1.0334529876708984,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.0951852798461914,
        "y": 0.16824313998222351,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 1.5256156921386719
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.16824313998222351,
        "z": 0.10793650150299072
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -1.5329833030700684
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.16824313998222351,
        "z": 0.0932011604309082
      },
      "isTrigger": false
    }
  ],
  "medium_square_008": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 2.0
      },
      "isTrigger": false
    }
  ],
  "rectangle_006": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "cleaver_001": [
    {
      "center": {
        "x": -0.011205479502677917,
        "y": 0.47758585214614868,
        "z": 0.0027481187134981155
      },
      "size": {
        "x": 0.69252860546112061,
        "y": 1.6663831472396851,
        "z": 0.056859951466321945
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.24006824195384979,
        "y": -0.86279255151748657,
        "z": 0.0018422529101371765
      },
      "size": {
        "x": 0.23025992512702942,
        "y": 0.90158069133758545,
        "z": 0.10214754939079285
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.24500070512294769,
        "y": -0.35259777307510376,
        "z": 0.00297660194337368
      },
      "size": {
        "x": 0.17818376421928406,
        "y": 0.13774240016937256,
        "z": 0.056179087609052658
      },
      "isTrigger": false
    }
  ],
  "check_point_001": [
    {
      "center": {
        "x": 0.0,
        "y": -2.9802322387695313E-08,
        "z": -0.49999997019767761
      },
      "size": {
        "x": 2.0,
        "y": 1.0000002384185791,
        "z": 2.0
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 1.440000057220459,
        "z": -0.49999997019767761
      },
      "size": {
        "x": 2.0,
        "y": 2.0199999809265137,
        "z": 2.0
      },
      "isTrigger": true
    }
  ],
  "large_square_005": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 3.0,
        "y": 1.0000002384185791,
        "z": 3.0
      },
      "isTrigger": false
    }
  ],
  "medium_square_007": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 2.0
      },
      "isTrigger": false
    }
  ],
  "fork_002": [
    {
      "center": {
        "x": 0.0,
        "y": 0.57128024101257324,
        "z": 0.048055566847324371
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.55277061462402344,
        "z": 0.021714936941862106
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": -0.39334407448768616,
        "z": -0.013488059863448143
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.94731277227401733,
        "z": 0.0341276191174984
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.18652744591236115,
        "z": 0.021313212811946869
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.22889444231987,
        "z": 0.045524150133132935
      },
      "isTrigger": false
    }
  ],
  "knife_001": [
    {
      "center": {
        "x": -0.011043436825275421,
        "y": 0.16311782598495483,
        "z": -0.0024069510400295258
      },
      "size": {
        "x": 0.3683963418006897,
        "y": 1.0150455236434937,
        "z": 0.05806925892829895
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.023808896541595459,
        "y": 0.82785052061080933,
        "z": -0.0020783524960279465
      },
      "size": {
        "x": 0.29890555143356323,
        "y": 0.47955977916717529,
        "z": 0.055922228842973709
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.050384253263473511,
        "y": 1.1830744743347168,
        "z": -0.0038808174431324005
      },
      "size": {
        "x": 0.24084553122520447,
        "y": 0.25540637969970703,
        "z": 0.059584043920040131
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.082142122089862823,
        "y": -0.86230021715164185,
        "z": 4.4703483581542969E-08
      },
      "size": {
        "x": 0.2261989563703537,
        "y": 0.89695441722869873,
        "z": 0.098463132977485657
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0823669582605362,
        "y": -0.38454991579055786,
        "z": 0.00085180718451738358
      },
      "size": {
        "x": 0.17745760083198547,
        "y": 0.0929117202758789,
        "z": 0.051103830337524414
      },
      "isTrigger": false
    }
  ],
  "bowl_004": [
    {
      "center": {
        "x": 0.0,
        "y": -0.32636058330535889,
        "z": 0.0
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.063359498977661133,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.49935406446456909,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.098891854286193848,
        "y": 0.71608066558837891,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.50108790397644043,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.0954243540763855,
        "y": 0.71608066558837891,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -0.50542956590652466
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.71608066558837891,
        "z": 0.0867408812046051
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.50675868988037109
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.71608066558837891,
        "z": 0.084082871675491333
      },
      "isTrigger": false
    }
  ],
  "large_square_001": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 3.0,
        "y": 1.0000002384185791,
        "z": 3.0
      },
      "isTrigger": false
    }
  ],
  "large_square_007": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 3.0,
        "y": 1.0000002384185791,
        "z": 3.0
      },
      "isTrigger": false
    }
  ],
  "large_square_003": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 3.0,
        "y": 1.0000002384185791,
        "z": 3.0
      },
      "isTrigger": false
    }
  ],
  "bowl_001": [
    {
      "center": {
        "x": 0.0,
        "y": -0.32636058330535889,
        "z": 0.0
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.063359498977661133,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.49935406446456909,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.098891854286193848,
        "y": 0.71608066558837891,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.50108790397644043,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.0954243540763855,
        "y": 0.71608066558837891,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -0.50542956590652466
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.71608066558837891,
        "z": 0.0867408812046051
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.50675868988037109
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.71608066558837891,
        "z": 0.084082871675491333
      },
      "isTrigger": false
    }
  ],
  "bowl_002": [
    {
      "center": {
        "x": 0.0,
        "y": -0.32636058330535889,
        "z": 0.0
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.063359498977661133,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.49935406446456909,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.098891854286193848,
        "y": 0.71608066558837891,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.50108790397644043,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.0954243540763855,
        "y": 0.71608066558837891,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -0.50542956590652466
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.71608066558837891,
        "z": 0.0867408812046051
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.50675868988037109
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.71608066558837891,
        "z": 0.084082871675491333
      },
      "isTrigger": false
    }
  ],
  "square_006": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "pan_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 1.0,
        "y": 0.18000000715255737,
        "z": 2.0199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.74000000953674316,
        "y": -0.0033398419618606567,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 0.57999992370605469,
        "y": 0.17332032322883606,
        "z": 1.5199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.74000000953674316,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 0.57999998331069946,
        "y": 0.18000000715255737,
        "z": 1.5199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -1.0199999809265137
      },
      "size": {
        "x": 0.38999998569488525,
        "y": 0.20999999344348907,
        "z": 1.559999942779541
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.014365881681442261,
        "y": 0.068935014307498932,
        "z": 0.80180740356445313
      },
      "size": {
        "x": 1.9003670215606689,
        "y": 0.19579802453517914,
        "z": 1.8452167510986328
      },
      "isTrigger": true
    }
  ],
  "bowl_003": [
    {
      "center": {
        "x": 0.0,
        "y": -0.32636058330535889,
        "z": 0.0
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.063359498977661133,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.49935406446456909,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.098891854286193848,
        "y": 0.71608066558837891,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.50108790397644043,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.0954243540763855,
        "y": 0.71608066558837891,
        "z": 1.0976001024246216
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -0.50542956590652466
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.71608066558837891,
        "z": 0.0867408812046051
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.50675868988037109
      },
      "size": {
        "x": 1.0976001024246216,
        "y": 0.71608066558837891,
        "z": 0.084082871675491333
      },
      "isTrigger": false
    }
  ],
  "plate_001": [
    {
      "center": {
        "x": 0.0,
        "y": 2.9802322387695313E-08,
        "z": 0.0
      },
      "size": {
        "x": 2.4441673755645752,
        "y": 0.30922162532806396,
        "z": 2.4441685676574707
      },
      "isTrigger": false
    }
  ],
  "rectangle_002": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "spatula_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -3.7252902984619141E-09
      },
      "size": {
        "x": 0.49156218767166138,
        "y": 2.005338191986084,
        "z": 0.21340546011924744
      },
      "isTrigger": false
    }
  ],
  "chopping_kinfe_003": [
    {
      "center": {
        "x": -0.013463951647281647,
        "y": 0.15075382590293884,
        "z": -0.0014901086688041687
      },
      "size": {
        "x": 0.48350924253463745,
        "y": 0.99960392713546753,
        "z": 0.054346740245819092
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.014500349760055542,
        "y": 0.87413740158081055,
        "z": -0.0014534033834934235
      },
      "size": {
        "x": 0.48358696699142456,
        "y": 0.4431614875793457,
        "z": 0.05543527752161026
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.032472580671310425,
        "y": 1.20098876953125,
        "z": -0.0057065002620220184
      },
      "size": {
        "x": 0.385860800743103,
        "y": 0.21957731246948242,
        "z": 0.060679636895656586
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.13517045974731445,
        "y": -0.37926691770553589,
        "z": -0.0019107433035969734
      },
      "size": {
        "x": 0.1827847957611084,
        "y": 0.083763360977172852,
        "z": 0.0556485578417778
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.13590458035469055,
        "y": -0.865192174911499,
        "z": 4.4703483581542969E-08
      },
      "size": {
        "x": 0.22677695751190186,
        "y": 0.89597654342651367,
        "z": 0.098463132977485657
      },
      "isTrigger": false
    }
  ],
  "square_008": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "plate_003": [
    {
      "center": {
        "x": 0.0,
        "y": 2.9802322387695313E-08,
        "z": 0.0
      },
      "size": {
        "x": 2.4441673755645752,
        "y": 0.30922162532806396,
        "z": 2.4441685676574707
      },
      "isTrigger": false
    }
  ],
  "plate_004": [
    {
      "center": {
        "x": 0.0,
        "y": 2.9802322387695313E-08,
        "z": 0.0
      },
      "size": {
        "x": 2.4441673755645752,
        "y": 0.30922162532806396,
        "z": 2.4441685676574707
      },
      "isTrigger": false
    }
  ],
  "baking_tray_003": [
    {
      "center": {
        "x": 0.0,
        "y": -0.058290369808673859,
        "z": 0.0
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.051662400364875793,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 1.0287703275680542,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.10455065965652466,
        "y": 0.16824313998222351,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -1.0334529876708984,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.0951852798461914,
        "y": 0.16824313998222351,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 1.5256156921386719
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.16824313998222351,
        "z": 0.10793650150299072
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -1.5329833030700684
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.16824313998222351,
        "z": 0.0932011604309082
      },
      "isTrigger": false
    }
  ],
  "pan_alt_003": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 5.9604644775390625E-08
      },
      "size": {
        "x": 2.0500655174255371,
        "y": 0.40493813157081604,
        "z": 3.8588204383850098
      },
      "isTrigger": false
    }
  ],
  "rectangle_003": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "rolling_pin": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.4123914241790771,
        "y": 0.30118736624717712,
        "z": 0.31668701767921448
      },
      "isTrigger": false
    }
  ],
  "square_003": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "vanilla_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.1085267066955566,
        "y": 0.70549595355987549,
        "z": 2.2170348167419434
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 1.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0,
        "y": 1.5,
        "z": 1.0
      },
      "isTrigger": true
    }
  ],
  "medium_square_004": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 2.0
      },
      "isTrigger": false
    }
  ],
  "square_001": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "toaster_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.13758084177970886
      },
      "size": {
        "x": 1.0,
        "y": 1.2379544973373413,
        "z": 2.1030893325805664
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0034229867160320282,
        "y": -0.25063121318817139,
        "z": -1.0408449172973633
      },
      "size": {
        "x": 0.21347165107727051,
        "y": 0.061112463474273682,
        "z": 0.29656124114990234
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.28114610910415649,
        "y": -0.17570196092128754,
        "z": -0.8950505256652832
      },
      "size": {
        "x": 0.096408486366271973,
        "y": 0.20048382878303528,
        "z": 0.0788068175315857
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.34417948126792908,
        "y": -0.24012662470340729,
        "z": -0.93979746103286743
      },
      "size": {
        "x": 0.24769987165927887,
        "y": 0.30055311322212219,
        "z": 0.10375559329986572
      },
      "isTrigger": false
    }
  ],
  "slow_block_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "large_cube_003": [
    {
      "center": {
        "x": 5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 2.0000002384185791,
        "z": 2.0000002384185791
      },
      "isTrigger": false
    }
  ],
  "chopping_kinfe_004": [
    {
      "center": {
        "x": -0.013463951647281647,
        "y": 0.15075382590293884,
        "z": -0.0014901086688041687
      },
      "size": {
        "x": 0.48350924253463745,
        "y": 0.99960392713546753,
        "z": 0.054346740245819092
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.014500349760055542,
        "y": 0.87413740158081055,
        "z": -0.0014534033834934235
      },
      "size": {
        "x": 0.48358696699142456,
        "y": 0.4431614875793457,
        "z": 0.05543527752161026
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.032472580671310425,
        "y": 1.20098876953125,
        "z": -0.0057065002620220184
      },
      "size": {
        "x": 0.385860800743103,
        "y": 0.21957731246948242,
        "z": 0.060679636895656586
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.13517045974731445,
        "y": -0.37926691770553589,
        "z": -0.0019107433035969734
      },
      "size": {
        "x": 0.1827847957611084,
        "y": 0.083763360977172852,
        "z": 0.0556485578417778
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.13590458035469055,
        "y": -0.865192174911499,
        "z": 4.4703483581542969E-08
      },
      "size": {
        "x": 0.22677695751190186,
        "y": 0.89597654342651367,
        "z": 0.098463132977485657
      },
      "isTrigger": false
    }
  ],
  "pan_002": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 1.0,
        "y": 0.18000000715255737,
        "z": 2.0199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.74000000953674316,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 0.57999998331069946,
        "y": 0.18000000715255737,
        "z": 1.5199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.74000000953674316,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 0.57999998331069946,
        "y": 0.18000000715255737,
        "z": 1.5199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -1.0199999809265137
      },
      "size": {
        "x": 0.38999998569488525,
        "y": 0.20999999344348907,
        "z": 1.559999942779541
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.014365881681442261,
        "y": 0.068935014307498932,
        "z": 0.80180740356445313
      },
      "size": {
        "x": 1.9003670215606689,
        "y": 0.19579802453517914,
        "z": 1.8452167510986328
      },
      "isTrigger": true
    }
  ],
  "knife_004": [
    {
      "center": {
        "x": -0.011043436825275421,
        "y": 0.16311782598495483,
        "z": -0.0024069510400295258
      },
      "size": {
        "x": 0.3683963418006897,
        "y": 1.0150455236434937,
        "z": 0.05806925892829895
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.023808896541595459,
        "y": 0.82785052061080933,
        "z": -0.0020783524960279465
      },
      "size": {
        "x": 0.29890555143356323,
        "y": 0.47955977916717529,
        "z": 0.055922228842973709
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.050384253263473511,
        "y": 1.1830744743347168,
        "z": -0.0038808174431324005
      },
      "size": {
        "x": 0.24084553122520447,
        "y": 0.25540637969970703,
        "z": 0.059584043920040131
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.082142122089862823,
        "y": -0.86230021715164185,
        "z": 4.4703483581542969E-08
      },
      "size": {
        "x": 0.2261989563703537,
        "y": 0.89695441722869873,
        "z": 0.098463132977485657
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0823669582605362,
        "y": -0.38454991579055786,
        "z": 0.00085180718451738358
      },
      "size": {
        "x": 0.17745760083198547,
        "y": 0.0929117202758789,
        "z": 0.051103830337524414
      },
      "isTrigger": false
    }
  ],
  "square_007": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "mango_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.1085267066955566,
        "y": 0.70549595355987549,
        "z": 2.2170348167419434
      },
      "isTrigger": false
    }
  ],
  "pot_004": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.51318621635437,
        "y": 1.2653341293334961,
        "z": 1.9708396196365356
      },
      "isTrigger": false
    }
  ],
  "large_cube_004": [
    {
      "center": {
        "x": 5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 2.0000002384185791,
        "z": 2.0000002384185791
      },
      "isTrigger": false
    }
  ],
  "medium_square_002": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 2.0
      },
      "isTrigger": false
    }
  ],
  "pan_alt_002": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0500655174255371,
        "y": 0.40493813157081604,
        "z": 3.8588201999664307
      },
      "isTrigger": false
    }
  ],
  "cutting_board": [
    {
      "center": {
        "x": 0.0,
        "y": 7.4505805969238281E-08,
        "z": 0.0
      },
      "size": {
        "x": 3.3922994136810303,
        "y": 0.16410917043685913,
        "z": 1.8569973707199097
      },
      "isTrigger": false
    }
  ],
  "rectangle_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "baking_pan_001": [
    {
      "center": {
        "x": -0.534504771232605,
        "y": 0.012933865189552307,
        "z": 0.0
      },
      "size": {
        "x": 0.14186429977416992,
        "y": 0.62792372703552246,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.544675350189209,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.12152343988418579,
        "y": 0.65379136800765991,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": -0.30177056789398193,
        "z": 0.0
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.050250142812728882,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -0.9915010929107666
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.65379136800765991,
        "z": 0.12336254119873047
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.97736108303070068
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.65379136800765991,
        "z": 0.1516425609588623
      },
      "isTrigger": false
    }
  ],
  "baking_pan_004": [
    {
      "center": {
        "x": -0.534504771232605,
        "y": 0.012933865189552307,
        "z": 0.0
      },
      "size": {
        "x": 0.14186429977416992,
        "y": 0.62792372703552246,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.544675350189209,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.12152343988418579,
        "y": 0.65379136800765991,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": -0.30177056789398193,
        "z": 0.0
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.050250142812728882,
        "z": 2.1063647270202637
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -0.9915010929107666
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.65379136800765991,
        "z": 0.12336254119873047
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.97736108303070068
      },
      "size": {
        "x": 1.2108739614486694,
        "y": 0.65379136800765991,
        "z": 0.1516425609588623
      },
      "isTrigger": false
    }
  ],
  "big_spoon_001": [
    {
      "center": {
        "x": -0.00052617490291595459,
        "y": -0.60967212915420532,
        "z": -0.11327926814556122
      },
      "size": {
        "x": 0.11691701412200928,
        "y": 0.77707874774932861,
        "z": 0.099885314702987671
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.573598325252533,
        "z": 0.079839743673801422
      },
      "size": {
        "x": 0.73750853538513184,
        "y": 0.84922635555267334,
        "z": 0.12918432056903839
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.0018323063850402832,
        "y": -0.043056756258010864,
        "z": -0.052273482084274292
      },
      "size": {
        "x": 0.10984069108963013,
        "y": 0.3983338475227356,
        "z": 0.14457553625106812
      },
      "isTrigger": false
    }
  ],
  "pot_002": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.51318621635437,
        "y": 1.2653341293334961,
        "z": 1.9708396196365356
      },
      "isTrigger": false
    }
  ],
  "baking_tray_001": [
    {
      "center": {
        "x": 0.0,
        "y": -0.058290369808673859,
        "z": 0.0
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.051662400364875793,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 1.0287703275680542,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.10455065965652466,
        "y": 0.16824313998222351,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -1.0334529876708984,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 0.0951852798461914,
        "y": 0.16824313998222351,
        "z": 3.159168004989624
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 1.5256156921386719
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.16824313998222351,
        "z": 0.10793650150299072
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -1.5329833030700684
      },
      "size": {
        "x": 2.1620912551879883,
        "y": 0.16824313998222351,
        "z": 0.0932011604309082
      },
      "isTrigger": false
    }
  ],
  "slippery_block_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.1000000238418579,
        "y": 1.1000000238418579,
        "z": 1.1000000238418579
      },
      "isTrigger": true
    }
  ],
  "fork_004": [
    {
      "center": {
        "x": 0.0,
        "y": 0.57128024101257324,
        "z": 0.048055566847324371
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.55277061462402344,
        "z": 0.021714936941862106
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": -0.39334407448768616,
        "z": -0.013488059863448143
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.94731277227401733,
        "z": 0.0341276191174984
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.18652744591236115,
        "z": 0.021313212811946869
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.22889444231987,
        "z": 0.045524150133132935
      },
      "isTrigger": false
    }
  ],
  "chopping_kinfe_002": [
    {
      "center": {
        "x": -0.013463951647281647,
        "y": 0.15075382590293884,
        "z": -0.0014901086688041687
      },
      "size": {
        "x": 0.48350924253463745,
        "y": 0.99960392713546753,
        "z": 0.054346740245819092
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.014500349760055542,
        "y": 0.87413740158081055,
        "z": -0.0014534033834934235
      },
      "size": {
        "x": 0.48358696699142456,
        "y": 0.4431614875793457,
        "z": 0.05543527752161026
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.032472580671310425,
        "y": 1.20098876953125,
        "z": -0.0057065002620220184
      },
      "size": {
        "x": 0.385860800743103,
        "y": 0.21957731246948242,
        "z": 0.060679636895656586
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.13517045974731445,
        "y": -0.37926691770553589,
        "z": -0.0019107433035969734
      },
      "size": {
        "x": 0.1827847957611084,
        "y": 0.083763360977172852,
        "z": 0.0556485578417778
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.13590458035469055,
        "y": -0.865192174911499,
        "z": 4.4703483581542969E-08
      },
      "size": {
        "x": 0.22677695751190186,
        "y": 0.89597654342651367,
        "z": 0.098463132977485657
      },
      "isTrigger": false
    }
  ],
  "rectangle_005": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "strawberry_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.1085267066955566,
        "y": 0.70549595355987549,
        "z": 2.2170348167419434
      },
      "isTrigger": false
    }
  ],
  "medium_square_003": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.0,
        "y": 1.0000001192092896,
        "z": 2.0
      },
      "isTrigger": false
    }
  ],
  "blueberry_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.1085267066955566,
        "y": 0.70549595355987549,
        "z": 2.2170348167419434
      },
      "isTrigger": false
    }
  ],
  "knife_003": [
    {
      "center": {
        "x": -0.011043436825275421,
        "y": 0.16311782598495483,
        "z": -0.0024069510400295258
      },
      "size": {
        "x": 0.3683963418006897,
        "y": 1.0150455236434937,
        "z": 0.05806925892829895
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.023808896541595459,
        "y": 0.82785052061080933,
        "z": -0.0020783524960279465
      },
      "size": {
        "x": 0.29890555143356323,
        "y": 0.47955977916717529,
        "z": 0.055922228842973709
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.050384253263473511,
        "y": 1.1830744743347168,
        "z": -0.0038808174431324005
      },
      "size": {
        "x": 0.24084553122520447,
        "y": 0.25540637969970703,
        "z": 0.059584043920040131
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.082142122089862823,
        "y": -0.86230021715164185,
        "z": 4.4703483581542969E-08
      },
      "size": {
        "x": 0.2261989563703537,
        "y": 0.89695441722869873,
        "z": 0.098463132977485657
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0823669582605362,
        "y": -0.38454991579055786,
        "z": 0.00085180718451738358
      },
      "size": {
        "x": 0.17745760083198547,
        "y": 0.0929117202758789,
        "z": 0.051103830337524414
      },
      "isTrigger": false
    }
  ],
  "pan_003": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 1.0,
        "y": 0.18000000715255737,
        "z": 2.0199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.74000000953674316,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 0.57999998331069946,
        "y": 0.18000000715255737,
        "z": 1.5199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.74000000953674316,
        "y": 0.0,
        "z": 0.76999998092651367
      },
      "size": {
        "x": 0.57999998331069946,
        "y": 0.18000000715255737,
        "z": 1.5199999809265137
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -1.0199999809265137
      },
      "size": {
        "x": 0.38999998569488525,
        "y": 0.20999999344348907,
        "z": 1.559999942779541
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.014365881681442261,
        "y": 0.068935014307498932,
        "z": 0.80180740356445313
      },
      "size": {
        "x": 1.9003670215606689,
        "y": 0.19579802453517914,
        "z": 1.8452167510986328
      },
      "isTrigger": true
    }
  ],
  "fork_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.57128024101257324,
        "z": 0.048055566847324371
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.55277061462402344,
        "z": 0.021714936941862106
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": -0.39334407448768616,
        "z": -0.013488059863448143
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.94731277227401733,
        "z": 0.0341276191174984
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.18652744591236115,
        "z": 0.021313212811946869
      },
      "size": {
        "x": 0.21315765380859375,
        "y": 0.22889444231987,
        "z": 0.045524150133132935
      },
      "isTrigger": false
    }
  ],
  "pistachio_001": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 2.1085267066955566,
        "y": 0.70549595355987549,
        "z": 2.2170348167419434
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 1.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0,
        "y": 1.5,
        "z": 1.0
      },
      "isTrigger": true
    }
  ],
  "big_spoon_003": [
    {
      "center": {
        "x": -0.00052617490291595459,
        "y": -0.60967212915420532,
        "z": -0.11327926814556122
      },
      "size": {
        "x": 0.11691701412200928,
        "y": 0.77707874774932861,
        "z": 0.099885314702987671
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.0,
        "y": 0.573598325252533,
        "z": 0.079839743673801422
      },
      "size": {
        "x": 0.73750853538513184,
        "y": 0.84922635555267334,
        "z": 0.12918432056903839
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.0018323063850402832,
        "y": -0.043056756258010864,
        "z": -0.052273482084274292
      },
      "size": {
        "x": 0.10984069108963013,
        "y": 0.3983338475227356,
        "z": 0.14457553625106812
      },
      "isTrigger": false
    }
  ],
  "spatula_002": [
    {
      "center": {
        "x": 0.0,
        "y": 0.0,
        "z": -3.7252902984619141E-09
      },
      "size": {
        "x": 0.49156197905540466,
        "y": 2.005338191986084,
        "z": 0.21340546011924744
      },
      "isTrigger": false
    }
  ],
  "tissue_box_004": [
    {
      "center": {
        "x": 0.0,
        "y": -0.078601464629173279,
        "z": 0.0
      },
      "size": {
        "x": 1.999428391456604,
        "y": 0.858273983001709,
        "z": 1.3068103790283203
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.009613335132598877,
        "y": 0.39551162719726563,
        "z": 0.28424099087715149
      },
      "size": {
        "x": 1.0883957147598267,
        "y": 0.22445368766784668,
        "z": 0.42347198724746704
      },
      "isTrigger": false
    }
  ],
  "tissue_box_001": [
    {
      "center": {
        "x": 0.0,
        "y": -0.078601464629173279,
        "z": 0.0
      },
      "size": {
        "x": 1.999428391456604,
        "y": 0.858273983001709,
        "z": 1.3068103790283203
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.009613335132598877,
        "y": 0.39551162719726563,
        "z": 0.28424099087715149
      },
      "size": {
        "x": 1.0883957147598267,
        "y": 0.22445368766784668,
        "z": 0.42347198724746704
      },
      "isTrigger": false
    }
  ],
  "tissue_box_003": [
    {
      "center": {
        "x": 0.0,
        "y": -0.078601464629173279,
        "z": 0.0
      },
      "size": {
        "x": 1.999428391456604,
        "y": 0.858273983001709,
        "z": 1.3068103790283203
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.009613335132598877,
        "y": 0.39551162719726563,
        "z": 0.28424099087715149
      },
      "size": {
        "x": 1.0883957147598267,
        "y": 0.22445368766784668,
        "z": 0.42347198724746704
      },
      "isTrigger": false
    }
  ],
  "fridge_001": [
    {
      "center": {
        "x": -2.2739014625549316,
        "y": -1.7672154903411865,
        "z": 2.1313319206237793
      },
      "size": {
        "x": 3.6225056648254395,
        "y": 7.8746209144592285,
        "z": 1.6682580709457397
      },
      "isTrigger": true
    },
    {
      "center": {
        "x": -2.2885668277740479,
        "y": 0.0,
        "z": 3.8404183387756348
      },
      "size": {
        "x": 5.0680980682373047,
        "y": 11.409032821655273,
        "z": 0.40044733881950378
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -2.2547409534454346,
        "y": 5.4907956123352051,
        "z": 1.8503458499908447
      },
      "size": {
        "x": 5.1357579231262207,
        "y": 0.42747393250465393,
        "z": 4.3805937767028809
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -4.7121400833129883,
        "y": 0.0,
        "z": 1.842522144317627
      },
      "size": {
        "x": 0.22095575928688049,
        "y": 11.409032821655273,
        "z": 4.3962440490722656
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.15681691467761993,
        "y": 0.0,
        "z": 1.8090213537216187
      },
      "size": {
        "x": 0.31952965259552,
        "y": 11.409032821655273,
        "z": 4.4632477760314941
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 4.0063643455505371,
        "y": 0.0,
        "z": -3.1928699016571045
      },
      "size": {
        "x": 1.6325113773345947,
        "y": 11.409032821655273,
        "z": 1.6955567598342896
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 2.4750275611877441,
        "y": 0.0,
        "z": -2.0202846527099609
      },
      "size": {
        "x": 1.4439038038253784,
        "y": 11.409032821655273,
        "z": 1.1224499940872192
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 1.3137660026550293,
        "y": 0.0,
        "z": -0.52438634634017944
      },
      "size": {
        "x": 1.4738149642944336,
        "y": 11.409032821655273,
        "z": 1.891829252243042
      },
      "isTrigger": false
    }
  ],
  "tissue_box_002": [
    {
      "center": {
        "x": 0.0,
        "y": -0.078601464629173279,
        "z": 0.0
      },
      "size": {
        "x": 1.999428391456604,
        "y": 0.858273983001709,
        "z": 1.3068103790283203
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.009613335132598877,
        "y": 0.39551162719726563,
        "z": 0.28424099087715149
      },
      "size": {
        "x": 1.0883957147598267,
        "y": 0.22445368766784668,
        "z": 0.42347198724746704
      },
      "isTrigger": false
    }
  ],
  "invisible_square_001": [
    {
      "center": {
        "x": -5.9604644775390625E-08,
        "y": 0.0,
        "z": 0.0
      },
      "size": {
        "x": 1.0000001192092896,
        "y": 1.0000001192092896,
        "z": 1.0000001192092896
      },
      "isTrigger": false
    }
  ],
  "start_line": [
    {
      "center": {
        "x": 0.0,
        "y": -2.384185791015625E-07,
        "z": 1.1200000047683716
      },
      "size": {
        "x": 85.550003051757813,
        "y": 45.25,
        "z": 1.6710872650146484
      },
      "isTrigger": true
    }
  ],
  "cleaver_003": [
    {
      "center": {
        "x": 0.24500070512294769,
        "y": -0.35259777307510376,
        "z": 0.00297660194337368
      },
      "size": {
        "x": 0.17818376421928406,
        "y": 0.13774240016937256,
        "z": 0.056179087609052658
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.24006824195384979,
        "y": -0.86279255151748657,
        "z": 0.0018422529101371765
      },
      "size": {
        "x": 0.23025992512702942,
        "y": 0.90158069133758545,
        "z": 0.10214754939079285
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.011205479502677917,
        "y": 0.47758585214614868,
        "z": 0.0027481187134981155
      },
      "size": {
        "x": 0.69252860546112061,
        "y": 1.6663831472396851,
        "z": 0.056859951466321945
      },
      "isTrigger": false
    }
  ],
  "cleaver_004": [
    {
      "center": {
        "x": 0.24500070512294769,
        "y": -0.35259777307510376,
        "z": 0.00297660194337368
      },
      "size": {
        "x": 0.17818376421928406,
        "y": 0.13774240016937256,
        "z": 0.056179087609052658
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.24006824195384979,
        "y": -0.86279255151748657,
        "z": 0.0018422529101371765
      },
      "size": {
        "x": 0.23025992512702942,
        "y": 0.90158069133758545,
        "z": 0.10214754939079285
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": -0.011205479502677917,
        "y": 0.47758585214614868,
        "z": 0.0027481187134981155
      },
      "size": {
        "x": 0.69252860546112061,
        "y": 1.6663831472396851,
        "z": 0.056859951466321945
      },
      "isTrigger": false
    }
  ],
  "cleaver_002": [
    {
      "center": {
        "x": -0.011205479502677917,
        "y": 0.47758585214614868,
        "z": 0.0027481187134981155
      },
      "size": {
        "x": 0.69252860546112061,
        "y": 1.6663831472396851,
        "z": 0.056859951466321945
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.24500070512294769,
        "y": -0.35259777307510376,
        "z": 0.00297660194337368
      },
      "size": {
        "x": 0.17818376421928406,
        "y": 0.13774240016937256,
        "z": 0.056179087609052658
      },
      "isTrigger": false
    },
    {
      "center": {
        "x": 0.24006824195384979,
        "y": -0.86279255151748657,
        "z": 0.0018422529101371765
      },
      "size": {
        "x": 0.23025992512702942,
        "y": 0.90158069133758545,
        "z": 0.10214754939079285
      },
      "isTrigger": false
    }
  ]
}