export class AuthExpiredError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("AuthExpiredError");
        this.message = errorMessage;
    }
}

export class UncatchableAlmostOOMError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("UncatchableAlmostOOMError");
        this.message = errorMessage;
    }
}

export class UnknownAPIError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("UnknownAPIError");
        this.message = errorMessage;
    }
}

export class UserNotFoundError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("UserNotFoundError");
        this.message = errorMessage;
    }
}

export class AllServersFullError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("AllServersFullError");
        this.message = errorMessage;
    }
}

export class NoServersOnlineError extends Error {
    public override message;
    public constructor(errorMessage: string) {
        super("NoServersOnlineError");
        this.message = errorMessage;
    }
}
