import { SharedCollisionSystem } from "../../shared/systems/SharedCollision";
import level from "../../shared/data/level_uppity.json";
import colliders from "../../shared/data/colliders.json";
import { getPlatformMetadataByJsonIdentifier } from "../../shared/data/PlatformData";
import { BoxGeometry, Mesh, MeshStandardMaterial, Vector3 } from "three";
import { ICollidable } from "../../shared/entities/SharedEntityTypes";
import { Config } from "../../shared/Config";

export class ClientCollisionSystem extends SharedCollisionSystem {
    private debugMeshes: Mesh[] = [];

    public constructor() {
        super();
    }

    protected override getSystemName(): string {
        return "CCollision";
    }

    public override Initialize() {
        for (const platform of level.platforms) {
            const { position: parentPlatformPosition, assetIdentifier, uniqueIdentifier } = platform;

            if (uniqueIdentifier.toLowerCase() === "spawn") continue;

            let { scale, rotation } = platform;

            if (scale === undefined) {
                scale = [1, 1, 1];
            }

            if (rotation === undefined) {
                rotation = [0, 0, 0];
            }

            const platformMetadata = getPlatformMetadataByJsonIdentifier(assetIdentifier!);

            if (!platformMetadata) {
                console.error("Could not find platform metadata for identifier:", assetIdentifier, platform);
                console.error("skipping this platform creation! level might be broken!");
                continue;
            } else {
                // @ts-ignore
                const possibleColliders = colliders[platformMetadata!.jsonIdentifier];

                if (possibleColliders === undefined || possibleColliders.length === 0) {
                    continue;
                } else {
                    if (Config.Collision.DRAW_COLLISION_BOXES) {
                        for (let i = 0; i < possibleColliders.length; i++) {
                            const collider = possibleColliders[i];
                            const { center: position, size } = collider;
                            const { x: width, y: height, z: depth } = size;
                            const positionOfThisCollider = new Vector3(parentPlatformPosition[0] + position.x, parentPlatformPosition[1] + position.y, parentPlatformPosition[2] + position.z);
                            this.RenderDebugCollider({
                                Scale: new Vector3(scale[0], scale[1], scale[2]),
                                Position: positionOfThisCollider,
                                Collider: {
                                    Height: height * scale[1],
                                    Width: width * scale[0],
                                    Depth: depth * scale[2],
                                    Position: positionOfThisCollider
                                }
                            });
                        }
                    }
                }
            }
        }

        Game.EmitEvent("LoadEvent", { eventName: "CollisionReady" });

        super.Initialize();
    }

    public RenderDebugCollider(collidable: ICollidable): void {
        const geometry = new BoxGeometry(collidable.Collider.Width, collidable.Collider.Height, collidable.Collider.Depth);

        const material = new MeshStandardMaterial({ color: 0xff00ff, wireframe: true });

        const renderedEntity = new Mesh(geometry, material);

        renderedEntity.position.set(collidable.Position.x, collidable.Position.y, collidable.Position.z);

        this.debugMeshes.push(renderedEntity);

        Game.Renderer.AddModelToScene(renderedEntity);
    }
}
