export enum UsernamePrefixesInt {
    Raw = 0,
    Ripe = 1,
    Whole = 2,
    Fuzzy = 3,
    Sour = 4,
    Sweet = 5,
    Rotten = 6,
    Peeled = 7,
    Squishy = 8,
    Firm = 9,
    Soft = 10,
    Tangy = 11,
    Savory = 12,
    Acidic = 13,
    Browning = 14,
    Chewy = 15,
    Crunchy = 16,
    Cold = 17,
    Bland = 18,
    Cracked = 19,
    Creamy = 20,
    Chilled = 21,
    Delicious = 22,
    Dainty = 23,
    Dirty = 24,
    Dense = 25,
    Dubious = 26,
    Delectable = 27,
    Dry = 28,
    Enormous = 29,
    Elegant = 30,
    Engorged = 31,
    Coarse = 32,
    Fluffy = 33,
    Frozen = 34,
    Fruity = 35,
    Dredged = 36,
    Falling = 37,
    FreeRange = 38,
    Golden = 39,
    Garnished = 40,
    GrassFed = 41,
    Grated = 42,
    Grotesque = 43,
    Green = 44,
    Hot = 45,
    Hairy = 46,
    Heavy = 47,
    Hollow = 48,
    Inedible = 49,
    Icky = 50,
    Iced = 51,
    Juicy = 52,
    Canned = 53,
    Digestible = 54,
    Jammy = 55,
    Jumbo = 56,
    Krispy = 57,
    Light = 58,
    Moist = 59,
    Yummy = 60,
    Nutty = 61,
    Natural = 62,
    Oozy = 63,
    Organic = 64,
    Old = 65,
    Pungent = 66,
    Peppery = 67,
    Putrid = 68,
    Quality = 69,
    Rich = 70,
    Robust = 71,
    Spicy = 72,
    Succulent = 73,
    Stinky = 74,
    Tart = 75,
    Tender = 76,
    Velvety = 77,
    Zesty = 78
}

export enum UsernameSuffixesInt {
    Apple = 0,
    Apricot = 1,
    Almond = 2,
    Acorn = 3,
    Artichoke = 4,
    Blueberry = 5,
    Banana = 6,
    Broccoli = 7,
    BrusselSprout = 8,
    Bread = 9,
    BigMac = 10,
    Cookie = 11,
    Carrot = 12,
    Celery = 13,
    Carp = 14,
    Crouton = 15,
    Cucumber = 16,
    Bean = 17,
    Beetroot = 18,
    Cauliflower = 19,
    Cake = 20,
    Cranberry = 21,
    Crab = 22,
    Danish = 23,
    Chip = 24,
    Donut = 25,
    Dumpling = 26,
    Durian = 27,
    Chocolate = 28,
    Date = 29,
    Eggplant = 30,
    Egg = 31,
    Enchilada = 32,
    Fry = 33,
    Fig = 34,
    Falafel = 35,
    Fudge = 36,
    Flapjack = 37,
    Fajita = 38,
    Chicken = 39,
    Grape = 40,
    Guacamole = 41,
    Grapefruit = 42,
    Gazpacho = 43,
    Gnocchi = 44,
    HotDog = 45,
    Ham = 46,
    Hazelnut = 47,
    Haggis = 48,
    IceCream = 49,
    Cupcake = 50,
    Jello = 51,
    Jerky = 52,
    Jackfruit = 53,
    Kale = 54,
    Kiwi = 55,
    Kimchi = 56,
    Lemon = 57,
    Lime = 58,
    Leek = 59,
    Lobster = 60,
    Muffin = 61,
    Macaroni = 62,
    Meatball = 63,
    Noodle = 64,
    Nectarine = 65,
    Nougat = 66,
    Orange = 67,
    Okra = 68,
    Peach = 69,
    Pizza = 70,
    Popcorn = 71,
    Quiche = 72,
    Quinoa = 73,
    Quesadilla = 74,
    Raspberry = 75,
    Radish = 76,
    Rutabaga = 77,
    Strawberry = 78,
    Spinach = 79,
    Sorbet = 80,
    Spaghetti = 81,
    Tomato = 82,
    Truffle = 83,
    Udon = 84,
    Veal = 85,
    Vegetable = 86,
    Fruit = 87,
    Venison = 88,
    Waffle = 89,
    Wasabi = 90,
    Walnut = 91,
    Watermelon = 92,
    Yogurt = 93,
    Zucchini = 94
}

export enum Countries {
    None = 1,
    AlandIslands = 2,
    Albania = 3,
    Algeria = 4,
    AmericanSamoa = 5,
    Andorra = 6,
    Angola = 7,
    Anguilla = 8,
    Antarctica = 9,
    AntiguaAndBarbuda = 10,
    Argentina = 11,
    Armenia = 12,
    Aruba = 13,
    Australia = 14,
    Austria = 15,
    Azerbaijan = 16,
    Bahamas = 17,
    Bahrain = 18,
    Bangladesh = 19,
    Barbados = 20,
    Belarus = 21,
    Belgium = 22,
    Belize = 23,
    Benin = 24,
    Bermuda = 25,
    Bhutan = 26,
    Bolivia = 27,
    BosniaAndHerzegovina = 28,
    Botswana = 29,
    BouvetIsland = 30,
    Brazil = 31,
    BritishIndianOceanTerritory = 32,
    Brunei = 33,
    Bulgaria = 34,
    BurkinaFaso = 35,
    Burundi = 36,
    Cambodia = 37,
    Cameroon = 38,
    Canada = 39,
    CapeVerde = 40,
    CaymanIslands = 41,
    CentralAfricanRepublic = 42,
    Chad = 43,
    Chile = 44,
    China = 45,
    ChristmasIsland = 46,
    CocosKeelingIslands = 47,
    Colombia = 48,
    Comoros = 49,
    RepublicOfTheCongo = 50,
    DRCongo = 51,
    CookIslands = 52,
    CostaRica = 53,
    IvoryCoast = 54,
    Croatia = 55,
    Cuba = 56,
    Curacao = 57,
    Cyprus = 58,
    Czechia = 59,
    Denmark = 60,
    Djibouti = 61,
    Dominica = 62,
    DominicanRepublic = 63,
    Ecuador = 64,
    Egypt = 65,
    ElSalvador = 66,
    England = 67,
    EquatorialGuinea = 68,
    Eritrea = 69,
    Estonia = 70,
    EswatiniSwaziland = 71,
    Ethiopia = 72,
    FalklandIslands = 73,
    FaroeIslands = 74,
    Fiji = 75,
    Finland = 76,
    France = 77,
    FrenchGuiana = 78,
    FrenchPolynesia = 79,
    FrenchSouthernAndAntarcticLands = 80,
    Gabon = 81,
    Gambia = 82,
    Georgia = 83,
    Germany = 84,
    Ghana = 85,
    Gibraltar = 86,
    Greece = 87,
    Greenland = 88,
    Grenada = 89,
    Guadeloupe = 90,
    Guam = 91,
    Guatemala = 92,
    Guernsey = 93,
    Guinea = 94,
    GuineaBissau = 95,
    Guyana = 96,
    Haiti = 97,
    HeardIslandAndMcdonaldIslands = 98,
    Honduras = 99,
    HongKong = 100,
    Hungary = 101,
    Iceland = 102,
    India = 103,
    Indonesia = 104,
    Iran = 105,
    Iraq = 106,
    Ireland = 107,
    IsleOfMan = 108,
    Israel = 109,
    Italy = 110,
    Jamaica = 111,
    Japan = 112,
    Jersey = 113,
    Jordan = 114,
    Kazakhstan = 115,
    Kenya = 116,
    Kiribati = 117,
    NorthKorea = 118,
    SouthKorea = 119,
    Kosovo = 120,
    Kuwait = 121,
    Kyrgyzstan = 122,
    Laos = 123,
    Latvia = 124,
    Lebanon = 125,
    Lesotho = 126,
    Liberia = 127,
    Libya = 128,
    Liechtenstein = 129,
    Lithuania = 130,
    Luxembourg = 131,
    Macau = 132,
    Madagascar = 133,
    Malawi = 134,
    Malaysia = 135,
    Maldives = 136,
    Mali = 137,
    Malta = 138,
    MarshallIslands = 139,
    Martinique = 140,
    Mauritania = 141,
    Mauritius = 142,
    Mayotte = 143,
    Mexico = 144,
    Micronesia = 145,
    Moldova = 146,
    Monaco = 147,
    Mongolia = 148,
    Montenegro = 149,
    Montserrat = 150,
    Morocco = 151,
    Mozambique = 152,
    Myanmar = 153,
    Namibia = 154,
    Nauru = 155,
    Nepal = 156,
    Netherlands = 157,
    NewCaledonia = 158,
    NewZealand = 159,
    Nicaragua = 160,
    Niger = 161,
    Nigeria = 162,
    Niue = 163,
    NorfolkIsland = 164,
    NorthMacedonia = 165,
    NorthernMarianaIslands = 166,
    Norway = 167,
    Oman = 168,
    Pakistan = 169,
    Palau = 170,
    Palestine = 171,
    Panama = 172,
    PapuaNewGuinea = 173,
    Paraguay = 174,
    Peru = 175,
    Philippines = 176,
    PitcairnIslands = 177,
    Poland = 178,
    Portugal = 179,
    PuertoRico = 180,
    Qatar = 181,
    Reunion = 182,
    Romania = 183,
    Russia = 184,
    Rwanda = 185,
    SaintBarthelemy = 186,
    SaintHelenaAscensionAndTristandaCunha = 187,
    SaintKittsAndNevis = 188,
    SaintLucia = 189,
    SaintMartin = 190,
    SaintPierreAndMiquelon = 191,
    SaintVincentAndTheGrenadines = 192,
    Samoa = 193,
    SanMarino = 194,
    SaoTomeAndPrincipe = 195,
    SaudiArabia = 196,
    Scotland = 197,
    Senegal = 198,
    Serbia = 199,
    Seychelles = 200,
    SierraLeone = 201,
    Singapore = 202,
    SintMaarten = 203,
    Slovakia = 204,
    Slovenia = 205,
    SolomonIslands = 206,
    Somalia = 207,
    SouthAfrica = 208,
    SouthGeorgia = 209,
    SouthSudan = 210,
    Spain = 211,
    SriLanka = 212,
    Sudan = 213,
    Suriname = 214,
    SvalbardAndJanMayen = 215,
    Sweden = 216,
    Switzerland = 217,
    Syria = 218,
    Taiwan = 219,
    Tajikistan = 220,
    Tanzania = 221,
    Thailand = 222,
    TimorLeste = 223,
    Togo = 224,
    Tokelau = 225,
    Tonga = 226,
    TrinidadAndTobago = 227,
    Tunisia = 228,
    Turkey = 229,
    Turkmenistan = 230,
    TurksAndCaicosIslands = 231,
    Tuvalu = 232,
    Uganda = 233,
    Ukraine = 234,
    UnitedArabEmirates = 235,
    UnitedKingdom = 236,
    UnitedStates = 237,
    Uruguay = 238,
    Uzbekistan = 239,
    Vanuatu = 240,
    VaticanCity = 241,
    Venezuela = 242,
    Vietnam = 243,
    BritishVirginIslands = 244,
    UnitedStatesVirginIslands = 245,
    Wales = 246,
    WallisAndFutuna = 247,
    WesternSahara = 248,
    Yemen = 249,
    Zambia = 25,
    Zimbabwe = 251
}

export const chooseRandomPrefixInt = (): UsernamePrefixesInt => {
    return Math.floor((Math.random() * Object.keys(UsernamePrefixesInt).length) / 2);
};

export const chooseRandomSuffixInt = (): UsernameSuffixesInt => {
    return Math.floor((Math.random() * Object.keys(UsernameSuffixesInt).length) / 2);
};

export const chooseRandomCountry = (): Countries => {
    return Math.floor((Math.random() * Object.keys(Countries).length) / 2);
};

export const getRandomUsername = (): string => {
    const prefixInt = chooseRandomPrefixInt();
    const suffixInt = chooseRandomSuffixInt();

    return getUsernameFromComponents(prefixInt, suffixInt);
};

export const getUsernameFromComponents = (prefixInt: UsernamePrefixesInt, suffixInt: UsernameSuffixesInt): string => {
    const prefix = UsernamePrefixesInt[prefixInt];
    const suffix = UsernameSuffixesInt[suffixInt];

    return `${prefix} ${suffix}`;
};
