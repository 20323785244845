import englishStrings from "./translations/lang_en.json";
import chineseStrings from "./translations/lang_zh.json";
import arabicStrings from "./translations/lang_ar.json";
import spanishStrings from "./translations/lang_es.json";
import hindiStrings from "./translations/lang_hi.json";
import portugueseStrings from "./translations/lang_pt.json";
import germanStrings from "./translations/lang_de.json";
import japaneseStrings from "./translations/lang_ja.json";
import frenchStrings from "./translations/lang_fr.json";
import turkishStrings from "./translations/lang_tr.json";
import dutchStrings from "./translations/lang_nl.json";

export const localizedStrings: Record<string, Record<string, string>> = {
    en: englishStrings,
    zh: chineseStrings,
    ar: arabicStrings,
    es: spanishStrings,
    hi: hindiStrings,
    pt: portugueseStrings,
    de: germanStrings,
    ja: japaneseStrings,
    fr: frenchStrings,
    tr: turkishStrings,
    nl: dutchStrings
};

export const t = (key: string) => {
    let language;

    // @ts-ignore
    if (globalThis.Lang === undefined || globalThis.Lang === null) {
        // @ts-ignore
        globalThis.Lang = "en";
    }

    // @ts-ignore
    if (localizedStrings[globalThis.Lang]) {
        // @ts-ignore
        language = globalThis.Lang;
        // console.warn('language was found in language map!!', language);
    } else {
        language = "en";
        // console.warn('language was NOT FOUND in language map! falling back to english.');
    }

    let string;

    if (localizedStrings[language][key] || localizedStrings[language][key] === "") {
        string = localizedStrings[language][key];
        //console.log("@@@ language:" , language, "Key: ", key);
        // console.warn('key was found in strings!!', language);
    } else {
        string = localizedStrings["en"][key];
        // console.warn('key was NOT FOUND in strings! falling back something easy for us to idenfify');
    }

    return string;
};

export const englishT = (key: string) => {
    const language = "en";

    let string;

    if (localizedStrings[language][key] || localizedStrings[language][key] === "") {
        string = localizedStrings[language][key];
        // console.warn('key was found in strings!!', language);
    } else {
        string = "{{ MISSING TRANSLATION }}";
        // console.warn('key was NOT FOUND in strings! falling back something easy for us to idenfify');
    }

    return string;
};
