import { AnimationClip, AnimationMixer, BoxGeometry, Group, Mesh, MeshStandardMaterial, MeshBasicMaterial, AnimationAction, Material, ShaderMaterial, Shader, PlaneGeometry, FrontSide, SRGBColorSpace, Vector3 } from "three";
import { PlayerEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { SharedPlayer } from "../../shared/entities/SharedPlayer";
import { CharacterSkinMetadata, Characters } from "../../shared/data/CharacterData";
import { Config } from "../../shared/Config";
//@ts-ignore
import { createDerivedMaterial } from "troika-three-utils";
import { EmoteAnimations, EmoteImages, Emotes } from "../../shared/data/EmoteData";
import { PlayerLabel } from "../ui/PlayerLabel";
import { Countries } from "../../shared/data/Usernames";
import { Alpha, Behaviour, BoxZone, Color, Emitter, Life, Mass, Position, RadialVelocity, Radius, Rate, Scale, Span, Vector3D } from "three-nebula";
import * as THREE from "three";
import { ParticleBehaviours, ParticleBurstCounts, ParticleEffects, ParticleInitializers, ParticleRates } from "../../shared/data/ParticleData";
import Initializer from "three-nebula/src/initializer/Initializer";

export class ClientPlayer extends SharedPlayer {
    public originalCreationPayload: PlayerEntityCreationPayload;
    public isMyEntity: boolean = false;
    private threeGLTF: GLTF;
    protected threeModel: Group;
    private animationController: AnimationMixer;
    private animationClips: AnimationClip[];
    private colliderVisual: Mesh;
    private playerVisualsAreSetUp: boolean = false;
    private toAction: AnimationAction;
    private fromAction: AnimationAction;
    private readonly modelOffsetY: number = -1;
    private localModelHidden: boolean = false;
    private fallSoundResetVelocity = -0.2;
    private labelOffsetY = 0.5;
    private currentParticleTrailEffect: ParticleEffects = ParticleEffects.None;
    private particleTrailEmitter: Emitter;
    private particleTrailActive: boolean = false;
    private particleTrailRate: Rate;
    private particleZeroRate: Rate;
    private particleBurstEmitter: Emitter;

    private name: string;
    protected nameLabel: PlayerLabel;
    private emoteMaterials: Map<Emotes, ShaderMaterial>;
    private emoteMesh: Mesh;
    private emoteOffsetY = this.labelOffsetY + 0.2;
    private emoteDuration = 2.5;
    private emoteFadeDuration = 0.3;
    private currentEmoteTimer = 0;
    private currentEmoteAnimation: EmoteAnimations;
    private country: Countries;

    public constructor(createPlayerPayload: PlayerEntityCreationPayload) {
        super(Game.Collision, createPlayerPayload.x, createPlayerPayload.y, createPlayerPayload.z, createPlayerPayload.runInProgress, createPlayerPayload.runDurationAcc);

        this.originalCreationPayload = createPlayerPayload;

        // console.log("\n\n Creating new local player entity...");
        // console.log("Original creation payload on client:", this.originalCreationPayload);

        this.characterSkin = createPlayerPayload.characterSkin;
        this.characterTrail = createPlayerPayload.characterTrail;

        const { nid } = createPlayerPayload;

        this.name = createPlayerPayload.username;

        //@ts-ignore
        this.country = createPlayerPayload.country;
        this.nid = nid;

        this._setupDebugVisuals();
        //FiniteStateMachine initalizes in below function
        //TODO: Potentially initialize FSM after _setupVisuals has been run
        this._setupVisuals().then(() => {
            this._setupEmotes();
            this._setupParticles();
        });
    }

    public RotateByMouseMovement(movementX: number, __movementY: number) {
        this.rotY -= movementX * Config.Camera.MOUSE_SENSITIVITY;
        // this.rotX -= movementY * Config.Camera.MOUSE_SENSITIVITY;
    }

    public UpdateCharacterSkin(newSkin: Characters) {
        // console.log("server told us to update our character skin to...", newSkin);
        this.characterSkin = newSkin;

        this._tearDownVisuals();

        this._setupVisuals().then(() => {
            this._setupEmotes();
        });
    }

    public GetModel(): Group {
        return this.threeModel;
    }

    public IdentifyAsMyPlayer(): void {
        this.isMyEntity = true;
    }

    public set visualModelRotation(newRotation: number) {
        // console.log("local setter of visualModelRotation", newRotation);
        this.rotY = newRotation;
    }

    private async _setupDebugVisuals() {
        // console.log("Debug visuals are being set up for player...");

        if (Config.Player.DRAW_COLLIDER) {
            const geometry = new BoxGeometry(this.Collider.Width, this.Collider.Height, this.Collider.Depth);
            const material = new MeshStandardMaterial({ color: 0xff00ff, wireframe: true });
            this.colliderVisual = new Mesh(geometry, material);
            this.colliderVisual.position.set(this.x, this.y, this.z);
            Game.Renderer.AddModelToScene(this.colliderVisual);
        }
    }

    private _tearDownVisuals() {
        try {
            // console.log("Tearing down visuals");

            Game.Renderer.RemoveModelFromScene(this.threeModel);

            // this.threeModel.traverse((node) => {
            //     // @ts-ignore
            //     if (node.isMesh) {
            //         if ()
            //         // @ts-ignore
            //         node.material.dispose();
            //         // @ts-ignore
            //         node.geometry.dispose();
            //     }
            // });

            // @ts-ignore
            this.threeModel = null;
            // @ts-ignore
            this.animationController = null;
            // @ts-ignore
            this.animationClips = null;

            this.playerVisualsAreSetUp = false;
        } catch (e) {
            console.error("Tried to call _tearDownVisuals but hit the catch clause!");
            console.error(e);
        }
    }

    public setCountry(country: Countries) {
        this.country = country;
        if (this.nameLabel !== undefined) {
            this.nameLabel.SetFlag(country);
        }
    }

    public setName(name: string) {
        this.name = name;
        if (this.nameLabel !== undefined) {
            this.nameLabel.SetText(this.name);
        }
    }

    public getName(): string {
        return this.name;
    }

    private _setupNameLabel() {
        // console.log(this.country);
        this.nameLabel = new PlayerLabel(`${this.name}`, this.country);

        if (this.nameLabel !== undefined) {
            this.threeModel.add(this.nameLabel.threeText);
            if (this.nameLabel.threeText !== undefined) {
                this.nameLabel.threeText.position.set(0, getHeightOfModel(this.threeModel) + this.labelOffsetY, 0);
            }
        }
    }

    public getSpeedBlockFactor(): number {
        return this.speedBlockFactor;
    }

    protected override _tryUpdateMobileMovementSideEffect(): void {
        if (!IsMobile) return;
        if (!Game.Input.MobilePlayerIsMoving) return;

        const cameraAngles = Game.Renderer.GetCameraAngle();

        this.rotY = Game.Input.GetMobileRotationAngle() - cameraAngles.lateral;
    }

    protected async _setupVisuals() {
        // console.log("Player visuals are being set up...");
        const sprite = CharacterSkinMetadata.get(this.characterSkin)!.assetPath;

        this.threeGLTF = await Game.Loader.LoadModel(sprite);

        this.threeModel = this.threeGLTF.scene;

        this.threeModel.name = "Player";

        this.threeModel.traverse((node) => {
            // @ts-ignore
            if (node.isMesh) {
                const newMaterial = new MeshBasicMaterial({ color: 0xffffff, wireframe: this.isMyEntity ? true : false });

                // @ts-ignore
                // If the original material uses textures, transfer them to the new material
                if (node.material.map) {
                    // @ts-ignore
                    newMaterial.map = node.material.map;
                    // @ts-ignore
                    newMaterial.transparent = node.material.transparent;
                    // @ts-ignore
                    newMaterial.alphaTest = node.material.alphaTest;
                }

                // Apply the new material
                // @ts-ignore
                node.material = newMaterial;
            }
        });

        this.threeModel.position.set(this.x, this.y + this.modelOffsetY, this.z);

        this.threeModel.scale.set(1, 1, 1);

        this.threeModel.rotation.set(0, 136.5, 0);

        this.animationController = new AnimationMixer(this.threeModel);

        this.animationClips = this.threeGLTF.animations;

        this._setupNameLabel();

        Game.Renderer.AddModelToScene(this.threeModel);

        this.playerVisualsAreSetUp = true;
    }

    private _setupEmotes(): void {
        const emoteLocation = "ui/emotes/";
        this.emoteMaterials = new Map<Emotes, ShaderMaterial>();

        EmoteImages.forEach((filename, emote) => {
            const emoteTexture = Game.Loader.TextureLoader.load(emoteLocation + filename);
            emoteTexture.colorSpace = SRGBColorSpace;
            const emoteMaterial = createBillboardEmoteMaterial(new MeshBasicMaterial({ map: emoteTexture, transparent: true, alphaTest: 0.5, side: FrontSide }));
            this.emoteMaterials.set(emote, emoteMaterial);
        });

        const emoteGeometry = new PlaneGeometry(2, 2);
        this.emoteMesh = new Mesh(emoteGeometry);
        this.emoteMesh.material = this.emoteMaterials.get(Emotes.Apple) as ShaderMaterial;

        this.threeModel.add(this.emoteMesh);
        this.emoteMesh.position.set(0, getHeightOfModel(this.threeModel) + this.emoteOffsetY, 0);
        this.emoteMesh.visible = false;
    }

    private _setupParticles(): void {
        if (!this.isMyEntity) {
            if (this.particleTrailEmitter) {
                this.particleTrailEmitter.reset();
            } else {
                this.particleTrailEmitter = new Emitter();
                Game.Renderer.AddParticleEmitter(this.particleTrailEmitter);
                this.particleZeroRate = new Rate(0, 0);
            }

            if (this.particleBurstEmitter) {
                this.particleBurstEmitter.reset();
            } else {
                this.particleBurstEmitter = new Emitter();
                Game.Renderer.AddParticleEmitter(this.particleBurstEmitter);
            }

            this.particleTrailEmitter.setRate(this.particleZeroRate).setPosition(this.threeModel.position).emit();

            this.particleBurstEmitter.setRate(this.particleZeroRate).setPosition(this.threeModel.position);

            // console.log("@@@", this.characterTrail);

            if (this.characterTrail !== -1) {
                this.SetParticleTrailEffect(this.characterTrail);
            }
        }
    }

    public Destroy(): void {
        this._tearDownVisuals();
    }

    public SetParticleTrailEffect(effect: ParticleEffects) {
        if (this.currentParticleTrailEffect === effect) {
            return;
        }

        if (this.particleTrailEmitter === undefined) {
            return;
        }

        this.currentParticleTrailEffect = effect;

        if (effect !== ParticleEffects.None) {
            if (ParticleRates.has(effect)) {
                this.particleTrailRate = ParticleRates.get(effect) as Rate;
            }
            if (ParticleInitializers.has(effect)) {
                this.particleTrailEmitter.setInitializers(ParticleInitializers.get(effect) as Initializer[]);
            }
            if (ParticleBehaviours.has(effect)) {
                this.particleTrailEmitter.setBehaviours(ParticleBehaviours.get(effect) as Behaviour[]);
            }
        }
    }

    // TODO: Not yet fully working
    public BurstParticleEffect(effect: ParticleEffects) {
        if (effect !== ParticleEffects.None) {
            if (this.particleBurstEmitter) {
                const parent = this.particleBurstEmitter.parent;
                this.particleBurstEmitter.reset();
                this.particleBurstEmitter.parent = parent;
            }

            if (ParticleRates.has(effect)) {
                this.particleBurstEmitter.setRate(ParticleRates.get(effect) as Rate);
            }
            if (ParticleInitializers.has(effect)) {
                this.particleBurstEmitter.setInitializers(ParticleInitializers.get(effect) as Initializer[]);
            }
            if (ParticleBehaviours.has(effect)) {
                this.particleBurstEmitter.setBehaviours(ParticleBehaviours.get(effect) as Behaviour[]);
            }
            if (ParticleBurstCounts.has(effect)) {
                this.particleBurstEmitter.emit(ParticleBurstCounts.get(effect), 0.1);
            } else {
                this.particleBurstEmitter.emit(50, 0.1);
            }
        }
    }

    private _updateVisuals(deltaTimeS: number, __deltaTimeMS: number, __currentTimeInMilliseconds: number) {
        if (this.localModelHidden === false && this.isMyEntity === true && Config.Player.DRAW_SERVERSIDE_SELF_DURING_PREDICTION === false) {
            this.threeModel.visible = false;
            this.localModelHidden = true;
        }

        if (this.localModelHidden === true) return;

        const prevPosition = this.threeModel.position.clone();

        this.animationController.update(deltaTimeS);
        this.threeModel.position.set(this.x, this.y + this.modelOffsetY, this.z);
        this.threeModel.rotation.set(this.rotX, this.rotY, this.rotZ);

        if (this.nameLabel !== undefined) {
            this.nameLabel.visible = this._isLabelVisible();
            this.nameLabel.Update(deltaTimeS);
        }

        this.currentEmoteTimer -= deltaTimeS;
        if (this.currentEmoteTimer > 0) {
            let fadeFactor = 1;
            if (this.currentEmoteTimer > this.emoteDuration - this.emoteFadeDuration) {
                fadeFactor = 1 - (this.currentEmoteTimer - (this.emoteDuration - this.emoteFadeDuration)) / this.emoteFadeDuration;
            } else if (this.currentEmoteTimer < this.emoteFadeDuration) {
                fadeFactor = this.currentEmoteTimer / this.emoteFadeDuration;
            }

            let scale = 1;
            if (this.currentEmoteAnimation === EmoteAnimations.Wiggle) {
                scale = 0.95 + Math.sin(this.currentEmoteTimer * 10) * 0.05;
            }

            this.emoteMesh.scale.set((0.5 + 0.5 * fadeFactor) * scale, (0.5 + 0.5 * fadeFactor) * scale, (0.5 + 0.5 * fadeFactor) * scale);

            const emoteMaterial = this.emoteMesh.material as ShaderMaterial;
            emoteMaterial.opacity = fadeFactor;

            if (this.currentEmoteAnimation === EmoteAnimations.Shake) {
                emoteMaterial.uniforms.u_positionOffsetX.value = Math.sin(this.currentEmoteTimer * 15) * 0.1;
                emoteMaterial.uniforms.u_positionOffsetY.value = 0;
            } else if (this.currentEmoteAnimation === EmoteAnimations.Bounce) {
                emoteMaterial.uniforms.u_positionOffsetX.value = 0;
                emoteMaterial.uniforms.u_positionOffsetY.value = Math.abs(Math.sin(this.currentEmoteTimer * 5)) * 0.2;
            } else {
                emoteMaterial.uniforms.u_positionOffsetX.value = 0;
                emoteMaterial.uniforms.u_positionOffsetY.value = 0;
            }
        } else {
            this.currentEmoteTimer = 0;
            this.emoteMesh.visible = false;
        }

        const prevParticleTrailActive = this.particleTrailActive;

        if (this.threeModel.position.distanceToSquared(prevPosition) < 0.001 || this.currentParticleTrailEffect === ParticleEffects.None) {
            this.particleTrailActive = false;
        } else {
            this.particleTrailActive = true;
            this.particleTrailEmitter?.setPosition(this.threeModel.position);
        }

        if (this.particleTrailActive !== prevParticleTrailActive) {
            if (this.particleTrailActive) {
                this.particleTrailEmitter?.setRate(this.particleTrailRate);
            } else {
                this.particleTrailEmitter?.setRate(this.particleZeroRate);
            }
        }

        this.particleBurstEmitter?.setPosition(this.threeModel.position);
    }

    protected _isLabelVisible(): boolean {
        if (Game.Predictor.PredictionReady === false) return false;

        if (Game.Predictor.GetPredictedEntity() === undefined) return false;

        if (Game.Predictor.GetPredictedEntity().threeModel === undefined || Game.Predictor.GetPredictedEntity().threeModel === null) return false;

        const distanceFromCamera = this.threeModel.position.distanceTo(Game.Predictor.GetPredictedEntity().threeModel.position);

        if (distanceFromCamera > 20) {
            return false;
        }

        return true;
    }

    private _updateDebugVisuals(__deltaTimeS: number, __deltaTimeMS: number, __currentTimeInMilliseconds: number) {
        if (Config.Player.DRAW_COLLIDER) {
            this.colliderVisual.position.set(this.x, this.y, this.z);
        }
    }

    public UpdateAnimationTimeScale(timeScale: number): void {
        if (this.animationController === undefined || this.animationController === null) return;
        this.animationController.timeScale = timeScale;
    }

    private _updatePlayerAnimations(): void {
        this.toAction = this.animationController.clipAction(this.getAnimationClipByName(this.currentState));
        this.fromAction = this.animationController.clipAction(this.getAnimationClipByName(this.previousState));

        if (this.currentState !== this.previousState) {
            //We were not in a previous state - Catches initializing edge case
            if (this.previousState === "none") {
                return;
            }
            this.toAction.enabled = true;
            this.toAction.time = 0.0;
            this.toAction.setEffectiveTimeScale(Math.max(1, this.animationSpeedMultiplier * 2));
            this.toAction.setEffectiveWeight(1);
            this.toAction.crossFadeFrom(this.fromAction, 0.1, true);

            if (this.toAction.getClip().name === "walking") {
                this.toAction.crossFadeFrom(this.fromAction, 0.08, false);
                this.toAction.startAt(0.3);
                this.toAction.timeScale = Math.max(1, this.animationSpeedMultiplier * 2);
            } else if (this.toAction.getClip().name === "jumping") {
                this.toAction.crossFadeFrom(this.fromAction, 0.15, true);
                this.toAction.startAt(0.0);
                this.toAction.timeScale = this.defaultAnimationSpeedMultiplier;
            }

            this.toAction.play();
            this.previousState = this.currentState;
        } else {
            if (this.toAction.getClip().name === "walking") {
                this.toAction.timeScale = Math.max(1, this.animationSpeedMultiplier * 2);
            }

            this.toAction.play();
        }
    }

    public Update(deltaTimeS: number, deltaTimeMS: number, currentTimeInMilliseconds: number): void {
        // console.log(this.Position);
        if (this.playerVisualsAreSetUp) {
            this._updateVisuals(deltaTimeS, deltaTimeMS, currentTimeInMilliseconds);
            this._updateDebugVisuals(deltaTimeS, deltaTimeMS, currentTimeInMilliseconds);
            this._updatePlayerAnimations();

            if (this.Velocity.y < this.fallSoundResetVelocity) {
                // @ts-ignore
                this.canPlayJumpSound = true;
            }

            if (this.currentState === "idle") {
                this.idleTimer += deltaTimeMS;
            }
        }
    }

    public get getAnimationClips(): AnimationClip[] {
        return this.animationClips;
    }

    public PopEmote(emote: Emotes, animation: EmoteAnimations): void {
        if (this.currentEmoteTimer > 0) return;

        if (this.emoteMaterials === undefined) return;

        if (this.emoteMaterials.get(emote) === undefined) return;

        if (this.emoteMesh === undefined) return;

        this.emoteMesh.material = this.emoteMaterials.get(emote) as ShaderMaterial;
        this.emoteMesh.visible = true;

        this.emoteMesh.scale.set(0.5, 0.5, 0.5);
        this.emoteMesh.material.opacity = 0;

        this.currentEmoteTimer = this.emoteDuration;

        this.currentEmoteAnimation = animation;
    }

    //Handles edge case of us wanting specific animations for certain states when the animation name does not match the state name
    //TODO: When/If animation names are changed, this can probably be re-worked
    public getAnimationClipByName(nameOfClip: string): AnimationClip {
        //Defaulting this to Idle
        let desiredClip: AnimationClip = AnimationClip.findByName(this.animationClips, "idle");

        if (nameOfClip === "falling") {
            desiredClip = AnimationClip.findByName(this.animationClips, "jumping");
        } else {
            desiredClip = AnimationClip.findByName(this.animationClips, nameOfClip);
        }

        return desiredClip;
    }

    public get getAnimationMixer(): AnimationMixer {
        return this.animationController;
    }
}

function createBillboardEmoteMaterial(baseMaterial: Material, opts: Partial<Shader> = {}): ShaderMaterial {
    return createDerivedMaterial(baseMaterial, {
        uniforms: {
            u_positionOffsetX: { value: 0.0 },
            u_positionOffsetY: { value: 0.0 }
        },
        vertexDefs: `
            uniform float u_positionOffsetX;
            uniform float u_positionOffsetY;
        `,
        vertexMainOutro: `
            vec4 mvPosition = modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0);
            vec3 scale = vec3(
                length(modelViewMatrix[0].xyz),
                length(modelViewMatrix[1].xyz),
                length(modelViewMatrix[2].xyz)
            );
            mvPosition.xyz += position * scale;

            mvPosition.x += u_positionOffsetX;
            mvPosition.y += u_positionOffsetY;
            
            gl_Position = projectionMatrix * mvPosition;
        `,
        ...opts
    });
}

function getHeightOfModel(object: Group) {
    let maxY = -Infinity;
    let minY = Infinity;

    object.traverse((child) => {
        // @ts-ignore
        if (child.isMesh) {
            // @ts-ignore
            const geometry = child.geometry;
            geometry.computeBoundingBox();
            const boundingBox = geometry.boundingBox;

            if (boundingBox) {
                maxY = Math.max(maxY, boundingBox.max.y);
                minY = Math.min(minY, boundingBox.min.y);
            }
        }
    });

    const height = maxY - minY;

    return height;
}
