import { InputCommand, NType } from "../SharedNetcodeSchemas";
import { SharedCollisionSystem } from "../systems/SharedCollision";
import { IEntity } from "nengi";
import { ITriggerEntity, SharedEntityBase, TriggerTypes } from "./SharedEntityTypes";
import { Config } from "../Config";
import { Euler, Vector3 } from "three";
import { Characters } from "../data/CharacterData";
import { playerSpawn } from "../../shared/data/LevelDataUtils";

export enum CausesOfDeath {
    TouchedADeathBlock = 0,
    FellIntoTheVoid = 1
}

export const PickRandomNewbieCharacterSkin = (): number => {
    const randomIndex = Math.floor(Math.random() * 3);

    switch (randomIndex) {
        case 0:
            return Characters.Broccoli;
        case 1:
            return Characters.Blueberry;
        case 2:
            return Characters.Apple;
        default:
            return Characters.Apple;
    }
};

export class SharedPlayer extends SharedEntityBase implements IEntity {
    public nid: NetworkEntityId = -1;
    public ntype: NType = NType.PlayerEntity;
    public characterSkin: number = 1;
    public characterTrail: number = -1;
    public finiteStateMachine: FiniteStateMachine = new FiniteStateMachine(this);

    protected RespawnPosition: Vector3 = new Vector3(0, 0, 0);
    protected CheckpointsReached: Set<string> = new Set();
    protected runHasStarted: boolean = false;
    protected runHasEnded: boolean = false;
    protected runDurationAcc: number = 0;
    protected animationSpeedMultiplier: number = 1;
    protected defaultAnimationSpeedMultiplier: number = 1;

    protected fallsThisSession: number = 0;
    protected connectedAt: number = Date.now();

    public animationState: string = "idle";
    private fallingThreshold: number = -0.4;
    private jumpingThreshold: number = 0.4;

    public MovementFrozenDuration: number = 0;
    public VerticallyResolvedThisFrame: boolean = false;
    public IsGrounded: boolean = false;
    public CollidingWithUIPopTrigger: boolean = false;
    protected uiPopTriggerAcculumator: number = 0;
    private deathFallTimer: number = 0;
    public shouldRespawn: boolean = false;

    public currentState: string = "idle";
    public previousState: string = "none";
    public idleToJumpTimer: number = 1;
    public idleTimer: number = 0;

    private mobileJoystickMovementMultplier: Vector3 = new Vector3(1, 0, 1);
    public movementVector: Vector3 = new Vector3();
    //public setXZMovementVector(x: number, z: number) { this.movementVector.add(new Vector3(x, this.movementVector.y, z)); }
    private forwardBackwardVector: Vector3 = new Vector3();
    private leftRightVector: Vector3 = new Vector3();

    private collisionSystemRef: SharedCollisionSystem;

    protected speedBlockFactor: number = 1;
    private isOnSpeedBlock: boolean = false;

    private lastRespawnedAtAccumulator: number = 0;

    public constructor(collisionSystemRef: SharedCollisionSystem, initialX: number, initialY: number, initialZ: number, runHasStarted: boolean, runInProgressDurationSeconds: number) {
        super(initialX, initialY, initialZ, 1, 1, 1, 0, 0, 0);

        // console.log("@@@ Run has started in shared player?", runHasStarted);

        if (runHasStarted) {
            this.runHasStarted = true;
            this.runDurationAcc = runInProgressDurationSeconds;
            this.runHasEnded = false;

            this._startingLineTriggerSideEffect();
        }

        this.Position.set(initialX, initialY, initialZ);
        this.RespawnPosition.set(initialX, initialY, initialZ);

        this.collisionSystemRef = collisionSystemRef;

        this.finiteStateMachine.Init();
    }

    public RunHasStarted(): boolean {
        return this.runHasStarted;
    }

    public get runInProgress(): boolean {
        return this.runHasStarted && this.runHasEnded === false ? true : false;
    }

    protected _updateSideEffects(): void {}

    protected _intentionalRespawnSideEffects(): void {}

    protected _jumpSideEffects(): void {}

    protected _bounceSideEffects(): void {}

    protected _checkpointSideEffects(): void {}

    protected _fallingDeathSideEffects(): void {}

    protected _intentionalRestartEntireRunSideEffects(): void {}

    protected _deathBlockTriggerSideEffect(): void {}

    protected _startingLineTriggerSideEffect(): void {}

    protected _resetUIPopTriggerSideEffect(): void {}

    protected _uiPopTriggerSideEffect(__trigger: ITriggerEntity, __deltaTimeS: number): void {}

    protected _finishLineTriggerSideEffect(): void {}

    protected _tryUpdateMobileMovementSideEffect(): void {}

    public ResetUIPopTriggerAcc(): void {
        this.uiPopTriggerAcculumator = 0;
        this._resetUIPopTriggerSideEffect();
    }

    public CollidedWithTrigger(trigger: ITriggerEntity, deltaTimeS: number) {
        if (trigger.TriggerType === TriggerTypes.Checkpoint) {
            // console.log("Player collided with check point");
            if (this.CheckpointsReached.has(trigger.AssociatedPlatform)) {
                // console.log("Player already reached this checkpoint, ignoring");
            } else {
                if (this.runHasEnded === true) return;
                // console.log("Player has not reached this checkpoint, adding to set");
                this.CheckpointsReached.add(trigger.AssociatedPlatform);
                this.RespawnPosition.set(trigger.Position.x, trigger.Position.y, trigger.Position.z);
                this._checkpointSideEffects();
            }
        } else if (trigger.TriggerType === TriggerTypes.DeathBlock) {
            this.shouldRespawn = true;
            this.fallsThisSession++;
            this._deathBlockTriggerSideEffect();
        } else if (trigger.TriggerType === TriggerTypes.StartingLine) {
            if (this.runHasStarted) return;

            this._startingLineTriggerSideEffect();

            this.runHasStarted = true;
        } else if (trigger.TriggerType === TriggerTypes.UI_PopUp_CharacterSelect || trigger.TriggerType === TriggerTypes.UI_PopUp_NameAndCountrySelect || trigger.TriggerType === TriggerTypes.UI_PopUp_EndRun || trigger.TriggerType === TriggerTypes.UI_PopUp_TrailSelect) {
            this._uiPopTriggerSideEffect(trigger, deltaTimeS);
        } else if (trigger.TriggerType === TriggerTypes.FinishLine) {
            if (this.runHasStarted === false) return;

            if (this.runHasEnded === false) {
                this._finishLineTriggerSideEffect();
            }

            this.runHasEnded = true;
        } else if (trigger.TriggerType === TriggerTypes.Bounce) {
            if (this.IsGrounded) {
                this.deathFallTimer = 0;
                this._jumpSideEffects();
                this._bounceSideEffects();
                this.Velocity.y = Config.Player.JUMP_VELOCITY * trigger.BehaviourValue;
                this.IsGrounded = false;
            }
        } else if (trigger.TriggerType === TriggerTypes.Speed) {
            if (this.IsGrounded) {
                this.speedBlockFactor = trigger.BehaviourValue;
                this.isOnSpeedBlock = true;
            }
        }
    }

    public Respawn(): void {
        // console.log(`Respawning player as death timer is > ${Config.Player.DEATH_FALL_TIMER}`);
        this.Position.set(this.RespawnPosition.x, this.RespawnPosition.y, this.RespawnPosition.z);
        this.Velocity.set(0, 0, 0);
        this.shouldRespawn = false;
        this.deathFallTimer = 0;
        this.MovementFrozenDuration = Config.Player.RESPAWN_MOVEMENT_DELAY;
        this.speedBlockFactor = 1;
        this.isOnSpeedBlock = false;
    }

    public DebugForceTeleport(): void {
        if (process.env.NODE_ENV === "development") {
            this.Position.set(Config.World.DEBUG_TELEPORT_LOCATION.X, Config.World.DEBUG_TELEPORT_LOCATION.Y, Config.World.DEBUG_TELEPORT_LOCATION.Z);
        }
    }

    public DebugForceCheckpoint(): void {
        if (process.env.NODE_ENV === "development") {
            this.RespawnPosition.set(this.Position.x, this.Position.y, this.Position.z);
            this._checkpointSideEffects();
        }
    }

    public ProcessInputs(inputCommand: InputCommand) {
        const { forward, back, left, right, jump, rotY: cameraYRotation, isMobile, playerIsMoving, mobileXDirection, mobileZDirection, mobileSpeedMultiplier, wantsToRespawnAtCheckpoint, wantsToRestartRun } = inputCommand;
        let { delta: deltaTimeS } = inputCommand;

        // console.log(this.CollidingWithUIPopTrigger);
        // console.log(this.uiPopTriggerAcculumator);

        // console.warn("Player wants to restart run???", wantsToRestartRun);

        this.finiteStateMachine.Update(deltaTimeS, this.animationState);

        this._processCurrentAnimationState();

        // Cap delta to minimum 10fps
        if (deltaTimeS > 0.1) {
            deltaTimeS = 0.1;
        }

        if (this.MovementFrozenDuration > 0) {
            this.MovementFrozenDuration = Math.max(this.MovementFrozenDuration - deltaTimeS, 0);
            return;
        }

        this.movementVector.set(0, 0, 0);
        this.forwardBackwardVector.set(0, 0, 0);
        this.leftRightVector.set(0, 0, 0);

        if (this.runHasStarted && this.runHasEnded === false) {
            this.runDurationAcc += deltaTimeS;
        }

        // console.warn("Player wants to respawn?", wantsToRespawnAtCheckpoint);

        this.lastRespawnedAtAccumulator += deltaTimeS;

        if (wantsToRestartRun && this.runHasStarted === true) {
            this._intentionalRestartEntireRunSideEffects();
            this.runHasStarted = false;

            this.runHasEnded = false;

            this.runDurationAcc = 0;

            this.CheckpointsReached.clear();

            this.RespawnPosition.set(playerSpawn[0], playerSpawn[1], playerSpawn[2]);

            this.shouldRespawn = true;
        }

        if (wantsToRespawnAtCheckpoint && this.runHasStarted === true) {
            if (this.lastRespawnedAtAccumulator > 1) {
                this._intentionalRespawnSideEffects();
                this.shouldRespawn = true;
                this.fallsThisSession++;
                this.lastRespawnedAtAccumulator = 0;
            }
        }

        if (this.y <= -250) {
            this.shouldRespawn = true;
        }

        if (this.shouldRespawn) {
            this.Respawn();
            return;
        }

        if (isMobile) {
            if (!playerIsMoving) this.movementVector.set(mobileXDirection, -this.movementVector.y, -mobileZDirection);
            this.animationSpeedMultiplier = mobileSpeedMultiplier;
            this.mobileJoystickMovementMultplier.set(mobileSpeedMultiplier, 0, mobileSpeedMultiplier);
            this.movementVector.add(new Vector3(-mobileXDirection, this.movementVector.y, mobileZDirection)).multiplyScalar(mobileSpeedMultiplier);
            this.movementVector.applyEuler(new Euler(0, cameraYRotation, 0, "XYZ"));
        } else {
            // Move the player forward, where forward is based on the camera's Y rotation
            const cameraForwardX = Math.sin(cameraYRotation);
            const cameraForwardZ = Math.cos(cameraYRotation);
            const cameraRightX = Math.sin(cameraYRotation - Math.PI / 2);
            const cameraRightZ = Math.cos(cameraYRotation - Math.PI / 2);

            this.forwardBackwardVector.set(cameraForwardX, 0, cameraForwardZ);
            this.leftRightVector.set(cameraRightX, 0, cameraRightZ);

            // console.log("ForwardBackwardVector: ", this.forwardBackwardVector);
            // console.log("LeftRightVector: ", this.leftRightVector);

            if (forward) {
                this.movementVector.add(this.forwardBackwardVector);
            } else if (back) {
                this.movementVector.sub(this.forwardBackwardVector);
            }

            if (left) {
                this.movementVector.add(this.leftRightVector.multiplyScalar(-1));
            } else if (right) {
                this.movementVector.add(this.leftRightVector);
            }
        }

        this._tryUpdateMobileMovementSideEffect();
        this.Velocity.x = 0;
        // this.Velocity.y = 0;
        this.Velocity.z = 0;

        if (isMobile) {
            this.Velocity.add(this.movementVector.normalize().multiply(this.mobileJoystickMovementMultplier));
        } else {
            this.Velocity.add(this.movementVector.normalize());
        }

        this.Velocity.x *= this.speedBlockFactor;
        this.Velocity.z *= this.speedBlockFactor;

        if (this.IsGrounded) {
            this.deathFallTimer = 0;

            if (!this.isOnSpeedBlock) {
                this.speedBlockFactor = 1;
            }
            this.isOnSpeedBlock = false;

            if (jump) {
                this._jumpSideEffects();
                this.Velocity.y = Config.Player.JUMP_VELOCITY;
                if (this.speedBlockFactor < 1) {
                    this.Velocity.y *= 0.7;
                }
            } else {
                this.Velocity.y = 0;
            }
        } else {
            this.Velocity.y = Math.max(this.Velocity.y - Config.World.GRAVITY * deltaTimeS, -5);

            // // Check if the players velocity is negative, if so increment a falling timer by deltaTimeS.
            if (this.Velocity.y < 0) {
                this.deathFallTimer += deltaTimeS;
            }

            // // If the players falling timer exceeds a certain amount, set the player to be in a death fall. Death fall timer = "how long does the player have to be falling for us to consider them to be dead"
            if (this.deathFallTimer > Config.Player.DEATH_FALL_TIMER && this.shouldRespawn === false && this.IsGrounded === false) {
                // If they've completed a run, use their super long death timer instead so they can fall to the bottom of the level
                if (this.runHasEnded) {
                    if (this.deathFallTimer > Config.Player.DEATH_FALL_TIMER_AFTER_RUN_COMPLETE) {
                        this.shouldRespawn = true;
                        this.fallsThisSession++;
                        this._fallingDeathSideEffects();
                    }
                } else {
                    this.shouldRespawn = true;
                    this._fallingDeathSideEffects();
                }
            }
        }

        const distanceToTravel = Config.Player.SPEED_PER_SECOND * deltaTimeS;

        this.x = this.x + this.Velocity.x * distanceToTravel;
        this.y = this.y + this.Velocity.y * distanceToTravel;
        this.z = this.z + this.Velocity.z * distanceToTravel;

        this.collisionSystemRef.CheckForCollisions(this, deltaTimeS);

        this._updateSideEffects();
    }

    private _processCurrentAnimationState(): void {
        //console.log(this.animationState);
        if (this.Velocity.y >= this.jumpingThreshold) {
            this.animationState = "jumping_up";
            //console.log("processing inputs");

            return;
        }

        this.fallingThreshold = -0.4;

        if (this.animationState === "jumping_up") {
            this.fallingThreshold = 0.1;
        }

        if (this.Velocity.y <= this.fallingThreshold) {
            this.animationState = "falling";
            return;
        }

        if ((Math.abs(this.Velocity.x) > 0 || Math.abs(this.Velocity.z) > 0) && this.IsGrounded) {
            this.animationState = "walking";
            return;
        }
        if (this.IsGrounded) {
            this.animationState = "idle";
        }

        //console.log(this.animationState);
    }
}

export class FiniteStateMachine {
    private states: { [name: string]: new (fsm: FiniteStateMachine) => State } = {};
    private currentState: State;
    public sharedPlayer: SharedPlayer;

    public constructor(sharedPlayer: SharedPlayer) {
        this.sharedPlayer = sharedPlayer;
        this.states = {};
        this.currentState = new IdleState(this);
    }

    public AddState(name: string, type: new (fsm: FiniteStateMachine) => State): void {
        this.states[name] = type;
    }

    public SetState(name: string) {
        const prevState: State = this.currentState;

        prevState.Exit();

        const stateConstructor = this.states[name];
        if (stateConstructor) {
            const state = new stateConstructor(this) as State;
            this.currentState = state;
            state.Enter(prevState);
        }
    }

    public Update(deltaTimeInSeconds: number, animationState: string) {
        this.currentState.Update(deltaTimeInSeconds);
        // console.log("processing inputs");

        if (this.currentState.Name === animationState) {
            return;
        }
        /*if (animationState === "jumping_up" && this.currentState.Name === "idle") {
            if (this.sharedPlayer.idleToJumpTimer < 0.455) {
                this.sharedPlayer.idleToJumpTimer += deltaTimeInSeconds;
                return;
            }
        }*/

        this.sharedPlayer.idleToJumpTimer = 0;
        this.SetState(animationState);
    }

    public Init() {
        this.AddState("idle", IdleState);
        this.AddState("walking", walkingState);
        this.AddState("jumping_up", jumpingState);
        this.AddState("death", DeathState);
        this.AddState("falling", fallingState);

        this.SetState("idle");
    }
}

class State {
    public parent: FiniteStateMachine;
    public stateDuration: number;
    public constructor(parent: FiniteStateMachine) {
        this.parent = parent;
        this.stateDuration = 0;
    }

    //The functions below are all overridden within each State.
    public get Name(): string {
        return "";
    }
    public Enter(__prevState: State): void {}
    public Update(__deltaTimeInSeconds: number): void {}
    public Exit(): void {}
}

class IdleState extends State {
    public constructor(parent: FiniteStateMachine) {
        super(parent);
    }

    public override get Name(): string {
        return "idle";
    }

    public override Enter(prevState: State) {
        this.parent.sharedPlayer.idleTimer = 0;
        // console.log("Entering Idle State");
        this.parent.sharedPlayer.currentState = this.Name;
        // console.log("currentState: " + this.parent.sharedPlayer.currentState);

        if (prevState) {
            this.parent.sharedPlayer.previousState = prevState.Name;
        } else {
            this.parent.sharedPlayer.previousState = "none";
        }
    }
}

class walkingState extends State {
    public constructor(parent: FiniteStateMachine) {
        super(parent);
    }

    public override get Name(): string {
        return "walking";
    }

    public override Enter(prevState: State) {
        // console.log("Entering walking State");
        this.parent.sharedPlayer.currentState = this.Name;
        this.parent.sharedPlayer.idleToJumpTimer = 1;

        if (prevState) {
            this.parent.sharedPlayer.previousState = prevState.Name;
        } else {
            this.parent.sharedPlayer.previousState = "none";
        }
    }
}

class jumpingState extends State {
    public constructor(parent: FiniteStateMachine) {
        super(parent);
    }

    public override get Name(): string {
        //We do not have a "Jumping" animation. Instead, jumping plays our "idle" animation and falling plays our "jumping" animation
        //TODO: If animation names are changed, adjust this to match the desired animation
        return "jumping_up";
    }

    public override Enter(prevState: State) {
        // console.log("Entering jumping State");
        this.parent.sharedPlayer.currentState = this.Name;

        if (prevState) {
            this.parent.sharedPlayer.previousState = prevState.Name;
        } else {
            this.parent.sharedPlayer.previousState = "none";
        }
    }
}

class fallingState extends State {
    public constructor(parent: FiniteStateMachine) {
        super(parent);
    }

    public override get Name(): string {
        return "falling";
    }

    public override Enter(prevState: State) {
        // console.log("Entering jumping State");
        this.parent.sharedPlayer.currentState = this.Name;

        if (prevState) {
            this.parent.sharedPlayer.previousState = prevState.Name;
        } else {
            this.parent.sharedPlayer.previousState = "none";
        }
    }
}

class DeathState extends State {
    public constructor(parent: FiniteStateMachine) {
        super(parent);
    }

    public override get Name(): string {
        return "death";
    }

    public override Enter(prevState: State) {
        this.parent.sharedPlayer.currentState = this.Name;

        if (prevState) {
            this.parent.sharedPlayer.previousState = prevState.Name;
        } else {
            this.parent.sharedPlayer.previousState = "none";
        }
    }

    public override Update(__deltaTimeInSeconds: number) {}

    public override Exit() {}
}
