import { Alpha, Behaviour, BodySprite, BoxZone, Color, Life, Mass, Position, RadialVelocity, Radius, Rate, Scale, Span, Vector3D } from "three-nebula";
import Initializer from "three-nebula/src/initializer/Initializer";
import * as THREE from "three";

export enum ParticleEffects {
    // Player Trails
    None = -1,
    Broccolis = 0,
    Circles = 1,
    Flies = 2,
    GoldenFlies = 3,
    Hearts = 4,
    Smoke = 5,
    Stars = 6,
    SquareStars = 7,
    Snow = 8,
    GingerbreadMan = 9

    // Other Effects
    // Confetti = 100
}

export const ParticleRates: Map<ParticleEffects, Rate> = new Map();
ParticleRates.set(ParticleEffects.Stars, new Rate(new Span(1, 2), new Span(0.01)));
ParticleRates.set(ParticleEffects.Hearts, new Rate(new Span(1, 2), new Span(0.02)));
ParticleRates.set(ParticleEffects.Circles, new Rate(new Span(1, 2), new Span(0.02)));
ParticleRates.set(ParticleEffects.SquareStars, new Rate(new Span(1, 2), new Span(0.02)));
ParticleRates.set(ParticleEffects.Broccolis, new Rate(new Span(1, 2), new Span(0.02)));
ParticleRates.set(ParticleEffects.Flies, new Rate(new Span(1, 2), new Span(0.1)));
ParticleRates.set(ParticleEffects.GoldenFlies, new Rate(new Span(1, 2), new Span(0.1)));
ParticleRates.set(ParticleEffects.Smoke, new Rate(new Span(2, 2.5), new Span(0.02)));
ParticleRates.set(ParticleEffects.Snow, new Rate(new Span(1, 2), new Span(0.02)));
ParticleRates.set(ParticleEffects.GingerbreadMan, new Rate(new Span(1,2), new Span(0.08)));


export const ParticleInitializers: Map<ParticleEffects, Initializer[]> = new Map();
ParticleInitializers.set(ParticleEffects.Stars, [new Position(new BoxZone(0, 0.1, 0, 1, 0.2, 1)), new Mass(5), new Radius(2, 3), new Life(0.35), new RadialVelocity(2, new Vector3D(10, 2, 3), 180), new BodySprite(THREE, "particles/star.png", { blending: THREE.NormalBlending })]);
ParticleInitializers.set(ParticleEffects.Hearts, [new Position(new BoxZone(0, 0.1, 0, 1, 0.2, 1)), new Mass(2), new Radius(2, 4), new Life(3), new RadialVelocity(1, new Vector3D(0, 1, 0), 180), new BodySprite(THREE, "particles/heart.png", { blending: THREE.NormalBlending })]);
ParticleInitializers.set(ParticleEffects.Circles, [new Position(new BoxZone(0, 0.1, 0, 1, 0.2, 1)), new Mass(2), new Radius(3, 4), new Life(0.5), new RadialVelocity(1, new Vector3D(0, 1, 0), 180), new BodySprite(THREE, "particles/circle.png", { blending: THREE.NormalBlending })]);
ParticleInitializers.set(ParticleEffects.SquareStars, [new Position(new BoxZone(0, 0.1, 0, 1, 0.2, 1)), new Mass(1), new Radius(1, 4), new Life(3), new RadialVelocity(0.25, new Vector3D(0, 0.25, 0), 180), new BodySprite(THREE, "particles/square_star_001.png", { blending: THREE.NormalBlending })]);
ParticleInitializers.set(ParticleEffects.Broccolis, [new Position(new BoxZone(0, 0.3, 0, 1, 0.2, 1)), new Mass(2), new Radius(2, 4), new Life(2.5), new RadialVelocity(0.7, new Vector3D(0, 1, 0), 180), new BodySprite(THREE, "particles/broccoli.png", { blending: THREE.NormalBlending })]);
ParticleInitializers.set(ParticleEffects.Flies, [new Position(new BoxZone(0, 0.4, 0, 1, 0.2, 1)), new Mass(2), new Radius(2, 4), new Life(3), new RadialVelocity(3, new Vector3D(3, 5, 3), 180), new BodySprite(THREE, "particles/fly.png", { blending: THREE.NormalBlending })]);
ParticleInitializers.set(ParticleEffects.GoldenFlies, [new Position(new BoxZone(0, 0.4, 0, 1, 0.2, 1)), new Mass(2), new Radius(2, 4), new Life(3), new RadialVelocity(3, new Vector3D(3, 5, 3), 180), new BodySprite(THREE, "particles/fly_gold.png", { blending: THREE.NormalBlending })]);
ParticleInitializers.set(ParticleEffects.Smoke, [new Position(new BoxZone(0, 0.1, 0, 0.5, 0.1, 0.5)), new Mass(2), new Radius(1.15, 2.4), new Life(1.1), new RadialVelocity(0.5, new Vector3D(0, 0.5, 0), 180), new BodySprite(THREE, "particles/smoke.png", { blending: THREE.NormalBlending })]);
ParticleInitializers.set(ParticleEffects.Snow, [new Position(new BoxZone(0,1, 0, 1, 0.2, 1)), new Mass(1), new Radius(1, 4), new Life(1.8), new RadialVelocity(0.25, new Vector3D(0, 0.25, 0), 180), new BodySprite(THREE, "particles/snowflake.png", { blending: THREE.NormalBlending })]);
ParticleInitializers.set(ParticleEffects.GingerbreadMan, [new Position(new BoxZone(0,1, 0, 1, 0.2, 1)), new Mass(1), new Radius(1, 4), new Life(1.8), new RadialVelocity(0.25, new Vector3D(0, 0.25, 0), 180), new BodySprite(THREE, "particles/gingerbreadman.png", { blending: THREE.NormalBlending })]);


export const ParticleBehaviours: Map<ParticleEffects, Behaviour[]> = new Map();
ParticleBehaviours.set(ParticleEffects.Stars, [new Alpha(1, 0), new Scale(0.3, 0.5), new Color(new THREE.Color(0xffffff), new THREE.Color(0xffff00))]);
ParticleBehaviours.set(ParticleEffects.Hearts, [new Alpha(1, 0), new Scale(0.25, 0.55), new Color(new THREE.Color(0xfc46aa), new THREE.Color(0xffc0cb))]);
ParticleBehaviours.set(ParticleEffects.Circles, [new Alpha(1, 1), new Scale(0.25, 0.5), new Color(new THREE.Color(0x0000ff), new THREE.Color(0xffffff))]);
ParticleBehaviours.set(ParticleEffects.Broccolis, [new Alpha(1, 0), new Scale(0.3, 0.5), new Color(new THREE.Color(0xffffff), new THREE.Color(0xffffff))]);
ParticleBehaviours.set(ParticleEffects.Flies, [new Alpha(1, 0), new Scale(0.3, 0.5), new Color(new THREE.Color(0xffffff), new THREE.Color(0xffffff))]);
ParticleBehaviours.set(ParticleEffects.GoldenFlies, [new Alpha(1, 0), new Scale(0.3, 0.5), new Color(new THREE.Color(0xffffff), new THREE.Color(0xffffff))]);
ParticleBehaviours.set(ParticleEffects.Smoke, [new Alpha(1, 0), new Scale(0.3, 1.25), new Color(new THREE.Color(0xffffff), new THREE.Color(0x000000))]);
ParticleBehaviours.set(ParticleEffects.Snow, [new Alpha(1, 0), new Scale(0.35, 0.2), new Color(new THREE.Color(0xffffff), new THREE.Color(0xffd700))]);
ParticleBehaviours.set(ParticleEffects.GingerbreadMan, [new Alpha(1, 1), new Scale(0.45, 0.3), new Color(new THREE.Color(0xb96b2b), new THREE.Color(0xd17a32))]);
ParticleBehaviours.set(ParticleEffects.SquareStars, [new Alpha(1, 0), new Scale(0.25, 0.4), new Color(new THREE.Color(0xffffff), new THREE.Color(0xffd700))]);


export const ParticleBurstCounts: Map<ParticleEffects, number> = new Map();
// ParticleBurstCounts.set(ParticleEffects.Confetti, 100);
