//@ts-ignore
import { FrontSide, Material, Mesh, MeshBasicMaterial, PlaneGeometry, Shader, ShaderMaterial, Texture } from "three";
//@ts-ignore
import { Text } from "troika-three-text";
//@ts-ignore
import { createDerivedMaterial } from "troika-three-utils";
import { Countries } from "../../shared/data/Usernames";

interface FadeOptions {
    fadeDuration: number;
}

abstract class FadeLabel {
    public threeText: Text;
    protected isVisible: boolean = false;
    protected currentOpacity: number = 0;
    private fadeDuration: number;

    public constructor(text: string, options?: Partial<FadeOptions>) {
        this.threeText = new Text();
        this.threeText.text = text;
        this.threeText.outlineOffsetX = 0.01;
        this.threeText.outlineOffsetY = 0.01;
        this.fadeDuration = options?.fadeDuration ?? 0.15;
        this.currentOpacity = 1;
    }

    public get visible(): boolean {
        return this.isVisible;
    }

    public set visible(val: boolean) {
        if (this.isVisible !== val) {
            this.isVisible = val;
            this.currentOpacity = val ? 0 : 1;
        }
    }

    public Update(deltaTimeS: number) {
        const targetOpacity = this.isVisible ? 1 : 0;

        // Update the opacity based on the target opacity
        if (this.currentOpacity !== targetOpacity) {
            this.currentOpacity = this.getNextOpacity(deltaTimeS);

            this.threeText.fillOpacity = this.currentOpacity;
            this.threeText.strokeOpacity = this.currentOpacity;
            this.threeText.outlineOpacity = this.currentOpacity;

            this.threeText.visible = this.currentOpacity > 0;
        }
    }

    protected getNextOpacity(deltaTimeS: number): number {
        const opacityChangePerSecond = (this.isVisible ? 1 : -1) / this.fadeDuration;
        const nextOpacity = Math.max(0, Math.min(1, this.currentOpacity + opacityChangePerSecond * deltaTimeS));
        return nextOpacity;
    }
}

const FLAG_SPRITE_WIDTH = 0.4;
const FLAG_SPRITE_HEIGHT = 0.3;
const FONT_SIZE_FUDGE = 0.55; //used to convert font-size to width
export class PlayerLabel extends FadeLabel {
    private labelFontSize = 0.4;
    private labelFont = "fonts/LuckiestGuy-Regular.ttf";
    public readonly width: number;
    private flagMesh: Mesh;
    private flagTexture: Texture;
    public constructor(text: string, country: Countries, options?: FadeOptions) {
        super(text, options);
        this.threeText.font = this.labelFont;
        this.threeText.fontSize = this.labelFontSize;
        this.threeText.anchorX = "left";

        const flagGeometry = new PlaneGeometry(FLAG_SPRITE_WIDTH, FLAG_SPRITE_HEIGHT);
        this.flagMesh = new Mesh(flagGeometry);

        this.SetFlag(country);

        this._updateMaterials();

        this.threeText.add(this.flagMesh);
    }

    public override Update(deltaTimeS: number) {
        //grab opacity before it's updated in parent
        const currentOpacity = this.currentOpacity;
        super.Update(deltaTimeS);

        const targetOpacity = this.isVisible ? 1 : 0;

        // Update the opacity based on the target opacity
        if (currentOpacity !== targetOpacity) {
            const newOpacity = this.getNextOpacity(deltaTimeS);

            //@ts-ignore
            this.flagMesh.material.opacity = newOpacity;
            this.flagMesh.visible = newOpacity > 0;
        }
    }

    public SetFlag(country: Countries) {
        // console.log("Country from server:", country);
        // console.log("Path to flag:", `ui/flags/${getCountryCodeFromCountry(country)}.png`);

        const textureLoader = Game.Loader.TextureLoader;
        this.flagTexture = textureLoader.load(`ui/flags/${getCountryCodeFromCountry(country)}.png`);
        this._updateMaterials();
    }

    private _updateMaterials() {
        const textWidth = this.threeText.text.length * this.labelFontSize * FONT_SIZE_FUDGE;
        const textXOffset = -(textWidth / 2);
        const textMaterial = createBillboardMaterial(new MeshBasicMaterial(), {
            uniforms: {
                u_positionOffsetX: { value: textXOffset },
                u_positionOffsetY: { value: 0 }
            }
        });
        this.threeText.material = textMaterial;

        const flagMaterial = createBillboardMaterial(new MeshBasicMaterial({ map: this.flagTexture, transparent: true, alphaTest: 0.5, side: FrontSide }), {
            uniforms: {
                u_positionOffsetX: { value: textXOffset - FLAG_SPRITE_WIDTH / 2 - 0.05 },
                u_positionOffsetY: { value: -FLAG_SPRITE_WIDTH / 2 + 0.05 }
            }
        });
        this.flagMesh.material = flagMaterial;
    }

    public SetText(text: string) {
        this.threeText.text = text;
        // update material to adjust offset
        this._updateMaterials();
    }
}

function createBillboardMaterial(baseMaterial: Material, opts: Partial<Shader> = {}): ShaderMaterial {
    return createDerivedMaterial(baseMaterial, {
        uniforms: opts.uniforms,
        vertexDefs: `
            uniform float u_positionOffsetX;
            uniform float u_positionOffsetY;
        `,
        vertexMainOutro: `
            vec4 mvPosition = modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0);
            vec3 scale = vec3(
                length(modelViewMatrix[0].xyz),
                length(modelViewMatrix[1].xyz),
                length(modelViewMatrix[2].xyz)
            );

            // Apply position offsets
            mvPosition.x += u_positionOffsetX;
            mvPosition.y += u_positionOffsetY;

            mvPosition.xyz += position * scale;
            gl_Position = projectionMatrix * mvPosition;
        `,
        ...opts
    });
}

const DEFAULT_COUNTRY_CODE = "none";
export const getCountryCodeFromCountry = (country: Countries): string => {
    // go through all countries in Countries enum and return the country code that matches flags filename
    switch (country) {
        case Countries.None:
            return DEFAULT_COUNTRY_CODE;
        case Countries.AlandIslands:
            return "ax";
        case Countries.Albania:
            return "al";
        case Countries.Algeria:
            return "dz";
        case Countries.AmericanSamoa:
            return "as";
        case Countries.Andorra:
            return "ad";
        case Countries.Angola:
            return "ao";
        case Countries.Anguilla:
            return "ai";
        case Countries.Antarctica:
            return "aq";
        case Countries.AntiguaAndBarbuda:
            return "ag";
        case Countries.Argentina:
            return "ar";
        case Countries.Armenia:
            return "am";
        case Countries.Aruba:
            return "aw";
        case Countries.Australia:
            return "au";
        case Countries.Austria:
            return "at";
        case Countries.Azerbaijan:
            return "az";
        case Countries.Bahamas:
            return "bs";
        case Countries.Bahrain:
            return "bh";
        case Countries.Bangladesh:
            return "bd";
        case Countries.Barbados:
            return "bb";
        case Countries.Belarus:
            return "by";
        case Countries.Belgium:
            return "be";
        case Countries.Belize:
            return "bz";
        case Countries.Benin:
            return "bj";
        case Countries.Bermuda:
            return "bm";
        case Countries.Bhutan:
            return "bt";
        case Countries.Bolivia:
            return "bo";
        case Countries.BosniaAndHerzegovina:
            return "ba";
        case Countries.Botswana:
            return "bw";
        case Countries.BouvetIsland:
            return "bv";
        case Countries.Brazil:
            return "br";
        case Countries.BritishIndianOceanTerritory:
            return "io";
        case Countries.Brunei:
            return "bn";
        case Countries.Bulgaria:
            return "bg";
        case Countries.BurkinaFaso:
            return "bf";
        case Countries.Burundi:
            return "bi";
        case Countries.Cambodia:
            return "kh";
        case Countries.Cameroon:
            return "cm";
        case Countries.Canada:
            return "ca";
        case Countries.CapeVerde:
            return "cv";
        case Countries.CaymanIslands:
            return "ky";
        case Countries.CentralAfricanRepublic:
            return "cf";
        case Countries.Chad:
            return "td";
        case Countries.Chile:
            return "cl";
        case Countries.China:
            return "cn";
        case Countries.ChristmasIsland:
            return "cx";
        case Countries.CocosKeelingIslands:
            return "cc";
        case Countries.Colombia:
            return "co";
        case Countries.Comoros:
            return "km";
        case Countries.DRCongo:
            return "cd";
        case Countries.RepublicOfTheCongo:
            return "cg";
        case Countries.CookIslands:
            return "ck";
        case Countries.CostaRica:
            return "cr";
        case Countries.IvoryCoast:
            return "ci";
        case Countries.Croatia:
            return "hr";
        case Countries.Cuba:
            return "cu";
        case Countries.Curacao:
            return "cw";
        case Countries.Cyprus:
            return "cy";
        case Countries.Czechia:
            return "cz";
        case Countries.Denmark:
            return "dk";
        case Countries.Djibouti:
            return "dj";
        case Countries.Dominica:
            return "dm";
        case Countries.DominicanRepublic:
            return "do";
        case Countries.Ecuador:
            return "ec";
        case Countries.Egypt:
            return "eg";
        case Countries.ElSalvador:
            return "sv";
        case Countries.England:
            return "gb-eng";
        case Countries.EquatorialGuinea:
            return "gq";
        case Countries.Eritrea:
            return "er";
        case Countries.Estonia:
            return "ee";
        case Countries.EswatiniSwaziland:
            return "sz";
        case Countries.Ethiopia:
            return "et";
        case Countries.FalklandIslands:
            return "fk";
        case Countries.FaroeIslands:
            return "fo";
        case Countries.Fiji:
            return "fj";
        case Countries.Finland:
            return "fi";
        case Countries.France:
            return "fr";
        case Countries.FrenchGuiana:
            return "gf";
        case Countries.FrenchPolynesia:
            return "pf";
        case Countries.FrenchSouthernAndAntarcticLands:
            return "tf";
        case Countries.Gabon:
            return "ga";
        case Countries.Gambia:
            return "gm";
        case Countries.Georgia:
            return "ge";
        case Countries.Germany:
            return "de";
        case Countries.Ghana:
            return "gh";
        case Countries.Gibraltar:
            return "gi";
        case Countries.Greece:
            return "gr";
        case Countries.Greenland:
            return "gl";
        case Countries.Grenada:
            return "gd";
        case Countries.Guadeloupe:
            return "gp";
        case Countries.Guam:
            return "gu";
        case Countries.Guatemala:
            return "gt";
        case Countries.Guernsey:
            return "gg";
        case Countries.Guinea:
            return "gn";
        case Countries.GuineaBissau:
            return "gw";
        case Countries.Guyana:
            return "gy";
        case Countries.Haiti:
            return "ht";
        case Countries.HeardIslandAndMcdonaldIslands:
            return "hm";
        case Countries.Honduras:
            return "hn";
        case Countries.HongKong:
            return "hk";
        case Countries.Hungary:
            return "hu";
        case Countries.Iceland:
            return "is";
        case Countries.India:
            return "in";
        case Countries.Indonesia:
            return "id";
        case Countries.Iran:
            return "ir";
        case Countries.Iraq:
            return "iq";
        case Countries.Ireland:
            return "ie";
        case Countries.IsleOfMan:
            return "im";
        case Countries.Israel:
            return "il";
        case Countries.Italy:
            return "it";
        case Countries.Jamaica:
            return "jm";
        case Countries.Japan:
            return "jp";
        case Countries.Jersey:
            return "je";
        case Countries.Jordan:
            return "jo";
        case Countries.Kazakhstan:
            return "kz";
        case Countries.Kenya:
            return "ke";
        case Countries.Kiribati:
            return "ki";
        case Countries.NorthKorea:
            return "kp";
        case Countries.SouthKorea:
            return "kr";
        case Countries.Kosovo:
            return "xk";
        case Countries.Kuwait:
            return "kw";
        case Countries.Kyrgyzstan:
            return "kg";
        case Countries.Laos:
            return "la";
        case Countries.Latvia:
            return "lv";
        case Countries.Lebanon:
            return "lb";
        case Countries.Lesotho:
            return "ls";
        case Countries.Liberia:
            return "lr";
        case Countries.Libya:
            return "ly";
        case Countries.Liechtenstein:
            return "li";
        case Countries.Lithuania:
            return "lt";
        case Countries.Luxembourg:
            return "lu";
        case Countries.Macau:
            return "mo";
        case Countries.Madagascar:
            return "mg";
        case Countries.Malawi:
            return "mw";
        case Countries.Malaysia:
            return "my";
        case Countries.Maldives:
            return "mv";
        case Countries.Mali:
            return "ml";
        case Countries.Malta:
            return "mt";
        case Countries.MarshallIslands:
            return "mh";
        case Countries.Martinique:
            return "mq";
        case Countries.Mauritania:
            return "mr";
        case Countries.Mauritius:
            return "mu";
        case Countries.Mayotte:
            return "yt";
        case Countries.Mexico:
            return "mx";
        case Countries.Micronesia:
            return "fm";
        case Countries.Moldova:
            return "md";
        case Countries.Monaco:
            return "mc";
        case Countries.Mongolia:
            return "mn";
        case Countries.Montenegro:
            return "me";
        case Countries.Montserrat:
            return "ms";
        case Countries.Morocco:
            return "ma";
        case Countries.Mozambique:
            return "mz";
        case Countries.Myanmar:
            return "mm";
        case Countries.Namibia:
            return "na";
        case Countries.Nauru:
            return "nr";
        case Countries.Nepal:
            return "np";
        case Countries.Netherlands:
            return "nl";
        case Countries.NewCaledonia:
            return "nc";
        case Countries.NewZealand:
            return "nz";
        case Countries.Nicaragua:
            return "ni";
        case Countries.Niger:
            return "ne";
        case Countries.Nigeria:
            return "ng";
        case Countries.Niue:
            return "nu";
        case Countries.NorfolkIsland:
            return "nf";
        case Countries.NorthMacedonia:
            return "mk";
        case Countries.NorthernMarianaIslands:
            return "mp";
        case Countries.Norway:
            return "no";
        case Countries.Oman:
            return "om";
        case Countries.Pakistan:
            return "pk";
        case Countries.Palau:
            return "pw";
        case Countries.Palestine:
            return "ps";
        case Countries.Panama:
            return "pa";
        case Countries.PapuaNewGuinea:
            return "pg";
        case Countries.Paraguay:
            return "py";
        case Countries.Peru:
            return "pe";
        case Countries.Philippines:
            return "ph";
        case Countries.PitcairnIslands:
            return "pn";
        case Countries.Poland:
            return "pl";
        case Countries.Portugal:
            return "pt";
        case Countries.PuertoRico:
            return "pr";
        case Countries.Qatar:
            return "qa";
        case Countries.Reunion:
            return "re";
        case Countries.Romania:
            return "ro";
        case Countries.Russia:
            return "ru";
        case Countries.Rwanda:
            return "rw";
        case Countries.SaintBarthelemy:
            return "bl";
        case Countries.SaintHelenaAscensionAndTristandaCunha:
            return "sh";
        case Countries.SaintKittsAndNevis:
            return "kn";
        case Countries.SaintLucia:
            return "lc";
        case Countries.SaintMartin:
            return "mf";
        case Countries.SaintPierreAndMiquelon:
            return "pm";
        case Countries.SaintVincentAndTheGrenadines:
            return "vc";
        case Countries.Samoa:
            return "ws";
        case Countries.SanMarino:
            return "sm";
        case Countries.SaoTomeAndPrincipe:
            return "st";
        case Countries.SaudiArabia:
            return "sa";
        case Countries.Scotland:
            return "gb-sct";
        case Countries.Senegal:
            return "sn";
        case Countries.Serbia:
            return "rs";
        case Countries.Seychelles:
            return "sc";
        case Countries.SierraLeone:
            return "sl";
        case Countries.Singapore:
            return "sg";
        case Countries.SintMaarten:
            return "sx";
        case Countries.Slovakia:
            return "sk";
        case Countries.Slovenia:
            return "si";
        case Countries.SolomonIslands:
            return "sb";
        case Countries.Somalia:
            return "so";
        case Countries.SouthAfrica:
            return "za";
        case Countries.SouthGeorgia:
            return "gs";
        case Countries.SouthSudan:
            return "ss";
        case Countries.Spain:
            return "es";
        case Countries.SriLanka:
            return "lk";
        case Countries.Sudan:
            return "sd";
        case Countries.Suriname:
            return "sr";
        case Countries.SvalbardAndJanMayen:
            return "sj";
        case Countries.Sweden:
            return "se";
        case Countries.Switzerland:
            return "ch";
        case Countries.Syria:
            return "sy";
        case Countries.Taiwan:
            return "tw";
        case Countries.Tajikistan:
            return "tj";
        case Countries.Tanzania:
            return "tz";
        case Countries.Thailand:
            return "th";
        case Countries.TimorLeste:
            return "tl";
        case Countries.Togo:
            return "tg";
        case Countries.Tokelau:
            return "tk";
        case Countries.Tonga:
            return "to";
        case Countries.TrinidadAndTobago:
            return "tt";
        case Countries.Tunisia:
            return "tn";
        case Countries.Turkey:
            return "tr";
        case Countries.Turkmenistan:
            return "tm";
        case Countries.TurksAndCaicosIslands:
            return "tc";
        case Countries.Tuvalu:
            return "tv";
        case Countries.Uganda:
            return "ug";
        case Countries.Ukraine:
            return "ua";
        case Countries.UnitedArabEmirates:
            return "ae";
        case Countries.UnitedKingdom:
            return "gb";
        case Countries.UnitedStates:
            return "us";
        case Countries.Uruguay:
            return "uy";
        case Countries.Uzbekistan:
            return "uz";
        case Countries.Vanuatu:
            return "vu";
        case Countries.VaticanCity:
            return "va";
        case Countries.Venezuela:
            return "ve";
        case Countries.Vietnam:
            return "vn";
        case Countries.BritishVirginIslands:
            return "vg";
        case Countries.UnitedStatesVirginIslands:
            return "vi";
        case Countries.Wales:
            return "gb-wls";
        case Countries.WallisAndFutuna:
            return "wf";
        case Countries.WesternSahara:
            return "eh";
        case Countries.Yemen:
            return "ye";
        case Countries.Zambia:
            return "zm";
        case Countries.Zimbabwe:
            return "zw";
        default:
            return DEFAULT_COUNTRY_CODE;
    }
};
