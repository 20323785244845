import { Vector3 } from "three";

export enum TriggerTypes {
    None = -1,
    Checkpoint = 0,
    StartingLine = 1,
    FinishLine = 2,
    UI_PopUp_CharacterSelect = 3,
    DeathBlock = 4,
    Bounce = 5,
    Speed = 6,
    UI_PopUp_NameAndCountrySelect = 7,
    UI_PopUp_EndRun = 8,
    UI_PopUp_TrailSelect = 9
}

export interface AABBBoxCollider {
    Height: number;
    Width: number;
    Depth: number;
    Position: Vector3;
}

export interface IEntityWithPosition {
    Position: Vector3;
}

export interface IEntityWithRotation {
    Rotation: Vector3;
}

export interface IEntityWithScale {
    Scale: Vector3;
}

export interface IEntityWithVelocity {
    Velocity: Vector3;
}

export interface ICollidable extends IEntityWithPosition, IEntityWithScale {
    Collider: AABBBoxCollider;
}

export interface ITriggerEntity extends ICollidable {
    AssociatedPlatform: string;
    TriggerType: TriggerTypes;
    BehaviourValue: number;
}

export class SharedEntityBase implements IEntityWithRotation, IEntityWithVelocity, ICollidable {
    public Position: Vector3 = new Vector3(0, 0, 0);
    public Scale: Vector3 = new Vector3(0, 0, 0);
    public Rotation: Vector3 = new Vector3(0, 0, 0);
    public Velocity: Vector3 = new Vector3(0, 0, 0);
    public Collider: AABBBoxCollider = {
        Height: 2,
        Width: 1,
        Depth: 1,
        Position: this.Position
    };

    public constructor(initialX: number, initialY: number, initialZ: number, scaleX: number, scaleY: number, scaleZ: number, rotX: number, rotY: number, rotZ: number) {
        this.Position.set(initialX, initialY, initialZ);
        this.Scale.set(scaleX, scaleY, scaleZ);
        this.Rotation.set(rotX, rotY, rotZ);
    }

    public get top() {
        return this.Position.y + this.Collider.Height / 2;
    }

    public get bottom() {
        return this.Position.y - this.Collider.Height / 2;
    }

    public get left() {
        return this.Position.x - this.Collider.Width / 2;
    }

    public get right() {
        return this.Position.x + this.Collider.Width / 2;
    }

    public get front() {
        return this.Position.z - this.Collider.Depth / 2;
    }

    public get back() {
        return this.Position.z + this.Collider.Depth / 2;
    }

    public get x() {
        return this.Position.x;
    }

    public set x(newX: number) {
        this.Position.x = newX;
        this.Collider.Position.x = newX;
    }

    public get y() {
        return this.Position.y;
    }

    public set y(newY: number) {
        this.Position.y = newY;
        this.Collider.Position.y = newY;
    }

    public get z() {
        return this.Position.z;
    }

    public set z(newZ: number) {
        this.Position.z = newZ;
        this.Collider.Position.z = newZ;
    }

    public get scaleX() {
        return this.Scale.x;
    }

    public get scaleY() {
        return this.Scale.y;
    }

    public get scaleZ() {
        return this.Scale.z;
    }

    public get rotX() {
        return this.Rotation.x;
    }

    public set rotX(eulerAngle: number) {
        this.Rotation.x = eulerAngle;
    }

    public get rotY() {
        return this.Rotation.y;
    }

    public set rotY(eulerAngle: number) {
        this.Rotation.y = eulerAngle;
    }

    public get rotZ() {
        return this.Rotation.z;
    }

    public set rotZ(eulerAngle: number) {
        this.Rotation.z = eulerAngle;
    }

    public get colliderWidth() {
        return this.Collider.Width;
    }

    public get colliderHeight() {
        return this.Collider.Height;
    }

    public get colliderDepth() {
        return this.Collider.Depth;
    }
}
