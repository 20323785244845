{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "error_screen__server_maybe_full": "Erro ao conectar ao servidor: os servidores podem estar cheios agora, tente novamente em alguns minutos!",
    "error_screen__maintenance_mode": "UpTogether.io está sendo atualizado! Volte em alguns minutos para experimentar as novidades 🎉",
    "error_screen__client_out_of_date": "Seu cliente de jogo está desatualizado! Por favor, atualize seu cliente de jogo para a versão mais recente e tente novamente.",
    "error_screen__invalid_connection": "O servidor rejeitou seu usuário! Por favor, tente novamente.",
    "error_screen__already_logged_in": "Você já está logado!",
    "error_screen__unknown_reason": "Erro de conexão desconhecido :(",
    "error_screen__disconnected_from_server": "Desconectado do servidor! Os servidores podem estar indo para baixo para uma atualização. Tente novamente em alguns minutos.",
    "heightmarker__to_bottom": "De volta ao fundo!",
    "heightmarker__inspiration_come_far": "Veja o quão longe você chegou!",
    "heightmarker__inspiration_keep_going": "Continue!",
    "heightmarker__inspiration_up_up": "Para cima! Para cima! Para cima!",
    "in_game__secret_edward": "Oi Edward!!",
    "heightmarker__secret_gorillas": "Quantos gorilas você consegue encontrar??",
    "heightmarker__change_character": "Trocar Personagem",
    "heightmarker__change_name": "Trocar Nome",
    "heightmarker__change_trail": "Obter uma trilha!",
    "heightmarker__global_leaderboard": "Classificação Global",
    "heightmarker__your_stats": "SUAS ESTATÍSTICAS",
    "heightmarker__game_stats": "ESTATÍSTICAS DO JOGO",
    "heightmarker__tutorial_speed": "Velocidade",
    "heightmarker_tutorial_boost": "Impulso!",
    "heightmarker__tutorial_climb": "ESCALA",
    "heightmarker__tutorial_the_wall": "A PAREDE",
    "heightmarker__tutorial_bouncy": "Saltitante!",
    "in_game__secret_invis_maze": "Ao lado da porta do labirinto, à esquerda...",
    "in_game__secret_upside_down_plate": "Salte para frente da\n     placa de cabeça para baixo!",
    "in_game__tutorial_start_timer": "Passe a linha de partida para iniciar o cronômetro",
    "in_game__tutorial_invis_platforms": "Existem plataformas invisíveis!",
    "in_game__how_fast_top": "Quão rápido você pode chegar ao topo?",
    "in_game__no_touchy": "NÃO TOQUE!",
    "in_game__tutorial_running": "Use o joystick esquerdo para correr",
    "in_game__tutorial_tap": "Toque",
    "in_game__tutorial_press": "Pressione",
    "in_game__tutorial_or": "ou",
    "in_game__tutorial__to_jump": "Para Saltar",
    "in_game__tutorial__hold": "Segure",
    "in_game__tutorial__jump_repeatedly": "Para saltar repetidamente",
    "in_game__tutorial_wall_climb": "Para escalar paredes",
    "in_game__tutorial_while_running": "Enquanto Corre",
    "in_game__tutorial_drag_move_camera": "Arraste a tela para \n mover a câmera",
    "in_game__checkpoint": "Posto de Controle!",
    "loading_text__connecting": "Conectando ao servidor...",
    "loading_text__splines": "Reticulando splines",
    "loading_text__tofu": "Marinando o tofu",
    "loading_text__corn": "Descascando o milho",
    "loading_text__broccoli": "Confirmar que o brócolis são árvores pequenas",
    "loading_text__brussel_sprouts": "Convencendo os jogadores de que os brotos de Bruxelas são deliciosos",
    "loading_text__secret_sauce": "Adicionando o molho secreto",
    "loading_text__digesting": "Digerindo",
    "loading_text__stirring": "Mexendo constantemente",
    "loading_text__fixing_ice_cream": "Consertando a máquina de sorvete no McDonald's",
    "loading_text__chopping_broccoli": "Picando o brócolis",
    "loading_text__carmelize_apples": "Caramelizando as maçãs",
    "loading_text__water_boil": "Observando a água ferver",
    "loading_text__filleting_fish": "Filetando o peixe",
    "loading_text__plating": "Montando o prato",
    "loading_text__garnishing": "Guarnecendo",
    "loading_text__moldy_bits": "Cortando as partes mofadas",
    "death_block__almost": "Quase!",
    "death_block__close_one": "Por pouco!",
    "death_block__try_again": "Tente novamente!",
    "death_block__roasted": "Assado!",
    "death_block__burnt": "Queimado!",
    "death_block__oh_no": "Ah, não!",
    "death_block__grilled": "Grelhado!",
    "death_block__blanched": "Branqueado!",
    "death_block__crisp": "Crocante!",
    "death_block__flameed": "Flambado!",
    "death_block__order_up": "Pedido pronto!",
    "death_block__ouch": "Ai!",
    "death_block__good_try": "Boa tentativa!",
    "death_block__baked": "Assado!",
    "death_block__crusty": "Crocante!",
    "death_block__next_time": "Na próxima!",
    "death_block__toast": "Você está torrado!",
    "death_block__no_chef": "Sem Chef!",
    "death_block__singe_left": "Apenas uma queimadura à esquerda!",
    "death_block__oof": "Oof!",
    "death_block__fried": "Atrapalhado e frito!",
    "death_block__recipe_ruined": "Receita arruinada",
    "death_block__sizzling_stop": "Uma parada crepitante!",
    "death_block__defeated": "Chef derrotado!",
    "death_block__burnt_out": "Queimado?",
    "death_block__meltdown": "Tendo um colapso?",
    "falling_death__slippery": "Escorregadio!",
    "falling_death__gg": "GG",
    "falling_death__you_died": "Você morreu ",
    "falling_death__f": "F",
    "falling_death__floor_food": "Comida no chão!",
    "falling_death__three_second": "Regra dos três segundos!",
    "falling_death__floor_seasoning": "Mmm! Tempero do chão!",
    "falling_death__just_missed": "Por pouco",
    "falling_death__rip": "Descanse em paz",
    "falling_death__floor_vs_flavor": "Chão: 1",
    "falling_death__floor_fall": "Falha no chão!",
    "falling_death__saucy_slip": "Deslize saboroso!",
    "falling_death__fried_fell": "Frito e caiu!",
    "falling_death__dive": "Mergulho sem sabor!",
    "falling_death__floor_finish": "Acabamento no chão!",
    "falling_death__dinner_dive": "Mergulho no jantar!",
    "falling_death__splat": "SPLAT!",
    "falling_death__oops": "Oops",
    "falling_death__greasy_spot": "Você encontrou o ponto oleoso!",
    "falling_death__barely_missed": "Por um triz!",
    "falling_death__down_we_go": "Lá vamos nós!",
    "button__play": "Jogar!",
    "restart__title": "Reiniciar?",
    "restart__are_you_sure": "Você tem certeza de que deseja reiniciar sua corrida?",
    "restart__confirm": "Sim!",
    "restart__restarted": "Reiniciado...",
    "leaderboard__players": "Jogadores",
    "leaderboard__countries": "Países",
    "leaderboard__falls": "Quedas",
    "leaderboard__hours_played": "Horas Jogadas",
    "leaderboard__got_to_top": "Chegou ao Topo",
    "leaderboard__personal_best": "Melhor Desempenho Pessoal",
    "leaderboard__global_rank": "Classificação Global",
    "leaderboard__rank": "Classificação",
    "leaderboard__runs": "Rodadas",
    "leaderboard__time_played": "Tempo Jogado"
}
