export enum Emotes {
    Apple = 0,
    AppleGold = 1,
    Baguette = 2,
    BaguetteGold = 3,
    Blueberry = 4,
    BlueberryGold = 5,
    Broccoli = 6,
    BroccoliGold = 7,
    ChefHat = 8,
    ChefHatGold = 9,
    ComeWithMe = 10,
    ComeWithMeGold = 11,
    Cupcake = 12,
    CupcakeGold = 13,
    Eggplant = 14,
    EggplantGold = 15,
    Fly = 16,
    FlyGold = 17,
    Icecream = 18,
    IcecreamGold = 19,
    InvisiblePlatform = 20,
    InvisiblePlatformGold = 21,
    Peach = 22,
    PeachGold = 23,
    Spatula = 24,
    SpatulaGold = 25
}

export const getEmoteFromEmoteString = (emoteString: string): Emotes => {
    let emote: Emotes = Emotes.Apple;
    switch (emoteString) {
        case "apple":
            emote = Emotes.Apple;
            break;
        case "baguette":
            emote = Emotes.Baguette;
            break;
        case "blueberry":
            emote = Emotes.Blueberry;
            break;
        case "broccoli":
            emote = Emotes.Broccoli;
            break;
        case "hat":
            emote = Emotes.ChefHat;
            break;
        case "come":
            emote = Emotes.ComeWithMe;
            break;
        case "cupcake":
            emote = Emotes.Cupcake;
            break;
        case "eggplant":
            emote = Emotes.Eggplant;
            break;
        case "fly":
            emote = Emotes.Fly;
            break;
        case "icecream":
            emote = Emotes.Icecream;
            break;
        case "invis":
            emote = Emotes.InvisiblePlatform;
            break;
        case "peach":
            emote = Emotes.Peach;
            break;
        case "spatula":
            emote = Emotes.Spatula;
            break;
        default:
            emote = Emotes.Apple;
            break;
    }

    return emote;
};

export const EmoteImages: Map<Emotes, string> = new Map();
EmoteImages.set(Emotes.Apple, "Apple_Regular.png");
EmoteImages.set(Emotes.AppleGold, "Apple_Gold.png");
EmoteImages.set(Emotes.Baguette, "Baguette_Regular.png");
EmoteImages.set(Emotes.BaguetteGold, "Baguette_Gold.png");
EmoteImages.set(Emotes.Blueberry, "Blueberry_Regular.png");
EmoteImages.set(Emotes.BlueberryGold, "Blueberry_Gold.png");
EmoteImages.set(Emotes.Broccoli, "Broccoli_Regular.png");
EmoteImages.set(Emotes.BroccoliGold, "Broccoli_Gold.png");
EmoteImages.set(Emotes.ChefHat, "ChefHat_Regular.png");
EmoteImages.set(Emotes.ChefHatGold, "ChefHat.png");
EmoteImages.set(Emotes.ComeWithMe, "ComeWithMe_Regular.png");
EmoteImages.set(Emotes.ComeWithMeGold, "ComeWithMe_Gold.png");
EmoteImages.set(Emotes.Cupcake, "Cupcake_Regular.png");
EmoteImages.set(Emotes.CupcakeGold, "Cupcake_Gold.png");
EmoteImages.set(Emotes.Eggplant, "Eggplant_Regular.png");
EmoteImages.set(Emotes.EggplantGold, "Eggplant_Gold.png");
EmoteImages.set(Emotes.Fly, "Fly_Regular.png");
EmoteImages.set(Emotes.FlyGold, "Fly_Gold.png");
EmoteImages.set(Emotes.Icecream, "Icecream_Regular.png");
EmoteImages.set(Emotes.IcecreamGold, "Icecream_Gold.png");
EmoteImages.set(Emotes.InvisiblePlatform, "InvisiblePlatform_Regular.png");
EmoteImages.set(Emotes.InvisiblePlatformGold, "InvisiblePlatform_Gold.png");
EmoteImages.set(Emotes.Peach, "Peach_Regular.png");
EmoteImages.set(Emotes.PeachGold, "Peach_Gold.png");
EmoteImages.set(Emotes.Spatula, "Spatula_Regular.png");
EmoteImages.set(Emotes.SpatulaGold, "Spatula.png");

export enum EmoteAnimations {
    Shake = 0,
    Bounce = 1,
    Wiggle = 2
}

export function GetRandomEmoteAnimation(): EmoteAnimations {
    const r = Math.floor(Math.random() * 3);
    return r as EmoteAnimations;
}
