import { Config } from "../../shared/Config";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";

export abstract class SDKWrapperSystem extends GameplaySystem {
    protected needsLazyInitialization: boolean = false;
    protected abstract _initializeSDK(): void;
}

export class ClientPokiSDKWrapperSystem extends SDKWrapperSystem {
    public constructor() {
        super();
    }

    private currentGameplayState: "loading" | "playing" | "paused" = "loading";

    private gameplayStartEventFired: boolean = false;
    private loadingFinishedEventFired: boolean = false;
    private hadToMuteMusic: boolean = false;
    private hadToMuteSFX: boolean = false;

    private systemInitializedAt = Date.now();

    private adSuccessfullyPlayedCallback: (() => void) | undefined = undefined;

    protected override getSystemName(): string {
        return "PokiSDKWrapper";
    }

    public override Initialize(): void {
        if (PokiSDK === undefined) {
            this.needsLazyInitialization = true;
        } else {
            this._initializeSDK();
        }
    }

    private _onGameplayStart(): void {
        // console.info("On gameplay START fired. Current state....", this.currentGameplayState);
        if (this.currentGameplayState !== "playing") {
            PokiSDK.gameplayStart();
            this.currentGameplayState = "playing";
        }
    }

    private _onGameplayStop(): void {
        // console.info("On gameplay STOP fired. Current state....", this.currentGameplayState);
        if (this.currentGameplayState !== "paused") {
            PokiSDK.gameplayStop();
            this.currentGameplayState = "paused";
        }
    }

    private _onLoadingFinished(): void {
        if (this.loadingFinishedEventFired === false) {
            this.loadingFinishedEventFired = true;
            PokiSDK.gameLoadingFinished();
        }
    }

    protected override _initializeSDK() {
        PokiSDK.init()
            .then(() => {
                Game.ListenForEvent("SDK::GameplayStart", this._onGameplayStart.bind(this));

                Game.ListenForEvent("SDK::GameplayStop", this._onGameplayStop.bind(this));

                Game.ListenForEvent("SDK::GameLoadingFinished", this._onLoadingFinished.bind(this));

                Game.ListenForEvent("SDK::PopRewardedAd", (cb: () => void) => {
                    this._popRewardedAd(cb);
                });

                Game.ListenForEvent("SDK::PopCommercialBreakAd", () => {
                    this._popCommercialBreak();
                });
            })
            .catch(() => {
                console.error("Initialized, but the user likely has adblock :/");
            });
    }

    private _oneMinuteHasPassedSinceSystemInitialization(): boolean {
        const oneMinuteInMS = 60000;
        const now = Date.now();
        const diff = now - this.systemInitializedAt;

        if (diff >= oneMinuteInMS) {
            return true;
        } else {
            return false;
        }
    }

    private _popCommercialBreak(): void {
        // console.warn("Popping commercial break!");

        // Game.UI.CancelPointerLock();

        if (this._oneMinuteHasPassedSinceSystemInitialization() === false) {
            this.LogInfo("Skipping ad play opportunity because it hasnt been 1 minute since system initialization yet");
            return;
        }

        if (Config.Rewards.SKIP_ADS && (window.location.href.includes("localhost") || window.location.href.includes("dev.uptogether.io"))) {
            this._onAdStarted();

            setTimeout(() => {
                this._onAdEnded(true);
            }, 100);
        } else {
            PokiSDK.commercialBreak(this._onAdStarted.bind(this)).then(this._onAdEnded.bind(this)).catch(this._onAdError.bind(this));
        }
    }

    private _popRewardedAd(cb: () => void): void {
        // console.warn("Popping rewarded ad!");
        this.adSuccessfullyPlayedCallback = cb;

        // Game.UI.CancelPointerLock();

        if (Config.Rewards.SKIP_ADS && (window.location.href.includes("localhost") || window.location.href.includes("dev.uptogether.io"))) {
            this._onAdStarted();

            setTimeout(() => {
                this._onAdEnded(true);
            }, 100);
        } else {
            PokiSDK.rewardedBreak(this._onAdStarted.bind(this)).then(this._onAdEnded.bind(this)).catch(this._onAdError.bind(this));
        }
    }

    private _onAdStarted(): void {
        // console.log("Ad started callback!");

        // console.log(Game.Audio.MusicMuted);

        // console.log(Game.Audio.SFXMuted);

        if (Game.Audio.MusicMuted === false) {
            // console.log("Player had music playing when we started the ad. temporarily mute it...");
            Game.Audio.ToggleMusicMute();
            this.hadToMuteMusic = true;
        } else {
            this.hadToMuteMusic = false;
        }

        if (Game.Audio.SFXMuted === false) {
            // console.log("Player had sfx playing when we started the ad. temporarily mute it...");
            Game.Audio.ToggleSFXMute();
            this.hadToMuteSFX = true;
        } else {
            this.hadToMuteSFX = false;
        }

        Game.UI.CancelPointerLock();
    }

    private _onAdEnded(success: boolean): void {
        // console.log("Ad ended callback!");

        if (success) {
            // console.info("ad was played (confirmed)");

            if (this.adSuccessfullyPlayedCallback !== undefined) {
                this.adSuccessfullyPlayedCallback();
            }
        } else {
            // console.error("ad was NOT played!!!");
        }

        if (this.hadToMuteMusic === true) {
            // console.log("Player had music playing when we started the ad. since we muted it before, resuming it now");
            Game.Audio.ToggleMusicMute();
            this.hadToMuteMusic = false;
        }

        if (this.hadToMuteSFX === true) {
            // console.log("Player had sfx playing when we started the ad. since we muted it before, resuming it now");
            Game.Audio.ToggleSFXMute();
            this.hadToMuteMusic = false;
        }

        // Game.UI.HideAllScreens();

        this.adSuccessfullyPlayedCallback = undefined;
    }

    private _onAdError(__error: unknown): void {
        // console.log("Ad error callback!", error);

        // Game.UI.HideAllScreens();

        if (this.hadToMuteMusic === true) {
            // console.log("Player had music playing when we started the ad. since we muted it before, resuming it now");
            Game.Audio.ToggleMusicMute();
            this.hadToMuteMusic = false;
        }

        if (this.hadToMuteSFX === true) {
            // console.log("Player had sfx playing when we started the ad. since we muted it before, resuming it now");
            Game.Audio.ToggleSFXMute();
            this.hadToMuteSFX = false;
        }
    }

    public override Update(__deltaTimeS: number, __deltaTimeMS: number, __currentTime: Timestamp): void {
        if (this.needsLazyInitialization && PokiSDK !== undefined) {
            this._initializeSDK();
            this.needsLazyInitialization = false;
        }
    }
    public override Cleanup(): void {}
}
