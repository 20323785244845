const GAME_VERSION = "2.0.0";

export const Config = {
    Game: {
        GAME_VERSION: GAME_VERSION,
        MAJOR_GAME_VERSION: GAME_VERSION.substring(0, 1),
        MAX_PLAYERS: 50,
        GAME_NAME: "UpTogether.io"
    },
    Rendering: {
        ENABLE_STATS: false
    },
    Camera: {
        FOV: 90,
        NEAR_PLANE: 0.1,
        FAR_PLANE: 10000.0,
        OFFSET_VERTICAL: 6,
        MOUSE_SENSITIVITY: 0.0035,
        MOBILE_CAMERA_ROTATION_SENSITIVITY: {
            VERTICAL: 1.5,
            HORIZONTAL: 2
        },
        PER_FRAME_MOVEMENT_CAP: 20 * (Math.PI / 180), // deg to rad conversion
        DRAW_CAMERA_TARGET_CUBE: false,
        RAYCAST_CAMERA: false,
        STARTING_DISTANCE: 6,
        STARTING_ANGLE: 40,
        ZOOM_SENSITIVITY: 0.005,
        MIN_DISTANCE: 2.5,
        MAX_DISTANCE: 10,
        MAX_HEIGHT_WHEN_CLOSE: -6,
        MIN_HEIGHT_WHEN_CLOSE: -6,
        MAX_HEIGHT_WHEN_FAR: 3.5,
        MIN_HEIGHT_WHEN_FAR: -6,
        FOV_SPEED_ADJUST_FACTOR: 1.1,
        FOV_SPEED_ADJUST_LERP_SPEED: 5
    },
    Server: {
        TARGET_DELTA_IN_SECONDS: 1 / 20,
        TARGET_DELTA_IN_MS: 1000 / 20
    },
    Collision: {
        DRAW_COLLISION_BOXES: false
    },
    Logging: {
        ALWAYS_LOG_LONG_FRAMES: true,
        LOG_TICK_METADATA_EVERY_SERVER_FRAME: false,
        ONLY_DISPLAY_ONE_FRAME_LOG_AT_A_TIME: false
    },
    Rewards: {
        SKIP_ADS: process.env.NODE_ENV === "development" ? true : false
    },
    World: {
        GRAVITY: 5.1,
        DEBUG_TELEPORT_LOCATION: {
            X: 87.22,
            Y: 1400,
            Z: 388.34
        }
    },
    Player: {
        ENABLE_PREDICTION: true,
        JUMP_VELOCITY: 2.25,
        SPEED_PER_SECOND: 10,
        DEATH_FALL_TIMER: 2.5,
        DEATH_FALL_TIMER_AFTER_RUN_COMPLETE: 35,
        RESPAWN_MOVEMENT_DELAY: 0.25,
        DRAW_SERVERSIDE_SELF_DURING_PREDICTION: false,
        DEBUG_SPAWN_POSITION: {
            ENABLED: false,
            X: 101,
            Y: 1,
            Z: -25
        },
        DRAW_COLLIDER: false
    },
    UI: {}
};
