/* eslint-disable @typescript-eslint/no-explicit-any */
import { getClassName } from "../SharedUtils";
import { LogLevel, padCenter } from "./SharedLogging";
import { Config } from "../Config";
const { Logging } = Config;

export class GameplaySystemLogger {
    private level: LogLevel;

    public constructor(level: LogLevel = LogLevel.INFO) {
        this.level = level;
    }

    protected setLogLevel(newLogLevel: LogLevel) {
        this.level = newLogLevel;
    }

    protected getSystemName(): string {
        return getClassName(this);
    }

    protected log(level: LogLevel, message: string, data?: any): void {
        if (level >= this.level) {
            let logFn;

            const frameNumberSegment = "[" + padCenter(globalThis.currentFrameNumber.toString(), 7) + "]";

            const systemSegment = "[" + padCenter(this.getSystemName(), 13) + "]";

            const logLevelSegment = "[" + padCenter(LogLevel[level], 9) + "]";

            const fullString = `%c ${frameNumberSegment} ${logLevelSegment} ${systemSegment} ${message}`;

            if (level === LogLevel.ERROR) {
                logFn = console.error.bind(console, fullString, "color: red");
            } else if (level === LogLevel.WARNING) {
                logFn = console.warn.bind(console, fullString, "color: orange");
            } else if (level === LogLevel.VERBOSE) {
                logFn = console.debug.bind(console, fullString, "color: gray");
            } else {
                logFn = console.log.bind(console, fullString, "color: white");
            }

            if (data !== undefined) {
                logFn(data); // Log the data alongside the message
            } else {
                logFn(); // Log the message without data
            }
        }
    }

    public ConditionallyLogTickMsg(msg: string, level: LogLevel = LogLevel.INFO, data?: any): void {
        if (Logging.LOG_TICK_METADATA_EVERY_SERVER_FRAME === true) {
            this.log(level, msg, data);
        }
    }

    public LogVerbose(message: string, data?: any): void {
        this.log(LogLevel.VERBOSE, message, data);
    }

    public LogInfo(message: string, data?: any): void {
        this.log(LogLevel.INFO, message, data);
    }

    public LogWarning(message: string, data?: any): void {
        this.log(LogLevel.WARNING, message, data);
    }

    public LogError(message: string, data?: any): void {
        this.log(LogLevel.ERROR, message, data);
    }
}
