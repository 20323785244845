"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PredictionEntity = void 0;
class PredictionEntity {
    constructor(nid, entity, props, nschema) {
        this.nid = nid;
        this.proxy = entity;
        this.props = props;
        this.nschema = nschema;
    }
}
exports.PredictionEntity = PredictionEntity;
