import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import * as Level from "../../shared/data/level_uppity.json";
import { PlatformDetails, Platforms, getPlatformMetadataByJsonIdentifier } from "../../shared/data/PlatformData";
import { Group, MeshStandardMaterial, Quaternion, Euler, Matrix4, Vector3, InstancedMesh } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { Behaviour, Emitter, Rate } from "three-nebula";
import { ParticleBehaviours, ParticleEffects, ParticleInitializers, ParticleRates } from "../../shared/data/ParticleData";
import Initializer from "three-nebula/src/initializer/Initializer";
import { randInt } from "three/src/math/MathUtils";

export class ClientLevelLoader extends GameplaySystem {
    private levelData: typeof Level.platforms;

    public constructor() {
        super();
    }

    //These need to be async in order for us to grab the model data needed for InstancedMeshes
    private async _loadLevelAssets(): Promise<Map<Platforms, InstancedMesh>> {
        const uniquePlatforms: PlatformDetails[] = [];
        const instancedMeshTotalCounts: Map<string, number> = new Map<string, number>();

        this.levelData
            .filter((platform) => platform.uniqueIdentifier.toLowerCase() !== "spawn")
            .forEach((platform) => {
                const platformMetadata = getPlatformMetadataByJsonIdentifier(platform.assetIdentifier!);

                if (platformMetadata === undefined) {
                    throw new Error(`Could not find platform metadata for platform with JSON identifier ${platform.assetIdentifier}!`);
                } else {
                    if (!uniquePlatforms.includes(platformMetadata)) {
                        // console.log("No instance of this platform found! Creating InstancedMesh");
                        uniquePlatforms.push(platformMetadata);
                    }

                    const jsonIdentifier = platformMetadata?.jsonIdentifier;
                    instancedMeshTotalCounts.set(jsonIdentifier, (instancedMeshTotalCounts.get(jsonIdentifier) || 0) + 1);
                }
            });

        const instancedMeshMapping: Map<Platforms, InstancedMesh> = new Map<Platforms, InstancedMesh>();

        const loadPlatformAssets = uniquePlatforms.map(async (platformDetails) => {
            const meshDetails = await this._createInstancedMesh(platformDetails, instancedMeshTotalCounts.get(platformDetails.jsonIdentifier)!);
            instancedMeshMapping.set(meshDetails!.id, meshDetails!.platformMesh);
        });
        await Promise.all(loadPlatformAssets);
        return instancedMeshMapping;
    }

    private async _setInstancedMeshMatrixLoop(instancedMeshMapping: Map<Platforms, InstancedMesh>) {
        const instancedMeshCurrentCount: Map<Platforms, number> = new Map<Platforms, number>();

        this.levelData
            .filter((platform: any) => platform.uniqueIdentifier.toLowerCase() !== "spawn")
            .forEach((platform: any) => {
                const platformMetadata = getPlatformMetadataByJsonIdentifier(platform.assetIdentifier);

                if (platformMetadata === undefined) {
                    throw new Error(`Could not find platform metadata for platform with JSON identifier ${platform.assetIdentifier}!`);
                } else {
                    if (!instancedMeshCurrentCount.has(platformMetadata.platformEnumIdentifier)) {
                        instancedMeshCurrentCount.set(platformMetadata.platformEnumIdentifier, 0);
                    }

                    //@ts-ignore
                    const mesh: InstancedMesh = instancedMeshMapping.get(platformMetadata?.platformEnumIdentifier);
                    if (mesh === undefined) {
                        throw new Error(`mesh is ${mesh}! at key ${platformMetadata.platformEnumIdentifier} in instancedMeshMapping`);
                    } else {
                        const position = new Vector3(platform.position[0], platform.position[1], platform.position[2]);
                        const quaternion = new Quaternion();
                        quaternion.setFromEuler(new Euler(platform.rotation[0], platform.rotation[1], platform.rotation[2]));

                        const scale = new Vector3(platform.scale[0], platform.scale[1], platform.scale[2]);

                        const matrix = new Matrix4();

                        matrix.compose(position, quaternion, scale);

                        if (instancedMeshCurrentCount.get(platformMetadata.platformEnumIdentifier)! > mesh.count) {
                            throw new Error("We are trying to create a platform that exceeds the current IM count for that platform!");
                        }

                        mesh.setMatrixAt(instancedMeshCurrentCount.get(platformMetadata.platformEnumIdentifier)!, matrix);

                        instancedMeshCurrentCount.set(platformMetadata.platformEnumIdentifier, instancedMeshCurrentCount.get(platformMetadata.platformEnumIdentifier)! + 1);

                        mesh.instanceMatrix.needsUpdate = true;
                        Game.Renderer.AddModelToScene(mesh);
                        
                        // Setup PFX above every cone and inside every fridge
                        const particleEmitter: Emitter = new Emitter();
                        const particleTrailRate: Rate = ParticleRates.get(ParticleEffects.Stars) as Rate;

                        if (platformMetadata.jsonIdentifier === "chocolate_001" || 
                            platformMetadata.jsonIdentifier === "vanilla_001" ||
                            platformMetadata.jsonIdentifier === "pistachio_001") {
                            particleEmitter.setRate(particleTrailRate).setPosition(new Vector3(platform.position[0], platform.position[1] + 3, platform.position[2])).emit();
                        } else if (platformMetadata.jsonIdentifier === "fridge_001") {
                            particleEmitter.setRate(particleTrailRate).setPosition(new Vector3(platform.position[0] - 1, platform.position[1], platform.position[2] + 1)).emit();
                        }

                        particleEmitter.setInitializers(ParticleInitializers.get(ParticleEffects.Stars) as Initializer[]);
                        particleEmitter.setBehaviours(ParticleBehaviours.get(ParticleEffects.Stars) as Behaviour[]);

                        Game.Renderer.AddParticleEmitter(particleEmitter);
                    }
                }
            });
    }

    protected override getSystemName(): string {
        return "LevelLoader";
    }

    public async Initialize(): Promise<void> {
        this.LogInfo("Initializing level loader...");

        //levelData = platformData in level_uppity.json
        this.levelData = Level.platforms;

        // Start the first loop and then start the second loop once it's completed
        const instancedMeshMapping = await this._loadLevelAssets();
        await this._setInstancedMeshMatrixLoop(instancedMeshMapping);

        this.LogInfo("Platforms have been created and Matrixed Neo!");
    }

    public override Update(__deltaTimeS: number, __deltaTimeMS: number, __currentTime: Timestamp): void {}
    public override Cleanup(): void {}

    private async _createInstancedMesh(_platformData: PlatformDetails, maxCount: number) {
        if (_platformData === undefined) {
            throw new Error(`Could not find platform metadata in _createInstancedMesh for platform with JSON identifier ${_platformData}!`);
        }

        const threeGLTF: GLTF = await Game.Loader.LoadModel(_platformData.assetPath);

        if (threeGLTF.scene) {
            const threeModel: Group = threeGLTF.scene;

            if (threeModel.children && threeModel.children[0]) {
                threeModel.traverse((node) => {
                    //@ts-ignore
                    if (node.isMesh) {
                        // Your material and other logic here...
                        const newMaterial = new MeshStandardMaterial({ color: 0xffffff }); // Set your desired properties here

                        // @ts-ignore
                        // If the original material uses textures, transfer them to the new material
                        if (node.material.map) {
                            // @ts-ignore
                            newMaterial.map = node.material.map;
                            // @ts-ignore
                            newMaterial.transparent = node.material.transparent;
                            // @ts-ignore
                            newMaterial.alphaTest = node.material.alphaTest;
                        }

                        // if (_platformData.jsonIdentifier === "death_block_001") {
                        //     // console.log("Rendering death block!");

                        //     newMaterial.depthWrite = false;
                        // }

                        // Apply the new material
                        // @ts-ignore
                        node.material = newMaterial;
                    }
                });

                // Create the InstancedMesh with the loaded model
                // console.log("Creating InstancedMesh for", _platformData.jsonIdentifier);
                const platformMesh = new InstancedMesh(
                    //@ts-ignore
                    threeModel.children[0].geometry,
                    //@ts-ignore
                    threeModel.children[0].material,
                    maxCount
                );

                platformMesh.name = "LevelPlatform";

                return {
                    id: _platformData.platformEnumIdentifier,
                    platformMesh
                };
            } else {
                throw new Error("No child at index 0 found in the loaded model");
            }
        } else {
            // console.error("Failed to load model for (retrying though)", _platformData.assetPath);

            // console.error("Retrying...");

            // Recursively retry loading the model
            await this._createInstancedMesh(_platformData, maxCount);

            //throw new Error(`Failed to load model for ${_platformData.assetPath}`);
        }
    }
}
