/********************************************/
/*                                          */
/*        Platform Models & Metadata        */
/*                                          */
/********************************************/

export enum Platforms {
    CuttingBoard = 0,
    LargeCube001 = 1,
    LargeCube002 = 2,
    LargeCube003 = 3,
    LargeCube004 = 4,
    LargeCube005 = 5,
    LargeCube006 = 6,
    LargeCube007 = 7,
    LargeCube008 = 8,
    LargeSquare001 = 9,
    LargeSquare002 = 10,
    LargeSquare003 = 11,
    LargeSquare004 = 12,
    LargeSquare005 = 13,
    LargeSquare006 = 14,
    LargeSquare007 = 15,
    LargeSquare008 = 16,
    Pan001 = 17,
    Pan002 = 18,
    Pan003 = 19,
    Pan004 = 20,
    MediumSquare001 = 21,
    MediumSquare002 = 22,
    MediumSquare003 = 23,
    MediumSquare004 = 24,
    MediumSquare005 = 25,
    MediumSquare006 = 26,
    MediumSquare007 = 27,
    MediumSquare008 = 28,
    AlternatePan001 = 29,
    AlternatePan002 = 30,
    AlternatePan003 = 31,
    AlternatePan004 = 32,
    Pot001 = 33,
    Pot002 = 34,
    Pot003 = 35,
    Pot004 = 36,
    Rectangle001 = 37,
    Rectangle002 = 38,
    Rectangle003 = 39,
    Rectangle004 = 40,
    Rectangle005 = 41,
    Rectangle006 = 42,
    Rectangle007 = 43,
    Rectangle008 = 44,
    Pepper = 45,
    Salt = 46,
    RollingPin = 47,
    Square001 = 48,
    Square002 = 49,
    Square003 = 50,
    Square004 = 51,
    Square005 = 52,
    Square006 = 53,
    Square007 = 54,
    Square008 = 55,
    Spatula001 = 56,
    Spatula002 = 57,
    Spatula003 = 58,
    Spatula004 = 59,
    Toaster001 = 60,
    Toaster002 = 61,
    Toaster003 = 62,
    Toaster004 = 63,
    TissueBox001 = 64,
    TissueBox002 = 65,
    TissueBox003 = 66,
    TissueBox004 = 67,
    Plate001 = 68,
    Plate002 = 69,
    Plate003 = 70,
    Plate004 = 71,
    Knife001 = 72,
    Knife002 = 73,
    Knife003 = 74,
    Knife004 = 75,
    Fork001 = 76,
    Fork002 = 77,
    Fork003 = 78,
    Fork004 = 79,
    FireExtinguisher = 80,
    FinishLine = 81,
    Cleaver001 = 82,
    Cleaver002 = 83,
    Cleaver003 = 84,
    Cleaver004 = 85,
    ChoppingKnife001 = 86,
    ChoppingKnife002 = 87,
    ChoppingKnife003 = 88,
    ChoppingKnife004 = 89,
    Checkpoint = 90,
    Bowl001 = 91,
    Bowl002 = 92,
    Bowl003 = 93,
    Bowl004 = 94,
    BigSpoon001 = 95,
    BigSpoon002 = 96,
    BigSpoon003 = 97,
    BigSpoon004 = 98,
    BakingTray001 = 99,
    BakingTray002 = 100,
    BakingTray003 = 101,
    BakingTray004 = 102,
    BakingPan001 = 103,
    BakingPan002 = 104,
    BakingPan003 = 105,
    BakingPan004 = 106,
    Blueberry = 107,
    Chocolate = 108,
    Mango = 109,
    Pistachio = 110,
    Purple = 111,
    Strawberry = 112,
    Vanilla = 113,
    DeathBlock001 = 114,
    MovingBlock001 = 115,
    SlipperyBlock001 = 116,
    SlowBlock001 = 117,
    StartingLine = 118,
    InvisibleSquare001 = 119,
    Fridge_001 = 120,
    Gorilla = 121
}

export interface PlatformDetails {
    platformEnumIdentifier: Platforms;
    jsonIdentifier: string;
    assetPath: string;
}

export const PlatformMetadata: Map<Platforms, PlatformDetails> = new Map();

export const getPlatformMetadataByJsonIdentifier = (jsonIdentifier: string): PlatformDetails | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_, platformMetadata] of PlatformMetadata) {
        if (platformMetadata.jsonIdentifier === jsonIdentifier) {
            return platformMetadata;
        }
    }

    return undefined;
};

PlatformMetadata.set(Platforms.CuttingBoard, {
    platformEnumIdentifier: Platforms.CuttingBoard,
    jsonIdentifier: "cutting_board",
    assetPath: "platforms/cutting_board.glb"
});

PlatformMetadata.set(Platforms.LargeCube001, {
    platformEnumIdentifier: Platforms.LargeCube001,
    jsonIdentifier: "large_cube_001",
    assetPath: "platforms/large_cube_001.glb"
});

PlatformMetadata.set(Platforms.LargeCube002, {
    platformEnumIdentifier: Platforms.LargeCube002,
    jsonIdentifier: "large_cube_002",
    assetPath: "platforms/large_cube_002.glb"
});

PlatformMetadata.set(Platforms.LargeCube003, {
    platformEnumIdentifier: Platforms.LargeCube003,
    jsonIdentifier: "large_cube_003",
    assetPath: "platforms/large_cube_003.glb"
});

PlatformMetadata.set(Platforms.LargeCube004, {
    platformEnumIdentifier: Platforms.LargeCube004,
    jsonIdentifier: "large_cube_004",
    assetPath: "platforms/large_cube_004.glb"
});

PlatformMetadata.set(Platforms.LargeCube005, {
    platformEnumIdentifier: Platforms.LargeCube005,
    jsonIdentifier: "large_cube_005",
    assetPath: "platforms/large_cube_005.glb"
});

PlatformMetadata.set(Platforms.LargeCube006, {
    platformEnumIdentifier: Platforms.LargeCube006,
    jsonIdentifier: "large_cube_006",
    assetPath: "platforms/large_cube_006.glb"
});

PlatformMetadata.set(Platforms.LargeCube007, {
    platformEnumIdentifier: Platforms.LargeCube007,
    jsonIdentifier: "large_cube_007LargeCube007",
    assetPath: "platforms/large_cube_007.glb"
});

PlatformMetadata.set(Platforms.LargeCube008, {
    platformEnumIdentifier: Platforms.LargeCube008,
    jsonIdentifier: "large_cube_008",
    assetPath: "platforms/large_cube_008.glb"
});

PlatformMetadata.set(Platforms.LargeSquare001, {
    platformEnumIdentifier: Platforms.LargeSquare001,
    jsonIdentifier: "large_square_001",
    assetPath: "platforms/large_square_001.glb"
});

PlatformMetadata.set(Platforms.LargeSquare002, {
    platformEnumIdentifier: Platforms.LargeSquare002,
    jsonIdentifier: "large_square_002",
    assetPath: "platforms/large_square_002.glb"
});

PlatformMetadata.set(Platforms.LargeSquare003, {
    platformEnumIdentifier: Platforms.LargeSquare003,
    jsonIdentifier: "large_square_003",
    assetPath: "platforms/large_square_003.glb"
});

PlatformMetadata.set(Platforms.LargeSquare004, {
    platformEnumIdentifier: Platforms.LargeSquare004,
    jsonIdentifier: "large_square_004",
    assetPath: "platforms/large_square_004.glb"
});

PlatformMetadata.set(Platforms.LargeSquare005, {
    platformEnumIdentifier: Platforms.LargeSquare005,
    jsonIdentifier: "large_square_005",
    assetPath: "platforms/large_square_005.glb"
});

PlatformMetadata.set(Platforms.LargeSquare006, {
    platformEnumIdentifier: Platforms.LargeSquare006,
    jsonIdentifier: "large_square_006",
    assetPath: "platforms/large_square_006.glb"
});

PlatformMetadata.set(Platforms.LargeSquare007, {
    platformEnumIdentifier: Platforms.LargeSquare007,
    jsonIdentifier: "large_square_007",
    assetPath: "platforms/large_square_007.glb"
});

PlatformMetadata.set(Platforms.LargeSquare008, {
    platformEnumIdentifier: Platforms.LargeSquare008,
    jsonIdentifier: "large_square_008",
    assetPath: "platforms/large_square_008.glb"
});

PlatformMetadata.set(Platforms.Pan001, {
    platformEnumIdentifier: Platforms.Pan001,
    jsonIdentifier: "pan_001",
    assetPath: "platforms/pan_001.glb"
});

PlatformMetadata.set(Platforms.Pan002, {
    platformEnumIdentifier: Platforms.Pan002,
    jsonIdentifier: "pan_002",
    assetPath: "platforms/pan_002.glb"
});

PlatformMetadata.set(Platforms.Pan003, {
    platformEnumIdentifier: Platforms.Pan003,
    jsonIdentifier: "pan_003",
    assetPath: "platforms/pan_003.glb"
});

PlatformMetadata.set(Platforms.Pan004, {
    platformEnumIdentifier: Platforms.Pan004,
    jsonIdentifier: "pan_004",
    assetPath: "platforms/pan_004.glb"
});

PlatformMetadata.set(Platforms.MediumSquare001, {
    platformEnumIdentifier: Platforms.MediumSquare001,
    jsonIdentifier: "medium_square_001",
    assetPath: "platforms/medium_square_001.glb"
});

PlatformMetadata.set(Platforms.MediumSquare002, {
    platformEnumIdentifier: Platforms.MediumSquare002,
    jsonIdentifier: "medium_square_002",
    assetPath: "platforms/medium_square_002.glb"
});

PlatformMetadata.set(Platforms.MediumSquare003, {
    platformEnumIdentifier: Platforms.MediumSquare003,
    jsonIdentifier: "medium_square_003",
    assetPath: "platforms/medium_square_003.glb"
});

PlatformMetadata.set(Platforms.MediumSquare004, {
    platformEnumIdentifier: Platforms.MediumSquare004,
    jsonIdentifier: "medium_square_004",
    assetPath: "platforms/medium_square_004.glb"
});

PlatformMetadata.set(Platforms.MediumSquare005, {
    platformEnumIdentifier: Platforms.MediumSquare005,
    jsonIdentifier: "medium_square_005",
    assetPath: "platforms/medium_square_005.glb"
});

PlatformMetadata.set(Platforms.MediumSquare006, {
    platformEnumIdentifier: Platforms.MediumSquare006,
    jsonIdentifier: "medium_square_006",
    assetPath: "platforms/medium_square_006.glb"
});

PlatformMetadata.set(Platforms.MediumSquare007, {
    platformEnumIdentifier: Platforms.MediumSquare007,
    jsonIdentifier: "medium_square_007",
    assetPath: "platforms/medium_square_007.glb"
});

PlatformMetadata.set(Platforms.MediumSquare008, {
    platformEnumIdentifier: Platforms.MediumSquare008,
    jsonIdentifier: "medium_square_008",
    assetPath: "platforms/medium_square_008.glb"
});

PlatformMetadata.set(Platforms.AlternatePan001, {
    platformEnumIdentifier: Platforms.AlternatePan001,
    jsonIdentifier: "alternate_pan_001",
    assetPath: "platforms/pan_alt_001.glb"
});

PlatformMetadata.set(Platforms.AlternatePan002, {
    platformEnumIdentifier: Platforms.AlternatePan002,
    jsonIdentifier: "alternate_pan_002",
    assetPath: "platforms/pan_alt_002.glb"
});

PlatformMetadata.set(Platforms.AlternatePan003, {
    platformEnumIdentifier: Platforms.AlternatePan003,
    jsonIdentifier: "alternate_pan_003",
    assetPath: "platforms/pan_alt_003.glb"
});

PlatformMetadata.set(Platforms.AlternatePan004, {
    platformEnumIdentifier: Platforms.AlternatePan004,
    jsonIdentifier: "alternate_pan_004",
    assetPath: "platforms/pan_alt_004.glb"
});

PlatformMetadata.set(Platforms.Pot001, {
    platformEnumIdentifier: Platforms.Pot001,
    jsonIdentifier: "pot_001",
    assetPath: "platforms/pot_001.glb"
});

PlatformMetadata.set(Platforms.Pot002, {
    platformEnumIdentifier: Platforms.Pot002,
    jsonIdentifier: "pot_002",
    assetPath: "platforms/pot_002.glb"
});

PlatformMetadata.set(Platforms.Pot003, {
    platformEnumIdentifier: Platforms.Pot003,
    jsonIdentifier: "pot_003",
    assetPath: "platforms/pot_003.glb"
});

PlatformMetadata.set(Platforms.Pot004, {
    platformEnumIdentifier: Platforms.Pot004,
    jsonIdentifier: "pot_004",
    assetPath: "platforms/pot_004.glb"
});

PlatformMetadata.set(Platforms.Rectangle001, {
    platformEnumIdentifier: Platforms.Rectangle001,
    jsonIdentifier: "rectangle_001",
    assetPath: "platforms/rectangle_001.glb"
});

PlatformMetadata.set(Platforms.Rectangle002, {
    platformEnumIdentifier: Platforms.Rectangle002,
    jsonIdentifier: "rectangle_002",
    assetPath: "platforms/rectangle_002.glb"
});

PlatformMetadata.set(Platforms.Rectangle003, {
    platformEnumIdentifier: Platforms.Rectangle003,
    jsonIdentifier: "rectangle_003",
    assetPath: "platforms/rectangle_003.glb"
});

PlatformMetadata.set(Platforms.Rectangle004, {
    platformEnumIdentifier: Platforms.Rectangle004,
    jsonIdentifier: "rectangle_004",
    assetPath: "platforms/rectangle_004.glb"
});

PlatformMetadata.set(Platforms.Rectangle005, {
    platformEnumIdentifier: Platforms.Rectangle005,
    jsonIdentifier: "rectangle_005",
    assetPath: "platforms/rectangle_005.glb"
});

PlatformMetadata.set(Platforms.Rectangle006, {
    platformEnumIdentifier: Platforms.Rectangle006,
    jsonIdentifier: "rectangle_006",
    assetPath: "platforms/rectangle_006.glb"
});

PlatformMetadata.set(Platforms.Rectangle007, {
    platformEnumIdentifier: Platforms.Rectangle007,
    jsonIdentifier: "rectangle_007",
    assetPath: "platforms/rectangle_007.glb"
});

PlatformMetadata.set(Platforms.Rectangle008, {
    platformEnumIdentifier: Platforms.Rectangle008,
    jsonIdentifier: "rectangle_008",
    assetPath: "platforms/rectangle_008.glb"
});

PlatformMetadata.set(Platforms.Pepper, {
    platformEnumIdentifier: Platforms.Pepper,
    jsonIdentifier: "pepper",
    assetPath: "platforms/pepper.glb"
});

PlatformMetadata.set(Platforms.Salt, {
    platformEnumIdentifier: Platforms.Salt,
    jsonIdentifier: "salt",
    assetPath: "platforms/salt.glb"
});

PlatformMetadata.set(Platforms.RollingPin, {
    platformEnumIdentifier: Platforms.RollingPin,
    jsonIdentifier: "rolling_pin",
    assetPath: "platforms/rolling_pin.glb"
});

PlatformMetadata.set(Platforms.Square001, {
    platformEnumIdentifier: Platforms.Square001,
    jsonIdentifier: "square_001",
    assetPath: "platforms/square_001.glb"
});

PlatformMetadata.set(Platforms.Square002, {
    platformEnumIdentifier: Platforms.Square002,
    jsonIdentifier: "square_002",
    assetPath: "platforms/square_002.glb"
});

PlatformMetadata.set(Platforms.Square003, {
    platformEnumIdentifier: Platforms.Square003,
    jsonIdentifier: "square_003",
    assetPath: "platforms/square_003.glb"
});

PlatformMetadata.set(Platforms.Square004, {
    platformEnumIdentifier: Platforms.Square004,
    jsonIdentifier: "square_004",
    assetPath: "platforms/square_004.glb"
});

PlatformMetadata.set(Platforms.Square005, {
    platformEnumIdentifier: Platforms.Square005,
    jsonIdentifier: "square_005",
    assetPath: "platforms/square_005.glb"
});

PlatformMetadata.set(Platforms.Square006, {
    platformEnumIdentifier: Platforms.Square006,
    jsonIdentifier: "square_006",
    assetPath: "platforms/square_006.glb"
});

PlatformMetadata.set(Platforms.Square007, {
    platformEnumIdentifier: Platforms.Square007,
    jsonIdentifier: "square_007",
    assetPath: "platforms/square_007.glb"
});

PlatformMetadata.set(Platforms.Square008, {
    platformEnumIdentifier: Platforms.Square008,
    jsonIdentifier: "square_008",
    assetPath: "platforms/square_008.glb"
});

PlatformMetadata.set(Platforms.Spatula001, {
    platformEnumIdentifier: Platforms.Spatula001,
    jsonIdentifier: "spatula_001",
    assetPath: "platforms/spatula_001.glb"
});

PlatformMetadata.set(Platforms.Spatula002, {
    platformEnumIdentifier: Platforms.Spatula002,
    jsonIdentifier: "spatula_002",
    assetPath: "platforms/spatula_002.glb"
});

PlatformMetadata.set(Platforms.Spatula003, {
    platformEnumIdentifier: Platforms.Spatula003,
    jsonIdentifier: "spatula_003",
    assetPath: "platforms/spatula_003.glb"
});

PlatformMetadata.set(Platforms.Spatula004, {
    platformEnumIdentifier: Platforms.Spatula004,
    jsonIdentifier: "spatula_004",
    assetPath: "platforms/spatula_004.glb"
});

PlatformMetadata.set(Platforms.Toaster001, {
    platformEnumIdentifier: Platforms.Toaster001,
    jsonIdentifier: "toaster_001",
    assetPath: "platforms/toaster_001.glb"
});

PlatformMetadata.set(Platforms.Toaster002, {
    platformEnumIdentifier: Platforms.Toaster002,
    jsonIdentifier: "toaster_002",
    assetPath: "platforms/toaster_002.glb"
});

PlatformMetadata.set(Platforms.Toaster003, {
    platformEnumIdentifier: Platforms.Toaster003,
    jsonIdentifier: "toaster_003",
    assetPath: "platforms/toaster_003.glb"
});

PlatformMetadata.set(Platforms.Toaster004, {
    platformEnumIdentifier: Platforms.Toaster004,
    jsonIdentifier: "toaster_004",
    assetPath: "platforms/toaster_004.glb"
});

PlatformMetadata.set(Platforms.TissueBox001, {
    platformEnumIdentifier: Platforms.TissueBox001,
    jsonIdentifier: "tissue_box_001",
    assetPath: "platforms/tissue_box_001.glb"
});

PlatformMetadata.set(Platforms.TissueBox002, {
    platformEnumIdentifier: Platforms.TissueBox002,
    jsonIdentifier: "tissue_box_002",
    assetPath: "platforms/tissue_box_002.glb"
});

PlatformMetadata.set(Platforms.TissueBox003, {
    platformEnumIdentifier: Platforms.TissueBox003,
    jsonIdentifier: "tissue_box_003",
    assetPath: "platforms/tissue_box_003.glb"
});

PlatformMetadata.set(Platforms.TissueBox004, {
    platformEnumIdentifier: Platforms.TissueBox004,
    jsonIdentifier: "tissue_box_004",
    assetPath: "platforms/tissue_box_004.glb"
});

PlatformMetadata.set(Platforms.Plate001, {
    platformEnumIdentifier: Platforms.Plate001,
    jsonIdentifier: "plate_001",
    assetPath: "platforms/plate_001.glb"
});

PlatformMetadata.set(Platforms.Plate002, {
    platformEnumIdentifier: Platforms.Plate002,
    jsonIdentifier: "plate_002",
    assetPath: "platforms/plate_002.glb"
});

PlatformMetadata.set(Platforms.Plate003, {
    platformEnumIdentifier: Platforms.Plate003,
    jsonIdentifier: "plate_003",
    assetPath: "platforms/plate_003.glb"
});

PlatformMetadata.set(Platforms.Plate004, {
    platformEnumIdentifier: Platforms.Plate004,
    jsonIdentifier: "plate_004",
    assetPath: "platforms/plate_004.glb"
});

PlatformMetadata.set(Platforms.Knife001, {
    platformEnumIdentifier: Platforms.Knife001,
    jsonIdentifier: "knife_001",
    assetPath: "platforms/knife_001.glb"
});

PlatformMetadata.set(Platforms.Knife002, {
    platformEnumIdentifier: Platforms.Knife002,
    jsonIdentifier: "knife_002",
    assetPath: "platforms/knife_002.glb"
});

PlatformMetadata.set(Platforms.Knife003, {
    platformEnumIdentifier: Platforms.Knife003,
    jsonIdentifier: "knife_003",
    assetPath: "platforms/knife_003.glb"
});

PlatformMetadata.set(Platforms.Knife004, {
    platformEnumIdentifier: Platforms.Knife004,
    jsonIdentifier: "knife_004",
    assetPath: "platforms/knife_004.glb"
});

PlatformMetadata.set(Platforms.Fork001, {
    platformEnumIdentifier: Platforms.Fork001,
    jsonIdentifier: "fork_001",
    assetPath: "platforms/fork_001.glb"
});

PlatformMetadata.set(Platforms.Fork002, {
    platformEnumIdentifier: Platforms.Fork002,
    jsonIdentifier: "fork_002",
    assetPath: "platforms/fork_002.glb"
});

PlatformMetadata.set(Platforms.Fork003, {
    platformEnumIdentifier: Platforms.Fork003,
    jsonIdentifier: "fork_003",
    assetPath: "platforms/fork_003.glb"
});

PlatformMetadata.set(Platforms.Fork004, {
    platformEnumIdentifier: Platforms.Fork004,
    jsonIdentifier: "fork_004",
    assetPath: "platforms/fork_004.glb"
});

PlatformMetadata.set(Platforms.FireExtinguisher, {
    platformEnumIdentifier: Platforms.FireExtinguisher,
    jsonIdentifier: "fire_extinguisher",
    assetPath: "platforms/fire_extinguisher_001.glb"
});

PlatformMetadata.set(Platforms.FinishLine, {
    platformEnumIdentifier: Platforms.FinishLine,
    jsonIdentifier: "finish_line_001",
    assetPath: "platforms/finish_line_001.glb"
});

PlatformMetadata.set(Platforms.Cleaver001, {
    platformEnumIdentifier: Platforms.Cleaver001,
    jsonIdentifier: "cleaver_001",
    assetPath: "platforms/cleaver_001.glb"
});

PlatformMetadata.set(Platforms.Cleaver002, {
    platformEnumIdentifier: Platforms.Cleaver002,
    jsonIdentifier: "cleaver_002",
    assetPath: "platforms/cleaver_002.glb"
});

PlatformMetadata.set(Platforms.Cleaver003, {
    platformEnumIdentifier: Platforms.Cleaver003,
    jsonIdentifier: "cleaver_003",
    assetPath: "platforms/cleaver_003.glb"
});

PlatformMetadata.set(Platforms.Cleaver004, {
    platformEnumIdentifier: Platforms.Cleaver004,
    jsonIdentifier: "cleaver_004",
    assetPath: "platforms/cleaver_004.glb"
});

PlatformMetadata.set(Platforms.ChoppingKnife001, {
    platformEnumIdentifier: Platforms.ChoppingKnife001,
    jsonIdentifier: "chopping_knife_001",
    assetPath: "platforms/chopping_knife_001.glb"
});

PlatformMetadata.set(Platforms.ChoppingKnife002, {
    platformEnumIdentifier: Platforms.ChoppingKnife002,
    jsonIdentifier: "chopping_knife_002",
    assetPath: "platforms/chopping_knife_002.glb"
});

PlatformMetadata.set(Platforms.ChoppingKnife003, {
    platformEnumIdentifier: Platforms.ChoppingKnife003,
    jsonIdentifier: "chopping_knife_003",
    assetPath: "platforms/chopping_knife_003.glb"
});

PlatformMetadata.set(Platforms.ChoppingKnife004, {
    platformEnumIdentifier: Platforms.ChoppingKnife004,
    jsonIdentifier: "chopping_knife_004",
    assetPath: "platforms/chopping_knife_004.glb"
});

PlatformMetadata.set(Platforms.Checkpoint, {
    platformEnumIdentifier: Platforms.Checkpoint,
    jsonIdentifier: "check_point_001",
    assetPath: "platforms/check_point_001.glb"
});

PlatformMetadata.set(Platforms.Bowl001, {
    platformEnumIdentifier: Platforms.Bowl001,
    jsonIdentifier: "bowl_001",
    assetPath: "platforms/bowl_001.glb"
});

PlatformMetadata.set(Platforms.Bowl002, {
    platformEnumIdentifier: Platforms.Bowl002,
    jsonIdentifier: "bowl_002",
    assetPath: "platforms/bowl_002.glb"
});

PlatformMetadata.set(Platforms.Bowl003, {
    platformEnumIdentifier: Platforms.Bowl003,
    jsonIdentifier: "bowl_003",
    assetPath: "platforms/bowl_003.glb"
});

PlatformMetadata.set(Platforms.Bowl004, {
    platformEnumIdentifier: Platforms.Bowl004,
    jsonIdentifier: "bowl_004",
    assetPath: "platforms/bowl_004.glb"
});

PlatformMetadata.set(Platforms.BigSpoon001, {
    platformEnumIdentifier: Platforms.BigSpoon001,
    jsonIdentifier: "big_spoon_001",
    assetPath: "platforms/big_spoon_001.glb"
});

PlatformMetadata.set(Platforms.BigSpoon002, {
    platformEnumIdentifier: Platforms.BigSpoon002,
    jsonIdentifier: "big_spoon_002",
    assetPath: "platforms/big_spoon_002.glb"
});

PlatformMetadata.set(Platforms.BigSpoon003, {
    platformEnumIdentifier: Platforms.BigSpoon003,
    jsonIdentifier: "big_spoon_003",
    assetPath: "platforms/big_spoon_003.glb"
});

PlatformMetadata.set(Platforms.BigSpoon004, {
    platformEnumIdentifier: Platforms.BigSpoon004,
    jsonIdentifier: "big_spoon_004",
    assetPath: "platforms/big_spoon_004.glb"
});

PlatformMetadata.set(Platforms.BakingTray001, {
    platformEnumIdentifier: Platforms.BakingTray001,
    jsonIdentifier: "baking_tray_001",
    assetPath: "platforms/baking_tray_001.glb"
});

PlatformMetadata.set(Platforms.BakingTray002, {
    platformEnumIdentifier: Platforms.BakingTray002,
    jsonIdentifier: "baking_tray_002",
    assetPath: "platforms/baking_tray_002.glb"
});

PlatformMetadata.set(Platforms.BakingTray003, {
    platformEnumIdentifier: Platforms.BakingTray003,
    jsonIdentifier: "baking_tray_003",
    assetPath: "platforms/baking_tray_003.glb"
});

PlatformMetadata.set(Platforms.BakingTray004, {
    platformEnumIdentifier: Platforms.BakingTray004,
    jsonIdentifier: "baking_tray_004",
    assetPath: "platforms/baking_tray_004.glb"
});

PlatformMetadata.set(Platforms.BakingPan001, {
    platformEnumIdentifier: Platforms.BakingPan001,
    jsonIdentifier: "baking_pan_001",
    assetPath: "platforms/baking_pan_001.glb"
});

PlatformMetadata.set(Platforms.BakingPan002, {
    platformEnumIdentifier: Platforms.BakingPan002,
    jsonIdentifier: "baking_pan_002",
    assetPath: "platforms/baking_pan_002.glb"
});

PlatformMetadata.set(Platforms.BakingPan003, {
    platformEnumIdentifier: Platforms.BakingPan003,
    jsonIdentifier: "baking_pan_003",
    assetPath: "platforms/baking_pan_003.glb"
});

PlatformMetadata.set(Platforms.BakingPan004, {
    platformEnumIdentifier: Platforms.BakingPan004,
    jsonIdentifier: "baking_pan_004",
    assetPath: "platforms/baking_pan_004.glb"
});

PlatformMetadata.set(Platforms.Blueberry, {
    platformEnumIdentifier: Platforms.Blueberry,
    jsonIdentifier: "blueberry",
    assetPath: "platforms/blueberry_001.glb"
});

PlatformMetadata.set(Platforms.Chocolate, {
    platformEnumIdentifier: Platforms.Chocolate,
    jsonIdentifier: "chocolate_001",
    assetPath: "platforms/chocolate_001.glb"
});

PlatformMetadata.set(Platforms.Mango, {
    platformEnumIdentifier: Platforms.Mango,
    jsonIdentifier: "mango",
    assetPath: "platforms/mango_001.glb"
});

PlatformMetadata.set(Platforms.Pistachio, {
    platformEnumIdentifier: Platforms.Pistachio,
    jsonIdentifier: "pistachio_001",
    assetPath: "platforms/pistachio_001.glb"
});

PlatformMetadata.set(Platforms.Purple, {
    platformEnumIdentifier: Platforms.Purple,
    jsonIdentifier: "purple",
    assetPath: "platforms/purple_001.glb"
});

PlatformMetadata.set(Platforms.Strawberry, {
    platformEnumIdentifier: Platforms.Strawberry,
    jsonIdentifier: "strawberry",
    assetPath: "platforms/strawberry_001.glb"
});

PlatformMetadata.set(Platforms.Vanilla, {
    platformEnumIdentifier: Platforms.Vanilla,
    jsonIdentifier: "vanilla_001",
    assetPath: "platforms/vanilla_001.glb"
});

PlatformMetadata.set(Platforms.DeathBlock001, {
    platformEnumIdentifier: Platforms.DeathBlock001,
    jsonIdentifier: "death_block_001",
    assetPath: "platforms/death_block_001.glb"
});

PlatformMetadata.set(Platforms.MovingBlock001, {
    platformEnumIdentifier: Platforms.MovingBlock001,
    jsonIdentifier: "moving_block_001",
    assetPath: "platforms/moving_block_001.glb"
});

PlatformMetadata.set(Platforms.SlipperyBlock001, {
    platformEnumIdentifier: Platforms.SlipperyBlock001,
    jsonIdentifier: "slippery_block_001",
    assetPath: "platforms/slippery_block_001.glb"
});

PlatformMetadata.set(Platforms.SlowBlock001, {
    platformEnumIdentifier: Platforms.SlowBlock001,
    jsonIdentifier: "slow_block_001",
    assetPath: "platforms/slow_block_001.glb"
});

PlatformMetadata.set(Platforms.StartingLine, {
    platformEnumIdentifier: Platforms.StartingLine,
    jsonIdentifier: "start_line",
    assetPath: "platforms/start_line.glb"
});

PlatformMetadata.set(Platforms.InvisibleSquare001, {
    platformEnumIdentifier: Platforms.InvisibleSquare001,
    jsonIdentifier: "invisible_square_001",
    assetPath: "platforms/invisible_square_001.glb"
});

PlatformMetadata.set(Platforms.Fridge_001, {
    platformEnumIdentifier: Platforms.Fridge_001,
    jsonIdentifier: "fridge_001",
    assetPath: "platforms/fridge_001.glb"
});

PlatformMetadata.set(Platforms.Gorilla, {
    platformEnumIdentifier: Platforms.Gorilla,
    jsonIdentifier: "gorilla",
    assetPath: "platforms/gorilla.glb"
});
