{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "error_screen__server_maybe_full": "Fehler bei der Verbindung zum Server: Die Server sind möglicherweise gerade voll, versuchen Sie es in ein paar Minuten erneut!",
    "error_screen__maintenance_mode": "UpTogether.io wird aktualisiert! Kommen Sie in ein paar Minuten wieder, um die neuen Funktionen auszuprobieren 🎉",
    "error_screen__client_out_of_date": "Ihr Spielclient ist veraltet! Aktualisieren Sie Ihren Spielclient auf die neueste Version und versuchen Sie es erneut.",
    "error_screen__invalid_connection": "Der Server hat Ihren Benutzer abgelehnt! Bitte versuchen Sie es erneut.",
    "error_screen__already_logged_in": "Sie sind bereits angemeldet!",
    "error_screen__unknown_reason": "Unbekannter Verbindungsfehler :(",
    "error_screen__disconnected_from_server": "Verbindung zum Server getrennt! Die Server werden möglicherweise für ein Update heruntergefahren. Versuchen Sie es in ein paar Minuten erneut.",
    "heightmarker__to_bottom": "Zurück nach unten!",
    "heightmarker__inspiration_come_far": "Schau, wie weit du gekommen bist!",
    "heightmarker__inspiration_keep_going": "Mach weiter!",
    "heightmarker__inspiration_up_up": "Hoch! hoch! hoch!",
    "in_game__secret_edward": "Hallo Edward!",
    "heightmarker__secret_gorillas": "Wie viele Gorillas kannst du finden??",
    "heightmarker__change_character": "Charakter ändern",
    "heightmarker__change_name": "Namen ändern",
    "heightmarker__change_trail": "Hol dir einen Trail!",
    "heightmarker__global_leaderboard": "Weltweite Bestenliste",
    "heightmarker__your_stats": "DEINE STATISTIKEN",
    "heightmarker__game_stats": "SPIELSTATISTIKEN",
    "heightmarker__tutorial_speed": "Geschwindigkeit",
    "heightmarker_tutorial_boost": "Boost!",
    "heightmarker__tutorial_climb": "KLETTERN",
    "heightmarker__tutorial_the_wall": "DIE WAND",
    "heightmarker__tutorial_bouncy": "Fedrig!",
    "in_game__secret_invis_maze": "Neben der Labyrinthtür, auf der linken Seite...",
    "in_game__secret_upside_down_plate": "Spring vorwärts von der umgedrehten Platte!",
    "in_game__tutorial_start_timer": "Überqueren Sie die Startlinie, um Ihren Timer zu starten",
    "in_game__tutorial_invis_platforms": "Es gibt unsichtbare Plattformen!",
    "in_game__how_fast_top": "Wie schnell kommst du nach oben?",
    "in_game__no_touchy": "NICHT BERÜHREN!",
    "in_game__tutorial_running": "Benutze den linken Stick zum Laufen",
    "in_game__tutorial_tap": "Tippen",
    "in_game__tutorial_press": "Drücken",
    "in_game__tutorial_or": "oder",
    "in_game__tutorial__to_jump": "Um zu springen",
    "in_game__tutorial__hold": "Halten",
    "in_game__tutorial__jump_repeatedly": "Um wiederholt zu springen",
    "in_game__tutorial_wall_climb": "Um Wände zu erklimmen",
    "in_game__tutorial_while_running": "Während des Laufens",
    "in_game__tutorial_drag_move_camera": "Ziehen Sie den Bildschirm, um die Kamera zu bewegen",
    "in_game__checkpoint": "Kontrollpunkt!",
    "loading_text__connecting": "Verbinde mit dem Server...",
    "loading_text__splines": "Splines retikulieren",
    "loading_text__tofu": "Tofu marinieren",
    "loading_text__corn": "Den Mais schälen",
    "loading_text__broccoli": "Bestätigen, dass Brokkoli kleine Bäume sind",
    "loading_text__brussel_sprouts": "Spieler davon überzeugen, dass Rosenkohl köstlich ist",
    "loading_text__secret_sauce": "Das Geheimrezept hinzufügen",
    "loading_text__digesting": "Verdauen",
    "loading_text__stirring": "Ständig umrühren",
    "loading_text__fixing_ice_cream": "Die Eismaschine bei McDonald's reparieren",
    "loading_text__chopping_broccoli": "Den Brokkoli hacken",
    "loading_text__carmelize_apples": "Die Äpfel karamellisieren",
    "loading_text__water_boil": "Zusehen, wie das Wasser kocht",
    "loading_text__filleting_fish": "Den Fisch filetieren",
    "loading_text__plating": "Anrichten",
    "loading_text__garnishing": "Garnieren",
    "loading_text__moldy_bits": "Die verschimmelten Teile abschneiden",
    "death_block__almost": "Fast!",
    "death_block__close_one": "Knapp daneben!",
    "death_block__try_again": "Versuch es erneut!",
    "death_block__roasted": "Geröstet!",
    "death_block__burnt": "Verbrannt!",
    "death_block__oh_no": "Oh nein!",
    "death_block__grilled": "Gegrillt!",
    "death_block__blanched": "Gedünstet!",
    "death_block__crisp": "Kross!",
    "death_block__flameed": "Flambiert!",
    "death_block__order_up": "Bestellung fertig!",
    "death_block__ouch": "Au!",
    "death_block__good_try": "Gut versucht!",
    "death_block__baked": "Gebacken!",
    "death_block__crusty": "Krustig!",
    "death_block__next_time": "Nächstes Mal!",
    "death_block__toast": "Du bist Toast!",
    "death_block__no_chef": "Kein Koch!",
    "death_block__singe_left": "Nur eine Schramme nach links!",
    "death_block__oof": "Oof!",
    "death_block__fried": "Gestolpert und frittiert!",
    "death_block__recipe_ruined": "Rezept ruiniert",
    "death_block__sizzling_stop": "Ein sizzliger Stopp!",
    "death_block__defeated": "Koch besiegt!",
    "death_block__burnt_out": "Ausgebrannt?",
    "death_block__meltdown": "Einen Zusammenbruch haben?",
    "falling_death__slippery": "Rutschig!",
    "falling_death__gg": "GG",
    "falling_death__you_died": "Du bist gestorben ",
    "falling_death__f": "F",
    "falling_death__floor_food": "Essen auf dem Boden!",
    "falling_death__three_second": "Drei-Sekunden-Regel!",
    "falling_death__floor_seasoning": "Mmm! Bodenwürzung!",
    "falling_death__just_missed": "Gerade verpasst!",
    "falling_death__rip": "Ruhe in Frieden",
    "falling_death__floor_vs_flavor": "Boden: 1",
    "falling_death__floor_fall": "Bodenfehler!",
    "falling_death__saucy_slip": "Würziger Ausrutscher!",
    "falling_death__fried_fell": "Gebraten und gefallen!",
    "falling_death__dive": "Geschmackloser Tauchgang!",
    "falling_death__floor_finish": "Boden-Finish!",
    "falling_death__dinner_dive": "Abendessen-Tauchgang!",
    "falling_death__splat": "SPLAT!",
    "falling_death__oops": "Oops",
    "falling_death__greasy_spot": "Du hast den fettigen Fleck gefunden!",
    "falling_death__barely_missed": "Knapp verpasst!",
    "falling_death__down_we_go": "Runter geht's!",
    "button__play": "Spielen!",
    "restart__title": "Neustarten?",
    "restart__are_you_sure": "Sind Sie sicher, dass Sie Ihren Lauf neu starten möchten?",
    "restart__confirm": "Ja!",
    "restart__restarted": "Neugestartet...",
    "leaderboard__players": "Spieler",
    "leaderboard__countries": "Länder",
    "leaderboard__falls": "Stürze",
    "leaderboard__hours_played": "Stunden Gespielt",
    "leaderboard__got_to_top": "Hat die Spitze erreicht",
    "leaderboard__personal_best": "Persönliche Bestleistung",
    "leaderboard__global_rank": "Weltweiter Rang",
    "leaderboard__rank": "Rang",
    "leaderboard__runs": "Spiele",
    "leaderboard__time_played": "Spielzeit"
}
