import EventEmitter from "eventemitter3";
import { Config } from "../shared/Config";
import { GameplaySystem } from "../shared/engine/SharedGameplaySystem";
import { ClientPlayer } from "./entities/ClientPlayer";
import { ClientSideEntity, GenericNengiUpdatePayload as EntityUpdatePayload, HostPortals } from "./ClientTypes";
import { EmoteMessage, IdentityMessage, LeaderboardMessage, NType, NTyped, PlayerEntityCreationPayload, RunCompleteMessage, RunDurationUpdatedMessage } from "../shared/SharedNetcodeSchemas";
import { LogLevel } from "../shared/engine/SharedLogging";
import { ClientRenderingSystem } from "./systems/ClientRenderer";
import { ClientAssetLoadingSystem } from "./systems/ClientLoader";
import { ClientUI } from "./systems/ClientUI";
import { ClientInputSystem } from "./systems/ClientInput";
import { ClientNetcodeSystem } from "./systems/ClientNetcode";
import { ClientCollisionSystem } from "./systems/ClientCollision";
import { ClientDebugSystem } from "./systems/ClientDebug";
import { ClientResizeSystem } from "./systems/ClientResizer";
import { ClientAudioSystem } from "./systems/ClientAudio";
import { ClientPokiSDKWrapperSystem, SDKWrapperSystem } from "./systems/ClientPortalSDKWrappers";
import { ClientLevelLoader } from "./systems/ClientLevelLoader";
import { ClientPredictor } from "./systems/ClientPredictor";
import { Characters } from "../shared/data/CharacterData";
import { Countries } from "../shared/data/Usernames";
import { ClientIdentity } from "./systems/ClientIdentity";
import { uuid } from "../shared/SharedTypes";
import { ParticleEffects } from "../shared/data/ParticleData";

export class GameClient extends GameplaySystem {
    public EventDispatcher: EventEmitter;
    private lastUpdateTime: number = 0;
    private isRunning: boolean = false;
    private currentFrame: number = 0;
    private myEntityNid: NetworkEntityId = -1;
    private entities: Set<ClientSideEntity> = new Set();
    private gameIsPortalEmbedded: boolean = false;

    // Systems
    public Loader: ClientAssetLoadingSystem;
    public Audio: ClientAudioSystem;
    public UI: ClientUI;
    public Renderer: ClientRenderingSystem;
    public Input: ClientInputSystem;
    public Netcode: ClientNetcodeSystem;
    public Collision: ClientCollisionSystem;
    public Debug: ClientDebugSystem;
    public Resizer: ClientResizeSystem;
    public HostPortalSDK: SDKWrapperSystem;
    public LevelLoader: ClientLevelLoader;
    public Predictor: ClientPredictor;
    public Identity: ClientIdentity;

    public constructor() {
        super();

        this.setLogLevel(LogLevel.VERBOSE);

        window.Game = this;

        this.EventDispatcher = new EventEmitter();

        this.Loader = new ClientAssetLoadingSystem();
        this.Audio = new ClientAudioSystem();
        this.UI = new ClientUI();
        this.Renderer = new ClientRenderingSystem();
        this.Input = new ClientInputSystem();
        this.Netcode = new ClientNetcodeSystem();
        this.Collision = new ClientCollisionSystem();
        this.Debug = new ClientDebugSystem();
        this.Resizer = new ClientResizeSystem();
        this.LevelLoader = new ClientLevelLoader();
        this.Predictor = new ClientPredictor();
        this.Identity = new ClientIdentity();

        if (globalThis.HostPortal === HostPortals.DIRECT || globalThis.HostPortal === HostPortals.Poki) {
            this.LogInfo("Game is running in a portal environment!");
            this.gameIsPortalEmbedded = true;
        }

        if (this.gameIsPortalEmbedded) {
            if (globalThis.HostPortal === HostPortals.Poki || globalThis.HostPortal === HostPortals.DIRECT) {
                this.LogInfo("Game is running in a portal environment, initializing Poki SDK wrapper system");
                this.HostPortalSDK = new ClientPokiSDKWrapperSystem();
                /* // ... etc etc
            } else if (globalThis.HostPortal === HostPortals.Kongregate) {
                this.LogInfo("Game is running in a portal environment, initializing Kongregate SDK wrapper system");
                this.HostPortalSDK = new ClientKongregateSDKWrapperSystem();

            */
            } else {
                this.LogError("Game is running in a portal environment, but we dont support this portal type yet!");
            }
        } else {
            this.LogInfo("Game is NOT running in a hosted portal environment, skipping SDK wrapper system initialization!");
        }
    }

    public EmitEvent(eventName: string, payload?: object | string | number | boolean): void {
        // this.LogVerbose(`Emitting event: ${eventName}`);
        this.EventDispatcher.emit(eventName, payload);
    }

    public ListenForEvent(eventName: string, callback: (payload: object) => void): void {
        this.EventDispatcher.on(eventName, callback);
    }

    public StopListeningForEvent(eventName: string, callback: (payload: object) => void): void {
        this.EventDispatcher.off(eventName, callback);
    }

    public override Initialize(): void {}

    public Bootstrap(): void {
        this.Loader.Initialize();
        this.Audio.Initialize();
        this.UI.Initialize();
        this.Renderer.Initialize();
        this.Input.Initialize();
        this.Netcode.Initialize();
        this.Collision.Initialize();
        this.Debug.Initialize();
        this.Resizer.Initialize();
        this.LevelLoader.Initialize();
        if (this.gameIsPortalEmbedded) {
            this.HostPortalSDK.Initialize();
        }
        this.Identity.Initialize();

        this.Start();
    }

    public ConnectToServer(serverAddress: string, connectionToken: uuid, gameClientVersion: string): void {
        // Connect to the server!
        this.Netcode.Connect(serverAddress, connectionToken, gameClientVersion);
    }

    public Cleanup(): void {}

    public Start(): void {
        if (this.isRunning) {
            return;
        }
        this.isRunning = true;
        this.lastUpdateTime = performance.now();
        requestAnimationFrame((time) => this.Update(time));
    }

    protected override getSystemName(): string {
        return "Client";
    }

    public Update(currentTime: number): void {
        try {
            if (!this.isRunning) {
                return;
            }

            this.currentFrame++;

            globalThis.currentFrameNumber = this.currentFrame;

            const currentTimestamp = Date.now();
            const deltaTimeMS = currentTime - this.lastUpdateTime;
            const deltaTimeS = deltaTimeMS / 1000;

            this.lastUpdateTime = currentTime;

            this.Loader.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.Renderer.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.Input.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.Netcode.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.Collision.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.Debug.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.Resizer.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.Audio.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.LevelLoader.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.Predictor.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            this.UI.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            if (this.gameIsPortalEmbedded) {
                this.HostPortalSDK.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            }

            this.entities.forEach((entity) => {
                entity.Update(deltaTimeS, deltaTimeMS, currentTimestamp);
            });

            requestAnimationFrame((time) => this.Update(time));
        } catch (e) {
            console.error("Caught error in game client update loop!");
            console.error(e);
        }
    }

    public WhereAmI(): void {
        console.info(`Your player is currently at position: ${this.Predictor.GetPredictedEntity().Position.x}x, ${this.Predictor.GetPredictedEntity().Position.y}y, ${this.Predictor.GetPredictedEntity().Position.z}z`);
    }

    public ProcessMessage(message: NTyped) {
        // this.LogInfo("Nengi server sent us a message:");
        // this.LogInfo("\n" + JSON.stringify(message, null, 2));

        switch (message.ntype) {
            case NType.RunCompleteMessage:
                const { minutes, seconds, milliseconds } = message as RunCompleteMessage;
                Game.UI.UpdateFinalRunTime(minutes, seconds, milliseconds);
                break;
            case NType.IdentityMessage:
                this.myEntityNid = (message as IdentityMessage).myId;
                this.LogInfo(`Self identified as nid: ${this.myEntityNid}`);
                Game.EmitEvent("Bootstrap::MyEntitiesNidIsKnown", message);
                break;
            case NType.LeaderboardMessage:
                Game.Renderer.UpdateLeaderboard((message as LeaderboardMessage).leaderboard);
                break;
            case NType.RunDurationUpdatedMessage:
                Game.UI.UpdateRunDuration((message as RunDurationUpdatedMessage).newRunDuration);
                break;
            case NType.EmoteMessage:
                if ((message as EmoteMessage).playerId === this.myEntityNid) {
                    const myEntity = Game.Predictor.GetPredictedEntity();
                    if (myEntity !== undefined) {
                        myEntity.PopEmote((message as EmoteMessage).emote, (message as EmoteMessage).animation);
                    }
                } else {
                    const foundEntity: ClientSideEntity | undefined = this.GetEntityByNid((message as EmoteMessage).playerId);
                    if (foundEntity !== undefined) {
                        (foundEntity as ClientPlayer).PopEmote((message as EmoteMessage).emote, (message as EmoteMessage).animation);
                    }
                }
                break;
            // case NType.ParticleTrailMessage:
            //     if ((message as ParticleTrailMessage).playerId === this.myEntityNid) {
            //         const myEntity = Game.Predictor.GetPredictedEntity();
            //         if (myEntity !== undefined) {
            //             myEntity.SetParticleTrailEffect((message as ParticleTrailMessage).particleTrail);
            //         }
            //     } else {
            //         const foundEntity: ClientSideEntity | undefined = this.GetEntityByNid((message as ParticleTrailMessage).playerId);
            //         if (foundEntity !== undefined) {
            //             (foundEntity as ClientPlayer).SetParticleTrailEffect((message as ParticleTrailMessage).particleTrail);
            //         }
            //     }
            //     break;
            default:
                this.LogWarning(`Unhandled message type: ${NType[message.ntype]}!`);
                break;
        }
    }

    private _addPlayerEntity(createPlayerPayload: PlayerEntityCreationPayload) {
        const createdEntity = new ClientPlayer(createPlayerPayload);
        return createdEntity;
    }

    public GetEntityByNid(nid: NetworkEntityId): ClientSideEntity | undefined {
        for (const entity of this.entities) {
            if (entity.nid === nid) {
                return entity;
            }
        }
    }

    public GetActiveSkin(): Characters {
        const myPredictedEntity = Game.Predictor.GetPredictedEntity();

        if (myPredictedEntity === undefined) {
            // this.LogWarning("GetActiveSkin called but my predicted entity is undefined!");
            return Characters.Broccoli;
        }

        return myPredictedEntity.characterSkin;
    }

    public AddEntity(createEntityPayload: NTyped) {
        let instantiatedEntity;

        // console.log("server sent us an add entity message", createEntityPayload);

        switch (createEntityPayload.ntype) {
            case NType.PlayerEntity:
                instantiatedEntity = this._addPlayerEntity(createEntityPayload as PlayerEntityCreationPayload);
                break;
            default:
                this.LogError("Server sent us an entity create for an entity of type: UNKNOWN");
                break;
        }

        if (instantiatedEntity === undefined) {
            this.LogError("instantiatedEntity is undefined, aborting entity creation");
            return;
        }

        // console.info("adding entity to gameclient this.entities");
        this.entities.add(instantiatedEntity);
    }

    public DeleteEntity(nid: NetworkEntityId) {
        // console.log("Server told us to delete entity with nid: " + nid);

        const foundEntity: ClientSideEntity | undefined = this.GetEntityByNid(nid);

        if (foundEntity === undefined) {
            this.LogWarning(`Received update event for an entity that our client doesnt think exists??? ${nid}`);
            return;
        }

        (foundEntity as ClientPlayer).Destroy();
    }

    public UpdateEntity(updateEntityPayload: EntityUpdatePayload) {
        // console.log("updateEntityPayload", updateEntityPayload);
        try {
            const { nid, prop, value } = updateEntityPayload;

            const foundEntity: ClientSideEntity | undefined = this.GetEntityByNid(nid);

            if (foundEntity === undefined) {
                this.LogWarning(`Received update event for an entity that our client doesnt think exists??? ${nid}`);
                return;
            }

            if (prop === "characterSkin") {
                if (nid === this.myEntityNid) {
                    if (Config.Player.ENABLE_PREDICTION) {
                        Game.Predictor.GetPredictedEntity()?.UpdateCharacterSkin(value as Characters);
                    }
                } else {
                    (foundEntity as ClientPlayer).UpdateCharacterSkin(value as Characters);
                }
            }

            if (prop === "characterTrail") {
                // console.log("Updating character trail");
                if (nid === this.myEntityNid) {
                    const myEntity = Game.Predictor.GetPredictedEntity();
                    if (myEntity !== undefined) {
                        myEntity.SetParticleTrailEffect(value as ParticleEffects);
                    }
                } else {
                    const foundEntity: ClientSideEntity | undefined = this.GetEntityByNid(nid);
                    if (foundEntity !== undefined) {
                        (foundEntity as ClientPlayer).SetParticleTrailEffect(value as ParticleEffects);
                    }
                }
            }

            if (prop === "country") {
                if (nid === this.myEntityNid) {
                    if (Config.Player.ENABLE_PREDICTION) {
                        Game.Predictor.GetPredictedEntity()?.setCountry(value as Countries);
                    }
                } else {
                    (foundEntity as ClientPlayer).setCountry(value as Countries);
                }
            }

            if (prop === "username") {
                if (nid === this.myEntityNid) {
                    if (Config.Player.ENABLE_PREDICTION) {
                        Game.Predictor.GetPredictedEntity()?.setName(value as string);
                    }
                } else {
                    (foundEntity as ClientPlayer).setName(value as string);
                }
            }

            if (prop === "animationSpeedMultiplier") {
                // console.log("animationSpeedMultiplier", value);
                if (nid === this.myEntityNid) {
                    if (Config.Player.ENABLE_PREDICTION) {
                        Game.Predictor.GetPredictedEntity()?.UpdateAnimationTimeScale(value as number);
                    }
                } else {
                    (foundEntity as ClientPlayer)?.UpdateAnimationTimeScale(value as number);
                }
            }

            if (prop === "runInProgress") {
                return;
            }

            // This makes typescript unhappy but its necessary in this case
            // @ts-ignore
            foundEntity[prop] = value;
        } catch (e) {
            console.error(`Caught error in update entity: ${e}`);
            console.error(e);
        }
    }
}
