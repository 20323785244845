"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Schema = void 0;
class Schema {
    constructor() {
        this.keys = [];
        this.props = {};
    }
}
exports.Schema = Schema;
