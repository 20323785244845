import { UserType, AnonymousUser } from "../../shared/SharedTypes";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { loadFromLocalStorage, LSKeys, loadFromOldLocalStorage, saveToLocalStorage, deleteFromOldLocalStorage, deleteFromLocalStorage, localStorageContainsKey } from "./ClientLocalStorage";
import { Config } from "../../shared/Config";
import { UserNotFoundError } from "../APIErrors";
import { checkMaintenanceModeRequestWithRetries, createAnonymousUserWithRetries, getAnonymousUserWithRetries } from "../ClientRequests";
import { clamp } from "../../shared/SharedUtils";
import { t } from "../../shared/data/Data_I18N";

export class ClientIdentity extends GameplaySystem {
    private identified: boolean = false;
    private userType: UserType;
    private identity: AnonymousUser;

    public constructor() {
        super();
    }

    public GetUserType(): UserType {
        return this.userType;
    }

    public override async Initialize(): Promise<void> {
        let identity: AnonymousUser | undefined = undefined;
        let userType: UserType | undefined = undefined;

        this._portUserAuthBetweenMajorGameVersionsIfNecessary();

        let maintenanceModeActive = false;

        try {
            const { isInMaintenanceMode } = await checkMaintenanceModeRequestWithRetries();

            maintenanceModeActive = isInMaintenanceMode;
        } catch (e) {
            Game.UI.ShowErrorScreen(t("error_screen__server_maybe_full"));
        }

        if (maintenanceModeActive) {
            console.info("Game is in maintenance mode currently, not attempting to connect to the server!");

            Game.UI.ShowErrorScreen(t("error_screen__maintenance_mode"));
        } else {
            try {
                if (this._isExistingAnonymousUser()) {
                    // load an existing anon user if none of the above apply
                    this.LogInfo("Anonymous user found, retrieving info for existing anonymous user");
                    const anonUserId = loadFromLocalStorage(LSKeys.AnonymousUserId) as string;
                    const retrievedAnonUser = await getAnonymousUserWithRetries(anonUserId);

                    if (retrievedAnonUser === undefined) throw new UserNotFoundError("User not found!");

                    identity = retrievedAnonUser;
                    userType = UserType.Anonymous;
                    // this.LogInfo("Retrieved existing anonymous user!");
                    // this.LogInfo("\n" + JSON.stringify(retrievedAnonUser, null, 2));
                } else {
                    // lastly, create a new anon user if none of the above apply
                    this.LogInfo("No anonymous user found, creating net new anon user");
                    const createdAnonUser = await createAnonymousUserWithRetries();

                    if (createdAnonUser === undefined) throw new Error("Failed to create user!");

                    saveToLocalStorage(LSKeys.AnonymousUserId, createdAnonUser!._id);
                    identity = createdAnonUser;
                    userType = UserType.Anonymous;
                    // this.LogInfo("Newly created anonymous user:");
                    // this.LogInfo("\n" + JSON.stringify(createdAnonUser, null, 2));
                }
            } catch (e) {
                if (e instanceof UserNotFoundError) {
                    this.ClearLocalAuth();
                    this.AuthExpiredLogout();
                    Game.UI.ShowErrorScreen("Error connecting to server: servers may be full right now, try again in a few minutes!");
                } else {
                    console.error("Unknown API Error", e);
                    Game.UI.ShowErrorScreen("Error connecting to server: servers may be full right now, try again in a few minutes!");
                }
            }

            if (identity === undefined || userType === undefined) {
                this.ClearLocalAuth();
                Game.UI.ShowErrorScreen("Error connecting to server: servers may be full right now, try again in a few minutes!");
                return;
            }

            Game.EmitEvent("Identity::Identified", { identity: identity });

            const { UpTogether } = identity;
            const { chosenSkin } = UpTogether;

            // console.log("@@@ identity", UpTogether);

            if (localStorageContainsKey(LSKeys.SkinUnlocks)) {
                const savedSkinUnlocks = loadFromLocalStorage(LSKeys.SkinUnlocks)!;

                try {
                    const parsedUnlocks = JSON.parse(savedSkinUnlocks);

                    // console.info("@@@ Got saved skin unlocks:", parsedUnlocks);

                    if (parsedUnlocks.includes(chosenSkin) === false) {
                        // console.info("@@@ Saveds kun unlocks did not yet have this players default skin, adding it!");
                        const updatedParsedUnlocks = [...parsedUnlocks, chosenSkin];
                        saveToLocalStorage(LSKeys.SkinUnlocks, JSON.stringify(updatedParsedUnlocks));
                    } else {
                        // console.info("@@@ Saved skin unlocks already had this players default skin, no-op");
                    }
                } catch (e) {
                    // console.error("@@@ Failed to parse saved skin unlocks:", savedSkinUnlocks);
                    saveToLocalStorage(LSKeys.SkinUnlocks, JSON.stringify([chosenSkin]));
                }
            } else {
                const defaultUnlocks: number[] = [chosenSkin];

                // console.info("@@@ No saved skin unlocks found, saving defaults (which includes current unlocked skin):", defaultUnlocks);

                saveToLocalStorage(LSKeys.SkinUnlocks, JSON.stringify(defaultUnlocks));
            }

            Game.EmitEvent("LoadEvent", { eventName: "IdentityReady" });

            this.identity = identity;
            this.userType = userType;
            this.identified = true;

            this.LogInfo("Ready!\n\nUsername is: " + this.identity.username);
        }
    }

    public GetUsername(): string {
        return this.identity.username;
    }

    public GetAnonymousUserId(): string {
        return this.identity._id;
    }

    public ClearLocalAuth(reload?: boolean): void {
        deleteFromLocalStorage(LSKeys.AuthToken);
        deleteFromLocalStorage(LSKeys.AnonymousUserId);

        if (reload) {
            window.location.reload();
        }
    }

    public AuthExpiredLogout(): void {
        window.location.reload();
    }

    protected override getSystemName(): string {
        return "Identity";
    }

    private _isExistingAnonymousUser(): boolean {
        const existingAnonUserId = loadFromLocalStorage(LSKeys.AnonymousUserId);
        if (existingAnonUserId !== null) {
            return true;
        } else {
            return false;
        }
    }

    private _portUserAuthBetweenMajorGameVersionsIfNecessary(): void {
        try {
            const currentVersionAsInt = parseInt(Config.Game.MAJOR_GAME_VERSION);

            const previousMajorGameVersion = clamp(currentVersionAsInt - 1, 0, Infinity);

            if (previousMajorGameVersion === currentVersionAsInt) {
                this.LogInfo("No new major game version detected; continuing...");
                return;
            }

            this.LogInfo("Current game version:", currentVersionAsInt);
            this.LogInfo("Prior game version:", previousMajorGameVersion);

            const existingPreviousAnonUserId = loadFromOldLocalStorage(LSKeys.AnonymousUserId, previousMajorGameVersion);

            if (existingPreviousAnonUserId !== null) {
                this.LogInfo(`Found legacy anonymous user id: ${existingPreviousAnonUserId} - porting to new version!`);
                saveToLocalStorage(LSKeys.AnonymousUserId, existingPreviousAnonUserId);
                deleteFromOldLocalStorage(LSKeys.AnonymousUserId, previousMajorGameVersion);
                this.LogInfo("New major game version detected, ported anonymous user id from previous version!");
            } else {
                this.LogInfo("No legacy anonymous user id found; continuing...");
            }

            const existingSfxMutedSetting = loadFromOldLocalStorage(LSKeys.SFXMuted, previousMajorGameVersion);

            if (existingSfxMutedSetting !== null) {
                this.LogInfo(`Found legacy sfx muted setting: ${existingSfxMutedSetting} - porting to new version!`);
                saveToLocalStorage(LSKeys.SFXMuted, existingSfxMutedSetting);
                deleteFromOldLocalStorage(LSKeys.SFXMuted, previousMajorGameVersion);
                this.LogInfo("New major game version detected, ported sfx muted setting from previous version!");
            } else {
                this.LogInfo("No legacy sfx muted setting found; continuing...");
            }

            const existingMusicMutedSetting = loadFromOldLocalStorage(LSKeys.MusicMuted, previousMajorGameVersion);

            if (existingMusicMutedSetting !== null) {
                this.LogInfo(`Found legacy music muted setting: ${existingMusicMutedSetting} - porting to new version!`);
                saveToLocalStorage(LSKeys.MusicMuted, existingMusicMutedSetting);
                deleteFromOldLocalStorage(LSKeys.MusicMuted, previousMajorGameVersion);
                this.LogInfo("New major game version detected, ported music muted setting from previous version!");
            } else {
                this.LogInfo("No legacy music muted setting found; continuing...");
            }

            const existingTrailUnlocks = loadFromOldLocalStorage(LSKeys.TrailUnlocks, previousMajorGameVersion);

            if (existingTrailUnlocks !== null) {
                this.LogInfo(`Found legacy trail unlocks: ${existingTrailUnlocks} - porting to new version!`);
                saveToLocalStorage(LSKeys.TrailUnlocks, existingTrailUnlocks);
                deleteFromOldLocalStorage(LSKeys.TrailUnlocks, previousMajorGameVersion);
                this.LogInfo("New major game version detected, ported trail unlocks from previous version!");
            } else {
                this.LogInfo("No legacy trail unlocks found; continuing...");
            }

            const existingSkinUnlocks = loadFromOldLocalStorage(LSKeys.SkinUnlocks, previousMajorGameVersion);

            if (existingSkinUnlocks !== null) {
                this.LogInfo(`Found legacy skin unlocks: ${existingSkinUnlocks} - porting to new version!`);
                saveToLocalStorage(LSKeys.SkinUnlocks, existingSkinUnlocks);
                deleteFromOldLocalStorage(LSKeys.SkinUnlocks, previousMajorGameVersion);
                this.LogInfo("New major game version detected, ported skin unlocks from previous version!");
            } else {
                this.LogInfo("No legacy skin unlocks found; continuing...");
            }
        } catch (e) {
            console.error(e);
        }
    }

    public override Update(__deltaTime: number): void {}

    public override Cleanup(): void {}
}
