import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { SharedPlayer } from "../entities/SharedPlayer";
import { AABBBoxCollider, ICollidable, ITriggerEntity, TriggerTypes } from "../entities/SharedEntityTypes";
import { Vector3 } from "three";
import level from "../../shared/data/level_uppity.json";
import colliders from "../../shared/data/colliders.json";
import { getPlatformMetadataByJsonIdentifier } from "../data/PlatformData";
import { ceil, floor } from "lodash";

const GRID_CELL_SIZE_X = 50;
const GRID_CELL_SIZE_Y = 50;
const GRID_CELL_SIZE_Z = 50;

export class SharedCollisionSystem extends GameplaySystem {
    protected collisionsCheckedThisFrame: number = 0;

    protected collidablePlatforms: Set<ICollidable> = new Set();
    protected triggers: Set<ITriggerEntity> = new Set();

    protected collisionGridCells: Map<number, CollisionGridCell> = new Map();
    protected collisionGridLength: number[] = [0, 0, 0];
    protected collisionGridDirty: boolean = true;
    protected worldMin: number[] = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY];
    protected worldMax: number[] = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];

    public constructor() {
        super();
    }

    public Initialize(): void {
        for (const platform of level.platforms) {
            const { position: parentPlatformPosition, assetIdentifier, uniqueIdentifier, behaviour } = platform;

            let { scale, rotation } = platform;

            if (scale === undefined) {
                scale = [1, 1, 1];
            }

            if (rotation === undefined) {
                rotation = [0, 0, 0];
            }

            // console.log(`Handling construction of platform #${uniqueIdentifier}`);

            if (assetIdentifier === undefined) {
                continue;
            }

            // console.log("Attempting to retrieve platform metadata for identifier:", assetIdentifier);

            const platformMetadata = getPlatformMetadataByJsonIdentifier(assetIdentifier!);

            if (!platformMetadata) {
                throw new Error("Could not find platform metadata for identifier: " + assetIdentifier);
            }

            if (!platformMetadata && assetIdentifier !== "spawn") {
                // console.error("Could not find platform metadata for identifier:", assetIdentifier);
                // console.error("skipping this platform creation! level might be broken!");
                continue;
            } else {
                // @ts-ignore
                const possibleColliders = colliders[platformMetadata!.jsonIdentifier];
                // console.log(possibleColliders);

                if (possibleColliders === undefined || possibleColliders.length === 0) {
                    continue;
                } else {
                    for (let i = 0; i < possibleColliders.length; i++) {
                        const collider = possibleColliders[i];
                        const { center: position, size, isTrigger } = collider;
                        const { x: width, y: height, z: depth } = size;

                        const positionOfThisCollider = new Vector3(parentPlatformPosition[0] + position.x * scale[0], parentPlatformPosition[1] + position.y * scale[1], parentPlatformPosition[2] + position.z * scale[2]);

                        if (isTrigger) {
                            let triggerType: TriggerTypes = TriggerTypes.None;

                            if (assetIdentifier.includes("check_point")) {
                                triggerType = TriggerTypes.Checkpoint;
                            } else if (assetIdentifier === "finish_line_001") {
                                triggerType = TriggerTypes.FinishLine;
                            } else if (assetIdentifier === "start_line") {
                                triggerType = TriggerTypes.StartingLine;
                            } else if (assetIdentifier === "vanilla_001") {
                                triggerType = TriggerTypes.UI_PopUp_NameAndCountrySelect;
                            } else if (assetIdentifier === "chocolate_001") {
                                triggerType = TriggerTypes.UI_PopUp_TrailSelect;
                            } else if (assetIdentifier === "death_block_001") {
                                triggerType = TriggerTypes.DeathBlock;
                            } else if (assetIdentifier === "pan_001" || assetIdentifier === "pan_003" || assetIdentifier === "pan_004") {
                                triggerType = TriggerTypes.Bounce;
                            } else if (assetIdentifier === "moving_block_001" || assetIdentifier === "slippery_block_001") {
                                triggerType = TriggerTypes.Speed;
                            } else if (assetIdentifier === "fridge_001") {
                                triggerType = TriggerTypes.UI_PopUp_CharacterSelect;
                            } else if (assetIdentifier === "pistachio_001") {
                                triggerType = TriggerTypes.UI_PopUp_EndRun;
                            } else {
                                throw new Error(`Could not find trigger type for asset identifier: ${assetIdentifier}`);
                            }

                            let behaviourValue = 1;

                            if ((triggerType === TriggerTypes.Bounce || triggerType === TriggerTypes.Speed) && behaviour !== undefined) {
                                if (behaviour[1] !== undefined) {
                                    behaviourValue = parseFloat(behaviour[1]);
                                }
                            }

                            this.AddTrigger({
                                AssociatedPlatform: uniqueIdentifier,
                                TriggerType: triggerType,
                                BehaviourValue: behaviourValue,
                                Scale: new Vector3(scale[0], scale[1], scale[2]),
                                Position: positionOfThisCollider,
                                Collider: {
                                    Height: height * scale[1],
                                    Width: width * scale[0],
                                    Depth: depth * scale[2],
                                    Position: positionOfThisCollider
                                }
                            });
                        } else {
                            this.AddCollidablePlatform({
                                Scale: new Vector3(scale[0], scale[1], scale[2]),
                                Position: positionOfThisCollider,
                                Collider: {
                                    Height: height * scale[1],
                                    Width: width * scale[0],
                                    Depth: depth * scale[2],
                                    Position: positionOfThisCollider
                                }
                            });
                        }

                        // Dynamically calculate min and max bounds of the world, to be divided up into grid cells
                        if (positionOfThisCollider.x < this.worldMin[0]) this.worldMin[0] = positionOfThisCollider.x;
                        if (positionOfThisCollider.y < this.worldMin[1]) this.worldMin[1] = positionOfThisCollider.y;
                        if (positionOfThisCollider.z < this.worldMin[2]) this.worldMin[2] = positionOfThisCollider.z;

                        if (positionOfThisCollider.x > this.worldMax[0]) this.worldMax[0] = positionOfThisCollider.x;
                        if (positionOfThisCollider.y > this.worldMax[1]) this.worldMax[1] = positionOfThisCollider.y;
                        if (positionOfThisCollider.z > this.worldMax[2]) this.worldMax[2] = positionOfThisCollider.z;
                    }
                }
            }
        }

        // Determine how many cells are contained in each axis of the grid, this will be necessary for finding neighbor cells
        this.collisionGridLength[0] = ceil((this.worldMax[0] - this.worldMin[0]) / GRID_CELL_SIZE_X);
        this.collisionGridLength[1] = ceil((this.worldMax[1] - this.worldMin[1]) / GRID_CELL_SIZE_Y);
        this.collisionGridLength[2] = ceil((this.worldMax[2] - this.worldMin[2]) / GRID_CELL_SIZE_Z);

        // Add platforms to grid
        this.collidablePlatforms.forEach((platform) => {
            const platformCenter = platform.Collider.Position;
            const gridIndex = this._positionToCollisionGridIndex(platformCenter.x, platformCenter.y, platformCenter.z);

            if (!this.collisionGridCells.has(gridIndex)) {
                this.collisionGridCells.set(gridIndex, new CollisionGridCell());
            }

            this.collisionGridCells.get(gridIndex)?.staticPlatforms.add(platform);
        });

        // Likewise, trigger volumes
        this.triggers.forEach((trigger) => {
            const triggerCenter = trigger.Collider.Position;
            const gridIndex = this._positionToCollisionGridIndex(triggerCenter.x, triggerCenter.y, triggerCenter.z);

            if (!this.collisionGridCells.has(gridIndex)) {
                this.collisionGridCells.set(gridIndex, new CollisionGridCell());
            }

            this.collisionGridCells.get(gridIndex)?.triggers.add(trigger);
        });
    }

    protected override getSystemName(): string {
        return "Collision";
    }

    public AddCollidablePlatform(entity: ICollidable): void {
        this.collidablePlatforms.add(entity);
    }

    public AddTrigger(entity: ITriggerEntity): void {
        this.triggers.add(entity);
    }

    public CheckForCollisions(player: SharedPlayer, deltaTimeS: number): void {
        if (this.collisionGridDirty) {
            // Clear out moving platforms, then re-add them in their new position
            // TODO: Dave - Uncomment this when moving platforms are added (or even just do it better because this is a wee bit jank)
            // this.collisionGridCells.forEach((cell) => {
            //     cell.dynamicPlatforms.clear();
            // });

            this.collisionGridDirty = false;
        }

        const playerCellIndex = this._positionToCollisionGridIndex(player.x, player.y, player.z);

        player.IsGrounded = false;
        player.CollidingWithUIPopTrigger = false;

        // Check neighboring cells, and run collision checks against their contents
        for (let z = -1; z <= 1; ++z) {
            for (let y = -1; y <= 1; ++y) {
                for (let x = -1; x <= 1; ++x) {
                    const neighborIndex = this._indexFromOffset(playerCellIndex, x, y, z);
                    const cell = this.collisionGridCells.get(neighborIndex);

                    cell?.staticPlatforms.forEach((platform) => {
                        this.collisionsCheckedThisFrame++;

                        // Calculate the half sizes of the colliders
                        const platformHalfWidth = platform.Collider.Width / 2;
                        const platformHalfHeight = platform.Collider.Height / 2;
                        const platformHalfDepth = platform.Collider.Depth / 2;

                        const playerHalfWidth = player.Collider.Width / 2;
                        const playerHalfHeight = player.Collider.Height / 2;
                        const playerHalfDepth = player.Collider.Depth / 2;

                        // Calculate the centers of the colliders
                        const platformCenter = platform.Collider.Position;
                        const playerCenter = player.Collider.Position;

                        // Calculate the distances between centers along each axis
                        const dx = Math.abs(platformCenter.x - playerCenter.x);
                        const dy = Math.abs(platformCenter.y - playerCenter.y);
                        const dz = Math.abs(platformCenter.z - playerCenter.z);

                        // Calculate the overlap along each axis
                        const overlapX = platformHalfWidth + playerHalfWidth - dx;
                        const overlapY = platformHalfHeight + playerHalfHeight - dy;
                        const overlapZ = platformHalfDepth + playerHalfDepth - dz;

                        const collided = overlapX > 0 && overlapY > 0 && overlapZ > 0;

                        // Check for a collision along all three axes
                        if (collided) {
                            // Resolve the collision based on the smallest overlap
                            if (overlapX <= overlapY && overlapX <= overlapZ) {
                                // Resolve along the X-axis
                                if (playerCenter.x < platformCenter.x) {
                                    // Move the player to the left
                                    player.Collider.Position.x -= overlapX;
                                } else {
                                    // Move the player to the right
                                    player.Collider.Position.x += overlapX;
                                }
                            } else if (overlapY <= overlapX && overlapY <= overlapZ) {
                                // Resolve along the Y-axis
                                if (playerCenter.y < platformCenter.y) {
                                    // Move the player upwards
                                    player.Collider.Position.y -= overlapY;
                                } else {
                                    // Move the player downwards
                                    player.Collider.Position.y += overlapY;
                                }
                            } else {
                                // Resolve along the Z-axis
                                if (playerCenter.z < platformCenter.z) {
                                    // Move the player forwards
                                    player.Collider.Position.z -= overlapZ;
                                } else {
                                    // Move the player backwards
                                    player.Collider.Position.z += overlapZ;
                                }
                            }

                            player.IsGrounded = true;
                        }
                    });

                    cell?.triggers.forEach((trigger) => {
                        this.collisionsCheckedThisFrame++;

                        // Calculate the half sizes of the colliders
                        const platformHalfWidth = trigger.Collider.Width / 2;
                        const platformHalfHeight = trigger.Collider.Height / 2;
                        const platformHalfDepth = trigger.Collider.Depth / 2;

                        const playerHalfWidth = player.Collider.Width / 2;
                        const playerHalfHeight = player.Collider.Height / 2;
                        const playerHalfDepth = player.Collider.Depth / 2;

                        // Calculate the centers of the colliders
                        const platformCenter = trigger.Collider.Position;
                        const playerCenter = player.Collider.Position;

                        // Calculate the distances between centers along each axis
                        const dx = Math.abs(platformCenter.x - playerCenter.x);
                        const dy = Math.abs(platformCenter.y - playerCenter.y);
                        const dz = Math.abs(platformCenter.z - playerCenter.z);

                        // Calculate the overlap along each axis
                        const overlapX = platformHalfWidth + playerHalfWidth - dx;
                        const overlapY = platformHalfHeight + playerHalfHeight - dy;
                        const overlapZ = platformHalfDepth + playerHalfDepth - dz;

                        const collided = overlapX > 0 && overlapY > 0 && overlapZ > 0;

                        // Check for a collision along all three axes
                        if (collided) {
                            player.CollidedWithTrigger(trigger, deltaTimeS);
                        }
                    });
                }
            }
        }

        if (player.CollidingWithUIPopTrigger === false) {
            player.ResetUIPopTriggerAcc();
        }
    }

    public CheckRayCollisions(rayOrigin: Vector3, rayDirection: Vector3): { hit: boolean; hitPoint: Vector3 | undefined } {
        const rayCellIndex = this._positionToCollisionGridIndex(rayOrigin.x, rayOrigin.y, rayOrigin.z);

        for (let z = -1; z <= 1; ++z) {
            for (let y = -1; y <= 1; ++y) {
                for (let x = -1; x <= 1; ++x) {
                    const neighborIndex = this._indexFromOffset(rayCellIndex, x, y, z);
                    const cell = this.collisionGridCells.get(neighborIndex);

                    if (cell) {
                        for (const platform of cell.staticPlatforms) {
                            const result = this.RayToAABBCollisionTest(rayOrigin, rayDirection, platform.Collider);
                            if (result.hit) {
                                return result;
                            }
                        }

                        for (const trigger of cell.triggers) {
                            if (trigger.TriggerType === TriggerTypes.DeathBlock) {
                                const result = this.RayToAABBCollisionTest(rayOrigin, rayDirection, trigger.Collider);
                                if (result.hit) {
                                    return result;
                                }
                            }
                        }
                    }
                }
            }
        }

        return { hit: false, hitPoint: undefined };
    }

    /* 
    (an adaptation of)
    Fast Ray-Box Intersection
    by Andrew Woo
    from "Graphics Gems", Academic Press, 1990
    */
    public RayToAABBCollisionTest(rayOrigin: Vector3, rayDirection: Vector3, aabb: AABBBoxCollider): { hit: boolean; hitPoint: Vector3 | undefined } {
        const numDimensions = 3;
        const right = 0;
        const left = 1;
        const middle = 2;

        const minB = [aabb.Position.x - aabb.Width / 2, aabb.Position.y - aabb.Height / 2, aabb.Position.z - aabb.Depth / 2];
        const maxB = [aabb.Position.x + aabb.Width / 2, aabb.Position.y + aabb.Height / 2, aabb.Position.z + aabb.Depth / 2];
        const origin = [rayOrigin.x, rayOrigin.y, rayOrigin.z];
        const dir = [rayDirection.x, rayDirection.y, rayDirection.z];

        const coord: number[] = [];
        let inside = true;
        const quadrant: number[] = [];
        const maxT: number[] = [];
        const candidatePlane: number[] = [];

        /* Find candidate planes; this loop can be avoided if
   	    rays cast all from the eye(assume perpsective view) */
        for (let i = 0; i < numDimensions; ++i) {
            if (origin[i] < minB[i]) {
                quadrant[i] = left;
                candidatePlane[i] = minB[i];
                inside = false;
            } else if (origin[i] > maxB[i]) {
                quadrant[i] = right;
                candidatePlane[i] = maxB[i];
                inside = false;
            } else {
                quadrant[i] = middle;
            }
        }

        /* Ray origin inside bounding box */
        if (inside) {
            return { hit: true, hitPoint: rayOrigin };
        }

        /* Calculate T distances to candidate planes */
        for (let i = 0; i < numDimensions; ++i) {
            if (quadrant[i] !== middle && dir[i] !== 0) {
                maxT[i] = (candidatePlane[i] - origin[i]) / dir[i];
            } else {
                maxT[i] = -1;
            }
        }

        /* Get largest of the maxT's for final choice of intersection */
        let whichPlane = 0;
        for (let i = 1; i < numDimensions; ++i) {
            if (maxT[whichPlane] < maxT[i]) {
                whichPlane = i;
            }
        }

        /* Check final candidate actually inside box */
        if (maxT[whichPlane] < 0) {
            return { hit: false, hitPoint: undefined };
        }
        for (let i = 0; i < numDimensions; ++i) {
            if (whichPlane !== i) {
                coord[i] = origin[i] + maxT[whichPlane] * dir[i];
                if (coord[i] < minB[i] || coord[i] > maxB[i]) {
                    return { hit: false, hitPoint: undefined };
                }
            } else {
                coord[i] = candidatePlane[i];
            }
        }
        return { hit: true, hitPoint: new Vector3(coord[0], coord[1], coord[2]) };
    }

    public Update(__deltaTimeS: number, __deltaTimeMS: number, __currentTickStartTimestampMs: number) {
        this.collisionGridDirty = true; // Just assume something moved each frame
    }

    public override Cleanup(): void {}

    private _positionToCollisionGridIndex(x: number, y: number, z: number): number {
        const gridPosX = floor(x / GRID_CELL_SIZE_X);
        const gridPosY = floor(y / GRID_CELL_SIZE_Y);
        const gridPosZ = floor(z / GRID_CELL_SIZE_Z);

        return gridPosX + gridPosY * this.collisionGridLength[0] + gridPosZ * this.collisionGridLength[0] * this.collisionGridLength[1];
    }

    private _indexFromOffset(baseIndex: number, xOffset: number, yOffset: number, zOffset: number): number {
        return baseIndex + xOffset + yOffset * this.collisionGridLength[0] + zOffset * this.collisionGridLength[0] * this.collisionGridLength[1];
    }
}

class CollisionGridCell {
    public triggers: Set<ITriggerEntity> = new Set();
    public staticPlatforms: Set<ICollidable> = new Set();
    public dynamicPlatforms: Set<ICollidable> = new Set();
}
