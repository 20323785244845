import { isNil } from "lodash";

export const getHighResolutionTimestampMs = (): number => {
    const time = process.hrtime.bigint();
    return Number(time) / 1e6;
};

export const getHighResolutionTimestampMsNew = typeof BigInt !== "undefined" ? (typeof process !== "undefined" && typeof process.hrtime !== "undefined" && typeof process.hrtime.bigint === "function" ? () => process.hrtime.bigint() : () => BigInt(Date.now() * 1e6)) : () => Date.now() * 1e6;

// eslint-disable-next-line no-control-regex
export const emailRegex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])");

export const getHighResolutionTimestampMsNewNew = () => Date.now();

export const pickRandom = <T>(arr: T[]): T => {
    return arr[Math.floor(Math.random() * arr.length)];
};

export const clientIsInIframe = (): boolean => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const getClassName = (caller: object): string => {
    const proto = Object.getPrototypeOf(caller);
    if (proto.constructor && proto.constructor.name) {
        return proto.constructor.name;
    }
    return "";
};

export const isNilOrEmptyString = (v: unknown) => {
    return isNil(v) || v === "";
};

export const secondsToTime = (seconds: number): string => {
    const hours: number = Math.floor(seconds / 3600);
    const minutes: number = Math.floor((seconds % 3600) / 60);
    const remainingSeconds: number = seconds % 60;

    const timeSegments: string[] = [];

    if (hours > 0) {
        const formattedHours: string = hours.toString().padStart(2, "0");
        timeSegments.push(formattedHours);
    }

    const formattedMinutes: string = minutes.toString().padStart(2, "0");
    const formattedSeconds: string = remainingSeconds.toString().padStart(2, "0");

    timeSegments.push(formattedMinutes, formattedSeconds);

    return timeSegments.join(":");
};

export const clamp = (val: number, min: number = 0, max: number = 1): number => Math.max(min, Math.min(max, val));

export const isFacingLeftBasedOnRads = (rads: number): boolean => {
    return rads > Math.PI / 2 || rads < -Math.PI / 2;
};

export const distanceBetweenPoints = (x1: number, y1: number, x2: number, y2: number): number => {
    const xDiff = x1 - x2;
    const yDiff = y1 - y2;
    return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
};

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export function toRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
}

export function toDegrees(radians: number): number {
    return radians * (180 / Math.PI);
}

export function randomizeAngleInRadiansByPercentage(angleInRadians: number, percentage: number): number {
    const range = Math.PI * 2 * percentage;
    const offset = Math.random() * range - range / 2;
    let newAngle = angleInRadians + offset;

    if (newAngle < -Math.PI) {
        newAngle += 2 * Math.PI;
    } else if (newAngle > Math.PI) {
        newAngle -= 2 * Math.PI;
    }

    return newAngle;
}

export const booleanWithRandomChance = (chance: number): boolean => Math.random() < chance;

export const randomBoolean = (): boolean => Math.random() >= 0.5;

export const randomAngleInRadians = (): number => Math.random() * 2 * Math.PI;

export const getUserFriendlyRunDurationFromSeconds = (seconds: number, shorterFormat?: boolean): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    if (shorterFormat) {
        const paddedMinutes = minutes.toString().padStart(2, "0");
        const paddedSeconds = remainingSeconds.toString().padStart(2, "0");
        return `${paddedMinutes}:${paddedSeconds}`;
    } else {
        return `${minutes < 1 ? "" : `${minutes}m`}${remainingSeconds}${remainingSeconds > 1 ? "s" : ""}`;
    }
};

export const getHoursMinutesFriendlyStringFromSeconds = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const paddedMinutes = minutes.toString().padStart(2, "0");
    return `${hours}h ${paddedMinutes}m`;
};
