import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { Howl } from "howler";
import { LSKeys, loadFromLocalStorage, localStorageContainsKey, saveToLocalStorage } from "./ClientLocalStorage";

const audioEventToFileNameMapping: { [key: string]: { files: string[]; volume: number } } = {
    Jump: { files: ["Jump-001", "Jump-002", "Jump-003", "Jump-004"], volume: 1 },
    Oof: { files: ["Oof1", "Oof2", "Oof3", "Oof4", "Oof5", "Oof6"], volume: 3.6 },
    Run: { files: ["Run-001", "Run-002", "Run-003", "Run-004", "Run-005", "Run-006", "Run-007"], volume: 1 },
    DeathBlock: { files: ["deathBlock"], volume: 1 },
    MovingBlock: { files: ["movingBlock"], volume: 1 },
    UI_Accept: { files: ["UI-Accept"], volume: 1 },
    UI_Decline: { files: ["UI-Decline"], volume: 1 },
    UI_Up: { files: ["UI-Up"], volume: 1 },
    UI_Down: { files: ["UI-Down"], volume: 1 },
    Start: { files: ["Start"], volume: 1 },
    Finish: { files: ["Finish"], volume: 1 },
    Checkpoint: { files: ["Checkpoint"], volume: 1 },
    Bounce: { files: ["Bounce"], volume: 3.5 }
};

export class ClientAudioSystem extends GameplaySystem {
    private sounds: { [key: string]: Howl[] } = {};
    private sfxVolume: number = 0.1;
    private musicVolume: number = 0.07;
    private musicHowl: Howl | null = null;
    private activeSounds: string[] = [];
    private musicShouldBePlaying: boolean = true;

    public MusicMuted: boolean = false;

    public SFXMuted: boolean = false;

    public constructor() {
        super();
    }

    protected override getSystemName(): string {
        return "Audio";
    }

    private _loadHowl(fileName: string, volume: number): Howl {
        const howl = new Howl({
            src: [/*`audio/webm/UpTogether_${fileName}.webm`,*/ `audio/mp3/UpTogether_${fileName}.mp3`],
            volume: volume // Set the volume for the Howl instance.
        });
        howl.on("loaderror", (id, error) => {
            throw new Error(`Error loading audio file ${fileName}: ${error}!`);
        });

        return howl;
    }

    private _initBackgroundMusic(): void {
        if (!this.musicShouldBePlaying) return;

        const soundName = "MusicLoop_Master";

        this.musicHowl = this._loadHowl(soundName, this.musicVolume);

        this.musicHowl.on("load", () => {
            this.musicHowl?.volume(this.MusicMuted ? 0 : this.musicVolume);
            this.musicHowl?.loop(true);
            this.musicHowl?.play();
            // this.activeSounds.push(soundName);
        });
    }

    public ToggleMusicMute(): void {
        this.MusicMuted = !this.MusicMuted;
        Game.UI.SetMusicMuteButtonState(this.MusicMuted);

        if (this.MusicMuted) {
            this.musicHowl?.volume(0);
            // if (this.activeSounds.length > 0) {
            //     this.activeSounds.splice(0, this.activeSounds.length);
            // }
        } else {
            this.musicHowl?.volume(this.musicVolume);
        }

        saveToLocalStorage(LSKeys.MusicMuted, this.MusicMuted.toString());
    }

    public ToggleSFXMute(): void {
        this.SFXMuted = !this.SFXMuted;
        Game.UI.SetSFXMuteButtonState(this.SFXMuted);

        saveToLocalStorage(LSKeys.SFXMuted, this.SFXMuted.toString());
    }

    public Initialize(): void {
        this.LogInfo("Initializing sound library...");

        if (localStorageContainsKey(LSKeys.MusicMuted)) {
            const currentMusicMuteState = loadFromLocalStorage(LSKeys.MusicMuted);

            this.MusicMuted = currentMusicMuteState === "true";
        } else {
            saveToLocalStorage(LSKeys.MusicMuted, "false");
        }

        if (localStorageContainsKey(LSKeys.SFXMuted)) {
            const currentSFXState = loadFromLocalStorage(LSKeys.SFXMuted);

            this.SFXMuted = currentSFXState === "true";
        } else {
            saveToLocalStorage(LSKeys.SFXMuted, "false");
        }

        for (const [audioEvent, { files, volume }] of Object.entries(audioEventToFileNameMapping)) {
            this.sounds[audioEvent] = [];

            for (const audioFileName of files) {
                const sound = this._loadHowl(audioFileName, volume * this.sfxVolume); // Pass the specific sound effect volume.
                this.sounds[audioEvent].push(sound);
            }

            // this.LogInfo("sounds", this.sounds);
        }

        // Listen for events and play associated sounds, picking a random one from the list if there are multiple
        Game.ListenForEvent("Audio::PlaySfx", (eventData: { sfxName: string }) => {
            if (this.SFXMuted === true) return;

            if (!this.musicShouldBePlaying) return;

            const sfxToPlay = eventData.sfxName;

            if (this.sounds[sfxToPlay] !== undefined) {
                if (this.activeSounds.includes(sfxToPlay)) {
                    // Don't play the same sound twice at once for now

                    return;
                }

                const soundList = this.sounds[sfxToPlay];
                const soundIndex = Math.floor(Math.random() * soundList.length);
                const howlToPlay = soundList[soundIndex];

                this.activeSounds.push(sfxToPlay);

                howlToPlay.once("end", () => {
                    // console.log("Sound done playing");

                    this.activeSounds.splice(this.activeSounds.indexOf(sfxToPlay), 1);

                    //this.activeSounds = this.activeSounds.filter((sound) => sound !== sfxToPlay);
                    // this.activeSounds = [];
                    // console.log("sound ended");
                });

                howlToPlay.play();
            } else {
                this.LogError(`Received request to play sound for event ${sfxToPlay}, but no sound is mapped to that event!`);
            }
        });

        Game.ListenForEvent("Audio::StartMusic", () => {
            this._initBackgroundMusic();
        });

        Game.EmitEvent("LoadEvent", { eventName: "AudioReady" });
    }

    public override Update(__deltaTimeS: number, __deltaTimeMS: number, __currentTime: Timestamp): void {}
    public override Cleanup(): void {}
}
