import { ClientPlayer } from "./entities/ClientPlayer";

export enum HostPortals {
    DIRECT = 0,
    Poki = 1
}
export interface GenericNengiUpdatePayload {
    nid: NetworkEntityId;
    prop: string;
    value: unknown;
}

export type ClientSideEntity = ClientPlayer;
