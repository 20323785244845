import { uuid, AnonymousUser } from "../shared/SharedTypes";
import { sleep } from "../shared/SharedUtils";
import { AuthExpiredError, AllServersFullError, NoServersOnlineError, UserNotFoundError, UnknownAPIError } from "./APIErrors";
import { saveToLocalStorage, LSKeys } from "./systems/ClientLocalStorage";
import axios, { AxiosResponse } from "axios";

/***********************************************************/
/*                                                         */
/*                           Routes                        */
/*                                                         */
/***********************************************************/

export const ClientAPIRoutes = {
    getAnonymousUserMe: "/uptogether/anonymous-user/me",
    createAnonymousUser: "/uptogether/anonymous-user",
    maintenanceStatus: "/uptogether/maintenance-status",
    matchmake: "/uptogether/matchmake"
};

/***********************************************************/
/*                                                         */
/*          Client-Side Only Request Utilities             */
/*                                                         */
/***********************************************************/

const parseResponseAndThrowIfError = (response: AxiosResponse): Promise<object> => {
    const responseData = response.data;
    const responseHeaders = response.headers;

    const potentialRefreshToken = responseHeaders["x-smg-refreshed-token"];

    if (potentialRefreshToken !== undefined && potentialRefreshToken !== null && potentialRefreshToken !== "") {
        console.info("Server gave us an updated auth token! Overwriting the one in local storage");
        saveToLocalStorage(LSKeys.AuthToken, potentialRefreshToken);
    }

    if (response.status === 200 || response.status === 201) {
        return responseData;
    } else if (response.status === 404 || response.status === 409 || response.status === 400 || response.status === 401 || response.status === 403 || response.status === 500) {
        if (responseData.error === "TokenExpired") {
            console.error("Auth token expired!");
            throw new AuthExpiredError("Users auth token has expired!");
        } else if (responseData.error === "AllServersFull") {
            console.error("All servers are full!");
            throw new AllServersFullError("All servers are full! Try again later.");
        } else if (responseData.error === "NoServersAvailable") {
            console.error("No servers are available!");
            throw new NoServersOnlineError("No servers are available! Try again later.");
        } else if (responseData.error === "UserNotFound" || responseData.error === "AnonymousUserNotFound") {
            console.error("User not found!");
            throw new UserNotFoundError("No user found with matching ID");
        } else {
            console.error("Unknown API Error!", responseData.error);
            throw new UnknownAPIError("An unhandled API error has occurred");
        }
    }
};

const getRequest = async (url: string, headers?: object): Promise<object> => {
    const rawResponse = await axios.get(url, {
        headers: {
            ...headers
        }
    });
    return parseResponseAndThrowIfError(rawResponse);
};

const postRequest = async (url: string, body: object, headers?: object): Promise<object> => {
    const rawResponse = await axios.post(url, body, {
        headers: {
            "Content-Type": "application/json",
            ...headers
        }
    });
    return parseResponseAndThrowIfError(rawResponse);
};

/***************************************/
/*                                     */
/*     Anonymous User Routes           */
/*                                     */
/***************************************/

const getAnonymousUser = async (id: uuid): Promise<AnonymousUser> => {
    const response = await getRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.getAnonymousUserMe}?anonymousUserId=${id}`);
    return response as AnonymousUser;
};

export const getAnonymousUserWithRetries = async (userId: string, retries: number = 0): Promise<AnonymousUser | undefined> => {
    try {
        return await getAnonymousUser(userId);
    } catch (error) {
        // console.error(`Caught error in getAnonymousUserWithRetries, retrying... but here's the error: ${error.message}`);
        // console.error(error);

        await sleep(3000);

        if (retries < 10) {
            return await getAnonymousUserWithRetries(userId, retries + 1);
        } else {
            console.error(`Maximum retries exceeded in getAnonymousUserWithRetries, throwing error: ${error.message}`);
            Game.UI.ShowErrorScreen("An error occurred while trying to connect to the server. Please try again later.", true);
        }
    }
};

const createAnonymousUser = async (): Promise<AnonymousUser> => {
    const lang = navigator.language;
    const userAgentString = navigator.userAgent;
    const originatingDomain = globalThis.OriginatingDomain;
    const documentReferrer = globalThis.DocumentReferrer;
    const createBody = {
        lang,
        userAgentString,
        originatingDomain,
        documentReferrer
    };

    const response = await postRequest(`${process.env.BACKEND_API_URL}${ClientAPIRoutes.createAnonymousUser}`, createBody);
    return response as AnonymousUser;
};

export const createAnonymousUserWithRetries = async (retries: number = 0): Promise<AnonymousUser | undefined> => {
    try {
        return await createAnonymousUser();
    } catch (error) {
        // console.error(`Caught error in createAnonymousUserWithRetries, retrying... but here's the error: ${error.message}`);
        // console.error(error);

        await sleep(3000);

        if (retries < 10) {
            return await createAnonymousUserWithRetries(retries + 1);
        } else {
            console.error(`Maximum retries exceeded in createAnonymousUserWithRetries, throwing error: ${error.message}`);
            Game.UI.ShowErrorScreen("An error occurred while trying to connect to the server. Please try again later.", true);
        }
    }
};

/*******************************************/
/*                                         */
/*     User Type Agnostic Routes           */
/*                                         */
/*******************************************/

interface MaintenanceStatusResult {
    isInMaintenanceMode: boolean;
}

const checkMaintenanceModeRequest = async (): Promise<MaintenanceStatusResult> => {
    const url = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.maintenanceStatus}`;

    // console.log("hitting url:", url);

    const response = await getRequest(url);

    const res = response as MaintenanceStatusResult;

    return res;
};

export const checkMaintenanceModeRequestWithRetries = async (retries: number = 0): Promise<MaintenanceStatusResult> => {
    try {
        return await checkMaintenanceModeRequest();
    } catch (error) {
        // console.error(`Caught error in checkMaintenanceModeRequestWithRetries, retrying... but here's the error: ${error.message}`);
        // console.error(error);

        await sleep(3000);

        if (retries < 5) {
            return await checkMaintenanceModeRequestWithRetries(retries + 1);
        } else {
            console.error(`Maximum retries exceeded in checkMaintenanceModeRequestWithRetries, throwing error: ${error.message}`);
            return {
                isInMaintenanceMode: true
            };
        }
    }
};

interface MatchmakeResult {
    server: string;
}

const matchmakeRequest = async (id: uuid, gameVersion: string): Promise<MatchmakeResult> => {
    const matchmakingUrl = `${process.env.BACKEND_API_URL}${ClientAPIRoutes.matchmake}?anonymousUserId=${id}&gameVersion=${gameVersion}`;
    console.info("Matchmaking URL:", matchmakingUrl);
    const response = await getRequest(matchmakingUrl);
    return response as MatchmakeResult;
};

export const matchmakeRequestWithRetries = async (userId: string, gameVersion: string, retries: number = 0): Promise<MatchmakeResult | undefined> => {
    try {
        return await matchmakeRequest(userId, gameVersion);
    } catch (error) {
        // console.error(`Caught error in matchmakeRequestWithRetries, retrying... but here's the error: ${error.message}`);
        // console.error(error);

        await sleep(1000);

        if (retries < 5) {
            return await matchmakeRequestWithRetries(userId, gameVersion, retries + 1);
        } else {
            console.error(`Maximum retries exceeded in matchmakeRequestWithRetries, throwing error: ${error.message}`);
            Game.UI.ShowErrorScreen("An error occurred while trying to connect to the server. Please try again later.", true);
        }
    }
};
