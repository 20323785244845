"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PredictionErrorProperty = void 0;
class PredictionErrorProperty {
    constructor(nid, prop, predictedValue, actualValue) {
        this.nid = nid;
        this.prop = prop;
        this.predictedValue = predictedValue;
        this.actualValue = actualValue;
    }
}
exports.PredictionErrorProperty = PredictionErrorProperty;
