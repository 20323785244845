import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { ClientPlayer } from "../entities/ClientPlayer";
import { IdentityMessage } from "../../shared/SharedNetcodeSchemas";
import { Config } from "../../shared/Config";
import Stats from "stats.js";
import { PerspectiveCamera, Object3D, Vector3, WebGLRenderer, Scene, AmbientLight, DirectionalLight, MeshBasicMaterial, Group, Mesh, BoxGeometry, MeshStandardMaterial, BufferGeometry, PlaneGeometry, DoubleSide, Euler, LinearFilter, SRGBColorSpace } from "three";
import { clamp } from "lodash";
import HeightMarkers from "../../shared/data/HeightMarkers.json";
import { ClientPlayerPredicted } from "../entities/ClientPlayerPredicted";
import { degToRad, lerp } from "three/src/math/MathUtils";
// @ts-ignore
import { Text } from "troika-three-text";
import System, { Emitter, Rate, SpriteRenderer } from "three-nebula";
import * as THREE from "three";
import { getCountryCodeFromCountry } from "../ui/PlayerLabel";
import { t } from "../../shared/data/Data_I18N";

function truncateString(inputString: string, limit: number): string {
    if (inputString.length <= limit) {
        return inputString; // No need to truncate
    } else {
        return inputString.substring(0, limit) + "...";
    }
}

class ThirdPersonCamera {
    private threeCamera: PerspectiveCamera;
    private target: Object3D;
    private currentPosition = new Vector3();
    private currentlyLookingAt = new Vector3();
    public dist = Config.Camera.STARTING_DISTANCE; // @sam we are currently using this instead of the horizontal offset from config.
    private lateralAngle = 0;
    private verticalAngle = Config.Camera.STARTING_ANGLE * (Math.PI / 180);

    public constructor(camera: PerspectiveCamera) {
        this.threeCamera = camera;
        this.target = new Object3D();
    }

    private yOffsetMultiplier = 1.5;

    public UpdateCameraAngles(mouseMovementX: number, mouseMovementY: number) {
        const { MOUSE_SENSITIVITY, PER_FRAME_MOVEMENT_CAP } = Config.Camera;

        this.verticalAngle += clamp(mouseMovementY * MOUSE_SENSITIVITY * this.yOffsetMultiplier, -PER_FRAME_MOVEMENT_CAP, PER_FRAME_MOVEMENT_CAP);
        this.verticalAngle = clamp(this.verticalAngle, degToRad(-40), degToRad(80));

        this.lateralAngle += clamp(mouseMovementX * MOUSE_SENSITIVITY, -PER_FRAME_MOVEMENT_CAP, PER_FRAME_MOVEMENT_CAP);
        while (this.lateralAngle >= Math.PI * 2) this.lateralAngle -= Math.PI * 2;
        while (this.lateralAngle < 0) this.lateralAngle += Math.PI * 2;
    }

    public UpdateCameraPosition() {
        const direction = new Vector3();
        direction.x = Math.cos(this.lateralAngle - Math.PI / 2) * Math.cos(this.verticalAngle);
        direction.y = Math.sin(this.verticalAngle);
        direction.z = Math.sin(this.lateralAngle - Math.PI / 2) * Math.cos(this.verticalAngle);

        let distance = this.dist;
        if (Config.Camera.RAYCAST_CAMERA) {
            const raycastResult = Game.Collision.CheckRayCollisions(this.target.position, direction);
            if (raycastResult.hit) {
                distance = clamp((raycastResult.hitPoint as Vector3).sub(this.target.position).length() - 1, 1, this.dist);
            }
        }

        this.currentPosition.x = this.target.position.x + direction.x * distance;
        this.currentPosition.y = this.target.position.y + direction.y * distance;
        this.currentPosition.z = this.target.position.z + direction.z * distance;
    }

    public UpdateTarget(newTarget: Object3D) {
        this.target.clear();
        this.target.removeFromParent();
        this.target = newTarget;
    }

    public GetCameraAngle(): { lateral: number; vertical: number } {
        return { lateral: this.lateralAngle, vertical: this.verticalAngle };
    }

    public SetCameraAngle(lateral: number, vertical: number) {
        this.lateralAngle = lateral;
        this.verticalAngle = vertical;
    }

    public Update(__deltaTimeS: number) {
        const idealLookAt = new Vector3(this.target.position.x, this.target.position.y, this.target.position.z);

        this.UpdateCameraPosition();
        this.currentlyLookingAt.copy(idealLookAt);

        this.threeCamera.position.copy(this.currentPosition);
        this.threeCamera.lookAt(this.currentlyLookingAt);
    }
}

class TutorialObj {
    public pos: Vector3;
    public rot: Vector3;
    public texts: Text[];
    public images: Mesh[];
}

export class ClientRenderingSystem extends GameplaySystem {
    public MyLocalPredictedEntity: ClientPlayerPredicted;
    public MyLocalNonPredictedEntityNid: NetworkEntityId;
    public MyLocalNonPredictedEntity: ClientPlayer;
    public MyLocalEntityHasInformationPassive: boolean = false;

    private cameraInitialized: boolean = false;
    private cameraFollowingPlayerCharacter: boolean = false;

    private stats: Stats;

    public Canvas: HTMLCanvasElement;
    private renderer: WebGLRenderer;
    private scene: Scene;

    private threeCamera: PerspectiveCamera;
    private thirdPersonCamera: ThirdPersonCamera;

    private skyboxModel: Group;
    private globalIlluminationLight: AmbientLight;
    private directionalLight: DirectionalLight;

    private touchStartX = 0;
    private touchStartY = 0;
    private currentlyTouchDragging: boolean = false;

    public PlayerRotationProxyCube: Mesh;

    private defaultTextSize = 1.2;
    private leaderboardBigTextSize = 3.8;
    private fontLocation = "fonts/LuckiestGuy-Regular.ttf";

    private particleSystem: System;
    private particleEmitter: Emitter;
    private particleTrailRate: Rate;

    public leaderBoard: TutorialObj[] = [];
    private leaderBoardLength = 10;
    private leaderBoardPosition = new Vector3(117.5, 18.7, -36.4);
    private leaderBoardRotationAsMultipleOfPI = 1.5;
    private leaderBoardVerticalOffset = 2;
    private leaderBoardFlagHorizontalSize = 1.3;
    private leaderBoardFlagVerticalSize = 0.975;

    private globalXOffset: number = 0;
    private globalYOffset: number = 0;

    private _drawGlobalStats(totalPlayers: number, totalCountries: number, totalDeaths: number, totalCompletions: number, totalHoursPlayed: number) {
        // total # of players
        const totalPlayersValue = new Text();
        totalPlayersValue.textAlign = "center";
        totalPlayersValue.position.set(98, 18, 1);
        totalPlayersValue.rotation.set(0, Math.PI, 0);
        totalPlayersValue.outlineOffsetX = 0.01;
        totalPlayersValue.outlineOffsetY = 0.01;
        totalPlayersValue.font = this.fontLocation;
        totalPlayersValue.text = totalPlayers.toLocaleString();
        totalPlayersValue.fontSize = this.leaderboardBigTextSize;
        totalPlayersValue.color = 0xffffff;
        this.scene.add(totalPlayersValue);
        const totalPlayersLabel = new Text();
        totalPlayersLabel.textAlign = "center";
        totalPlayersLabel.position.set(92.5, 14.7, 1);
        totalPlayersLabel.rotation.set(0, Math.PI, 0);
        totalPlayersLabel.outlineOffsetX = 0.01;
        totalPlayersLabel.outlineOffsetY = 0.01;
        totalPlayersLabel.font = this.fontLocation;
        totalPlayersLabel.text = t("leaderboard__players");
        totalPlayersLabel.fontSize = this.defaultTextSize;
        totalPlayersLabel.color = 0xffffff;
        this.scene.add(totalPlayersLabel);

        // # of countries
        const totalCountriesValue = new Text();
        totalCountriesValue.textAlign = "center";
        totalCountriesValue.position.set(99, 12, 1);
        totalCountriesValue.rotation.set(0, Math.PI, 0);
        totalCountriesValue.outlineOffsetX = 0.01;
        totalCountriesValue.outlineOffsetY = 0.01;
        totalCountriesValue.font = this.fontLocation;
        totalCountriesValue.text = totalCountries.toLocaleString();
        totalCountriesValue.fontSize = this.leaderboardBigTextSize * 0.75;
        totalCountriesValue.color = 0xffffff;
        this.scene.add(totalCountriesValue);
        const totalCountriesLabel = new Text();
        totalCountriesLabel.position.set(100.2, 9.5, 1);
        totalCountriesLabel.rotation.set(0, Math.PI, 0);
        totalCountriesLabel.outlineOffsetX = 0.01;
        totalCountriesLabel.outlineOffsetY = 0.01;
        totalCountriesLabel.font = this.fontLocation;
        totalCountriesLabel.text = t("leaderboard__countries");
        totalCountriesLabel.fontSize = this.defaultTextSize;
        totalCountriesLabel.color = 0xffffff;
        this.scene.add(totalCountriesLabel);

        // total # of deaths
        const totalFallsValue = new Text();
        totalFallsValue.textAlign = "center";
        totalFallsValue.position.set(90.8, 12, 1);
        totalFallsValue.rotation.set(0, Math.PI, 0);
        totalFallsValue.outlineOffsetX = 0.01;
        totalFallsValue.outlineOffsetY = 0.01;
        totalFallsValue.font = this.fontLocation;
        totalFallsValue.text = totalDeaths.toLocaleString();
        totalFallsValue.fontSize = this.leaderboardBigTextSize * 0.75;
        totalFallsValue.color = 0xffffff;
        this.scene.add(totalFallsValue);
        const totalFallsLabel = new Text();
        totalFallsLabel.textAlign = "center";
        totalFallsLabel.position.set(86.2, 9.5, 1);
        totalFallsLabel.rotation.set(0, Math.PI, 0);
        totalFallsLabel.outlineOffsetX = 0.01;
        totalFallsLabel.outlineOffsetY = 0.01;
        totalFallsLabel.font = this.fontLocation;
        totalFallsLabel.text = t("leaderboard__falls");
        totalFallsLabel.fontSize = this.defaultTextSize;
        totalFallsLabel.color = 0xffffff;
        this.scene.add(totalFallsLabel);

        // total # of completions
        const totalCompletionsValue = new Text();
        totalCompletionsValue.textAlign = "center";
        totalCompletionsValue.position.set(88.3, 6, 1);
        totalCompletionsValue.rotation.set(0, Math.PI, 0);
        totalCompletionsValue.outlineOffsetX = 0.01;
        totalCompletionsValue.outlineOffsetY = 0.01;
        totalCompletionsValue.font = this.fontLocation;
        totalCompletionsValue.text = totalCompletions.toLocaleString();
        totalCompletionsValue.fontSize = this.leaderboardBigTextSize * 0.75;
        totalCompletionsValue.color = 0xffffff;
        this.scene.add(totalCompletionsValue);
        const totalCompletionsLabel = new Text();
        totalCompletionsLabel.textAlign = "center";
        totalCompletionsLabel.position.set(88.3, 3.5, 1);
        totalCompletionsLabel.rotation.set(0, Math.PI, 0);
        totalCompletionsLabel.outlineOffsetX = 0.01;
        totalCompletionsLabel.outlineOffsetY = 0.01;
        totalCompletionsLabel.font = this.fontLocation;
        totalCompletionsLabel.text = t("leaderboard__got_to_top");
        totalCompletionsLabel.fontSize = this.defaultTextSize;
        totalCompletionsLabel.color = 0xffffff;
        this.scene.add(totalCompletionsLabel);

        // # of time played
        const totalTimePlayedValue = new Text();
        totalTimePlayedValue.textAlign = "center";
        totalTimePlayedValue.position.set(101.8, 6, 1);
        totalTimePlayedValue.rotation.set(0, Math.PI, 0);
        totalTimePlayedValue.outlineOffsetX = 0.01;
        totalTimePlayedValue.outlineOffsetY = 0.01;
        totalTimePlayedValue.font = this.fontLocation;
        totalTimePlayedValue.text = totalHoursPlayed.toLocaleString();
        totalTimePlayedValue.fontSize = this.leaderboardBigTextSize * 0.75;
        totalTimePlayedValue.color = 0xffffff;
        this.scene.add(totalTimePlayedValue);
        const totalTimePlayedLabel = new Text();
        totalTimePlayedLabel.position.set(101.2, 3.5, 1);
        totalTimePlayedLabel.rotation.set(0, Math.PI, 0);
        totalTimePlayedLabel.outlineOffsetX = 0.01;
        totalTimePlayedLabel.outlineOffsetY = 0.01;
        totalTimePlayedLabel.font = this.fontLocation;
        totalTimePlayedLabel.text = t("leaderboard__hours_played");
        totalTimePlayedLabel.fontSize = this.defaultTextSize;
        totalTimePlayedLabel.color = 0xffffff;
        this.scene.add(totalTimePlayedLabel);
    }

    private _drawPersonalStats(pb: string, globalRank: string, countryRank: string, deaths: string, runs: string, timePlayed: string, country: number) {
        // your best run
        const bestRunValue = new Text();
        bestRunValue.textAlign = "center";
        const xPos1 = pb === "None yet!" ? 82 : 82.5;
        bestRunValue.position.set(xPos1, 18, -52);
        bestRunValue.outlineOffsetX = 0.01;
        bestRunValue.outlineOffsetY = 0.01;
        bestRunValue.font = this.fontLocation;
        bestRunValue.text = pb;
        bestRunValue.fontSize = this.leaderboardBigTextSize;
        bestRunValue.color = 0xffffff;
        this.scene.add(bestRunValue);
        const bestRunLabel = new Text();
        bestRunLabel.textAlign = "center";
        bestRunLabel.position.set(86.5, 15, -52);
        bestRunLabel.outlineOffsetX = 0.01;
        bestRunLabel.outlineOffsetY = 0.01;
        bestRunLabel.font = this.fontLocation;
        bestRunLabel.text = t("leaderboard__personal_best");
        bestRunLabel.fontSize = this.defaultTextSize;
        bestRunLabel.color = 0xffffff;
        this.scene.add(bestRunLabel);

        // your global rank
        const yourGlobalRankValue = new Text();
        yourGlobalRankValue.textAlign = "center";
        const xPos2 = globalRank === "N/A" ? 83 : 84;
        yourGlobalRankValue.position.set(xPos2, 13, -52);
        yourGlobalRankValue.outlineOffsetX = 0.01;
        yourGlobalRankValue.outlineOffsetY = 0.01;
        yourGlobalRankValue.font = this.fontLocation;
        yourGlobalRankValue.text = globalRank;
        yourGlobalRankValue.fontSize = this.leaderboardBigTextSize;
        yourGlobalRankValue.color = 0xffffff;
        this.scene.add(yourGlobalRankValue);
        const yourGlobalRankLabel = new Text();
        yourGlobalRankLabel.textAlign = "center";
        if (globalThis.Lang === "nl" || globalThis.Lang === "pt" || globalThis.Lang === "fr") {
            yourGlobalRankLabel.position.set(82.6 + 3 * this.globalXOffset, 10, -52);
        } else {
            yourGlobalRankLabel.position.set(82.6, 10, -52);
        }
        yourGlobalRankLabel.outlineOffsetX = 0.01;
        yourGlobalRankLabel.outlineOffsetY = 0.01;
        yourGlobalRankLabel.font = this.fontLocation;
        yourGlobalRankLabel.text = t("leaderboard__global_rank");
        yourGlobalRankLabel.fontSize = this.defaultTextSize;
        yourGlobalRankLabel.color = 0xffffff;
        this.scene.add(yourGlobalRankLabel);

        // your country rank
        const yourCountryRankValue = new Text();
        yourCountryRankValue.textAlign = "center";
        const xPos3 = countryRank === "N/A" ? 93 : 94;
        yourCountryRankValue.position.set(xPos3, 13, -52);
        yourCountryRankValue.outlineOffsetX = 0.01;
        yourCountryRankValue.outlineOffsetY = 0.01;
        yourCountryRankValue.font = this.fontLocation;
        yourCountryRankValue.text = countryRank;
        yourCountryRankValue.fontSize = this.leaderboardBigTextSize;
        yourCountryRankValue.color = 0xffffff;
        this.scene.add(yourCountryRankValue);
        const yourCountryRankLabel = new Text();
        yourCountryRankLabel.textAlign = "center";
        yourCountryRankLabel.position.set(95.6, 10, -52);
        yourCountryRankLabel.outlineOffsetX = 0.01;
        yourCountryRankLabel.outlineOffsetY = 0.01;
        yourCountryRankLabel.font = this.fontLocation;
        yourCountryRankLabel.text = t("leaderboard__rank");
        yourCountryRankLabel.fontSize = this.defaultTextSize;
        yourCountryRankLabel.color = 0xffffff;
        this.scene.add(yourCountryRankLabel);
        const usersCountryFlagTexture = Game.Loader.TextureLoader.load("ui/flags/" + getCountryCodeFromCountry(country) + ".png");
        usersCountryFlagTexture.colorSpace = SRGBColorSpace;
        const usersCountryFlagGeo = new PlaneGeometry(this.leaderBoardFlagHorizontalSize * 1.5, this.leaderBoardFlagVerticalSize * 1.5);
        const usersCountryMaterial = new MeshBasicMaterial({ map: usersCountryFlagTexture, transparent: true, alphaTest: 0.5 });
        const usersCountryMesh = new Mesh(usersCountryFlagGeo, usersCountryMaterial);
        usersCountryMesh.position.set(94.2, 9.5, -52);
        this.scene.add(usersCountryMesh);

        // your # of falls
        const yourFallsValue = new Text();
        yourFallsValue.textAlign = "center";
        const xPos4 = deaths === "0" ? 80.4 : 78.3;
        yourFallsValue.position.set(xPos4, 6, -52);
        yourFallsValue.outlineOffsetX = 0.01;
        yourFallsValue.outlineOffsetY = 0.01;
        yourFallsValue.font = this.fontLocation;
        yourFallsValue.text = deaths;
        yourFallsValue.fontSize = this.leaderboardBigTextSize * 0.75;
        yourFallsValue.color = 0xffffff;
        this.scene.add(yourFallsValue);
        const yourFallsLabel = new Text();
        yourFallsLabel.textAlign = "center";
        yourFallsLabel.position.set(79.2, 3.3, -52);
        yourFallsLabel.outlineOffsetX = 0.01;
        yourFallsLabel.outlineOffsetY = 0.01;
        yourFallsLabel.font = this.fontLocation;
        yourFallsLabel.text = t("leaderboard__falls");
        yourFallsLabel.fontSize = this.defaultTextSize;
        yourFallsLabel.color = 0xffffff;
        this.scene.add(yourFallsLabel);

        // your completions
        const yourCompletionsValue = new Text();
        yourCompletionsValue.textAlign = "center";
        const xPos5 = runs === "0" ? 86.6 : 86.3;
        yourCompletionsValue.position.set(xPos5, 6, -52);
        yourCompletionsValue.outlineOffsetX = 0.01;
        yourCompletionsValue.outlineOffsetY = 0.01;
        yourCompletionsValue.font = this.fontLocation;
        yourCompletionsValue.text = runs;
        yourCompletionsValue.fontSize = this.leaderboardBigTextSize * 0.75;
        yourCompletionsValue.color = 0xffffff;
        this.scene.add(yourCompletionsValue);
        const yourCompletionsLabel = new Text();
        yourCompletionsLabel.textAlign = "center";
        yourCompletionsLabel.position.set(86.2, 3.3, -52);
        yourCompletionsLabel.outlineOffsetX = 0.01;
        yourCompletionsLabel.outlineOffsetY = 0.01;
        yourCompletionsLabel.font = this.fontLocation;
        yourCompletionsLabel.text = t("leaderboard__runs");
        yourCompletionsLabel.fontSize = this.defaultTextSize;
        yourCompletionsLabel.color = 0xffffff;
        this.scene.add(yourCompletionsLabel);

        // your time played
        const yourTimePlayedValue = new Text();
        yourTimePlayedValue.textAlign = "center";
        yourTimePlayedValue.position.set(91.3, 6, -52);
        yourTimePlayedValue.outlineOffsetX = 0.01;
        yourTimePlayedValue.outlineOffsetY = 0.01;
        yourTimePlayedValue.font = this.fontLocation;
        yourTimePlayedValue.text = timePlayed;
        yourTimePlayedValue.fontSize = this.leaderboardBigTextSize * 0.75;
        yourTimePlayedValue.color = 0xffffff;
        this.scene.add(yourTimePlayedValue);
        const yourTimePlayedLabel = new Text();
        yourTimePlayedLabel.textAlign = "center";
        yourTimePlayedLabel.position.set(94.2, 3.3, -52);
        yourTimePlayedLabel.outlineOffsetX = 0.01;
        yourTimePlayedLabel.outlineOffsetY = 0.01;
        yourTimePlayedLabel.font = this.fontLocation;
        yourTimePlayedLabel.text = t("leaderboard__time_played");
        yourTimePlayedLabel.fontSize = this.defaultTextSize;
        yourTimePlayedLabel.color = 0xffffff;
        this.scene.add(yourTimePlayedLabel);
    }

    private _drawLeaderboard() {
        const textureLoader = Game.Loader.TextureLoader;
        const localOffset = new Vector3();
        const objWorldSpacePos = new Vector3();
        for (let i = 0; i < this.leaderBoardLength; i++) {
            // create a tutorialObj that holds this row of data
            const thisLeaderboardEntry = new TutorialObj();
            thisLeaderboardEntry.pos = new Vector3(this.leaderBoardPosition.x, this.leaderBoardPosition.y - i * this.leaderBoardVerticalOffset, this.leaderBoardPosition.z);
            thisLeaderboardEntry.rot = new Vector3(0, Math.PI * this.leaderBoardRotationAsMultipleOfPI, 0);
            thisLeaderboardEntry.images = [];
            thisLeaderboardEntry.texts = [];

            // create a text object for the row #
            const leaderBoardNumberText = new Text();
            leaderBoardNumberText.outlineOffsetX = 0.01;
            leaderBoardNumberText.outlineOffsetY = 0.01;
            leaderBoardNumberText.font = this.fontLocation;
            leaderBoardNumberText.text = (i + 1).toString();
            leaderBoardNumberText.fontSize = this.defaultTextSize;

            localOffset.set(0, 0.45, 0);
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, thisLeaderboardEntry));
            leaderBoardNumberText.position.copy(objWorldSpacePos);
            leaderBoardNumberText.rotation.set(thisLeaderboardEntry.rot.x, thisLeaderboardEntry.rot.y, thisLeaderboardEntry.rot.z);
            leaderBoardNumberText.color = 0xffffff;
            thisLeaderboardEntry.texts.push(leaderBoardNumberText);

            // create an image that will hold the flag for this row
            const thisLeaderBoardTexture = textureLoader.load("ui/flags/none.png");
            thisLeaderBoardTexture.colorSpace = SRGBColorSpace;
            const thisLeaderBoardGeometry = new PlaneGeometry(this.leaderBoardFlagHorizontalSize, this.leaderBoardFlagVerticalSize); // Adjust the size as needed
            const thisLeaderBoardMaterial = new MeshBasicMaterial({ map: thisLeaderBoardTexture, transparent: true, alphaTest: 0.5 });
            const thisLeaderBoardImg = new Mesh(thisLeaderBoardGeometry, thisLeaderBoardMaterial);
            localOffset.set(2.1, 0, 0);
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, thisLeaderboardEntry));
            thisLeaderBoardImg.position.copy(objWorldSpacePos);
            thisLeaderBoardImg.rotation.set(thisLeaderboardEntry.rot.x, thisLeaderboardEntry.rot.y, thisLeaderboardEntry.rot.z);
            thisLeaderboardEntry.images.push(thisLeaderBoardImg);

            // create a text object for the row's player name
            const leaderBoardNameText = new Text();
            leaderBoardNameText.outlineOffsetX = 0.01;
            leaderBoardNameText.outlineOffsetY = 0.01;
            leaderBoardNameText.font = this.fontLocation;

            leaderBoardNameText.text = "Loading...";

            leaderBoardNameText.fontSize = this.defaultTextSize;

            localOffset.set(3.2, 0.45, 0);
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, thisLeaderboardEntry));
            leaderBoardNameText.position.copy(objWorldSpacePos);
            leaderBoardNameText.rotation.set(thisLeaderboardEntry.rot.x, thisLeaderboardEntry.rot.y, thisLeaderboardEntry.rot.z);
            leaderBoardNameText.color = 0xffffff;
            thisLeaderboardEntry.texts.push(leaderBoardNameText);

            // create a text object for the row's time (score)
            const leaderBoardTimeText = new Text();
            leaderBoardTimeText.outlineOffsetX = 0.01;
            leaderBoardTimeText.outlineOffsetY = 0.01;
            leaderBoardTimeText.font = this.fontLocation;
            leaderBoardTimeText.textAlign = "right";
            leaderBoardTimeText.anchorX = "right";

            leaderBoardTimeText.text = "99:99.999";

            leaderBoardTimeText.fontSize = this.defaultTextSize;

            localOffset.set(26.25, 0.45, 0);
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, thisLeaderboardEntry));
            leaderBoardTimeText.position.copy(objWorldSpacePos);
            leaderBoardTimeText.rotation.set(thisLeaderboardEntry.rot.x, thisLeaderboardEntry.rot.y, thisLeaderboardEntry.rot.z);
            leaderBoardTimeText.color = 0xffffff;
            thisLeaderboardEntry.texts.push(leaderBoardTimeText);

            this.leaderBoard.push(thisLeaderboardEntry);

            this.scene.add(thisLeaderBoardImg);
            this.scene.add(leaderBoardNumberText);
            this.scene.add(leaderBoardNameText);
            this.scene.add(leaderBoardTimeText);
        }
    }

    public UpdateLeaderboard(leaderboardString: string) {
        try {
            const parsed = JSON.parse(leaderboardString);

            // console.log("@@@ Parsed leaderboard from server:", parsed);

            const { topTen, globalStats } = parsed;

            for (let i = 0; i < topTen.length; i++) {
                const { username, durationString, country } = topTen[i];

                const thisLeaderboardEntry = this.leaderBoard[i];

                if (thisLeaderboardEntry === undefined) {
                    // console.error("Leaderboard entry undefined at index:", i);
                    continue;
                }

                // Update the flag
                const currentImage = thisLeaderboardEntry.images[0];
                if (currentImage === undefined) {
                    console.error("Leaderboard image undefined at index:", i);
                    continue;
                }
                const newTexture = Game.Loader.TextureLoader.load("ui/flags/" + getCountryCodeFromCountry(country) + ".png");

                // @ts-ignore
                currentImage.material.map = newTexture;
                // @ts-ignore
                currentImage.material.needsUpdate = true;

                // Update the name text
                const currentNameText = thisLeaderboardEntry.texts[1];
                if (currentNameText === undefined) {
                    console.error("Leaderboard name text undefined at index:", i);
                    continue;
                }
                currentNameText.text = username;

                // Update the time text
                const currentTimeText = thisLeaderboardEntry.texts[2];
                if (currentTimeText === undefined) {
                    console.error("Leaderboard time text undefined at index:", i);
                    continue;
                }
                currentTimeText.text = durationString;
            }

            this._drawGlobalStats(globalStats.totalPlayers, globalStats.totalCountries, globalStats.totalDeaths, globalStats.totalCompletions, globalStats.totalHoursPlayed);
        } catch (e) {
            console.error("Caught error parsing leaderboard from server!", e, leaderboardString);
        }
    }

    private font: object;

    public UpdateLocalEntityRotation(rotX: number, rotY: number, rotZ: number) {
        if (Config.Player.ENABLE_PREDICTION) {
            if (this.MyLocalPredictedEntity !== undefined) {
                this.MyLocalPredictedEntity.Rotation.x = rotX;
                this.MyLocalPredictedEntity.Rotation.y = rotY;
                this.MyLocalPredictedEntity.Rotation.z = rotZ;
            }
        } else {
            if (this.MyLocalNonPredictedEntity !== undefined) {
                this.MyLocalNonPredictedEntity.Rotation.x = rotX;
                this.MyLocalNonPredictedEntity.Rotation.y = rotY;
                this.MyLocalNonPredictedEntity.Rotation.z = rotZ;
            }
        }
    }

    private _addDebugCube(startX: number, startY: number, startZ: number): void {
        const geometry = new BoxGeometry(1, 1, 1);
        const material = new MeshStandardMaterial({ color: 0xff00ff, wireframe: true });
        this.PlayerRotationProxyCube = new Mesh(geometry, material);
        this.PlayerRotationProxyCube.position.set(startX, startY, startZ);

        if (Config.Camera.DRAW_CAMERA_TARGET_CUBE) {
            Game.Renderer.AddModelToScene(this.PlayerRotationProxyCube);
        }
    }

    public constructor() {
        super();
    }

    public CountSceneVerts(): void {
        let totalVertices = 0;

        this.scene.traverse((object) => {
            if (object instanceof Mesh) {
                const geometry = object.geometry;
                if (geometry instanceof BufferGeometry) {
                    // For BufferGeometry, get the number of vertices directly
                    totalVertices += geometry.attributes.position.count;
                } else if (geometry instanceof geometry) {
                    // For Geometry, count the number of vertices in the vertices array
                    totalVertices += geometry.vertices.length;
                }
            }
        });

        console.log(`Total vertices in the scene: ${totalVertices}`);
    }

    public Initialize(): void {
        this._initThreeRenderer();

        if (Config.Rendering.ENABLE_STATS) {
            this._initPerformanceStats();
        }

        if (globalThis.Lang === "en") {
            this.globalXOffset = 0;
            this.globalYOffset = 0;
        } else if (globalThis.Lang === "es") {
            this.globalXOffset = -1;
            this.globalYOffset = 0;
        } else if (globalThis.Lang === "hi") {
            this.globalXOffset = 0;
            this.globalYOffset = 0.5;
        } else if (globalThis.Lang === "zh") {
            this.globalXOffset = 0;
            this.globalYOffset = 0;
        } else if (globalThis.Lang === "ar") {
            this.globalXOffset = 0;
            this.globalYOffset = 1;
        } else if (globalThis.Lang === "pt") {
            this.globalXOffset = -1;
            this.globalYOffset = 0;
        } else if (globalThis.Lang === "ja") {
            this.globalXOffset = -1;
            this.globalYOffset = 0;
        } else if (globalThis.Lang === "tr") {
            this.globalXOffset = -0.7;
            this.globalYOffset = 0;
        } else if (globalThis.Lang === "fr") {
            this.globalXOffset = -1;
            this.globalYOffset = 0;
        } else if (globalThis.Lang === "nl") {
            this.globalXOffset = -1;
            this.globalYOffset = 0;
        } else if (globalThis.Lang === "de") {
            this.globalXOffset = -0.85;
            this.globalYOffset = 0;
        }

        this._light();

        // @ts-ignore
        if (globalThis.IsMobile) {
            this._drawTutorialTextMobile();
        } else {
            this._drawTutorialTextBrowser();
        }
        this._drawHeightMarkers();
        this._drawLeaderboard();

        // @ts-ignore
        Game.ListenForEvent("Identity::Identified", ({ identity }) => {
            // console.info("@@@ CLient Renderer got identity....", identity);
            const { PersonalLeaderboardData } = identity;
            const { pb, globalRank, countryRank, deaths, runs, timePlayed } = PersonalLeaderboardData;
            this._drawPersonalStats(pb, globalRank, countryRank, deaths, runs, timePlayed, identity.UpTogether!.country);
        });
        Game.ListenForEvent("Resizer::RealtimeGameWindowResize", this.Resize.bind(this));
        Game.ListenForEvent("Resizer::GameWindowResizeComplete", this.Resize.bind(this));
        Game.ListenForEvent("Bootstrap::MyEntitiesNidIsKnown", (event: IdentityMessage) => {
            const { myId } = event;
            this.MyLocalNonPredictedEntityNid = myId;
        });

        if (!globalThis.IsMobile) {
            document.addEventListener("mousemove", (event: MouseEvent) => {
                if (this.thirdPersonCamera && Game.UI.PointerIsCurrentlyLocked()) {
                    this._rotateEmptyBasedOnMouseMovement(event.movementX, event.movementY);
                    this.thirdPersonCamera.UpdateCameraAngles(event.movementX, event.movementY);
                    this._updatePlayerRotationProxyCubePositionBasedOnMouseMovement(event.movementX, event.movementY);
                }
            });
        }

        window.addEventListener("wheel", (event) => {
            if (Game.UI.UIScreenIsOpen()) return;

            event.preventDefault();

            this.thirdPersonCamera.dist += event.deltaY * Config.Camera.ZOOM_SENSITIVITY;
            this.thirdPersonCamera.dist = clamp(this.thirdPersonCamera.dist, Config.Camera.MIN_DISTANCE, Config.Camera.MAX_DISTANCE);
        });

        if (globalThis.IsMobile) {
            this.renderer.domElement.addEventListener("touchstart", (event: TouchEvent) => {
                event.preventDefault();
                this.touchStartX = event.targetTouches[0].clientX;
                this.touchStartY = event.targetTouches[0].clientY;
                this.currentlyTouchDragging = true;
            });

            this.renderer.domElement.addEventListener("touchmove", (event: TouchEvent) => {
                event.preventDefault();
                const movementX = (event.targetTouches[0].clientX - this.touchStartX) * Config.Camera.MOBILE_CAMERA_ROTATION_SENSITIVITY.HORIZONTAL;
                const movementY = (event.targetTouches[0].clientY - this.touchStartY) * Config.Camera.MOBILE_CAMERA_ROTATION_SENSITIVITY.VERTICAL;

                this.touchStartX = event.targetTouches[0].clientX;
                this.touchStartY = event.targetTouches[0].clientY;

                if (this.currentlyTouchDragging === false) return;

                if (this.thirdPersonCamera) {
                    this._rotateEmptyBasedOnMouseMovement(movementX, movementY);
                    this.thirdPersonCamera.UpdateCameraAngles(movementX, movementY);
                    this._updatePlayerRotationProxyCubePositionBasedOnMouseMovement(movementX, movementY);
                }
            });

            this.renderer.domElement.addEventListener("touchend", () => {
                this.currentlyTouchDragging = false;
            });
        }

        Game.EmitEvent("LoadEvent", { eventName: "RendererReady" });

        this.particleSystem = new System();
        const renderer = new SpriteRenderer(this.scene, THREE);

        this.particleSystem.addRenderer(renderer).emit({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            onStart: () => {},
            onUpdate: () => {},
            onEnd: () => {}
        });
    }

    public GetCameraAngle(): { lateral: number; vertical: number } {
        return this.thirdPersonCamera.GetCameraAngle();
    }

    public SetCameraAngle(lateral: number, vertical: number) {
        this.thirdPersonCamera.SetCameraAngle(lateral, vertical);
    }

    public AddParticleEmitter(emitter: Emitter) {
        this.particleSystem.addEmitter(emitter);
    }

    public RemoveParticleEmitter(emitter: Emitter) {
        this.particleSystem.removeEmitter(emitter);
    }

    private _getPosLocalToTutorialObj(myLocalOffset: Vector3, tutorial: TutorialObj): Vector3 {
        // Convert degrees to radians (if needed)
        //const euler = tutorial.rot.clone().multiplyScalar(Math.PI / 180);

        // Apply the local offset to the position relative to tutorial.pos
        myLocalOffset.applyEuler(new Euler(tutorial.rot.x, tutorial.rot.y, tutorial.rot.z, "XYZ"));
        const finalPosition = myLocalOffset.clone().add(tutorial.pos);

        return finalPosition;
    }

    private _drawHeightMarkers() {
        const heightMarkers = HeightMarkers.HeightMarkers;

        for (const marker of heightMarkers) {
            // Access properties of each marker
            const isNumber = marker.isNumber;
            const position = marker.pos;
            const rotationAsMultOfPi = marker.rotationAsMultOfPi;
            const stringIfNAN = marker.stringIfNAN;

            if (isNumber) {
                const thisMarkerText = new Text();
                thisMarkerText.font = this.fontLocation;
                thisMarkerText.outlineOffsetX = 0.01;
                thisMarkerText.outlineOffsetY = 0.01;
                const yPosTruncated = Math.trunc(position.y);
                thisMarkerText.text = yPosTruncated.toString() + "m";
                thisMarkerText.fontSize = this.defaultTextSize;
                thisMarkerText.position.copy(new Vector3(-position.x, position.y, position.z));
                thisMarkerText.rotation.set(0, Math.PI * rotationAsMultOfPi, 0);
                thisMarkerText.color = 0xffffff;
                this.scene.add(thisMarkerText);
            } else {
                const thisMarkerText = new Text();
                thisMarkerText.font = this.fontLocation;
                thisMarkerText.outlineOffsetX = 0.01;
                thisMarkerText.outlineOffsetY = 0.01;
                thisMarkerText.text = t(stringIfNAN);
                thisMarkerText.fontSize = this.defaultTextSize;
                thisMarkerText.position.copy(new Vector3(-position.x, position.y, position.z));
                thisMarkerText.rotation.set(0, Math.PI * rotationAsMultOfPi, 0);
                thisMarkerText.color = 0xffffff;
                this.scene.add(thisMarkerText);
            }
        }
    }

    private _drawTutorialTextBrowser() {
        const localOffset = new Vector3(0, 0, 0);
        const objWorldSpacePos = new Vector3(0, 0, 0);

        const uiLocation = "ui/tutorial/";
        const allTutorialObjs: TutorialObj[] = [];
        const textureLoader = Game.Loader.TextureLoader;

        // walk tutorial
        const tutorialWalk = new TutorialObj();
        tutorialWalk.pos = new Vector3(-4, 2, -94.2);
        tutorialWalk.rot = new Vector3(0, Math.PI, 0);
        tutorialWalk.images = [];
        tutorialWalk.texts = [];

        // WASD Image
        const wasdTexture = textureLoader.load(uiLocation + "wasd.png");
        wasdTexture.colorSpace = SRGBColorSpace;

        const wasdGeometry = new PlaneGeometry(4, 2.8); // Adjust the size as needed
        const wasdMaterial = new MeshBasicMaterial({ map: wasdTexture, transparent: true, alphaTest: 0.5, side: DoubleSide });
        const tutorialWASDImg = new Mesh(wasdGeometry, wasdMaterial);
        localOffset.set(-3.5, 0, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialWalk));
        tutorialWASDImg.position.copy(objWorldSpacePos);
        tutorialWASDImg.rotation.set(tutorialWalk.rot.x, tutorialWalk.rot.y, tutorialWalk.rot.z);
        tutorialWalk.images.push(tutorialWASDImg);

        // arrowkeys Image
        const arrowsTexture = textureLoader.load(uiLocation + "arrow_keys.png");
        arrowsTexture.colorSpace = SRGBColorSpace;
        const arrowsGeometry = new PlaneGeometry(4, 2.8); // Adjust the size as needed
        const arrowsMaterial = new MeshBasicMaterial({ map: arrowsTexture, transparent: true, alphaTest: 0.5, side: DoubleSide });
        const tutorialArrowsImage = new Mesh(arrowsGeometry, arrowsMaterial);
        localOffset.set(3.9, 0, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialWalk));
        tutorialArrowsImage.position.copy(objWorldSpacePos);
        tutorialArrowsImage.rotation.set(tutorialWalk.rot.x, tutorialWalk.rot.y, tutorialWalk.rot.z);
        tutorialWalk.images.push(tutorialArrowsImage);

        // slash text
        /*const pressTextWalk = new Text();
        pressTextWalk.font = fontLocation;
        pressTextWalk.text = "/";
        // PRESS text
        const pressTextWalk = new Text();
        pressTextWalk.font = this.fontLocation;
        pressTextWalk.text = "Press";
        pressTextWalk.fontSize = this.defaultTextSize;
        localOffset.set(-6, -0.4, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialWalk));
        pressTextWalk.position.copy(objWorldSpacePos);
        pressTextWalk.rotation.set(tutorialWalk.rot.x, tutorialWalk.rot.y, tutorialWalk.rot.z);
        pressTextWalk.color = 0xffffff;
        tutorialWalk.texts.push(pressTextWalk);*/

        // OR text
        const toWalkText = new Text();
        toWalkText.outlineOffsetX = 0.01;
        toWalkText.outlineOffsetY = 0.01;
        toWalkText.font = this.fontLocation;
        toWalkText.text = t("in_game__tutorial_or");
        toWalkText.fontSize = this.defaultTextSize;
        if (globalThis.Lang === "ja" || globalThis.Lang === "de" || globalThis.Lang === "tr") {
            localOffset.set(-0.7 + this.globalXOffset, -0.25 + this.globalYOffset, 0);
        } else {
            localOffset.set(-0.7, -0.25 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialWalk));
        toWalkText.position.copy(objWorldSpacePos);
        toWalkText.rotation.set(tutorialWalk.rot.x, tutorialWalk.rot.y, tutorialWalk.rot.z);
        toWalkText.color = 0xffffff;
        tutorialWalk.texts.push(toWalkText);

        allTutorialObjs.push(tutorialWalk);

        // jump tutorial
        const tutorialJump = new TutorialObj();
        tutorialJump.pos = new Vector3(0.3, 1, -62);
        tutorialJump.rot = new Vector3(0, Math.PI, 0);
        tutorialJump.images = [];
        tutorialJump.texts = [];

        // SPACE image
        const texture = textureLoader.load(uiLocation + "space_key.png");
        texture.colorSpace = SRGBColorSpace;
        const geometry = new PlaneGeometry(3.263, 1); // Adjust the size as needed
        const material = new MeshBasicMaterial({ map: texture });
        const tutorialJumpSpacebarImg = new Mesh(geometry, material);
        localOffset.set(0, 0, 0);
        if (globalThis.Lang === "tr") {
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(new Vector3(localOffset.x + this.globalXOffset, localOffset.y, localOffset.z), tutorialJump));
        } else {
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialJump));
        }
        tutorialJumpSpacebarImg.position.copy(objWorldSpacePos);
        tutorialJumpSpacebarImg.rotation.set(tutorialJump.rot.x, tutorialJump.rot.y, tutorialJump.rot.z);
        tutorialJump.images.push(tutorialJumpSpacebarImg);

        // "Press" text
        const pressText = new Text();
        pressText.outlineOffsetX = 0.01;
        pressText.outlineOffsetY = 0.01;
        pressText.font = this.fontLocation;
        pressText.text = t("in_game__tutorial_press");
        pressText.fontSize = this.defaultTextSize;
        if (globalThis.Lang === "fr") {
            localOffset.set(-6 + -1, 0.4 + this.globalYOffset, 0);
        } else if (globalThis.Lang === "pt") {
            localOffset.set(-6 + (2 * this.globalXOffset), 0.4 + this.globalYOffset, 0);
        } else if (globalThis.Lang === "ja") {
            localOffset.set(-4, 0.4 + this.globalYOffset, 0);
        } else {
            localOffset.set(-6 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialJump));
        pressText.position.copy(objWorldSpacePos);
        pressText.rotation.set(tutorialJump.rot.x, tutorialJump.rot.y, tutorialJump.rot.z);
        pressText.color = 0xffffff;
        tutorialJump.texts.push(pressText);

        // "To jump" text
        const toJumpText = new Text();
        toJumpText.outlineOffsetX = 0.01;
        toJumpText.outlineOffsetY = 0.01;
        toJumpText.font = this.fontLocation;
        toJumpText.text = t("in_game__tutorial__to_jump");
        toJumpText.fontSize = this.defaultTextSize;

        localOffset.set(2.7 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialJump));
        toJumpText.position.copy(objWorldSpacePos);
        toJumpText.rotation.set(tutorialJump.rot.x, tutorialJump.rot.y, tutorialJump.rot.z);
        toJumpText.color = 0xffffff;
        tutorialJump.texts.push(toJumpText);

        //IMPORTANT ADD OBJECT TO LIST OF TUTORIAL ITEMS
        allTutorialObjs.push(tutorialJump);

        //KEEP JUMPING TUTORIAL
        const tutorialKeepJumping = new TutorialObj();
        tutorialKeepJumping.pos = new Vector3(-26.9, 8.75, 2.5);
        tutorialKeepJumping.rot = new Vector3(0, Math.PI / 2, 0);
        tutorialKeepJumping.images = [];
        tutorialKeepJumping.texts = [];

        //SPACE image
        const spaceKeepJumpingTexture = textureLoader.load(uiLocation + "space_key.png");
        spaceKeepJumpingTexture.colorSpace = SRGBColorSpace;
        const spaceKeepJumpingGeometry = new PlaneGeometry(3.263, 1); // Adjust the size as needed
        const spaceKeepJumpingMaterial = new MeshBasicMaterial({ map: spaceKeepJumpingTexture });
        const tutorialKeepJumpingSpacebarImg = new Mesh(spaceKeepJumpingGeometry, spaceKeepJumpingMaterial);
        localOffset.set(0, 0, 0);
        if (globalThis.Lang === "tr") {
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(new Vector3(localOffset.x + this.globalXOffset, localOffset.y, localOffset.z), tutorialKeepJumping));
        } else {
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialKeepJumping));
        }        tutorialKeepJumpingSpacebarImg.position.copy(objWorldSpacePos);
        tutorialKeepJumpingSpacebarImg.rotation.set(tutorialKeepJumping.rot.x, tutorialKeepJumping.rot.y, tutorialJump.rot.z);
        tutorialKeepJumping.images.push(tutorialKeepJumpingSpacebarImg);

        // HOLD Text
        const holdTextKeepJumping = new Text();
        holdTextKeepJumping.outlineOffsetX = 0.01;
        holdTextKeepJumping.outlineOffsetY = 0.01;
        holdTextKeepJumping.font = this.fontLocation;
        holdTextKeepJumping.text = t("in_game__tutorial__hold");
        holdTextKeepJumping.fontSize = this.defaultTextSize;
        if (globalThis.Lang === "nl") {
            console.log("Adjusting extra for Dutch");
            localOffset.set(-5.25 + 3 * this.globalXOffset, 0.4 + this.globalYOffset, 0);
        } else {
            localOffset.set(-5.25 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialKeepJumping));
        holdTextKeepJumping.position.copy(objWorldSpacePos);
        holdTextKeepJumping.rotation.set(tutorialKeepJumping.rot.x, tutorialKeepJumping.rot.y, tutorialKeepJumping.rot.z);
        holdTextKeepJumping.color = 0xffffff;
        tutorialKeepJumping.texts.push(holdTextKeepJumping);

        // to jump repeatedly text
        const toJumpRepeatedlyTextKeepJumping = new Text();
        toJumpRepeatedlyTextKeepJumping.outlineOffsetX = 0.01;
        toJumpRepeatedlyTextKeepJumping.outlineOffsetY = 0.01;
        toJumpRepeatedlyTextKeepJumping.font = this.fontLocation;
        toJumpRepeatedlyTextKeepJumping.text = t("in_game__tutorial__jump_repeatedly");
        toJumpRepeatedlyTextKeepJumping.fontSize = this.defaultTextSize;
        localOffset.set(2.7 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialKeepJumping));
        toJumpRepeatedlyTextKeepJumping.position.copy(objWorldSpacePos);
        toJumpRepeatedlyTextKeepJumping.rotation.set(tutorialKeepJumping.rot.x, tutorialKeepJumping.rot.y, tutorialKeepJumping.rot.z);
        toJumpRepeatedlyTextKeepJumping.color = 0xffffff;
        tutorialKeepJumping.texts.push(toJumpRepeatedlyTextKeepJumping);

        allTutorialObjs.push(tutorialKeepJumping);

        //CLIMBING TUTORIAL
        const tutorialClimbing = new TutorialObj();
        tutorialClimbing.pos = new Vector3(3.3, 3.5, -43);
        tutorialClimbing.rot = new Vector3(0, Math.PI, 0);
        tutorialClimbing.images = [];
        tutorialClimbing.texts = [];

        //SPACE image
        const spaceClimbingTexture = textureLoader.load(uiLocation + "space_key.png");
        spaceClimbingTexture.colorSpace = SRGBColorSpace;
        const spaceClimbingGeometry = new PlaneGeometry(3.263, 1); // Adjust the size as needed
        const spaceClimbingMaterial = new MeshBasicMaterial({ map: spaceClimbingTexture });
        const tutorialSpaceClimbingImg = new Mesh(spaceClimbingGeometry, spaceClimbingMaterial);
        localOffset.set(0, 0, 0);
        if (globalThis.Lang === "tr") {
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(new Vector3(localOffset.x + 2 * this.globalXOffset, localOffset.y, localOffset.z), tutorialClimbing));
        } else {
            objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialClimbing));
        }        tutorialSpaceClimbingImg.position.copy(objWorldSpacePos);
        tutorialSpaceClimbingImg.rotation.set(tutorialClimbing.rot.x, tutorialClimbing.rot.y, tutorialClimbing.rot.z);
        tutorialKeepJumping.images.push(tutorialSpaceClimbingImg);

        // HOLD text (climbing)
        const holdTextClimbing = new Text();
        holdTextClimbing.outlineOffsetX = 0.01;
        holdTextClimbing.outlineOffsetY = 0.01;
        holdTextClimbing.font = this.fontLocation;
        holdTextClimbing.text = t("in_game__tutorial__hold");
        if (globalThis.Lang === "nl") {
            holdTextClimbing.fontSize = this.defaultTextSize - 0.2;
        } else {
            holdTextClimbing.fontSize = this.defaultTextSize;
        }
        if (globalThis.Lang === "nl") {
            localOffset.set(-6.25 + 2 * this.globalXOffset, 0.4 + this.globalYOffset, 0);
        } else {
            localOffset.set(-5.25 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialClimbing));
        holdTextClimbing.position.copy(objWorldSpacePos);
        holdTextClimbing.rotation.set(tutorialClimbing.rot.x, tutorialClimbing.rot.y, tutorialClimbing.rot.z);
        holdTextClimbing.color = 0xffffff;
        tutorialClimbing.texts.push(holdTextClimbing);

        // To climb walls text
        const toClimbText = new Text();
        toClimbText.outlineOffsetX = 0.01;
        toClimbText.outlineOffsetY = 0.01;
        toClimbText.font = this.fontLocation;
        toClimbText.text = t("in_game__tutorial_wall_climb");
        if (globalThis.Lang === "tr" || globalThis.Lang === "nl") {
            toClimbText.fontSize = this.defaultTextSize - 0.2;
        } else {
            toClimbText.fontSize = this.defaultTextSize;
        }
        if (globalThis.Lang === "tr") {
            localOffset.set(2.7 + 3 * this.globalXOffset, 0.4 + this.globalYOffset, 0);
        } else {
            localOffset.set(2.7 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialClimbing));
        toClimbText.position.copy(objWorldSpacePos);
        toClimbText.rotation.set(tutorialClimbing.rot.x, tutorialClimbing.rot.y, tutorialClimbing.rot.z);
        toClimbText.color = 0xffffff;
        tutorialClimbing.texts.push(toClimbText);

        // WHILE RUNNING text
        const whileRunningText = new Text();
        whileRunningText.outlineOffsetX = 0.01;
        whileRunningText.outlineOffsetY = 0.01;
        whileRunningText.font = this.fontLocation;
        whileRunningText.text = t("in_game__tutorial_while_running");
        whileRunningText.fontSize = this.defaultTextSize;
        localOffset.set(-0.3 + this.globalXOffset, -1 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialClimbing));
        whileRunningText.position.copy(objWorldSpacePos);
        whileRunningText.rotation.set(tutorialClimbing.rot.x, tutorialClimbing.rot.y, tutorialClimbing.rot.z);
        whileRunningText.color = 0xffffff;
        tutorialClimbing.texts.push(whileRunningText);

        allTutorialObjs.push(tutorialClimbing);

        //TIMER TUTORIAL
        const tutorialTimer = new TutorialObj();
        tutorialTimer.pos = new Vector3(-4.5, 0.02, 11.5);
        tutorialTimer.rot = new Vector3(Math.PI / 2, Math.PI, 0);
        tutorialTimer.images = [];
        tutorialTimer.texts = [];

        const timerText = new Text();
        timerText.outlineOffsetX = 0.01;
        timerText.outlineOffsetY = 0.01;
        timerText.font = this.fontLocation;
        timerText.text = t("in_game__tutorial_start_timer");
        timerText.fontSize = 0.8;
        if (globalThis.Lang === "de") {
            localOffset.set(-10 + 3 * this.globalXOffset, 0.4 + this.globalYOffset, 0);
        } else if (globalThis.Lang === "fr") {
            localOffset.set(-10 + 5 * this.globalXOffset, 0.4 + this.globalYOffset, 0);
        } else {
            localOffset.set(-10 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        }        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialTimer));
        timerText.position.copy(objWorldSpacePos);
        timerText.rotation.set(tutorialTimer.rot.x, tutorialTimer.rot.y, tutorialTimer.rot.z);
        timerText.color = 0xffffff;
        tutorialTimer.texts.push(timerText);

        const highscoreText = new Text();
        highscoreText.outlineOffsetX = 0.01;
        highscoreText.outlineOffsetY = 0.01;
        highscoreText.font = this.fontLocation;
        highscoreText.text = t("in_game__how_fast_top");
        highscoreText.fontSize = 0.8;
        if (globalThis.Lang === "fr") {
            localOffset.set(-8 + 4 * this.globalXOffset, -1.25 + this.globalYOffset, 0);
        } else {
            localOffset.set(-8 + this.globalXOffset, -1.25 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialTimer));
        highscoreText.position.copy(objWorldSpacePos);
        highscoreText.rotation.set(tutorialTimer.rot.x, tutorialTimer.rot.y, tutorialTimer.rot.z);
        highscoreText.color = 0xffffff;
        tutorialTimer.texts.push(highscoreText);

        allTutorialObjs.push(tutorialTimer);

        const tutorialStoves = new TutorialObj();
        tutorialStoves.pos = new Vector3(5, 2.6, -81.2);
        tutorialStoves.rot = new Vector3(0, Math.PI, 0);
        tutorialStoves.images = [];
        tutorialStoves.texts = [];

        const stoveText = new Text();
        stoveText.outlineOffsetX = 0.01;
        stoveText.outlineOffsetY = 0.01;
        stoveText.font = this.fontLocation;
        stoveText.text = t("in_game__no_touchy");
        stoveText.fontSize = 1.65;
        if (globalThis.Lang === "de" || globalThis.Lang === "fr" || globalThis.Lang === "nl") {
            localOffset.set(0 + 1.5 * this.globalXOffset, 0 + this.globalYOffset, 0);
        } else {
            localOffset.set(0 - this.globalXOffset, 0 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialStoves));
        stoveText.position.copy(objWorldSpacePos);
        stoveText.rotation.set(tutorialStoves.rot.x, tutorialStoves.rot.y, tutorialStoves.rot.z);
        stoveText.color = 0xffffff;
        tutorialStoves.texts.push(stoveText);

        allTutorialObjs.push(tutorialStoves);

        // maze secret
        const tutorialSecretMaze = new TutorialObj();
        tutorialSecretMaze.pos = new Vector3(90, 36.4, -191.5);
        tutorialSecretMaze.rot = new Vector3(0, Math.PI * 1.5, 0);
        tutorialSecretMaze.images = [];
        tutorialSecretMaze.texts = [];

        const secretMazeText = new Text();
        secretMazeText.outlineOffsetX = 0.01;
        secretMazeText.outlineOffsetY = 0.01;
        secretMazeText.font = this.fontLocation;
        secretMazeText.text = t("in_game__secret_invis_maze");
        if (globalThis.Lang === "nl" || globalThis.Lang === "es") {
            secretMazeText.fontSize = this.defaultTextSize - 0.3;
        } else {
            secretMazeText.fontSize = this.defaultTextSize;
        }
        if (globalThis.Lang === "fr" || globalThis.Lang === "pt" || globalThis.Lang === "nl" || globalThis.Lang === "es") {
            localOffset.set(0 + 2 * this.globalXOffset, 0 + this.globalYOffset, 0);

        } else {
            localOffset.set(0 + this.globalXOffset, 0 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialSecretMaze));
        secretMazeText.position.copy(objWorldSpacePos);
        secretMazeText.rotation.set(tutorialSecretMaze.rot.x, tutorialSecretMaze.rot.y, tutorialSecretMaze.rot.z);
        secretMazeText.color = 0xffffff;
        tutorialSecretMaze.texts.push(secretMazeText);

        allTutorialObjs.push(tutorialSecretMaze);

        // upside down plate secret
        const tutorialSecretPlates = new TutorialObj();
        tutorialSecretPlates.pos = new Vector3(-80, 15, -16);
        tutorialSecretPlates.rot = new Vector3(0, Math.PI * 1.5, 0);
        tutorialSecretPlates.images = [];
        tutorialSecretPlates.texts = [];

        const secretPlatesText = new Text();
        secretPlatesText.outlineOffsetX = 0.01;
        secretPlatesText.outlineOffsetY = 0.01;
        secretPlatesText.font = this.fontLocation;
        secretPlatesText.text = t("in_game__secret_upside_down_plate");
        secretPlatesText.fontSize = this.defaultTextSize;
        if (globalThis.Lang === "es" || globalThis.Lang === "de" || globalThis.Lang === "fr" || globalThis.Lang === "tr" || globalThis.Lang === "nl") {
            localOffset.set(0 + (10 * this.globalXOffset), 0 + this.globalYOffset, 0);
        } else if (globalThis.Lang === "ja") {
            localOffset.set(-1 + this.globalXOffset, 0 + this.globalYOffset, 0);
        } else {
            localOffset.set(0 + this.globalXOffset, 0 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialSecretPlates));
        secretPlatesText.position.copy(objWorldSpacePos);
        secretPlatesText.rotation.set(tutorialSecretPlates.rot.x, tutorialSecretPlates.rot.y, tutorialSecretPlates.rot.z);
        secretPlatesText.color = 0xffffff;
        tutorialSecretPlates.texts.push(secretPlatesText);

        allTutorialObjs.push(tutorialSecretPlates);

        const tutorialInvisibleBlocks = new TutorialObj();
        tutorialInvisibleBlocks.pos = new Vector3(-74.6, 19, 0.5);
        tutorialInvisibleBlocks.rot = new Vector3(0, Math.PI * 0.5, 0);
        tutorialInvisibleBlocks.images = [];
        tutorialInvisibleBlocks.texts = [];

        const invisibleBlocksText = new Text();
        invisibleBlocksText.outlineOffsetX = 0.01;
        invisibleBlocksText.outlineOffsetY = 0.01;
        invisibleBlocksText.font = this.fontLocation;
        invisibleBlocksText.text = t("in_game__tutorial_invis_platforms");
        invisibleBlocksText.fontSize = 1.65;
        localOffset.set(0 + this.globalXOffset, 0 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialInvisibleBlocks));
        invisibleBlocksText.position.copy(objWorldSpacePos);
        invisibleBlocksText.rotation.set(tutorialInvisibleBlocks.rot.x, tutorialInvisibleBlocks.rot.y, tutorialInvisibleBlocks.rot.z);
        invisibleBlocksText.color = 0xffffff;
        tutorialInvisibleBlocks.texts.push(invisibleBlocksText);

        allTutorialObjs.push(tutorialInvisibleBlocks);

        // add all elements from tutorialObj's to the scene
        allTutorialObjs.forEach((element) => {
            element.texts.forEach((myText) => {
                this.scene.add(myText);
            });

            element.images.forEach((myImage) => {
                this.scene.add(myImage);
            });
        });

        // add the arrow to the start line
        const startArrowTexture = textureLoader.load(uiLocation + "start_arrow.png");
        startArrowTexture.colorSpace = SRGBColorSpace;
        startArrowTexture.minFilter = LinearFilter;
        const startArrowGeometry = new PlaneGeometry(15, 15); // Adjust the size as needed
        const startArrowMaterial = new MeshBasicMaterial({ map: startArrowTexture, transparent: true });
        const tutorialStartArrow = new Mesh(startArrowGeometry, startArrowMaterial);

        tutorialStartArrow.position.set(-2.25, 0.05, 1);
        tutorialStartArrow.rotation.set(Math.PI * 0.5, Math.PI, 0);
        this.scene.add(tutorialStartArrow);
    }

    private _drawTutorialTextMobile() {
        const localOffset = new Vector3(0, 0, 0);
        const objWorldSpacePos = new Vector3(0, 0, 0);

        const fontLocation = "fonts/LuckiestGuy-Regular.ttf";
        const uiLocation = "ui/tutorial/";
        const allTutorialObjs: TutorialObj[] = [];
        const textureLoader = Game.Loader.TextureLoader;

        // walk tutorial
        const tutorialWalk = new TutorialObj();
        tutorialWalk.pos = new Vector3(-4, 2.65, -94.2);
        tutorialWalk.rot = new Vector3(0, Math.PI, 0);
        tutorialWalk.images = [];
        tutorialWalk.texts = [];

        // WASD Image
        /*const wasdTexture = textureLoader.load(uiLocation + 'wasd.png');
                const wasdGeometry = new PlaneGeometry(4, 2.8); // Adjust the size as needed
                const wasdMaterial = new MeshBasicMaterial({ map: wasdTexture, transparent: true, alphaTest: 0.5, side: DoubleSide  });
                const tutorialWASDImg = new Mesh(wasdGeometry, wasdMaterial);
                localOffset.set(0,0,0);
                objWorldSpacePos.copy(this.GetPosLocalToTutorialObj(localOffset,tutorialWalk));
                tutorialWASDImg.position.copy(objWorldSpacePos);
                tutorialWASDImg.rotation.set(tutorialWalk.rot.x, tutorialWalk.rot.y, tutorialWalk.rot.z);
                tutorialWalk.images.push(tutorialWASDImg);*/

        // PRESS text
        const pressTextWalk = new Text();
        pressTextWalk.outlineOffsetX = 0.01;
        pressTextWalk.outlineOffsetY = 0.01;
        pressTextWalk.font = fontLocation;
        pressTextWalk.text = t("in_game__tutorial_running");
        pressTextWalk.fontSize = this.defaultTextSize;
        localOffset.set(-6 + this.globalXOffset, 0.25 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialWalk));
        pressTextWalk.position.copy(objWorldSpacePos);
        pressTextWalk.rotation.set(tutorialWalk.rot.x, tutorialWalk.rot.y, tutorialWalk.rot.z);
        pressTextWalk.color = 0xffffff;
        tutorialWalk.texts.push(pressTextWalk);

        // TO WALK text
        /*const toWalkText = new Text();
                toWalkText.font = fontLocation;
                toWalkText.text = "To run";
                toWalkText.fontSize =  this.defaultTextSize;

                localOffset.set(2.7,-0.4,0);
                objWorldSpacePos.copy(this.GetPosLocalToTutorialObj(localOffset,tutorialWalk));
                toWalkText.position.copy(objWorldSpacePos);
                toWalkText.rotation.set(tutorialWalk.rot.x, tutorialWalk.rot.y, tutorialWalk.rot.z);
                toWalkText.color = 0xffffff;
                tutorialWalk.texts.push(toWalkText);*/

        allTutorialObjs.push(tutorialWalk);

        // jump tutorial
        const tutorialJump = new TutorialObj();
        tutorialJump.pos = new Vector3(0.3, 1.1, -62);
        tutorialJump.rot = new Vector3(0, Math.PI, 0);
        tutorialJump.images = [];
        tutorialJump.texts = [];

        // SPACE image
        const texture = textureLoader.load(uiLocation + "mobileJump.png");
        texture.colorSpace = SRGBColorSpace;
        const geometry = new PlaneGeometry(3, 3); // Adjust the size as needed
        const material = new MeshBasicMaterial({ map: texture, transparent: true, alphaTest: 0.5 });
        const tutorialJumpSpacebarImg = new Mesh(geometry, material);
        localOffset.set(0, -0.1, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialJump));
        tutorialJumpSpacebarImg.position.copy(objWorldSpacePos);
        tutorialJumpSpacebarImg.rotation.set(tutorialJump.rot.x, tutorialJump.rot.y, tutorialJump.rot.z);
        tutorialJump.images.push(tutorialJumpSpacebarImg);

        // "Press" text
        const pressText = new Text();
        pressText.outlineOffsetX = 0.01;
        pressText.outlineOffsetY = 0.01;
        pressText.font = fontLocation;
        pressText.text = t("in_game__tutorial_tap");
        pressText.fontSize = this.defaultTextSize;
        localOffset.set(-4.3 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialJump));
        pressText.position.copy(objWorldSpacePos);
        pressText.rotation.set(tutorialJump.rot.x, tutorialJump.rot.y, tutorialJump.rot.z);
        pressText.color = 0xffffff;
        tutorialJump.texts.push(pressText);

        // "To jump" text
        const toJumpText = new Text();
        toJumpText.outlineOffsetX = 0.01;
        toJumpText.outlineOffsetY = 0.01;
        toJumpText.font = fontLocation;
        toJumpText.text = t("in_game__tutorial__to_jump");
        toJumpText.fontSize = this.defaultTextSize;

        localOffset.set(2.7 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialJump));
        toJumpText.position.copy(objWorldSpacePos);
        toJumpText.rotation.set(tutorialJump.rot.x, tutorialJump.rot.y, tutorialJump.rot.z);
        toJumpText.color = 0xffffff;
        tutorialJump.texts.push(toJumpText);

        //IMPORTANT ADD OBJECT TO LIST OF TUTORIAL ITEMS
        allTutorialObjs.push(tutorialJump);

        //KEEP JUMPING TUTORIAL
        const tutorialKeepJumping = new TutorialObj();
        tutorialKeepJumping.pos = new Vector3(-26.9, 8.75, 2.5);
        tutorialKeepJumping.rot = new Vector3(0, Math.PI / 2, 0);
        tutorialKeepJumping.images = [];
        tutorialKeepJumping.texts = [];

        //SPACE image
        const spaceKeepJumpingTexture = textureLoader.load(uiLocation + "mobileJump.png");
        spaceKeepJumpingTexture.colorSpace = SRGBColorSpace;
        const spaceKeepJumpingGeometry = new PlaneGeometry(3.75, 3.75); // Adjust the size as needed
        const spaceKeepJumpingMaterial = new MeshBasicMaterial({ map: spaceKeepJumpingTexture, transparent: true, alphaTest: 0.5 });
        const tutorialKeepJumpingSpacebarImg = new Mesh(spaceKeepJumpingGeometry, spaceKeepJumpingMaterial);
        localOffset.set(0, 0, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialKeepJumping));
        tutorialKeepJumpingSpacebarImg.position.copy(objWorldSpacePos);
        tutorialKeepJumpingSpacebarImg.rotation.set(tutorialKeepJumping.rot.x, tutorialKeepJumping.rot.y, tutorialJump.rot.z);
        tutorialKeepJumping.images.push(tutorialKeepJumpingSpacebarImg);

        // HOLD Text
        const holdTextKeepJumping = new Text();
        holdTextKeepJumping.outlineOffsetX = 0.01;
        holdTextKeepJumping.outlineOffsetY = 0.01;
        holdTextKeepJumping.font = fontLocation;
        holdTextKeepJumping.text = t("in_game__tutorial__hold");
        holdTextKeepJumping.fontSize = this.defaultTextSize;
        localOffset.set(-5.25 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialKeepJumping));
        holdTextKeepJumping.position.copy(objWorldSpacePos);
        holdTextKeepJumping.rotation.set(tutorialKeepJumping.rot.x, tutorialKeepJumping.rot.y, tutorialKeepJumping.rot.z);
        holdTextKeepJumping.color = 0xffffff;
        tutorialKeepJumping.texts.push(holdTextKeepJumping);

        // to jump repeatedly text
        const toJumpRepeatedlyTextKeepJumping = new Text();
        toJumpRepeatedlyTextKeepJumping.outlineOffsetX = 0.01;
        toJumpRepeatedlyTextKeepJumping.outlineOffsetY = 0.01;
        toJumpRepeatedlyTextKeepJumping.font = fontLocation;
        toJumpRepeatedlyTextKeepJumping.text = t("in_game__tutorial__jump_repeatedly");
        toJumpRepeatedlyTextKeepJumping.fontSize = this.defaultTextSize;
        localOffset.set(2.7 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialKeepJumping));
        toJumpRepeatedlyTextKeepJumping.position.copy(objWorldSpacePos);
        toJumpRepeatedlyTextKeepJumping.rotation.set(tutorialKeepJumping.rot.x, tutorialKeepJumping.rot.y, tutorialKeepJumping.rot.z);
        toJumpRepeatedlyTextKeepJumping.color = 0xffffff;
        tutorialKeepJumping.texts.push(toJumpRepeatedlyTextKeepJumping);

        allTutorialObjs.push(tutorialKeepJumping);

        //CLIMBING TUTORIAL
        const tutorialClimbing = new TutorialObj();
        tutorialClimbing.pos = new Vector3(3.3, 3.5, -43);
        tutorialClimbing.rot = new Vector3(0, Math.PI, 0);
        tutorialClimbing.images = [];
        tutorialClimbing.texts = [];

        //SPACE image
        const spaceClimbingTexture = textureLoader.load(uiLocation + "mobileJump.png");
        spaceClimbingTexture.colorSpace = SRGBColorSpace;
        const spaceClimbingGeometry = new PlaneGeometry(3.75, 3.75); // Adjust the size as needed
        const spaceClimbingMaterial = new MeshBasicMaterial({ map: spaceClimbingTexture, transparent: true, alphaTest: 0.5 });
        const tutorialSpaceClimbingImg = new Mesh(spaceClimbingGeometry, spaceClimbingMaterial);
        localOffset.set(0, 0.4, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialClimbing));
        tutorialSpaceClimbingImg.position.copy(objWorldSpacePos);
        tutorialSpaceClimbingImg.rotation.set(tutorialClimbing.rot.x, tutorialClimbing.rot.y, tutorialClimbing.rot.z);
        tutorialKeepJumping.images.push(tutorialSpaceClimbingImg);

        // HOLD text (climbing)
        const holdTextClimbing = new Text();
        holdTextClimbing.outlineOffsetX = 0.01;
        holdTextClimbing.outlineOffsetY = 0.01;
        holdTextClimbing.font = fontLocation;
        holdTextClimbing.text = t("in_game__tutorial__hold");
        holdTextClimbing.fontSize = this.defaultTextSize;
        localOffset.set(-5.25 + this.globalXOffset, 0.8 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialClimbing));
        holdTextClimbing.position.copy(objWorldSpacePos);
        holdTextClimbing.rotation.set(tutorialClimbing.rot.x, tutorialClimbing.rot.y, tutorialClimbing.rot.z);
        holdTextClimbing.color = 0xffffff;
        tutorialClimbing.texts.push(holdTextClimbing);

        // To climb walls text
        const toClimbText = new Text();
        toClimbText.outlineOffsetX = 0.01;
        toClimbText.outlineOffsetY = 0.01;
        toClimbText.font = fontLocation;
        toClimbText.text = t("in_game__tutorial_wall_climb");
        toClimbText.fontSize = this.defaultTextSize;
        localOffset.set(2.7 + this.globalXOffset, 0.8 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialClimbing));
        toClimbText.position.copy(objWorldSpacePos);
        toClimbText.rotation.set(tutorialClimbing.rot.x, tutorialClimbing.rot.y, tutorialClimbing.rot.z);
        toClimbText.color = 0xffffff;
        tutorialClimbing.texts.push(toClimbText);

        // WHILE RUNNING text
        const whileRunningText = new Text();
        whileRunningText.outlineOffsetX = 0.01;
        whileRunningText.outlineOffsetY = 0.01;
        whileRunningText.font = fontLocation;
        whileRunningText.text = t("in_game__tutorial_while_running");
        whileRunningText.fontSize = this.defaultTextSize;
        localOffset.set(-0.3 + this.globalXOffset, -1.65 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialClimbing));
        whileRunningText.position.copy(objWorldSpacePos);
        whileRunningText.rotation.set(tutorialClimbing.rot.x, tutorialClimbing.rot.y, tutorialClimbing.rot.z);
        whileRunningText.color = 0xffffff;
        tutorialClimbing.texts.push(whileRunningText);

        allTutorialObjs.push(tutorialClimbing);

        //TIMER TUTORIAL
        const tutorialTimer = new TutorialObj();
        tutorialTimer.pos = new Vector3(-4.5, 0.02, 11.5);
        tutorialTimer.rot = new Vector3(Math.PI / 2, Math.PI, 0);
        tutorialTimer.images = [];
        tutorialTimer.texts = [];

        const timerText = new Text();
        timerText.outlineOffsetX = 0.01;
        timerText.outlineOffsetY = 0.01;
        timerText.font = this.fontLocation;
        timerText.text = t("in_game__tutorial_start_timer");
        timerText.fontSize = 0.8;
        if (globalThis.Lang === "de" || globalThis.Lang === "fr") {
            localOffset.set(-0 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        } else {
            localOffset.set(-10 + this.globalXOffset, 0.4 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialTimer));
        timerText.position.copy(objWorldSpacePos);
        timerText.rotation.set(tutorialTimer.rot.x, tutorialTimer.rot.y, tutorialTimer.rot.z);
        timerText.color = 0xffffff;
        tutorialTimer.texts.push(timerText);

        const highscoreText = new Text();
        highscoreText.outlineOffsetX = 0.01;
        highscoreText.outlineOffsetY = 0.01;
        highscoreText.font = this.fontLocation;
        highscoreText.text = t("in_game__how_fast_top");
        highscoreText.fontSize = 0.8;
        if (globalThis.Lang === "fr") {
            localOffset.set(-0 + this.globalXOffset, -1.25 + this.globalYOffset, 0);
        } else {
            localOffset.set(-8 + this.globalXOffset, -1.25 + this.globalYOffset, 0);
        }
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialTimer));
        highscoreText.position.copy(objWorldSpacePos);
        highscoreText.rotation.set(tutorialTimer.rot.x, tutorialTimer.rot.y, tutorialTimer.rot.z);
        highscoreText.color = 0xffffff;
        tutorialTimer.texts.push(highscoreText);

        allTutorialObjs.push(tutorialTimer);

        // TUTORIAL STOVES
        const tutorialStoves = new TutorialObj();
        tutorialStoves.pos = new Vector3(5, 2.6, -81.2);
        tutorialStoves.rot = new Vector3(0, Math.PI, 0);
        tutorialStoves.images = [];
        tutorialStoves.texts = [];

        const stoveText = new Text();
        stoveText.outlineOffsetX = 0.01;
        stoveText.outlineOffsetY = 0.01;
        stoveText.font = fontLocation;
        stoveText.text = t("in_game__no_touchy");
        stoveText.fontSize = 1.65;
        localOffset.set(0 - this.globalXOffset, 0 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialStoves));
        stoveText.position.copy(objWorldSpacePos);
        stoveText.rotation.set(tutorialStoves.rot.x, tutorialStoves.rot.y, tutorialStoves.rot.z);
        stoveText.color = 0xffffff;
        tutorialStoves.texts.push(stoveText);

        allTutorialObjs.push(tutorialStoves);

        //TUTORIAL MOBILE CAMERA
        const tutorialMobileCamera = new TutorialObj();
        tutorialMobileCamera.pos = new Vector3(24, 4, -84.2);
        tutorialMobileCamera.rot = new Vector3(0, Math.PI + 0.8, 0);
        tutorialMobileCamera.images = [];
        tutorialMobileCamera.texts = [];

        const mobileCameraText = new Text();
        mobileCameraText.outlineOffsetX = 0.01;
        mobileCameraText.outlineOffsetY = 0.01;
        mobileCameraText.font = fontLocation;
        mobileCameraText.text = t("in_game__tutorial_drag_move_camera");
        mobileCameraText.fontSize = 1.65;
        localOffset.set(0 + this.globalXOffset, 0 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialMobileCamera));
        mobileCameraText.position.copy(objWorldSpacePos);
        mobileCameraText.rotation.set(tutorialMobileCamera.rot.x, tutorialMobileCamera.rot.y, tutorialMobileCamera.rot.z);
        mobileCameraText.color = 0xffffff;
        tutorialMobileCamera.texts.push(mobileCameraText);

        allTutorialObjs.push(tutorialMobileCamera);

        const tutorialSecretMaze = new TutorialObj();
        tutorialSecretMaze.pos = new Vector3(74, 36.4, -142.5);
        tutorialSecretMaze.rot = new Vector3(0, Math.PI * 1.5, 0);
        tutorialSecretMaze.images = [];
        tutorialSecretMaze.texts = [];

        const secretMazeText = new Text();
        secretMazeText.outlineOffsetX = 0.01;
        secretMazeText.outlineOffsetY = 0.01;
        secretMazeText.font = fontLocation;
        secretMazeText.text = t("in_game__secret_invis_maze");
        secretMazeText.fontSize = this.defaultTextSize;
        localOffset.set(0 + this.globalXOffset, 0 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialSecretMaze));
        secretMazeText.position.copy(objWorldSpacePos);
        secretMazeText.rotation.set(tutorialSecretMaze.rot.x, tutorialSecretMaze.rot.y, tutorialSecretMaze.rot.z);
        secretMazeText.color = 0xffffff;
        tutorialSecretMaze.texts.push(secretMazeText);

        allTutorialObjs.push(tutorialSecretMaze);

        const tutorialSecretPlates = new TutorialObj();
        tutorialSecretPlates.pos = new Vector3(-80, 15, -16);
        tutorialSecretPlates.rot = new Vector3(0, Math.PI * 1.5, 0);
        tutorialSecretPlates.images = [];
        tutorialSecretPlates.texts = [];

        const secretPlatesText = new Text();
        secretPlatesText.outlineOffsetX = 0.01;
        secretPlatesText.outlineOffsetY = 0.01;
        secretPlatesText.font = fontLocation;
        secretPlatesText.text = t("in_game__secret_upside_down_plate");
        secretPlatesText.fontSize = this.defaultTextSize;
        localOffset.set(0 + this.globalXOffset, 0 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialSecretPlates));
        secretPlatesText.position.copy(objWorldSpacePos);
        secretPlatesText.rotation.set(tutorialSecretPlates.rot.x, tutorialSecretPlates.rot.y, tutorialSecretPlates.rot.z);
        secretPlatesText.color = 0xffffff;
        tutorialSecretPlates.texts.push(secretPlatesText);

        allTutorialObjs.push(tutorialSecretPlates);

        const tutorialInvisibleBlocks = new TutorialObj();
        tutorialInvisibleBlocks.pos = new Vector3(-74.6, 19, 0.5);
        tutorialInvisibleBlocks.rot = new Vector3(0, Math.PI * 0.5, 0);
        tutorialInvisibleBlocks.images = [];
        tutorialInvisibleBlocks.texts = [];

        const invisibleBlocksText = new Text();
        invisibleBlocksText.outlineOffsetX = 0.01;
        invisibleBlocksText.outlineOffsetY = 0.01;
        invisibleBlocksText.font = fontLocation;
        invisibleBlocksText.text = t("in_game__tutorial_invis_platforms");
        invisibleBlocksText.fontSize = 1.65;
        localOffset.set(0 + this.globalXOffset, 0 + this.globalYOffset, 0);
        objWorldSpacePos.copy(this._getPosLocalToTutorialObj(localOffset, tutorialInvisibleBlocks));
        invisibleBlocksText.position.copy(objWorldSpacePos);
        invisibleBlocksText.rotation.set(tutorialInvisibleBlocks.rot.x, tutorialInvisibleBlocks.rot.y, tutorialInvisibleBlocks.rot.z);
        invisibleBlocksText.color = 0xffffff;
        tutorialInvisibleBlocks.texts.push(invisibleBlocksText);

        allTutorialObjs.push(tutorialInvisibleBlocks);

        // add all elements from tutorialObj's to the scene
        allTutorialObjs.forEach((element) => {
            element.texts.forEach((myText) => {
                this.scene.add(myText);
            });

            element.images.forEach((myImage) => {
                this.scene.add(myImage);
            });
        });
    }

    private _updatePlayerRotationProxyCubePositionBasedOnMouseMovement(movementX: number, movementY: number) {
        if (this.PlayerRotationProxyCube) {
            const { MOUSE_SENSITIVITY, PER_FRAME_MOVEMENT_CAP } = Config.Camera;
            this.PlayerRotationProxyCube.position.x -= clamp(movementX * MOUSE_SENSITIVITY, -PER_FRAME_MOVEMENT_CAP, PER_FRAME_MOVEMENT_CAP);
            this.PlayerRotationProxyCube.position.y -= clamp(movementY * MOUSE_SENSITIVITY, -PER_FRAME_MOVEMENT_CAP, PER_FRAME_MOVEMENT_CAP);
        }
    }

    private _rotateEmptyBasedOnMouseMovement(movementX: number, __movementY: number) {
        if (this.PlayerRotationProxyCube !== undefined) {
            const { MOUSE_SENSITIVITY, PER_FRAME_MOVEMENT_CAP } = Config.Camera;
            this.PlayerRotationProxyCube.rotation.y -= clamp(movementX * MOUSE_SENSITIVITY, -PER_FRAME_MOVEMENT_CAP, PER_FRAME_MOVEMENT_CAP);
        }
    }

    private _light(): void {
        this.globalIlluminationLight = new AmbientLight(0xffffff, 2.8);
        this.scene.add(this.globalIlluminationLight);

        this.directionalLight = new DirectionalLight(0xffffff, 1.2);
        this.directionalLight.position.set(5, 10, 3);
        this.directionalLight.castShadow = false;

        this.scene.add(this.directionalLight);
    }

    private _initThreeRenderer(): void {
        try {
            const gameContainer = document.getElementById("game-container") as HTMLElement;

            this.Canvas = document.getElementById("game-canvas") as HTMLCanvasElement;

            const rendererContainerWidth = parseInt(window.getComputedStyle(gameContainer).width, 10);

            const rendererContainerHeight = parseInt(window.getComputedStyle(gameContainer).height, 10);

            this.scene = new Scene();

            this.renderer = new WebGLRenderer({ canvas: this.Canvas });

            this.renderer.shadowMap.enabled = true;

            this.renderer.sortObjects = true;

            this.renderer.setSize(rendererContainerWidth, rendererContainerHeight);

            //this.Raycaster = new Raycaster();

            gameContainer.appendChild(this.renderer.domElement);

            // const size = 10000;
            // const divisions = 1000;

            // const gridHelper = new GridHelper(size, divisions);
            // this.scene.add(gridHelper);
        } catch (e) {
            console.error(`Caught an error attempting to initialize the renderer: Code: ${e.code} - Message: ${e.message} Error: ${e}`);
            Game.UI.ShowErrorScreen(t("error_screen__renderer_failed"));
        }
    }

    public GetScene() {
        return this.scene;
    }

    public RemoveModelFromScene(model: Object3D): void {
        this.scene.remove(model);
    }

    public AddModelToScene(model: Object3D): void {
        this.scene.add(model);
    }

    private async _drawSkybox(): Promise<void> {
        // console.log("drawing skybox");

        const skyboxGltf = await Game.Loader.LoadModel("skybox/skybox_geo.glb");

        // console.log("skyboxGltf", skyboxGltf);

        this.skyboxModel = skyboxGltf.scene;

        this.skyboxModel.traverse((node) => {
            // @ts-ignore
            if (node.isMesh) {
                const newMaterial = new MeshBasicMaterial({ color: 0xffffff });

                // @ts-ignore
                // If the original material uses textures, transfer them to the new material
                if (node.material.map) {
                    // @ts-ignore
                    newMaterial.map = node.material.map;
                    // @ts-ignore
                    newMaterial.transparent = node.material.transparent;
                    // @ts-ignore
                    newMaterial.alphaTest = node.material.alphaTest;
                }

                // Apply the new material
                // @ts-ignore
                node.material = newMaterial;
            }
        });

        this.skyboxModel.position.set(0, 600, 0);

        this.skyboxModel.scale.set(150, 150, 150);

        this.skyboxModel.rotation.set(0, 0, 0);

        Game.Renderer.AddModelToScene(this.skyboxModel);
    }

    private _initCamera(): void {
        const { FOV, NEAR_PLANE: NEAR, FAR_PLANE: FAR } = Config.Camera;

        this.threeCamera = new PerspectiveCamera(FOV, window.innerWidth / window.innerHeight, NEAR, FAR);
        this.threeCamera.position.set(0, 0, 0);

        this.thirdPersonCamera = new ThirdPersonCamera(this.threeCamera);

        this.cameraInitialized = true;

        this._drawSkybox();
    }

    public GetMyLocalEntity(): ClientPlayer {
        return this.MyLocalNonPredictedEntity;
    }

    private _initPerformanceStats(): void {
        this.stats = new Stats();

        this.stats.dom.classList.add("debug-stats-graphs");

        this.stats.dom.style.bottom = "0px";
        this.stats.dom.style.top = "unset";
        this.stats.dom.style.left = "unset";
        this.stats.dom.style.right = "0px";

        this.stats.showPanel(0);

        document.body.appendChild(this.stats.dom);
    }

    protected override getSystemName(): string {
        return "Renderer";
    }

    public Update(deltaTimeS: number, __deltaTimeMS: number, __currentTimestamp: number): void {
        try {
            if (Config.Rendering.ENABLE_STATS) {
                this.stats.begin();
            }

            if (!this.cameraInitialized) {
                this._initCamera();
            }

            // Lazily associated our local entity if it isnt already
            if (this.MyLocalNonPredictedEntityNid !== undefined && this.MyLocalNonPredictedEntity === undefined) {
                // It may not be created yet! Try grabbing it by nid
                const entity = Game.GetEntityByNid(this.MyLocalNonPredictedEntityNid);
                // If we successfully found it, that means it exists and is fully initialized
                if (entity) {
                    this.LogInfo("My entity is identified, and we got their actual entity");
                    this.MyLocalNonPredictedEntity = entity as ClientPlayer;
                    this.MyLocalNonPredictedEntity.IdentifyAsMyPlayer();

                    if (Config.Player.ENABLE_PREDICTION === false) {
                        this._addDebugCube(this.MyLocalNonPredictedEntity.Position.x, this.MyLocalNonPredictedEntity.Position.y, this.MyLocalNonPredictedEntity.Position.z);
                    }

                    Game.EmitEvent("Bootstrap::MyServerSideEntityIsFullyCreatedLocally", { myEntity: this.MyLocalNonPredictedEntity });

                    if (Config.Player.ENABLE_PREDICTION) {
                        Game.EmitEvent("Prediction::ReadyToCreatePredictedEntity", { originalCreationPayload: this.MyLocalNonPredictedEntity.originalCreationPayload });
                    }
                }
            }

            // Lazily associate our local predicted entity if it isnt already
            if (Config.Player.ENABLE_PREDICTION) {
                if (this.MyLocalPredictedEntity === undefined && Game.Predictor.GetPredictedEntity() !== undefined) {
                    this.MyLocalPredictedEntity = Game.Predictor.GetPredictedEntity();
                    this._addDebugCube(this.MyLocalPredictedEntity.Position.x, this.MyLocalPredictedEntity.Position.y, this.MyLocalPredictedEntity.Position.z);
                }
            }

            if (this.PlayerRotationProxyCube) {
                if (Config.Player.ENABLE_PREDICTION) {
                    if (this.MyLocalPredictedEntity !== undefined) {
                        this.PlayerRotationProxyCube.position.copy(this.MyLocalPredictedEntity.Position);
                    }
                } else {
                    if (this.MyLocalNonPredictedEntity !== undefined) {
                        this.PlayerRotationProxyCube.position.copy(this.MyLocalNonPredictedEntity.Position);
                    }
                }
            }

            if (this.cameraInitialized && this.cameraFollowingPlayerCharacter === false) {
                if (Config.Player.ENABLE_PREDICTION) {
                    if (this.MyLocalPredictedEntity !== undefined && this.MyLocalPredictedEntity.GetModel() !== undefined) {
                        this.thirdPersonCamera.UpdateTarget(this.PlayerRotationProxyCube);
                        this.cameraFollowingPlayerCharacter = true;

                        Game.EmitEvent("Audio::StartMusic");
                    }
                } else {
                    if (this.MyLocalNonPredictedEntity !== undefined && this.MyLocalNonPredictedEntity.GetModel() !== undefined) {
                        this.thirdPersonCamera.UpdateTarget(this.PlayerRotationProxyCube);
                        this.cameraFollowingPlayerCharacter = true;

                        Game.EmitEvent("Audio::StartMusic");
                    }
                }
            }

            if (this.cameraInitialized) {
                let targetFOV = Config.Camera.FOV;
                const prevFOV = this.threeCamera.fov;
                if (this.MyLocalPredictedEntity !== undefined) {
                    // targetFOV = prevFOV;
                    const speedFactor = this.MyLocalPredictedEntity.getSpeedBlockFactor();
                    if (speedFactor > 1) {
                        targetFOV *= Config.Camera.FOV_SPEED_ADJUST_FACTOR * Math.sqrt(1 + (speedFactor - 1) / 2);
                    } else if (speedFactor < 1) {
                        targetFOV /= Config.Camera.FOV_SPEED_ADJUST_FACTOR * Math.sqrt(1 + (1 - speedFactor) / 2);
                    }
                }

                this.threeCamera.fov = lerp(this.threeCamera.fov, targetFOV, Math.min(Config.Camera.FOV_SPEED_ADJUST_LERP_SPEED * deltaTimeS, 0.8));
                if (Math.abs(this.threeCamera.fov - targetFOV) < 0.01) this.threeCamera.fov = targetFOV;
                if (this.threeCamera.fov !== prevFOV) this.threeCamera.updateProjectionMatrix();

                this.thirdPersonCamera.Update(deltaTimeS);
                this.renderer.render(this.scene, this.threeCamera);

                if (Config.Rendering.ENABLE_STATS) {
                    this.stats.update();
                }
            }

            if (Config.Rendering.ENABLE_STATS) {
                this.stats.end();
            }

            this.particleSystem.update(deltaTimeS);
        } catch (e) {
            console.error(`Caught an error in the renderer: Code: ${e.code} - Message: ${e.message} Error: ${e}`);
        }
    }

    public Cleanup(): void {}

    public Resize({ newWidth, newHeight }: { newWidth: number; newHeight: number }) {
        if (this.threeCamera !== undefined) {
            this.threeCamera.aspect = newWidth / newHeight;
            this.threeCamera.updateProjectionMatrix();
            this.renderer.setSize(newWidth, newHeight, true);
        }
    }
}
