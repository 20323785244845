import { InputCommand, PlayerEntityCreationPayload } from "../../shared/SharedNetcodeSchemas";
import { ITriggerEntity, TriggerTypes } from "../../shared/entities/SharedEntityTypes";
import { Screens } from "../systems/ClientUI";
import { ClientPlayer } from "./ClientPlayer";
import { t } from "../../shared/data/Data_I18N";

export class ClientPlayerPredicted extends ClientPlayer {
    private canPlayJumpSound = true;
    private cameraOrientationAsOfLastCheckpoint: { x: number; y: number; z: number; lateral: number; vertical: number } = { x: 0, y: 0, z: 0, lateral: 0, vertical: 0 };
    private readonly uiPopSeconds: number = 0.75;
    private isShowingUIScreen: boolean = false;
    public ShouldFireGameplayStartEventOnNextMovement: boolean = true;

    private lastBroadcastedHeightFlooredNumber: number = 1;
    private lastBroadcastedHeightToHUD: number = 0;

    public constructor(createPlayerPayload: PlayerEntityCreationPayload) {
        super(createPlayerPayload);
    }

    protected override _jumpSideEffects(): void {
        if (this.canPlayJumpSound) {
            Game.EmitEvent("Audio::PlaySfx", { sfxName: "Jump" });
            this.canPlayJumpSound = false;
        }
    }

    protected override _updateSideEffects(): void {
        const flooredHeight = Math.floor(this.Position.y);

        // Dont update the height in HUD too quickly
        if (Date.now() - this.lastBroadcastedHeightToHUD < 250) return;

        // Dont update the height in HUD if it hasnt changed
        if (flooredHeight === this.lastBroadcastedHeightFlooredNumber) return;

        Game.UI.UpdateHeightValue(flooredHeight);
        this.lastBroadcastedHeightFlooredNumber = flooredHeight;
        this.lastBroadcastedHeightToHUD = Date.now();
    }

    protected override _bounceSideEffects(): void {
        Game.EmitEvent("Audio::PlaySfx", { sfxName: "Bounce" });
    }

    protected override _intentionalRestartEntireRunSideEffects(): void {
        Game.UI.HideRunRestartHUDButtons();

        Game.UI.UpdateRunDuration(0);
        Game.UI.PopRestartedEntireRunText();
        Game.UI.ResetDeaths();
        Game.EmitEvent("Audio::PlaySfx", { sfxName: "Oof" });

        if (Game.Predictor.GetPredictedEntity() !== undefined) {
            Game.EmitEvent("SDK::GameplayStop");

            setTimeout(() => {
                this._popCommercialBreak();
                Game.Predictor.GetPredictedEntity().ShouldFireGameplayStartEventOnNextMovement = true;
            }, 400);
        }
    }

    protected override _intentionalRespawnSideEffects(): void {
        Game.Renderer.PlayerRotationProxyCube.rotation.x = this.cameraOrientationAsOfLastCheckpoint.x;
        Game.Renderer.PlayerRotationProxyCube.rotation.y = this.cameraOrientationAsOfLastCheckpoint.y;
        Game.Renderer.PlayerRotationProxyCube.rotation.z = this.cameraOrientationAsOfLastCheckpoint.z;
        Game.Renderer.SetCameraAngle(this.cameraOrientationAsOfLastCheckpoint.lateral, this.cameraOrientationAsOfLastCheckpoint.vertical);
        Game.EmitEvent("Audio::PlaySfx", { sfxName: "Oof" });
        Game.UI.PopIntentionalRespawnText();
        Game.UI.IncrementDeaths();

        // console.log("intentional respawn side effects!!");

        if (Game.Predictor.GetPredictedEntity() !== undefined) {
            Game.EmitEvent("SDK::GameplayStop");
            this._popCommercialBreak();
            Game.Predictor.GetPredictedEntity().ShouldFireGameplayStartEventOnNextMovement = true;
        }
    }

    public override ProcessInputs(inputCommand: InputCommand): void {
        const { forward, back, left, right, jump } = inputCommand;

        if ((forward || back || left || right || jump) && this.ShouldFireGameplayStartEventOnNextMovement) {
            // console.info("Emitting gameplay start 3!");
            Game.EmitEvent("SDK::GameplayStart");
            this.ShouldFireGameplayStartEventOnNextMovement = false;
        }

        // console.info("Should the charactert jump?", jump);

        super.ProcessInputs(inputCommand);
    }

    protected override _resetUIPopTriggerSideEffect(): void {
        this.uiPopTriggerAcculumator = 0;
        Game.UI.UpdateUIPopAccumulator(0);
        this.isShowingUIScreen = false;
    }

    protected override _isLabelVisible(): boolean {
        return this.currentState === "idle" && this.idleTimer > 750;
    }

    protected override _uiPopTriggerSideEffect(trigger: ITriggerEntity, deltaTimeS: number): void {
        const uiScreenToPop = trigger.TriggerType;

        this.CollidingWithUIPopTrigger = true;

        this.uiPopTriggerAcculumator += deltaTimeS;

        if (this.isShowingUIScreen) return;

        const uiPopPercentage = this.uiPopTriggerAcculumator / this.uiPopSeconds;

        Game.UI.UpdateUIPopAccumulator(uiPopPercentage);

        if (this.uiPopTriggerAcculumator >= this.uiPopSeconds) {
            if (uiScreenToPop === TriggerTypes.UI_PopUp_CharacterSelect) {
                Game.UI.ShowScreen(Screens.CharacterSelect);

                this.isShowingUIScreen = true;
            }

            if (uiScreenToPop === TriggerTypes.UI_PopUp_EndRun) {
                Game.UI.ShowScreen(Screens.RunComplete);
                this.isShowingUIScreen = true;
            }

            if (uiScreenToPop === TriggerTypes.UI_PopUp_NameAndCountrySelect) {
                Game.UI.ShowScreen(Screens.ChangeUsername);
                this.isShowingUIScreen = true;
            }

            if (uiScreenToPop === TriggerTypes.UI_PopUp_TrailSelect) {
                console.log("Character Trail: ", this.characterTrail);

                if (Game.UI.getSelectedTrail() !== Game.Renderer.MyLocalNonPredictedEntity.characterTrail) {
                    Game.UI.setSelectedTrail(Game.Renderer.MyLocalNonPredictedEntity.characterTrail);
                }

                Game.UI.ShowScreen(Screens.TrailSelect);
                this.isShowingUIScreen = true;
            }

            this.ShouldFireGameplayStartEventOnNextMovement = true;
        }
    }

    protected override _deathBlockTriggerSideEffect(): void {
        Game.Renderer.PlayerRotationProxyCube.rotation.x = this.cameraOrientationAsOfLastCheckpoint.x;
        Game.Renderer.PlayerRotationProxyCube.rotation.y = this.cameraOrientationAsOfLastCheckpoint.y;
        Game.Renderer.PlayerRotationProxyCube.rotation.z = this.cameraOrientationAsOfLastCheckpoint.z;
        Game.Renderer.SetCameraAngle(this.cameraOrientationAsOfLastCheckpoint.lateral, this.cameraOrientationAsOfLastCheckpoint.vertical);
        Game.EmitEvent("Audio::PlaySfx", { sfxName: "DeathBlock" });
        Game.EmitEvent("Audio::PlaySfx", { sfxName: "Oof" });
        Game.UI.PopCookedDeathText();
        Game.UI.IncrementDeaths();

        Game.EmitEvent("SDK::GameplayStop");
        this._popCommercialBreak();
    }

    private _popCommercialBreak(): void {
        Game.EmitEvent("SDK::PopCommercialBreakAd");
    }

    protected override _fallingDeathSideEffects(): void {
        Game.Renderer.PlayerRotationProxyCube.rotation.x = this.cameraOrientationAsOfLastCheckpoint.x;
        Game.Renderer.PlayerRotationProxyCube.rotation.y = this.cameraOrientationAsOfLastCheckpoint.y;
        Game.Renderer.PlayerRotationProxyCube.rotation.z = this.cameraOrientationAsOfLastCheckpoint.z;
        Game.Renderer.SetCameraAngle(this.cameraOrientationAsOfLastCheckpoint.lateral, this.cameraOrientationAsOfLastCheckpoint.vertical);
        Game.EmitEvent("Audio::PlaySfx", { sfxName: "Oof" });
        Game.UI.PopFallingDeathText();
        Game.UI.IncrementDeaths();

        if (Game.Predictor.GetPredictedEntity() !== undefined) {
            Game.EmitEvent("SDK::GameplayStop");
            this._popCommercialBreak();
            Game.Predictor.GetPredictedEntity().ShouldFireGameplayStartEventOnNextMovement = true;
        }
    }

    protected override _checkpointSideEffects(): void {
        const { x, y, z } = Game.Renderer.PlayerRotationProxyCube.rotation;
        const { lateral, vertical } = Game.Renderer.GetCameraAngle();

        this.cameraOrientationAsOfLastCheckpoint.x = x;
        this.cameraOrientationAsOfLastCheckpoint.y = y;
        this.cameraOrientationAsOfLastCheckpoint.z = z;
        this.cameraOrientationAsOfLastCheckpoint.lateral = lateral;
        this.cameraOrientationAsOfLastCheckpoint.vertical = vertical;

        Game.EmitEvent("Audio::PlaySfx", { sfxName: "Checkpoint" });
        Game.UI.PopText(t("in_game__checkpoint"));

        // this.BurstParticleEffect(ParticleEffects.Confetti);
    }

    protected override _finishLineTriggerSideEffect(): void {
        Game.EmitEvent("Audio::PlaySfx", { sfxName: "Finish" });
        Game.UI.PopText("Finish!");

        Game.UI.HideRunRestartHUDButtons();

        // this.BurstParticleEffect(ParticleEffects.Confetti);
    }

    protected override _startingLineTriggerSideEffect(): void {
        Game.EmitEvent("Audio::PlaySfx", { sfxName: "Start" });
        Game.UI.PopText("Go!");

        // console.log("@@@ Starting line side effects!");

        Game.UI.ShowRunRestartHUDButtons();

        // this.BurstParticleEffect(ParticleEffects.Confetti);
    }
}
